import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import { GetSites_Minimal, GetSites_MinimalVariables } from '../../../graphql/queries/typings/GetSites_Minimal'
import { useErrorService } from '../errors/ErrorService'
import { Site_Minimal } from './models/Site'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'

type SitesState = {
    error?: QueryError
    sitesLoading: boolean
    sites: Site_Minimal[] | null
}

const useGetSites = (customerId: string): SitesState => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetSites_Minimal, GetSites_MinimalVariables>(queries.GetSites_Minimal, {
        variables: { customerId },
    })

    const errorMessage = 'Erreur lors de la récupération des sites'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const sites = responseData && responseData.sites ? responseData.sites : null

        return {
            error,
            sites,
            sitesLoading: false,
        }
    } else {
        return {
            error,
            sites: null,
            sitesLoading: true,
        }
    }
}

export default useGetSites
