import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import {
    GetGoods_Minimal,
    GetGoods_MinimalVariables,
    GetGoods_Minimal_goods,
} from '../../../graphql/queries/typings/GetGoods_Minimal'
import { useErrorService } from '../errors/ErrorService'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'

type GoodsData = {
    error?: QueryError
    goods: GetGoods_Minimal_goods[] | null
    goodsLoading: boolean
}

type GetGoodsProps = {
    customerId: string
    enterpriseId?: string
}

const useGetGoods = ({ customerId, enterpriseId }: GetGoodsProps): GoodsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetGoods_Minimal, GetGoods_MinimalVariables>(queries.GetGoods_Minimal, {
        variables: {
            customerId,
            enterpriseId,
        },
    })

    const errorMessage = 'Erreur lors de la récupération des biens'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const goods = responseData && responseData.goods ? responseData.goods : null

        return {
            error,
            goods,
            goodsLoading: false,
        }
    } else {
        return {
            error,
            goods: null,
            goodsLoading: true,
        }
    }
}

export default useGetGoods
