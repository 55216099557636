import { Tooltip, IconButton, Icon } from '@material-ui/core'
import { useState } from 'react'
import useSnackBar from '../../../hooks/useSnackBar'
import DocumentEdit, { DocumentEditMode, DocumentForEdit } from '../DocumentForms/Admin/DocumentEdit/DocumentEdit'

type DocumentListAnswer = {
    document: DocumentForEdit
}

const DocumentListAnswer = ({ document }: DocumentListAnswer) => {
    const { openSnack } = useSnackBar()

    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const answerClick = () => {
        setOpenDialog(true)
    }

    const onAnswered = () => {
        openSnack({
            type: 'success',
            message: 'Document ajouté avec succès !',
        })
    }

    return (
        <>
            <Tooltip title="Envoyer le document">
                <IconButton onClick={answerClick} color="primary">
                    <Icon>backup</Icon>
                </IconButton>
            </Tooltip>
            <DocumentEdit
                onDone={onAnswered}
                document={document}
                open={openDialog}
                closeDialog={() => setOpenDialog(false)}
                mode={DocumentEditMode.EDITING}
            />
        </>
    )
}

export default DocumentListAnswer
