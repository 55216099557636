import { gql } from '@apollo/client/core'

export default gql`
    fragment Vehicle_Minimal on Vehicle {
        id
        title
        registration: matriculation
        description
        fleet {
            id
            title
        }
        enterprise {
            id
        }
    }
`
