import { gql } from "@apollo/client/core";

export default gql`
    mutation DeleteContractGuarantee($id: ID!) {
        deleteGuarantee(input: { id: $id }) {
            errors
            status
            statusCode
        }
    }
`
