import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import React, { Fragment } from 'react'
import { EnumUser } from '../../graphql/typings/global_types'
import UserHelper from '../helpers/UserHelper'
import { useAuthUser } from '../hooks/useAuth'

type TableHeaderProps = {
    title: string
    editClick?: () => void
    deleteClick?: () => void
}

// Put it before the <Table> component
const TableHeader = ({ title, editClick, deleteClick }: TableHeaderProps) => {
    const auth = useAuthUser()

    const editComponent =
        auth.user.userType === EnumUser.admin && editClick ? (
            <Grid item>
                <Button className="p-4" variant="text" onClick={editClick} color="primary">
                    <Icon className="mr-2" fontSize="small">
                        edit
                    </Icon>
                    Modifier
                </Button>
            </Grid>
        ) : null

    const deleteComponent =
        auth.user.userType === EnumUser.admin && deleteClick ? (
            <Grid item>
                <Button className="p-4" variant="text" onClick={deleteClick} color="secondary">
                    <Icon className="mr-2" fontSize="small">
                        delete
                    </Icon>
                    Supprimer
                </Button>
            </Grid>
        ) : null

    return (
        <Fragment>
            <Grid container className="items-center justify-between">
                <Grid item style={{ flexGrow: 1 }}>
                    <h5 className="p-4 m-0">{title}</h5>
                </Grid>
                {editComponent}
                {deleteComponent}
            </Grid>
            <Divider />
        </Fragment>
    )
}

export default TableHeader
