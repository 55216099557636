import React from 'react'
import { BreadcrumbSegment } from '../../../../matx/components/Breadcrumb'
import Disaster from '../../../components/customer/disasters/disaster/Disaster'
import { Disaster_Full } from '../../../helpers/data/models/disaster/Disaster'
import RoutesHelper from '../../../helpers/RoutesHelper'

const CustomerDisaster = () => {
    const getBreadcrumbSegments = (disaster: Disaster_Full): BreadcrumbSegment[] => {
        return [
            {
                name: 'Sinistres',
                path: RoutesHelper.views.Customer.disasters.Home.to().pathname,
            },
            {
                name: disaster.title,
                path: RoutesHelper.views.Customer.disasters.Disaster.to({
                    disasterId: disaster.id,
                }).pathname,
            },
        ]
    }

    return (
        <Disaster
            route={RoutesHelper.views.Customer.disasters.Disaster}
            getBreadcrumbSegments={getBreadcrumbSegments}
        />
    )
}

export default CustomerDisaster
