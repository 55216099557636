import { gql } from "@apollo/client/core";
import Site_Minimal from '../Site/Site_Minimal'

export default gql`
    ${Site_Minimal}
    fragment Place_Minimal on Local {
        id
        title
        address
        wallsOwner
        site {
            ...Site_Minimal
        }
    }
`
