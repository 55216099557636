import { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import queries from '../../../../../../graphql/queries'
import {
    GetVehicle_Guarantees,
    GetVehicle_GuaranteesVariables,
    GetVehicle_Guarantees_vehicle,
    GetVehicle_Guarantees_vehicle_guarantees_contract,
} from '../../../../../../graphql/queries/typings/GetVehicle_Guarantees'
import { EnumGuaranteeTarget } from '../../../../../../graphql/typings/global_types'
import ErrorView from '../../../../ErrorView'
import LoadingDisplay from '../../../../LoadingDisplay'
import ObjectContracts, { ContractWrapper } from '../../../contracts/ObjectContracts'

type VehicleContractsProps = {
    vehicleID: string
}

type VehicleContract = GetVehicle_Guarantees_vehicle_guarantees_contract

type FormattedContracts = {
    title: string
    reference: string
    insurance: string
    enterprise: string
    guarantees: string
    baseContract: VehicleContract
}

const VehicleContracts = ({ vehicleID }: VehicleContractsProps) => {
    const queryResult = useQuery<GetVehicle_Guarantees, GetVehicle_GuaranteesVariables>(queries.GetVehicle_Guarantees, {
        variables: {
            id: vehicleID,
        },
    })

    const content = queryResult.loading ? (
        <LoadingDisplay />
    ) : queryResult.error || !queryResult.data ? (
        <ErrorView
            message={
                queryResult.error?.message || 'Une erreur est survenue pendant la récupération des garanties du véhicle'
            }
        />
    ) : (
        <VehicleContractsReady vehicle={queryResult.data.vehicle} />
    )

    return content
}

type VehicleContractsReadyProps = {
    vehicle: GetVehicle_Guarantees_vehicle
}

const VehicleContractsReady = ({ vehicle }: VehicleContractsReadyProps) => {
    const contractsWrappers: ContractWrapper[] = useMemo(() => {
        const contractsWrappers: ContractWrapper[] = []

        vehicle.guarantees.forEach((guarantee) => {
            const contract = guarantee.contract

            const existingWrapper = contractsWrappers.find((wrapper) => wrapper.contract.id === contract.id)

            if (existingWrapper) {
                existingWrapper.guarantees.push(guarantee)
            } else {
                contractsWrappers.push({
                    contract,
                    guarantees: [guarantee],
                })
            }
        })

        return contractsWrappers
    }, [vehicle.guarantees])

    return (
        <ObjectContracts
            title="Contrats sur ce véhicule"
            onSuccessText="Garanties du véhicule modifiées !"
            confirmText="Voulez-vous vraiment retirer ce véhicule de toutes les garanties de ce contrat ?"
            contractsWrappers={contractsWrappers}
            guaranteeTarget={{
                targetId: vehicle.id,
                targetType: EnumGuaranteeTarget.Vehicle,
            }}
        />
    )
}

export default VehicleContracts
