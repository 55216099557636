import React, { Fragment } from 'react'
import AEAPreventionCategoriesList from './AEAPreventionCategoriesList'

const AEAPreventionCategories = () => {
    return (
        <Fragment>
            <div className="m-sm-30">
                <h3 className="m-0 mb-6">Liste des catégories de prévention</h3>
                <AEAPreventionCategoriesList />
            </div>
        </Fragment>
    )
}

export default AEAPreventionCategories
