import { gql } from '@apollo/client/core'

export default gql`
    query GetVehicle_Guarantees($id: ID!) {
        vehicle(id: $id) {
            id
            guarantees {
                id
                ceiling
                deductible
                guaranteeCategory {
                    id
                    title
                    description
                }
                contract {
                    id
                    title
                    reference
                    insurance {
                        id
                        title
                    }
                    enterprise {
                        id
                        title
                    }
                }
            }
        }
    }
`
