import { Dispatch, SetStateAction, useEffect } from 'react'
import { EnumDocumentStatus } from '../../../../../../graphql/typings/global_types'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useCreateDocument } from '../../../../../hooks/useMutations'
import LoadingDisplay from '../../../../LoadingDisplay'
import Spacer from '../../../../Spacer'
import { DocumentAskToCustomerData } from './DocumentAskToCustomer'

type DocumentAskToCustomerUploadingProps = {
    emptyData: DocumentAskToCustomerData
    data: DocumentAskToCustomerData
    setData: Dispatch<SetStateAction<DocumentAskToCustomerData>>
    onDone: () => void
    closeDialog: () => void
}

const DocumentAskToCustomerUploading = ({
    emptyData,
    data,
    setData,
    onDone,
    closeDialog,
}: DocumentAskToCustomerUploadingProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { errorAlert } = useErrorService()
    const createDocumentMutation = useCreateDocument()

    const handleSubmit = async () => {
        if (data.title === null) {
            errorAlert('Veuillez définir un titre au document.', data)
            return
        }
        if (data.category === null) return
        if (data.docElementType === null) return
        if (data.docElementId === null) return

        try {
            //Creating
            const response = await createDocumentMutation.run({
                customerId: customer.id,
                title: data.title,
                category: data.category,
                parentType: data.docElementType,
                parentId: data.docElementId || customer.id,
                status: EnumDocumentStatus.asked_by_admin,
                statusComment: data.statusComment,
                userIDs: data.askedTo,
            })

            onDone()
            setData(emptyData)
            closeDialog()
        } catch (error) {
            errorAlert(error)
        }
    }

    useEffect(() => {
        handleSubmit()
    }, [])

    return (
        <>
            <LoadingDisplay text={'Création de la demande...'} />
            <Spacer />
        </>
    )
}

export default DocumentAskToCustomerUploading
