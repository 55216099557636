import { gql } from '@apollo/client/core'
import Enterprise_Minimal from '../fragments/Enterprise/Enterprise_Minimal'
import Fleet_Minimal from '../fragments/Fleet/Fleet_Minimal'
import Good_Minimal from '../fragments/Good/Good_Minimal'
import Place_Minimal from '../fragments/Place/Place_Minimal'
import Vehicle_Minimal from '../fragments/Vehicle/Vehicle_Minimal'

export default gql`
    ${Fleet_Minimal}
    ${Vehicle_Minimal}
    ${Place_Minimal}
    ${Enterprise_Minimal}
    ${Good_Minimal}
    query GetEnterprise_Elements($enterpriseId: ID!) {
        enterprise(id: $enterpriseId) {
            id
            fleets(considerDescendants: true) {
                ...Fleet_Minimal
            }
            vehicles(considerDescendants: true) {
                ...Vehicle_Minimal
            }
            places: locals(considerDescendants: true) {
                ...Place_Minimal
            }
            descendants {
                ...Enterprise_Minimal
            }
            goods(considerDescendants: true) {
                ...Good_Minimal
            }
        }
    }
`
