import { MenuItem } from '@material-ui/core'
import TextField from '@material-ui/core/TextField/TextField'
import { ReactNode, useMemo } from 'react'
import { EnumDocumentParent } from '../../../../../../../graphql/typings/global_types'
import DocumentHelper from '../../../../../../helpers/DocumentHelper'
import ErrorDisplay from '../../../../../../helpers/errors/ErrorDisplay'
import useSnackBar from '../../../../../../hooks/useSnackBar'
import DMAButtons, { DMAButtonsProps } from './DMAButtons'
import DMA_Else from './DocumentManagerAssociationElse'
import DMA_Fleet from './DocumentManagerAssociationFleet'
import DMA_Goods from './DocumentManagerAssociationGoods'
import DMA_Prevention from './DocumentManagerAssociationPrevention'
import DMA_Vehicles from './DocumentManagerAssociationVehicles'

export type DMA_Data = { docElementType: EnumDocumentParent | null; docElementId: string | null }
export type DMA_SetData = (data: DMA_Data) => void

type DocumentManagerAssociationProps = {
    data: DMA_Data
    setData: DMA_SetData
    closeDialog: () => void
    canGoPrevious: boolean
    goPrevious: () => void
    goNext: () => void
    canGoNext: boolean
}

const DocumentManagerAssociation = ({
    data,
    setData,
    closeDialog,
    goNext,
    goPrevious,
    canGoNext,
    canGoPrevious,
}: DocumentManagerAssociationProps) => {
    const { openSnack } = useSnackBar()

    const handleElementCategoryChange = (value: string) => {
        const valueAsCategory = value as EnumDocumentParent

        setData({
            docElementType: valueAsCategory,
            docElementId: null,
        })
    }

    const setNext = () => {
        if (!data.docElementType) {
            openSnack({
                type: 'error',
                message: "Veuillez saisir un type d'association avant de continuer.",
            })
            return
        }

        if (!data.docElementId) {
            openSnack({
                type: 'error',
                message: 'Veuillez saisir un élément à associer avec le document avant de continuer.',
            })
            return
        }

        goNext()
    }

    const buttonsProps: DMAButtonsProps = {
        closeDialog,
        goNext: setNext,
        goPrevious,
        canGoNext,
        canGoPrevious,
    }

    const elementInput: ReactNode = useMemo(() => {
        // Project is the default parent type -- used when the user submits a file
        if (!data.docElementType || data.docElementType === EnumDocumentParent.Project) {
            return <DMAButtons {...buttonsProps} />
        }

        switch (data.docElementType) {
            case EnumDocumentParent.Prevention:
                return <DMA_Prevention data={data} setData={setData} {...buttonsProps} />
            case EnumDocumentParent.Fleet:
                return <DMA_Fleet data={data} setData={setData} {...buttonsProps} />
            case EnumDocumentParent.Vehicle:
                return <DMA_Vehicles data={data} setData={setData} {...buttonsProps} />
            case EnumDocumentParent.Good:
                return <DMA_Goods data={data} setData={setData} {...buttonsProps} />
            case EnumDocumentParent.Enterprise:
                return <DMA_Else data={data} setData={setData} {...buttonsProps} />
            case EnumDocumentParent.Contract:
                return <DMA_Else data={data} setData={setData} {...buttonsProps} />
            case EnumDocumentParent.Disaster:
                return <DMA_Else data={data} setData={setData} {...buttonsProps} />
            case EnumDocumentParent.Local:
                return <DMA_Else data={data} setData={setData} {...buttonsProps} />
            default:
                return <ErrorDisplay message="Erreur : type d'élément non reconnu" debug={data} />
        }
    }, [data.docElementType, data, setData])

    return (
        <>
            <TextField
                margin="dense"
                id="type"
                label="Associer à :"
                type="text"
                fullWidth
                value={data.docElementType || ''}
                onChange={(event) => handleElementCategoryChange(event.target.value)}
                select>
                {Object.values(EnumDocumentParent)
                    .sort((docParentA, docParentB) => {
                        const labelA = DocumentHelper.getDocumentElementCategoryLabel(docParentA)
                        const labelB = DocumentHelper.getDocumentElementCategoryLabel(docParentB)
                        return labelA.localeCompare(labelB)
                    })
                    .map((docElementCategory, index) => {
                        // We don't display sites because they don't have linked documents for now
                        if (docElementCategory === EnumDocumentParent.Site) return null
                        // We don't display project/customer because we want the user
                        // To focus an enterprise by default
                        if (docElementCategory === EnumDocumentParent.Project) return null

                        return (
                            <MenuItem value={docElementCategory} key={index}>
                                {DocumentHelper.getDocumentElementCategoryLabel(docElementCategory)}
                            </MenuItem>
                        )
                    })}
            </TextField>
            {elementInput}
        </>
    )
}

export default DocumentManagerAssociation
