import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import React, { Fragment } from 'react'
import ElevatedCard from '../../../../ElevatedCard'
import PlaceGeneralData from './PlaceGeneralData'

const PlaceGeneral = () => {
    const downloadLeaseAgreement = () => {
        const link = document.createElement('a')
        link.download = `contrat.txt`
        let blob = new Blob(['Contrat'], { type: 'text/plain' })
        link.href = URL.createObjectURL(blob)
        link.click()
        URL.revokeObjectURL(link.href)
    }

    return (
        <Fragment>
            {/* <Grid container spacing={3} className="mb-6">
                <Grid item xs={4} sm={4} md={3} lg={2}>
                    <ElevatedCard
                        className="flex-column justify-center items-center py-6 px-8 m-2 cursor-pointer"
                        onClick={downloadLeaseAgreement}>
                        <Fragment>
                            <Icon className="text-40 mb-2">picture_as_pdf</Icon>
                            <h5 className="m-0 text-center">Télécharger le bail</h5>
                        </Fragment>
                    </ElevatedCard>
                </Grid>
            </Grid> */}
            <PlaceGeneralData />
        </Fragment>
    )
}

export default PlaceGeneral
