import Icon from '@material-ui/core/Icon/Icon'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { useState } from 'react'
import useSnackBar from '../../../hooks/useSnackBar'
import RejectDocumentDialog, { DocumentForReject } from '../RejectDocumentDialog'

type DocumentListRejectProps = {
    document: DocumentForReject
}

const DocumentListReject = ({ document }: DocumentListRejectProps) => {
    const { openSnack } = useSnackBar()

    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const rejectDoc = () => {
        setOpenDialog(true)
    }

    const onRejected = () => {
        openSnack({
            type: 'success',
            message: 'Document rejeté.',
        })
    }

    return (
        <>
            <Tooltip title="Rejeter le document">
                <IconButton onClick={rejectDoc} style={{ color: 'red' }}>
                    <Icon>clear</Icon>
                </IconButton>
            </Tooltip>
            <RejectDocumentDialog
                onDone={onRejected}
                document={document}
                open={openDialog}
                closeDialog={() => setOpenDialog(false)}
            />
        </>
    )
}

export default DocumentListReject
