import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import React, { Fragment, useState } from 'react'
import { GetEnterprise_Full_enterprise_fleets } from '../../../../../../graphql/queries/typings/GetEnterprise_Full'
import { EnumUser } from '../../../../../../graphql/typings/global_types'
import { getErrorMessage } from '../../../../../../utilsTs'
import IconHelper from '../../../../../helpers/IconHelper'
import VehicleHelper from '../../../../../helpers/VehicleHelper'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useEnterpriseLoadedExisting } from '../../../../../hooks/useEnterprise'
import { useDeleteFleet } from '../../../../../hooks/useMutations'
import useSnackBar from '../../../../../hooks/useSnackBar'
import DataTable from '../../../../DataTable'
import { ConfirmDialog } from '../../../../Dialog'
import DocumentsModal from '../../../../documentsList/DocumentModal/DocumentsModal'
import Panel from '../../../../Panel'
import FleetDialog from './FleetDialog'

type FleetDialogState =
    | {
          open: true
          fleet: GetEnterprise_Full_enterprise_fleets
      }
    | { open: false }

const EnterpriseFleetsList = () => {
    const { enterprise } = useEnterpriseLoadedExisting()
    const { customer } = useCustomerLoadedExisting()
    const { openSnack } = useSnackBar()
    const deleteFleetMutation = useDeleteFleet()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<FleetDialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<FleetDialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [openDocsDialog, setOpenDocsDialog] = useState<FleetDialogState>({ open: false })

    const fleets = enterprise.fleets

    const columns = [
        {
            name: 'title',
            label: 'Nom de la flotte',
        },
        {
            name: 'vehicles',
            label: 'Véhicules de la flotte',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const vehicles = fleets[dataIndex].vehicles

                    return vehicles.map((vehicle) => {
                        const enterpriseVehicle = enterprise.vehicles.find(
                            (enterpriseVehicle) => enterpriseVehicle.id === vehicle.id
                        )

                        if (!enterpriseVehicle) return null

                        return <div>{VehicleHelper.getLabel(enterpriseVehicle)}</div>
                    })
                },
            },
        },
    ]

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'une flotte`,
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `Flotte créée avec succès !`,
        })
    }

    const onEditClick = (fleet: GetEnterprise_Full_enterprise_fleets) => {
        setOpenEditDialog({
            open: true,
            fleet,
        })
    }

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: `Flotte modifiée avec succès !`,
        })
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const fleet = fleets[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Voir les documents">
                    <IconButton onClick={() => setOpenDocsDialog({ open: true, fleet })}>
                        <Icon>{IconHelper.elementIcons.docsIcon}</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Modifier la flotte">
                    <IconButton onClick={() => onEditClick(fleet)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer la flotte">
                    <IconButton onClick={() => deleteFleet(fleet)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const deleteFleet = (fleet: GetEnterprise_Full_enterprise_fleets) => {
        setOpenDeleteDialog({ open: true, fleet })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const response = await deleteFleetMutation.run({
                enterpriseId: enterprise.id,
                customerId: customer.id,
                id: openDeleteDialog.fleet.id,
            })

            if (response.errors.length > 0) {
                throw 'Erreur lors de la suppression de la flotte'
            } else {
                openSnack({
                    type: 'success',
                    message: 'Flotte supprimée avec succès !',
                })
            }
        } catch (error) {
            const message = getErrorMessage(error)
            openSnack({
                type: 'error',
                message,
            })
        } finally {
            setOpenDeleteDialog({ open: false })
            setDeleteLoading(false)
        }
    }

    return (
        <Fragment>
            <Panel>
                <DataTable<GetEnterprise_Full_enterprise_fleets>
                    title={`Flottes de l'entreprise`}
                    icon={IconHelper.elementIcons.fleetIcon}
                    data={fleets}
                    columns={columns}
                    showSearch={true}
                    addFeature={addFeatureData}
                    actionsColumn={actionsColumn}
                />
            </Panel>
            <FleetDialog
                onDone={onCreated}
                open={openAddDialog}
                closeDialog={() => setOpenAddDialog(false)}
                enterpriseId={enterprise.id}
                vehicles={enterprise.vehicles}
            />
            {openEditDialog.open && (
                <FleetDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    fleet={openEditDialog.fleet}
                    enterpriseId={enterprise.id}
                    vehicles={enterprise.vehicles}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={() => setOpenDeleteDialog({ open: false })}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'une flotte"
                    text={`Voulez-vous vraiment supprimer la flotte "${openDeleteDialog.fleet.title}" ? Les véhicles associés ne seront pas supprimés.`}
                    loading={deleteLoading}
                />
            )}
            {openDocsDialog.open && (
                <DocumentsModal
                    open={openDocsDialog.open}
                    closeDialog={() => setOpenDocsDialog({ open: false })}
                    elementType={'fleet'}
                    elementId={openDocsDialog.fleet.id}
                />
            )}
        </Fragment>
    )
}

export default EnterpriseFleetsList
