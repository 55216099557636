import {
    DisasterGeneric_disaster,
    DisasterGeneric_disaster_disasterDamages,
    DisasterGeneric_disaster_payments,
} from '../../graphql/queries/typings/DisasterGeneric'
import { EnumDisasterPaymentStatus, EnumDisasterStatus } from '../../graphql/typings/global_types'
import { uniqMergeId } from '../../utilsTs'
import { GuaranteeItems } from './data/models/contract/Guarantee'
import { Disaster_Minimal } from './data/models/disaster/Disaster'

type DisasterForLoss = Pick<DisasterGeneric_disaster, 'id'> & {
    disasterDamages: Pick<DisasterGeneric_disaster_disasterDamages, 'lost_amount'>[]
}

const getFullLoss = (disaster: DisasterForLoss): number => {
    const fullLoss = disaster.disasterDamages.reduce((value, newDamage) => {
        return value + newDamage.lost_amount
    }, 0)

    return fullLoss
}

type DisasterForPayment = Pick<DisasterGeneric_disaster, 'id'> & {
    payments: Pick<DisasterGeneric_disaster_payments, 'id' | 'amount' | 'paid_at'>[]
}

const getPaymentAlreadyPaidAmount = (disaster: DisasterForPayment): number => {
    const paymentsPaid = disaster.payments.reduce((value, newPayment) => {
        if (newPayment.paid_at) return value + newPayment.amount
        else return value
    }, 0)

    return paymentsPaid
}

const getWaitingPaymentsAmount = (disaster: DisasterForPayment): number => {
    const paymentsWaitingAmount = disaster.payments.reduce((value, newPayment) => {
        if (!newPayment.paid_at) return value + newPayment.amount
        else return value
    }, 0)

    return paymentsWaitingAmount
}

const getFullPaymentAmount = (disaster: DisasterForPayment): number => {
    const paymentsAmount = disaster.payments.reduce((value, newPayment) => {
        return value + newPayment.amount
    }, 0)

    return paymentsAmount
}

const getDisasterElements = (disaster: Disaster_Minimal) => {
    const elements: GuaranteeItems = {
        enterprises: [],
        sites: [],
        places: [],
        goods: [],
        fleets: [],
        vehicles: [],
    }

    disaster.disasterDamages.forEach((disasterDamage) => {
        elements.enterprises = uniqMergeId(elements.enterprises, disasterDamage.enterprises)
        elements.sites = uniqMergeId(elements.sites, disasterDamage.sites)
        elements.places = uniqMergeId(elements.places, disasterDamage.places)
        elements.goods = uniqMergeId(elements.goods, disasterDamage.goods)
        elements.fleets = uniqMergeId(elements.fleets, disasterDamage.fleets)
        elements.vehicles = uniqMergeId(elements.vehicles, disasterDamage.vehicles)
    })

    return elements
}

export const getDisasterStatusLabel = (status: EnumDisasterStatus): string => {
    if (status === EnumDisasterStatus.closed) {
        return 'Clôturé'
    } else if (status === EnumDisasterStatus.open) {
        return 'Ouvert'
    } else if (status === EnumDisasterStatus.waiting_payments) {
        return 'En attente de paiement'
    } else if (status === EnumDisasterStatus.pending_price_calculation) {
        return 'En attente de chiffrage'
    } else {
        return 'Inconnu'
    }
}

export const getDisasterPaymentStatusLabel = (status: EnumDisasterPaymentStatus): string => {
    if (status === EnumDisasterPaymentStatus.checked) {
        return 'Clôturé'
    } else if (status === EnumDisasterPaymentStatus.waiting_docs) {
        return 'En attente de vos justificatifs'
    } else if (status === EnumDisasterPaymentStatus.waiting_payment) {
        return 'En attente de paiement'
    } else {
        return 'Inconnu'
    }
}

const DisasterHelper = {
    getFullLoss,
    getPaymentAlreadyPaidAmount,
    getWaitingPaymentsAmount,
    getFullPaymentAmount,
    getDisasterElements,
    getDisasterStatusLabel,
    getDisasterPaymentStatusLabel,
}

export default DisasterHelper
