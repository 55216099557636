import { gql } from "@apollo/client/core";
import Place_Minimal from '../Place/Place_Minimal'
import Prevention_Minimal from './Prevention_Minimal'

export default gql`
    ${Place_Minimal}
    ${Prevention_Minimal}
    fragment Prevention_PlaceMinimal on Prevention {
        ...Prevention_Minimal
        place: local {
            ...Place_Minimal
        }
    }
`
