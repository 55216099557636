import React from 'react'
import Breadcrumb from '../../../../matx/components/Breadcrumb'
import Disasters from '../../../components/customer/disasters/Disasters'
import IconHelper from '../../../helpers/IconHelper'
import { useContractsLoadedExisting } from '../../../hooks/useContracts'
import useNavContext from '../../../hooks/useNavContext'

const CustomerDisasters = () => {
    const { contracts } = useContractsLoadedExisting()
    const { getCustomerRoute, getDisastersRoute } = useNavContext()

    const fullSegments = [
        {
            name: 'Sinistres',
            icon: IconHelper.elementIcons.disasterIcon,
            path: getDisastersRoute().pathname,
        },
    ]

    const customerRoute = getCustomerRoute()

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Breadcrumb homePath={customerRoute.pathname} routeSegments={fullSegments} />
            </div>
            <Disasters contracts={contracts} />
        </div>
    )
}

export default CustomerDisasters
