import React from 'react'
import Documents from '../../../components/customer/documents/Documents'
import IconHelper from '../../../helpers/IconHelper'
import RoutesHelper from '../../../helpers/RoutesHelper'

const CustomerDocuments = () => {
    return (
        <Documents
            route={RoutesHelper.views.Customer.Documents}
            routeSegments={[{ name: 'Documents', icon: IconHelper.elementIcons.docsIcon }]}
            homePath={RoutesHelper.views.Customer.Home.to().pathname}
        />
    )
}

export default CustomerDocuments
