import { gql } from "@apollo/client/core";
import DisasterCategory from './DisasterCategory'
import DisasterDamage_Minimal from './DisasterDamage/DisasterDamage_Minimal'
import DisasterEvent_Minimal from './DisasterEvent/DisasterEvent_Minimal'
import DisasterPayment_Minimal from './DisasterPayment/DisasterPayment_Minimal'

export default gql`
    ${DisasterDamage_Minimal}
    ${DisasterPayment_Minimal}
    ${DisasterEvent_Minimal}
    ${DisasterCategory}
    fragment Disaster_Minimal on Disaster {
        id
        disasterCategory {
            ...DisasterCategory
        }
        date: occurredAt
        status
        percentage
        amount
        billingDate
        insuranceReference
        internalReference
        comment
        event {
            ...DisasterEvent_Minimal
        }
        title
        disasterDamages: damages {
            ...DisasterDamage_Minimal
        }
        payments: disasterPayments {
            ...DisasterPayment_Minimal
        }
        contract {
            id
            enterprise {
                id
            }
        }
        customer: project {
            id
            title
        }
    }
`
