import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Site.Site_Minimal}
    mutation UpdateSite($id: ID!, $title: String!, $customerId: ID!) {
        updateSite(input: { id: $id, title: $title, projectId: $customerId }) {
            errors
            status
            statusCode
            site {
                ...Site_Minimal
            }
        }
    }
`
