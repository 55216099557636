import { gql } from "@apollo/client/core";

export default gql`
    fragment Insurance_Minimal on Insurance {
        id
        title
        email
        phone
    }
`
