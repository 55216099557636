import IconHelper from '../helpers/IconHelper'
import RoutesHelper from '../helpers/RoutesHelper'

export type Navigation =
    | {
          type: 'link'
          name: string
          path: string
          icon: string
          exactMatch?: boolean
      }
    | {
          type: 'label'
          label: string
      }

export enum NavType {
    aea = 'aea',
    aeaCustomer = 'aea_customer',
    customer = 'customer',
}

export const useNavigation = () => {
    const getAeaRootNavigations = (): Navigation[] => {
        return [
            {
                type: 'link',
                name: 'Synthèse AEA',
                path: RoutesHelper.views.AEA.Home.to().pathname,
                icon: 'dashboard',
            },
            {
                type: 'link',
                name: 'Clients',
                path: RoutesHelper.views.AEA.customers.Home.to().pathname,
                icon: IconHelper.elementIcons.customerIcon,
            },
            {
                type: 'link',
                name: 'Administrateurs',
                path: RoutesHelper.views.AEA.admins.Home.to().pathname,
                icon: IconHelper.elementIcons.adminUsersIcon,
            },
            {
                type: 'link',
                name: 'Assurances',
                path: RoutesHelper.views.AEA.insurances.Home.to().pathname,
                icon: IconHelper.elementIcons.insuranceIcon,
            },
            {
                type: 'link',
                name: 'Evénements',
                path: RoutesHelper.views.AEA.events.Home.to().pathname,
                icon: IconHelper.elementIcons.disasterEventIcon,
            },
            {
                type: 'link',
                name: 'Catégories de prévention',
                path: RoutesHelper.views.AEA.preventionCategories.Home.to().pathname,
                icon: IconHelper.elementIcons.preventionIcon,
            },
            {
                type: 'link',
                name: 'Catégories de sinistres',
                path: RoutesHelper.views.AEA.disasterCategories.Home.to().pathname,
                icon: IconHelper.elementIcons.disasterIcon,
            },
            {
                type: 'link',
                name: 'Catégories de garanties',
                path: RoutesHelper.views.AEA.guaranteeCategories.Home.to().pathname,
                icon: IconHelper.elementIcons.contractIcon,
            },
        ]
    }

    const getAeaCustomerNavigation = (customerId: string): Navigation[] => {
        return [
            {
                type: 'link',
                name: 'Paramètres généraux',
                path: RoutesHelper.views.AEA.Home.to().pathname,
                icon: 'settings',
                exactMatch: true,
            },
            {
                label: 'Client',
                type: 'label',
            },
            {
                type: 'link',
                name: 'Synthèse',
                path: RoutesHelper.views.AEA.customer.Home.to({ customerId: customerId }).pathname,
                icon: 'dashboard',
                exactMatch: true,
            },
            {
                type: 'link',
                name: 'Entreprises',
                path: RoutesHelper.views.AEA.customer.enterprises.Home.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.enterpriseIcon,
            },
            {
                type: 'link',
                name: 'Sites',
                path: RoutesHelper.views.AEA.customer.sites.Home.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.siteIcon,
            },
            {
                type: 'link',
                name: 'Locaux',
                path: RoutesHelper.views.AEA.customer.places.Home.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.placeIcon,
            },
            {
                type: 'link',
                name: 'Véhicules',
                path: RoutesHelper.views.AEA.customer.vehicles.Home.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.vehicleIcon,
            },
            {
                type: 'link',
                name: 'Contrats',
                path: RoutesHelper.views.AEA.customer.contracts.Home.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.contractIcon,
            },
            {
                type: 'link',
                name: 'Sinistres',
                path: RoutesHelper.views.AEA.customer.disasters.Home.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.disasterIcon,
            },
            {
                type: 'link',
                name: 'Documents',
                path: RoutesHelper.views.AEA.customer.Documents.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.docsIcon,
            },
            {
                type: 'link',
                name: 'Préventions',
                path: RoutesHelper.views.AEA.customer.preventions.Home.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.preventionIcon,
            },
            {
                type: 'link',
                name: 'Projets à venir',
                path: RoutesHelper.views.AEA.customer.Roadmap.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.roadmapIcon,
            },
            {
                type: 'link',
                name: 'Utilisateurs',
                path: RoutesHelper.views.AEA.customer.Users.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.usersIcon,
            },
            {
                type: 'link',
                name: 'Contrat client',
                path: RoutesHelper.views.AEA.customer.AdminContract.Home.to({ customerId: customerId }).pathname,
                icon: IconHelper.elementIcons.contractIcon,
            },
        ]
    }

    const getCustomerNavigation = (): Navigation[] => {
        return [
            {
                type: 'link',
                name: 'Synthèse',
                path: RoutesHelper.views.Customer.Home.to().pathname,
                icon: 'dashboard',
                exactMatch: true,
            },
            {
                type: 'link',
                name: 'Entreprises',
                path: RoutesHelper.views.Customer.enterprises.Home.to().pathname,
                icon: IconHelper.elementIcons.enterpriseIcon,
            },
            {
                type: 'link',
                name: 'Locaux',
                path: RoutesHelper.views.Customer.places.Home.to().pathname,
                icon: IconHelper.elementIcons.placeIcon,
            },
            {
                type: 'link',
                name: 'Véhicules',
                path: RoutesHelper.views.Customer.vehicles.Home.to().pathname,
                icon: IconHelper.elementIcons.vehicleIcon,
            },
            {
                type: 'link',
                name: 'Contrats',
                path: RoutesHelper.views.Customer.contracts.Home.to().pathname,
                icon: IconHelper.elementIcons.contractIcon,
            },
            {
                type: 'link',
                name: 'Sinistres',
                path: RoutesHelper.views.Customer.disasters.Home.to().pathname,
                icon: IconHelper.elementIcons.disasterIcon,
            },
            {
                type: 'link',
                name: 'Documents',
                path: RoutesHelper.views.Customer.Documents.to({}).pathname,
                icon: IconHelper.elementIcons.docsIcon,
            },
            {
                type: 'link',
                name: 'Préventions',
                path: RoutesHelper.views.Customer.preventions.Home.to().pathname,
                icon: IconHelper.elementIcons.preventionIcon,
            },
            {
                type: 'link',
                name: 'Projets à venir',
                path: RoutesHelper.views.Customer.Roadmap.to().pathname,
                icon: IconHelper.elementIcons.roadmapIcon,
            },
            {
                type: 'link',
                name: 'Utilisateurs',
                path: RoutesHelper.views.Customer.Users.to().pathname,
                icon: IconHelper.elementIcons.usersIcon,
            },
            {
                type: 'link',
                name: 'Mon contrat avec AEA',
                path: RoutesHelper.views.Customer.AdminContract.Home.to().pathname,
                icon: IconHelper.elementIcons.contractIcon,
            },
        ]
    }

    return { getAeaRootNavigations, getAeaCustomerNavigation, getCustomerNavigation }
}

export default useNavigation
