import Preventions from '../../../components/customer/prevention/Preventions'
import IconHelper from '../../../helpers/IconHelper'
import RoutesHelper from '../../../helpers/RoutesHelper'

const CustomerPreventions = () => {
    return (
        <Preventions
            routeSegments={[{ name: 'Moyens de prévention', icon: IconHelper.elementIcons.preventionIcon }]}
            homePath={RoutesHelper.views.Customer.Home.to().pathname}
        />
    )
}

export default CustomerPreventions
