import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Fleet.Fleet_Documents}
    query GetFleet_Documents($id: ID!) {
        fleet(id: $id) {
            ...Fleet_Documents
        }
    }
`
