import layout1Settings, { LayoutSettings } from './Layout1/Layout1Settings'
import { themeColors } from './MatxTheme/themeColors'
import { createTheme, Theme } from '@material-ui/core/styles'
import { forEach, merge } from 'lodash'
import themeOptions from './MatxTheme/themeOptions'

interface Themes {
    [key: string]: Theme
}

function createMatxThemes() {
    let themes: Themes = {}

    forEach(themeColors, (value, key) => {
        themes[key] = createTheme(merge({}, themeOptions, value) as unknown as Theme)
    })
    return themes
}
const themes = createMatxThemes()

export type MatxSettings = {
    activeLayout: 'layout1'
    activeTheme: string // View all valid theme colors inside MatxTheme/themeColors.js
    perfectScrollbar: boolean

    themes: Themes
    layout1Settings: LayoutSettings // open Layout1/Layout1Settings.js
    secondarySidebar: {
        show: boolean
        open: boolean
        theme: string // View all valid theme colors inside MatxTheme/themeColors.js
    }
    // Footer options
    footer: {
        show: boolean
        fixed: boolean
        theme: string // View all valid theme colors inside MatxTheme/themeColors.js
    }
}

export const MatxLayoutSettings: MatxSettings = {
    activeLayout: 'layout1',
    activeTheme: 'blue', // View all valid theme colors inside MatxTheme/themeColors.js
    perfectScrollbar: false,

    themes: themes,
    layout1Settings, // open Layout1/Layout1Settings.js
    secondarySidebar: {
        show: true,
        open: false,
        theme: 'slateDark1', // View all valid theme colors inside MatxTheme/themeColors.js
    },
    // Footer options
    footer: {
        show: true,
        fixed: false,
        theme: 'slateDark1', // View all valid theme colors inside MatxTheme/themeColors.js
    },
}
