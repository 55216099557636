const elementIcons = {
    enterpriseIcon: 'poll',
    // enterpriseIcon: 'store',
    siteIcon: 'location_on',
    placeIcon: 'apartment',
    goodIcon: 'single_bed',
    vehicleIcon: 'directions_car',
    fleetIcon: 'commute',
    disasterIcon: 'air',
    disasterIndemnitiesIcon: 'payments',
    disasterEventIcon: 'new_releases',
    eventsIcon: 'notification_important',
    preventionIcon: 'security',
    pendingDocsIcon: 'pending_actions',
    docsIcon: 'description',
    roadmapIcon: 'update',
    usersIcon: 'people',
    adminUsersIcon: 'admin_panel_settings',
    contractIcon: 'verified_user',
    insuranceIcon: 'shield',
    customerIcon: 'list_alt',
}

const IconHelper = {
    elementIcons,
}

export default IconHelper
