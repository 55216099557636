import { useMemo } from 'react'

export type ErrorViewProps = {
    message: string
    debug?: any
}

const ErrorView = ({ message, debug }: ErrorViewProps) => {
    console.error('ERROR MESSAGE :  ', message)
    console.error('ERROR DEBUG : ', debug)

    return <div style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>{message}</div>
}

export default ErrorView
