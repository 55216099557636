import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Fleet.Fleet_Minimal}
    query GetFleets_Minimal($customerId: ID!, $enterpriseId: ID) {
        fleets(projectId: $customerId, enterpriseId: $enterpriseId) {
            ...Fleet_Minimal
        }
    }
`
