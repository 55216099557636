import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Agreement.Agreement_Minimal}
    mutation CreateAgreement(
        $enterpriseId: ID!
        $title: String!
        $description: String
        $startAt: String
        $endAt: String
        $amount: Float
        $category: EnumAgreementCategory
        $file: String
    ) {
        createAgreement(
            input: {
                enterpriseId: $enterpriseId
                title: $title
                description: $description
                startAt: $startAt
                endAt: $endAt
                amount: $amount
                category: $category
                file: $file
            }
        ) {
            errors
            status
            statusCode
            agreement {
                ...Agreement_Minimal
            }
        }
    }
`
