import React from 'react'
import { GetContract_Disasters_contract_disasters } from '../../../../../../graphql/queries/typings/GetContract_Disasters'
import { useGetContractDisasters } from '../../../../../helpers/data/getContract'
import ErrorDisplay from '../../../../../helpers/errors/ErrorDisplay'
import { useContractLoadedExisting } from '../../../../../hooks/useContract'
import { useContractsLoadedExisting } from '../../../../../hooks/useContracts'
import DisastersList from '../../../../disaster/DisastersList'
import LoadingDisplay from '../../../../LoadingDisplay'

type ContractDisastersProps = {
    customerId: string
    contractId: string
}

const ContractDisasters = ({ customerId, contractId }: ContractDisastersProps) => {
    // This retrieves only the contract's disasters
    const { contract, contractLoading } = useGetContractDisasters(contractId)

    if (contractLoading) return <LoadingDisplay />
    if (!contract) return <ErrorDisplay message="Problème lors de la récupération des sinistres" debug={contract} />
    else return <ContractDisastersReady disasters={contract.disasters} />
}

type ContractDisastersReadyProps = {
    disasters: GetContract_Disasters_contract_disasters[]
}

const ContractDisastersReady = ({ disasters }: ContractDisastersReadyProps) => {
    const { contracts } = useContractsLoadedExisting()
    const { contract } = useContractLoadedExisting()

    return <DisastersList disasters={disasters} contracts={contracts} contractId={contract.id} />
}

export default ContractDisasters
