import { Typography, useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

export type LoadingDisplayProps = {
    text?: string
    size?: number
}

const LoadingDisplay = ({ size = 5, text }: LoadingDisplayProps) => {
    const theme = useTheme()

    const spacing = theme.spacing(size)

    return (
        <Box className="m-4" style={{ width: '100%', textAlign: 'center' }}>
            <CircularProgress size={spacing} />
            {text && (
                <>
                    <div className="mt-5" />
                    <Typography color="primary" variant="body1">
                        {text}
                    </Typography>
                </>
            )}
        </Box>
    )
}

export default LoadingDisplay
