import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import {
    GetContracts,
    GetContractsVariables,
    GetContracts_contracts,
} from '../../../graphql/queries/typings/GetContracts'
import {
    GetEnterpriseContracts,
    GetEnterpriseContractsVariables,
    GetEnterpriseContracts_contracts,
} from '../../../graphql/queries/typings/GetEnterpriseContracts'
import { DateRange } from '../../components/DateCategoryInput'
import DateHelper from '../DateHelper'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'
import { useErrorService } from '../errors/ErrorService'

type ContractsState = {
    error?: QueryError
    contractsLoading: boolean
    contracts: GetContracts_contracts[] | null
}

type GetContractsProps = {
    customerId: string
    dateRange?: DateRange
}

const useGetContracts = ({ customerId, dateRange }: GetContractsProps): ContractsState => {
    const { errorAlert } = useErrorService()

    const { fromDateToServer } = DateHelper()

    const queryResult = useQuery<GetContracts, GetContractsVariables>(queries.GetContracts, {
        variables: {
            customerId,
            startAtAfter: dateRange && dateRange.startDate ? fromDateToServer(dateRange.startDate) : undefined,
            startAtBefore: dateRange && dateRange.endDate ? fromDateToServer(dateRange.endDate) : undefined,
        },
    })

    const errorMessage = 'Erreur lors de la récupération des contrats'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const contracts = responseData && responseData.contracts ? responseData.contracts : null

        return {
            error,
            contracts,
            contractsLoading: false,
        }
    } else {
        return {
            error,
            contracts: null,
            contractsLoading: true,
        }
    }
}

type UseGetEnterpriseContractsProps = {
    customerId: string
    enterpriseId: string
}

type EnterpriseContractsData = {
    error?: QueryError
    contracts: GetEnterpriseContracts_contracts[] | null
    contractsLoading: boolean
}

export const useGetEnterpriseContracts = ({
    customerId,
    enterpriseId,
}: UseGetEnterpriseContractsProps): EnterpriseContractsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetEnterpriseContracts, GetEnterpriseContractsVariables>(
        queries.GetEnterpriseContracts,
        {
            variables: {
                customerId,
                enterpriseId,
            },
        }
    )

    const errorMessage = "Erreur lors de la récupération des contrats de l'enterprise"
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const contracts = responseData && responseData.contracts ? responseData.contracts : null

        return {
            error,
            contracts,
            contractsLoading: false,
        }
    } else {
        return {
            error,
            contracts: null,
            contractsLoading: true,
        }
    }
}

export default useGetContracts
