import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetCustomer_Users_project_preventions } from '../../../../graphql/queries/typings/GetCustomer_Users'
import IconHelper from '../../../helpers/IconHelper'
import PreventionHelper, { PreventionByCategories } from '../../../helpers/PreventionHelper'
import useNavContext from '../../../hooks/useNavContext'
import DataTable from '../../DataTable'

type PreventionForPreventionList = GetCustomer_Users_project_preventions

type PreventionListProps = {
    preventions: PreventionForPreventionList[]
}

const PreventionList = ({ preventions: allPreventions }: PreventionListProps) => {
    const navigate = useNavigate()

    const { getPlaceRoute } = useNavContext()

    const preventions = useMemo(() => PreventionHelper.getLastPreventions(allPreventions), [allPreventions])
    const preventionCategories = useMemo(() => PreventionHelper.getAllPreventionCategories(preventions), [preventions])
    const preventionsByCategories = useMemo(
        () => PreventionHelper.getLastPreventionsByCategories(preventions),
        [preventions]
    )

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'place',
            label: 'Local',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const place = preventionsByCategories[dataIndex].place
                    return <div>{place.title}</div>
                },
            },
        },
    ]

    const dynamicColumns: MUIDataTableColumnDef[] = useMemo(() => {
        return preventionCategories
            .sort((prevA, prevB) => {
                return prevA.title.localeCompare(prevB.title)
            })
            .map((category) => {
                return {
                    name: category.id,
                    options: {
                        label: `${category.title}${category.description && ` - ${category.description}`}`,
                        customHeadLabelRender: () => {
                            return (
                                <div>
                                    <div>{category.title}</div>
                                    {category.description && <div>({category.description})</div>}
                                </div>
                            )
                        },
                        customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                            const categoriesData = preventionsByCategories[dataIndex].categoriesData

                            const prevention = categoriesData[category.id]

                            if (!prevention) {
                                return '-'
                            }

                            const criticality = PreventionHelper.getPreventionCriticality(prevention)
                            const criticalityColor = PreventionHelper.getPreventionCriticalityColor(criticality)
                            const criticalityText = PreventionHelper.getPreventionCriticalityText(prevention)

                            return (
                                <div style={{ color: criticalityColor }}>
                                    <p>{prevention.description}</p>
                                    <p>{criticalityText}</p>
                                </div>
                            )
                        },
                    },
                }
            })
    }, [preventions])

    const fullColumns = columns.concat(dynamicColumns)

    const onPlaceClick = (preventionByCategory: PreventionByCategories) => {
        navigate(getPlaceRoute(preventionByCategory.place.id, 'prevention'))
    }

    return (
        <Fragment>
            <DataTable<PreventionByCategories>
                title={'Plans de prévention'}
                icon={IconHelper.elementIcons.preventionIcon}
                data={preventionsByCategories}
                columns={fullColumns}
                showSearch={true}
                rowClick={onPlaceClick}
            />
        </Fragment>
    )
}

export default PreventionList
