import { gql } from '@apollo/client/core'
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.Disaster_Minimal}
    query GetDisasters_Minimal($customerId: ID, $enterpriseId: ID, $categoryIds: [ID!], $through: EnumDisasterThrough) {
        disasters(
            projectId: $customerId
            enterpriseId: $enterpriseId
            categoryIds: $categoryIds
            through: $through
            limit: 200
        ) {
            ...Disaster_Minimal
        }
    }
`
