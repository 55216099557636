import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid/Grid'
import Link from '@material-ui/core/Link/Link'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useMemo } from 'react'
import MultiSelect, { MultiSelectProps } from './MultiSelect'

type MultiSelectCheckboxProps = Omit<MultiSelectProps, 'onChange' | 'value'> & {
    availableIds: string[]
    onChange: (selectedValues: string[]) => void
    value: string[]
}

const MultiSelectCheckbox = ({ availableIds, onChange, ...multiSelectProps }: MultiSelectCheckboxProps) => {
    const { getLabel, value } = multiSelectProps

    const isItemChecked = (itemId: string) => {
        return value.includes(itemId)
    }

    const baseSelectChange = (
        event: React.ChangeEvent<{
            name?: string | undefined
            value: unknown
        }>
    ) => {
        event.preventDefault()
        event.stopPropagation()

        const values = event.target.value as string[]

        // The checkAll box is considered as an item and returns undefined
        onChange(values.filter((value) => value !== undefined))
    }

    const onCheckboxClick = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
        event.stopPropagation()
        event.preventDefault()
        return
    }

    const selectAll = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()
        onChange(availableIds)
    }

    const unselectAll = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        event.stopPropagation()
        onChange([])
    }

    const alphaSorting = (itemId1: string, itemId2: string) => {
        const item1 = getLabel(itemId1)
        const item2 = getLabel(itemId2)

        if (item1 < item2) {
            return -1
        } else if (item1 > item2) {
            return 1
        } else return 0
    }

    const sortedAvailableIds = useMemo(() => {
        return availableIds.sort(alphaSorting)
    }, [availableIds])

    return (
        <MultiSelect {...multiSelectProps} onChange={(event) => baseSelectChange(event)}>
            <Box paddingX={2} paddingY={1}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Link
                            underline="always"
                            style={{ cursor: 'pointer' }}
                            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => selectAll(event)}>
                            Tout cocher
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link
                            underline="always"
                            style={{ cursor: 'pointer' }}
                            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => unselectAll(event)}>
                            Tout décocher
                        </Link>
                    </Grid>
                </Grid>
            </Box>
            {sortedAvailableIds.map((itemId) => {
                const isChecked = isItemChecked(itemId)

                return (
                    <MenuItem key={itemId} value={itemId}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    checked={isChecked}
                                    onChange={(event) => onCheckboxClick(event, itemId)}
                                />
                            }
                            label={getLabel(itemId)}
                        />
                    </MenuItem>
                )
            })}
        </MultiSelect>
    )
}

export default MultiSelectCheckbox
