import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useState } from 'react'
import {
    ContractGeneric_contract_guarantees,
    ContractGeneric_contract_guarantees_guaranteeCategory,
} from '../../../../../../graphql/queries/typings/ContractGeneric'
import { EnterpriseGeneric_enterprise } from '../../../../../../graphql/queries/typings/EnterpriseGeneric'
import { GetContract_Full_contract } from '../../../../../../graphql/queries/typings/GetContract_Full'
import { GetEnterprise_Elements_enterprise } from '../../../../../../graphql/queries/typings/GetEnterprise_Elements'
import { EnumGuaranteeTarget, GuaranteeTargetInput } from '../../../../../../graphql/typings/global_types'
import VehicleHelper from '../../../../../helpers/VehicleHelper'
import { useGetEnterpriseElements } from '../../../../../helpers/data/getEnterprise'
import { GuaranteeItems } from '../../../../../helpers/data/models/contract/Guarantee'
import ErrorDisplay from '../../../../../helpers/errors/ErrorDisplay'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useCommonDataLoadedExisting } from '../../../../../hooks/useCommonData'
import { useCreateContractGuarantee, useUpdateContractGuarantee } from '../../../../../hooks/useMutations'
import CustomButton from '../../../../CustomButton'
import { Dialog } from '../../../../Dialog'
import LoadingDisplay from '../../../../LoadingDisplay'
import MultiSelectCheckbox from '../../../../customInputs/MultiSelectCheckbox'

type GuaranteeDialogProps = {
    open: boolean
    guarantee?: GuaranteeForDialog
    contract: GetContract_Full_contract
    closeDialog: () => void
    onDone: (guarantee: GuaranteeForDialog) => void
}

const ContractGuaranteeDialog = (props: GuaranteeDialogProps) => {
    const { enterprise, enterpriseLoading, error } = useGetEnterpriseElements(props.contract.enterprise.id)

    const children = enterpriseLoading ? (
        <LoadingDisplay />
    ) : error ? (
        <ErrorDisplay
            message="Erreur lors du chargement des éléments de l'entreprise"
            queryError={error}
            debug={error}
        />
    ) : !enterprise ? (
        <ErrorDisplay message="Une erreur est survenue pendant la récupération des éléments de l'entreprise" />
    ) : (
        <ContractGuaranteeDialogReady {...props} enterprise={enterprise} />
    )

    return (
        <Dialog open={props.open} onClose={props.closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'une garantie du contrat</DialogTitle>
            {children}
        </Dialog>
    )
}

type Data = {
    guaranteeCategory: GuaranteeCategoryForDialog | null
    ceiling: string | null
    deductible: string | null
    coveredVehiclesIds: string[]
    coveredFleetsIds: string[]
    coveredGoodsIds: string[]
    coveredEnterprisesIds: string[]
    coveredPlacesIds: string[]
}

const emptyGuarantee: Data = {
    guaranteeCategory: null,
    ceiling: null,
    deductible: null,
    coveredVehiclesIds: [],
    coveredFleetsIds: [],
    coveredGoodsIds: [],
    coveredEnterprisesIds: [],
    coveredPlacesIds: [],
}

type GuaranteeDialogReadyProps = GuaranteeDialogProps & {
    enterprise: GetEnterprise_Elements_enterprise
}

export type GuaranteeCategoryForDialog = Pick<ContractGeneric_contract_guarantees_guaranteeCategory, 'id' | 'title'>

export type GuaranteeForDialog = GuaranteeItems &
    Pick<ContractGeneric_contract_guarantees, 'id' | 'ceiling' | 'deductible'> & {
        guaranteeCategory: GuaranteeCategoryForDialog
    }

type EnterpriseForDialog = Pick<EnterpriseGeneric_enterprise, 'id' | 'title'>

const ContractGuaranteeDialogReady = ({
    guarantee,
    contract,
    onDone,
    open,
    closeDialog,
    enterprise,
}: GuaranteeDialogReadyProps) => {
    const createContractGuaranteeMutation = useCreateContractGuarantee()
    const updateContractGuaranteeMutation = useUpdateContractGuarantee()
    const { errorAlert } = useErrorService()

    const { guaranteeCategories } = useCommonDataLoadedExisting()

    const enterprises: EnterpriseForDialog[] = [...enterprise.descendants, contract.enterprise]

    const [data, setData] = useState<Data>(
        guarantee
            ? {
                  ceiling: guarantee.ceiling,
                  deductible: guarantee.deductible,
                  guaranteeCategory: guarantee.guaranteeCategory,
                  coveredVehiclesIds: guarantee.vehicles.map((elem) => elem.id),
                  coveredFleetsIds: guarantee.fleets.map((elem) => elem.id),
                  coveredGoodsIds: guarantee.goods.map((elem) => elem.id),
                  coveredEnterprisesIds: guarantee.enterprises.map((elem) => elem.id),
                  coveredPlacesIds: guarantee.places.map((elem) => elem.id),
              }
            : emptyGuarantee
    )
    const [loading, setLoading] = useState<boolean>(false)

    const getGuaranteeTargetsAsInputs = (): GuaranteeTargetInput[] => {
        const guaranteeTargets: GuaranteeTargetInput[] = []

        const addTargets = (targets: string[], targetType: EnumGuaranteeTarget): void => {
            targets.forEach((targetId) => guaranteeTargets.push({ targetId, targetType }))
        }

        addTargets(data.coveredEnterprisesIds, EnumGuaranteeTarget.Enterprise)
        addTargets(data.coveredPlacesIds, EnumGuaranteeTarget.Local)
        addTargets(data.coveredFleetsIds, EnumGuaranteeTarget.Fleet)
        addTargets(data.coveredGoodsIds, EnumGuaranteeTarget.Good)
        addTargets(data.coveredVehiclesIds, EnumGuaranteeTarget.Vehicle)

        return guaranteeTargets
    }

    const handleSubmit = async () => {
        if (!data.guaranteeCategory) return
        if (!data.ceiling) return
        if (!data.deductible) return

        setLoading(true)

        try {
            if (guarantee) {
                // Update
                const response = await updateContractGuaranteeMutation.run({
                    contractId: contract.id,
                    id: guarantee.id,
                    guaranteeCategoryId: data.guaranteeCategory.id,
                    ceiling: data.ceiling,
                    deductible: data.deductible,
                    guaranteeTargets: getGuaranteeTargetsAsInputs(),
                })

                if (response.guarantee) {
                    onDone(response.guarantee)
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour de la garantie'
                }
            } else {
                // Create
                const response = await createContractGuaranteeMutation.run({
                    contractId: contract.id,
                    guaranteeCategoryId: data.guaranteeCategory.id,
                    ceiling: data.ceiling,
                    deductible: data.deductible,
                    guaranteeTargets: getGuaranteeTargetsAsInputs(),
                })

                if (response.guarantee) {
                    onDone(response.guarantee)
                } else {
                    throw 'Une erreur est survenue pendant la création de la garantie'
                }

                setData(emptyGuarantee)
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const handleChange = (value: string, prop: 'ceiling' | 'deductible') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const handleGuaranteeCategoryChange = (value: string | null) => {
        const guaranteeCategory = guaranteesAsList.find((guarantee) => guarantee.id === value) || null

        if (guaranteeCategory === null) {
            errorAlert('Un problème est survenu pendant la mise à jour de la catégorie de garantie')
        }

        setData((oldData) => {
            return {
                ...oldData,
                guaranteeCategory,
            }
        })
    }

    const handleMultiSelectChange = (
        values: string[],
        prop:
            | 'coveredFleetsIds'
            | 'coveredVehiclesIds'
            | 'coveredGoodsIds'
            | 'coveredEnterprisesIds'
            | 'coveredPlacesIds'
    ) => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: values,
            }
        })
    }

    const getEnterpriseLabel = (id: string): string => {
        const enterprise = enterprises.find((enterprise) => enterprise.id === id)

        if (!enterprise) return 'Enterprise inconnue'
        else return enterprise.title
    }

    const getPlaceLabel = (id: string): string => {
        const place = enterprise.places.find((place) => place.id === id)

        if (!place) return 'Local inconnu'
        else return place.title
    }

    const getFleetLabel = (id: string): string => {
        const fleet = enterprise.fleets.find((fleet) => fleet.id === id)

        if (!fleet) return 'Flotte inconnue'
        else return fleet.title
    }

    const getVehicleLabel = (id: string): string => {
        const vehicle = enterprise.vehicles.find((vehicle) => vehicle.id === id)

        if (!vehicle) return 'Véhicule inconnu'
        else return VehicleHelper.getLabel(vehicle)
    }

    const getGoodLabel = (id: string): string => {
        const good = enterprise.goods.find((good) => good.id === id)

        if (!good) return 'Bien inconnu'
        else return good.title
    }

    // Because the array is frozen in strict mode, you'll need to copy the array before sorting it:
    const guaranteesAsList = guaranteeCategories.slice().sort((categoryA, categoryB) => {
        const titleA = categoryA.title
        const titleB = categoryB.title
        const otherString = 'AUTRES'

        if (titleA === otherString) return 1
        else if (titleB === otherString) return -1

        return titleA.localeCompare(titleB)
    })

    const isFormValid = !!data.guaranteeCategory && !!data.ceiling && !!data.deductible

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'une garantie du contrat</DialogTitle>
            <DialogContent>
                <Autocomplete
                    options={guaranteesAsList}
                    getOptionLabel={(option) => option.title}
                    fullWidth
                    onChange={(event, newValue) => handleGuaranteeCategoryChange(newValue ? newValue.id : null)}
                    value={data.guaranteeCategory || undefined}
                    renderInput={(params) => (
                        <TextField {...params} label="Type de garantie *" margin="dense" type="text" autoFocus />
                    )}
                />
                <TextField
                    margin="dense"
                    id="ceiling"
                    label="Plafond de garantie *"
                    type="text"
                    fullWidth
                    value={data.ceiling || undefined}
                    onChange={(event) => handleChange(event.target.value, 'ceiling')}
                />
                <TextField
                    margin="dense"
                    id="deductible"
                    label="Franchise *"
                    type="text"
                    fullWidth
                    value={data.deductible || undefined}
                    onChange={(event) => handleChange(event.target.value, 'deductible')}
                />
                <MultiSelectCheckbox
                    selectTitle={
                        enterprises.length === 0 ? 'Aucune entreprise pour cette entreprise' : 'Entreprises couvertes'
                    }
                    name="enterprises"
                    value={data.coveredEnterprisesIds}
                    onChange={(selectedValues) => handleMultiSelectChange(selectedValues, 'coveredEnterprisesIds')}
                    getLabel={getEnterpriseLabel}
                    fullWidth
                    disabled={enterprises.length === 0}
                    availableIds={[...enterprises]
                        .sort((enterpriseA, enterpriseB) => {
                            return enterpriseA.title.localeCompare(enterpriseB.title)
                        })
                        .map((enterprise) => enterprise.id)}
                />
                <MultiSelectCheckbox
                    selectTitle={
                        enterprise.places.length === 0 ? 'Aucun local pour cette entreprise' : 'Locaux couverts'
                    }
                    name="places"
                    value={data.coveredPlacesIds}
                    onChange={(selectedValues) => handleMultiSelectChange(selectedValues, 'coveredPlacesIds')}
                    getLabel={getPlaceLabel}
                    fullWidth
                    disabled={enterprise.places.length === 0}
                    availableIds={[...enterprise.places]
                        .sort((placeA, placeB) => {
                            return placeA.title.localeCompare(placeB.title)
                        })
                        .map((place) => place.id)}
                />
                <MultiSelectCheckbox
                    selectTitle={enterprise.goods.length === 0 ? 'Aucun bien pour cette entreprise' : 'Biens couverts'}
                    name="goods"
                    value={data.coveredGoodsIds}
                    onChange={(selectedValues) => handleMultiSelectChange(selectedValues, 'coveredGoodsIds')}
                    getLabel={getGoodLabel}
                    fullWidth
                    disabled={enterprise.goods.length === 0}
                    availableIds={[...enterprise.goods]
                        .sort((goodA, goodB) => {
                            return goodA.title.localeCompare(goodB.title)
                        })
                        .map((good) => good.id)}
                />
                <MultiSelectCheckbox
                    selectTitle={
                        enterprise.fleets.length === 0 ? 'Aucune flotte pour cette entreprise' : 'Flottes couvertes'
                    }
                    name="fleets"
                    value={data.coveredFleetsIds}
                    onChange={(selectedValues) => handleMultiSelectChange(selectedValues, 'coveredFleetsIds')}
                    getLabel={getFleetLabel}
                    fullWidth
                    disabled={enterprise.fleets.length === 0}
                    availableIds={[...enterprise.fleets]
                        .sort((fleetA, fleetB) => {
                            return fleetA.title.localeCompare(fleetB.title)
                        })
                        .map((fleet) => fleet.id)}
                />
                <MultiSelectCheckbox
                    selectTitle={
                        enterprise.vehicles.length === 0 ? 'Aucun véhicule pour cette entreprise' : 'Véhicules couverts'
                    }
                    name="vehicles"
                    value={data.coveredVehiclesIds}
                    onChange={(selectedValues) => handleMultiSelectChange(selectedValues, 'coveredVehiclesIds')}
                    getLabel={getVehicleLabel}
                    fullWidth
                    disabled={enterprise.vehicles.length === 0}
                    availableIds={[...enterprise.vehicles]
                        .sort((vehicleA, vehicleB) => {
                            return vehicleA.title.localeCompare(vehicleB.title)
                        })
                        .map((vehicle) => vehicle.id)}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={closeDialog}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default ContractGuaranteeDialog
