import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Contract.Contract_Documents}
    query GetContract_Documents($contractId: ID!) {
        contract(id: $contractId) {
            ...Contract_Documents
        }
    }
`
