import { gql } from "@apollo/client/core";
import Prevention_PlaceMinimal from '../Prevention/Prevention_PlaceMinimal'
import User_Minimal from '../User/User_Minimal'
import Vehicle_Minimal from '../Vehicle/Vehicle_Minimal'
import Document_Minimal from './Document_Minimal'

export default gql`
    ${Document_Minimal}
    ${User_Minimal}
    ${Prevention_PlaceMinimal}
    ${Vehicle_Minimal}
    fragment Document_User on Document {
        ...Document_Minimal
        askedBy {
            ...User_Minimal
        }
        contract {
            id
            title
        }
        disaster {
            id
            title
        }
        enterprise {
            id
            title
        }
        fleet {
            id
            title
        }
        good {
            id
            title
        }
        place: local {
            id
            title
        }
        prevention {
            ...Prevention_PlaceMinimal
        }
        project {
            id
        }
        site {
            id
            title
        }
        vehicle {
            ...Vehicle_Minimal
        }
    }
`
