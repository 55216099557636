import { gql } from '@apollo/client/core'

export default gql`
    query GetContractsForList($customerID: ID!, $startAtBefore: String, $renewableDateAfter: String) {
        contracts(projectId: $customerID, startAtBefore: $startAtBefore, renewableDateAfter: $renewableDateAfter) {
            id
            title
            insurance {
                id
                title
            }
            guarantees {
                id
                guaranteeCategory {
                    id
                    title
                }
            }
        }
    }
`
