import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import {
    GetCustomer_Users,
    GetCustomer_UsersVariables,
    GetCustomer_Users_project,
} from '../../../graphql/queries/typings/GetCustomer_Users'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'
import { useErrorService } from '../errors/ErrorService'

type CustomerState = {
    error?: QueryError
    customerLoading: boolean
    customer: GetCustomer_Users_project | null
}

const useGetCustomer = (customerID: string): CustomerState => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetCustomer_Users, GetCustomer_UsersVariables>(queries.GetCustomer_Users, {
        variables: { id: customerID },
    })

    const errorMessage = 'Erreur lors de la récupération du client'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const customer = responseData && responseData.project ? responseData.project : null

        return {
            error,
            customer,
            customerLoading: false,
        }
    } else {
        return {
            error,
            customer: null,
            customerLoading: true,
        }
    }
}

export default useGetCustomer
