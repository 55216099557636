import { gql } from "@apollo/client/core";
import Broker_Minimal from '../Broker/Broker_Minimal'
import Enterprise_Minimal from '../Enterprise/Enterprise_Minimal'
import Guarantee_Minimal from '../Guarantee/Guarantee_Minimal'
import Insurance_Minimal from '../Insurance/Insurance_Minimal'
import Insurer_Minimal from '../Insurer/Insurer_Minimal'

export default gql`
    ${Broker_Minimal}
    ${Guarantee_Minimal}
    ${Insurance_Minimal}
    ${Insurer_Minimal}
    ${Enterprise_Minimal}
    fragment Contract_Minimal on Contract {
        id
        reference
        title
        bounty
        startAt
        renewableDate
        renewableNotice
        insurer {
            ...Insurer_Minimal
        }
        broker {
            ...Broker_Minimal
        }
        insurance {
            ...Insurance_Minimal
        }
        guarantees {
            ...Guarantee_Minimal
        }
        enterprise {
            ...Enterprise_Minimal
        }
    }
`
