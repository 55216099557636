import { gql } from "@apollo/client/core";

export default gql`
    query GetAdminDashboardData($startDate: String!, $endDate: String!) {
        dashboardAea(startDate: $startDate, endDate: $endDate) {
            agreementAeaProfit
            disasterAeaProfit
            nbCustomers
            nbDisasters
            nbOpenedDisasters
        }
    }
`
