import { gql } from '@apollo/client/core'

export default gql`
    mutation UpdatePlace(
        $id: ID!
        $title: String!
        $address: String
        $wallsOwner: String!
        $enterpriseId: ID!
        $siteId: ID
    ) {
        updateLocal(
            input: {
                id: $id
                title: $title
                address: $address
                wallsOwner: $wallsOwner
                enterpriseId: $enterpriseId
                siteId: $siteId
            }
        ) {
            errors
            status
            statusCode
            place: local {
                id
                title
                address
                wallsOwner
                enterprise {
                    id
                    title
                    address
                }
                site {
                    id
                    title
                }
            }
        }
    }
`
