import { useQuery } from '@apollo/client'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@material-ui/core'
import { useMemo, useState } from 'react'
import queries from '../../../../graphql/queries'
import {
    GetContractsForList,
    GetContractsForListVariables,
    GetContractsForList_contracts,
} from '../../../../graphql/queries/typings/GetContractsForList'
import { GuaranteeTargetInput } from '../../../../graphql/typings/global_types'
import ContractHelper from '../../../helpers/ContractHelper'
import DateHelper from '../../../helpers/DateHelper'
import ErrorDisplay from '../../../helpers/errors/ErrorDisplay'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useLinkToGuarantee, useUnlinkFromGuarantee } from '../../../hooks/useMutations'
import CustomButton from '../../CustomButton'
import LoadingDisplay from '../../LoadingDisplay'
import MultiSelectCheckbox from '../../customInputs/MultiSelectCheckbox'
import { ObjectGuarantees } from './ObjectContracts'

type ObjectContractDialogProps = {
    open: boolean
    guaranteeTarget: GuaranteeTargetInput
    objectGuarantees: ObjectGuarantees[]
    contractID?: string
    closeDialog: () => void
    onDone: () => void
}

const ObjectContractDialog = (props: ObjectContractDialogProps) => {
    const { open, closeDialog, onDone } = props
    const { customer } = useCustomerLoadedExisting()
    const { fromDateToServer } = DateHelper()
    const dateNow = useMemo(() => {
        return fromDateToServer(new Date())
    }, [])

    const queryResult = useQuery<GetContractsForList, GetContractsForListVariables>(queries.GetContractsForList, {
        variables: {
            customerID: customer.id,
            startAtBefore: dateNow,
            renewableDateAfter: dateNow,
        },
        fetchPolicy: 'cache-and-network',
    })

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    const content = queryLoading ? (
        <LoadingDisplay />
    ) : !responseData ? (
        <ErrorDisplay message="Problème lors de la récupération des contrats" debug={queryResult} />
    ) : (
        <ObjectContractDialogContent contracts={responseData.contracts} {...props} />
    )

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle>Modification des garanties du local</DialogTitle>
            {content}
        </Dialog>
    )
}

type ObjectContractDialogContentProps = Omit<ObjectContractDialogProps, 'open'> & {
    contracts: GetContractsForList_contracts[]
}

const ObjectContractDialogContent = ({
    contracts,
    onDone,
    closeDialog,
    objectGuarantees,
    guaranteeTarget,
    ...props
}: ObjectContractDialogContentProps) => {
    const [contractID, setContractID] = useState<string | null>(
        props.contractID !== undefined ? props.contractID : null
    )
    const [guaranteeIDs, setGuaranteeIDs] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const doLinkGuarantees = useLinkToGuarantee()
    const doUnlinkGuarantees = useUnlinkFromGuarantee()

    const { errorAlert } = useErrorService()

    const handleContractChange = (contractID: string) => {
        setContractID(contractID)
    }

    const handleGuaranteesChange = (values: string[]) => {
        setGuaranteeIDs(values)
    }

    const handleSubmit = async () => {
        if (!currentContract) {
            errorAlert('Vous devez sélectionner un contrat avant de valider !')
            return null
        }

        setLoading(true)

        const existingContractGuarantees: string[] = objectGuarantees
            .filter((guarantee) => guarantee.contract.id === currentContract.id)
            .map((guarantee) => guarantee.id)

        const guaranteesToAdd = guaranteeIDs.filter((guaranteeID) => !existingContractGuarantees.includes(guaranteeID))

        const guaranteesToRemove = existingContractGuarantees.filter(
            (guaranteeID) => !guaranteeIDs.includes(guaranteeID)
        )

        try {
            await doLinkGuarantees.run({
                contractID: currentContract.id,
                guaranteeIds: guaranteesToAdd,
                guaranteeTarget,
            })

            await doUnlinkGuarantees.run({
                contractID: currentContract.id,
                guaranteeIds: guaranteesToRemove,
                guaranteeTarget,
            })

            onDone()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const getGuaranteeLabel = (guaranteeID: string) => {
        if (!currentContract) return 'Inconnu'

        const guarantee = currentContract.guarantees.find((guarantee) => guarantee.id === guaranteeID)

        if (!guarantee) return 'Inconnu'

        return guarantee.guaranteeCategory.title
    }

    const currentContract = useMemo(() => {
        if (contractID === null) {
            setGuaranteeIDs([])
            return null
        }

        const contract = contracts.find((contract) => contract.id === contractID)

        if (!contract) {
            errorAlert('Une erreur est survenue pendant la sélection du contrat', { contractID, contracts })
            setGuaranteeIDs([])
            return null
        }

        const existingGuarantees = objectGuarantees
            .filter((guarantee) => guarantee.contract.id === contract.id)
            .map((guarantee) => guarantee.id)

        setGuaranteeIDs(existingGuarantees)
        return contract
    }, [contractID])

    const isFormValid = useMemo(() => {
        return contractID !== null
    }, [contractID])

    return (
        <>
            <DialogContent>
                <TextField
                    autoFocus
                    label="Contrat"
                    type="text"
                    fullWidth
                    value={contractID || ''}
                    onChange={(event) => handleContractChange(event.target.value)}
                    select
                    disabled={props.contractID !== undefined}
                    className="mb-5">
                    {[...contracts]
                        .sort((contractA, contractB) => {
                            return contractA.title.localeCompare(contractB.title)
                        })
                        .map((contract) => {
                            return (
                                <MenuItem value={contract.id} key={contract.id}>
                                    {ContractHelper.getContractLabel(contract)}
                                </MenuItem>
                            )
                        })}
                </TextField>
                {currentContract && (
                    <MultiSelectCheckbox
                        selectTitle={
                            currentContract.guarantees.length === 0
                                ? 'Aucune garantie pour ce contrat'
                                : 'Garanties actives'
                        }
                        value={guaranteeIDs}
                        onChange={handleGuaranteesChange}
                        getLabel={getGuaranteeLabel}
                        fullWidth
                        disabled={currentContract.guarantees.length === 0}
                        availableIds={[...currentContract.guarantees]
                            .sort((guaranteeA, guaranteeB) => {
                                const labelA = getGuaranteeLabel(guaranteeA.id)
                                const labelB = getGuaranteeLabel(guaranteeB.id)
                                return labelA.localeCompare(labelB)
                            })
                            .map((guarantee) => guarantee.id)}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={closeDialog}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </>
    )
}

export default ObjectContractDialog
