import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { useState } from 'react'
import {
    EnterpriseGeneric_enterprise,
    EnterpriseGeneric_enterprise_places,
} from '../../../../../../graphql/queries/typings/EnterpriseGeneric'
import { GoodGeneric_good, GoodGeneric_good_place } from '../../../../../../graphql/queries/typings/GoodGeneric'
import { PlaceGeneric_place } from '../../../../../../graphql/queries/typings/PlaceGeneric'
import { isStringInputValid } from '../../../../../../utilsTs'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useCreateGood, useUpdateGood } from '../../../../../hooks/useMutations'
import CustomButton from '../../../../CustomButton'
import { Dialog } from '../../../../Dialog'

type GoodDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (good: GoodForDialog) => void
    goodEnterprise: GoodEnterpriseForDialog
    good?: GoodForDialog
}

type GoodData = {
    title: string | null
    placeId: string | null
}

const emptyGood: GoodData = {
    title: null,
    placeId: null,
}

type GoodForDialog = Pick<GoodGeneric_good, 'id' | 'title'> & {
    place: Pick<GoodGeneric_good_place, 'id'> | null
}

type GoodEnterpriseForDialog = Pick<EnterpriseGeneric_enterprise, 'id'> & {
    places: Pick<PlaceGeneric_place, 'id' | 'title'>[]
}

const nullValue = 'null'

const GoodDialog = ({ onDone, open, closeDialog, good, goodEnterprise }: GoodDialogProps) => {
    const [data, setData] = useState<GoodData>(
        good
            ? {
                  title: good.title,
                  placeId: good.place ? good.place.id : null,
              }
            : emptyGood
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createGoodMutation = useCreateGood()
    const updateGoodMutation = useUpdateGood()

    const { errorAlert } = useErrorService()
    const { customer } = useCustomerLoadedExisting()

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title' | 'placeId') => {
        const formattedValue = value === nullValue ? null : value

        setData((oldData) => {
            return {
                ...oldData,
                [prop]: formattedValue,
            }
        })
    }

    const handleSubmit = async () => {
        if (!data.title) return

        setLoading(true)

        try {
            if (good) {
                //Update
                const response = await updateGoodMutation.run({
                    id: good.id,
                    // We must only provide placeId OR enterpriseId
                    enterpriseId: data.placeId ? null : goodEnterprise.id,
                    title: data.title,
                    placeId: data.placeId,
                })

                if (response.good) {
                    onDone(response.good)
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour du bien'
                }
            } else {
                const response = await createGoodMutation.run({
                    parentEnterpriseId: goodEnterprise.id,
                    enterpriseId: data.placeId ? null : goodEnterprise.id,
                    title: data.title,
                    placeId: data.placeId,
                    customerId: customer.id,
                })

                if (response.good) {
                    onDone(response.good)
                } else {
                    throw 'Une erreur est survenue pendant la création du bien'
                }

                setData(emptyGood)
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid = isStringInputValid(data.title)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un bien</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Libellé *"
                    type="text"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    className="mb-5"
                    margin="dense"
                    id="place"
                    label={goodEnterprise.places.length === 0 ? 'Aucun local' : 'Associer à un local ?'}
                    type="text"
                    fullWidth
                    value={data.placeId || nullValue}
                    onChange={(event) => handleChange(event.target.value, 'placeId')}
                    select
                    disabled={goodEnterprise.places.length === 0}>
                    <MenuItem value={nullValue}>Aucun</MenuItem>
                    {[...goodEnterprise.places]
                        .sort((placeA, placeB) => {
                            return placeA.title.localeCompare(placeB.title)
                        })
                        .map((place) => {
                            return (
                                <MenuItem value={place.id} key={place.id}>
                                    {place.title}
                                </MenuItem>
                            )
                        })}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default GoodDialog
