import React, { Fragment } from 'react'
import ContractGeneralData from './ContractGeneralData'

const ContractGeneral = () => {
    const downloadContract = () => {
        const link = document.createElement('a')
        link.download = `contrat.txt`
        let blob = new Blob(['Contrat'], { type: 'text/plain' })
        link.href = URL.createObjectURL(blob)
        link.click()
        URL.revokeObjectURL(link.href)
    }

    const downloadMatrix = () => {
        const link = document.createElement('a')
        link.download = `contrat.txt`
        let blob = new Blob(['Contrat'], { type: 'text/plain' })
        link.href = URL.createObjectURL(blob)
        link.click()
        URL.revokeObjectURL(link.href)
    }

    return (
        <Fragment>
            <ContractGeneralData />
        </Fragment>
    )
}

export default ContractGeneral
