import { useMemo } from 'react'
import { DateRange } from '../components/DateCategoryInput'
import useGetCustomerDashboardData, { GetCustomerDashboardDataProps } from '../helpers/data/getCustomerDashboardData'
import DateHelper from '../helpers/DateHelper'

type UseCustomerDashboardDataProps = Omit<GetCustomerDashboardDataProps, 'startDate' | 'endDate'> & DateRange

const useCustomerDashboardData = ({ startDate, endDate, ...props }: UseCustomerDashboardDataProps) => {
    const { fromDateToServer } = DateHelper()

    const computedStartDate = useMemo(() => (startDate === null ? new Date(0) : startDate), [startDate])
    const computedEndDate = useMemo(() => (endDate === null ? new Date() : endDate), [endDate])

    const formattedStartDate = fromDateToServer(computedStartDate)
    const formattedEndDate = fromDateToServer(computedEndDate)

    return useGetCustomerDashboardData({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        ...props,
    })
}

export default useCustomerDashboardData
