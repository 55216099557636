import { gql } from '@apollo/client/core'

export default gql`
    fragment User_Customers on User {
        id
        firstname
        lastname
        email
        userType
        phone
        avatar: urlToUserAvatar
        customers: projects {
            id
            title
        }
    }
`
