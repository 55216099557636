import { gql } from '@apollo/client/core'
import fragments from '../fragments'

export default gql`
    ${fragments.Contract.Contract_Minimal}
    query GetContracts(
        $customerId: ID!
        $startAtAfter: String
        $startAtBefore: String
        $renewableDateAfter: String
        $renewableDateBefore: String
    ) {
        contracts(
            projectId: $customerId
            startAtAfter: $startAtAfter
            startAtBefore: $startAtBefore
            renewableDateAfter: $renewableDateAfter
            renewableDateBefore: $renewableDateBefore
        ) {
            ...Contract_Minimal
        }
    }
`
