import React, { useMemo, useState } from 'react'
import ErrorDisplay from '../../../helpers/errors/ErrorDisplay'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import useCustomerDashboardData from '../../../hooks/useCustomerDashboardData'
import { DateRange } from '../../DateCategoryInput'
import LoadingDisplay from '../../LoadingDisplay'
import CustomerAdmin from './CustomerAdmin'
import CustomerStats from './CustomerStats'

const defaultDateRange: DateRange = {
    startDate: null,
    endDate: null,
}

const CustomerDashboard = () => {
    const [dateRange, setDateRange] = useState<DateRange>(defaultDateRange)

    const { customer } = useCustomerLoadedExisting()

    const { dashboardData, dashboardDataLoading } = useCustomerDashboardData({
        customerId: customer.id,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    })

    const dataContent = useMemo(
        () =>
            dashboardDataLoading ? (
                <LoadingDisplay />
            ) : !dashboardData ? (
                <ErrorDisplay message="Erreur lors de la récupération des données du dashboard" debug={dashboardData} />
            ) : (
                <CustomerStats dashboardData={dashboardData} />
            ),
        [dashboardDataLoading, dashboardData]
    )

    const onDateRangeChange = (start: Date | null, end: Date | null) => {
        setDateRange((oldDateRange) => {
            return {
                ...oldDateRange,
                startDate: start,
                endDate: end,
            }
        })
    }

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <div className="mb-6 w-full">
                    <CustomerAdmin />
                </div>
                {/* <Grid container style={{ width: '100%' }} justifyContent="flex-end">
                    <Grid item>
                        <DateCategoryInput onDateRangeChange={onDateRangeChange} />
                    </Grid>
                </Grid> */}
                <div className="mb-6 w-full">{dataContent}</div>
            </div>
        </div>
    )
}

export default CustomerDashboard
