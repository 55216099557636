import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Place.Place_Documents}
    query GetPlace_Documents($placeId: ID!) {
        local(id: $placeId) {
            ...Place_Documents
        }
    }
`
