import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Site.Site_Minimal}
    query GetSites_Minimal($customerId: ID!) {
        sites(projectId: $customerId) {
            ...Site_Minimal
        }
    }
`
