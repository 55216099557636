import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.DisasterEvent_Minimal}
    mutation UpdateDisasterEvent($id: ID!, $title: String!) {
        updateEvent(input: { id: $id, title: $title }) {
            errors
            status
            statusCode
            disasterEvent: event {
                ...DisasterEvent_Minimal
            }
        }
    }
`
