import { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { client } from '../../graphql/client'
import queries from '../../graphql/queries'
import {
    GetContract_CoveredElements,
    GetContract_CoveredElementsVariables,
} from '../../graphql/queries/typings/GetContract_CoveredElements'
import { GetDisaster_Full_disaster } from '../../graphql/queries/typings/GetDisaster_Full'
import useGetDisaster from '../helpers/data/getDisaster'
import { Contract_CoveredElements } from '../helpers/data/models/contract/Contract'
import ErrorDisplay from '../helpers/errors/ErrorDisplay'
import { useErrorService } from '../helpers/errors/ErrorService'
import { useAuthUser } from './useAuth'

type DisasterContext = {
    disaster: GetDisaster_Full_disaster | null
    disasterLoading: boolean
    contractCoveredElements: Contract_CoveredElements | null
    contractCoveredElementsLoading: boolean
}

const DisasterContext = createContext<DisasterContext>({
    disaster: null,
    disasterLoading: true,
    contractCoveredElements: null,
    contractCoveredElementsLoading: true,
})

type ProvideDisasterProps = {
    children: ReactElement
    disasterId: string
}

export function ProvideDisaster({ children, disasterId }: ProvideDisasterProps) {
    try {
        const disasterData = useProvideDisaster(disasterId)

        return <DisasterContext.Provider value={disasterData}>{children}</DisasterContext.Provider>
    } catch (error) {
        return <ErrorDisplay message="Une erreur est survenue pendant la récupération du sinistre" debug={error} />
    }
}

export const useDisaster = () => {
    return useContext(DisasterContext)
}

export const useDisasterLoaded = () => {
    const context = useContext(DisasterContext)
    if (context.disasterLoading) {
        console.error('Current disaster is loading !')
        throw new Error('Current disaster is loading')
    }

    return context
}

export const useDisasterLoadedExisting = () => {
    const context = useContext(DisasterContext)
    if (context.disasterLoading) {
        console.error('Current disaster is loading !')
        throw new Error('Current disaster is loading')
    }
    if (context.contractCoveredElementsLoading) {
        console.error('Contract covered elements are loading !')
        throw new Error('Contract covered elements are loading')
    }
    if (!context.disaster) {
        console.error(`No disaster found for id !`)
        throw new Error('No disaster found for id !')
    }
    if (!context.contractCoveredElements) {
        console.error(`Contract covered elements not found for id !`)
        throw new Error('Contract covered elements not found for id !')
    }

    return {
        disaster: context.disaster,
        disasterLoading: context.disasterLoading,
        contractCoveredElements: context.contractCoveredElements,
        contractCoveredElementsLoading: context.contractCoveredElementsLoading,
    }
}

const useProvideDisaster = (disasterId: string) => {
    const auth = useAuthUser()
    const { errorAlert } = useErrorService()

    const { disaster, disasterLoading } = useGetDisaster(disasterId)

    const [contractCoveredElements, setContractCoveredElements] = useState<Contract_CoveredElements | null>(null)
    const [contractCoveredElementsLoading, setContractCoveredElementsLoading] = useState<boolean>(true)

    const getContractCoveredElements = async () => {
        if (!disaster) return

        try {
            setContractCoveredElementsLoading(true)

            const contractId = disaster.contract.id

            const contractCoveredElementsResponse = await client.query<
                GetContract_CoveredElements,
                GetContract_CoveredElementsVariables
            >({
                query: queries.GetContract_CoveredElements,
                variables: { contractId },
            })

            if (!contractCoveredElementsResponse.data || !contractCoveredElementsResponse.data.contract) {
            }

            setContractCoveredElements(contractCoveredElementsResponse.data.contract)
            setContractCoveredElementsLoading(false)
        } catch (error) {
            errorAlert(`Erreur lors de la récupération du sinistre ${disasterId}`)
        }
    }

    // Is only triggered when disaster has been loaded
    useEffect(() => {
        if (!disaster) return

        getContractCoveredElements()
    }, [auth, disaster])

    return {
        disaster,
        disasterLoading,
        contractCoveredElements,
        contractCoveredElementsLoading,
    }
}

export default useDisaster
