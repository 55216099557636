import { Card, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { ReactNode } from 'react'
import LogURL from '../../../assets/images/aea-custom/AEAE_LOGO_RVB_medium.png'
import TribeURL from '../../../assets/images/aea-custom/aea_tribe.webp'
import BackgroundURL from '../../../assets/images/aea-custom/austral-expert-dassure-nutshell.png'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    cardHolder: {
        background: '#1A2038',
    },
    card: {
        maxWidth: 800,
        margin: '1rem',
    },
    cardLeft: {
        background: '#161c37 url(/assets/images/bg-3.png) no-repeat',
        backgroundSize: 'cover',
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            minWidth: 200,
        },
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            fontSize: 26,
            lineHeight: 1.3,
            fontWeight: 800,
        },
    },
    mainTitle: {
        fontSize: 18,
        lineHeight: 1.3,
        marginBottom: 24,
        marginTop: 10,
    },
    features: {
        '& .item': {
            position: 'relative',
            marginBottom: 12,
            paddingLeft: 20,
            '&::after': {
                position: 'absolute',
                content: '""',
                width: 4,
                height: 4,
                borderRadius: 4,
                left: 4,
                top: 7,
                backgroundColor: palette.error.main,
            },
        },
    },
    socialButton: {
        width: '100%',
        '& img': {
            margin: '0 8px',
        },
    },
}))

type ExternalLinkProps = {
    title?: string
    children: ReactNode
}

const ExternalLinkLayout = ({ children, title }: ExternalLinkProps) => {
    const classes = useStyles()

    return (
        <div
            className={clsx('flex justify-center items-center  min-h-full-screen', classes.cardHolder)}
            style={{
                background: `url(${BackgroundURL})  no-repeat center fixed`,
                backgroundSize: 'cover',
                position: 'relative',
            }}>
            <div
                style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    left: '0',
                    bottom: '0',
                    backgroundColor: 'lightgrey',
                    opacity: '0.6',
                }}
            />
            <Card className={classes.card} style={{ zIndex: 10 }} elevation={12}>
                <Grid container style={{ minHeight: 280 }}>
                    <Grid item lg={6} md={6} sm={5} xs={12}>
                        <div
                            className={clsx({
                                'py-8 px-14 h-full': true,
                                [classes.cardLeft]: true,
                            })}>
                            <div className={classes.logo}>
                                <img src={LogURL} className="mr-2" style={{ width: 24 }} /> <span>Portail AEA</span>
                            </div>
                            <h1 className={classes.mainTitle}>Austral Expert d'Assuré</h1>
                            <div>Vos défenseurs face à tous vos tracas d'assurance</div>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <div className="p-8 h-full relative">
                            {title && (
                                <Typography className="mb-6" variant="h6">
                                    {title}
                                </Typography>
                            )}
                            {children}
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

export default ExternalLinkLayout
