import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid/Grid'
import { GetDisastersIndicators_disasterIndicators } from '../../../../graphql/queries/typings/GetDisastersIndicators'
import MoneyDisplayAnalyzer from '../../customInputs/MoneyDisplayAnalyzer'
import ElevatedCard from '../../ElevatedCard'

type DisastersIndicatorsProps = {
    disastersIndicators: GetDisastersIndicators_disasterIndicators
}

const DisastersIndicators = ({ disastersIndicators }: DisastersIndicatorsProps) => {
    return (
        <Grid container spacing={4}>
            <Grid item>
                <div>
                    <DisastersIndicatorsTitle title="Sur la période sélectionnée" />
                </div>
                <div>
                    <Grid container spacing={4}>
                        <Grid item>
                            <DisastersIndicatorsCard
                                label="Sinistres survenus"
                                value={disastersIndicators.nbDisasters}
                                isCurrency={false}
                            />
                        </Grid>
                        <Grid item>
                            <DisastersIndicatorsCard
                                label="Indemnités versées"
                                value={disastersIndicators.disasterPaymentsPaidAmount}
                                isCurrency={true}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item>
                <div
                    style={{
                        margin: 'auto',
                        width: 3,
                        height: '100%',
                        backgroundColor: 'rgba(52, 49, 76, 0.14)',
                    }}></div>
            </Grid>
            <Grid item>
                <div>
                    <DisastersIndicatorsTitle title="A date d'aujourd'hui" />
                </div>
                <div>
                    <Grid item>
                        <DisastersIndicatorsCard
                            label="Restant à percevoir"
                            value={disastersIndicators.pendingIndemnitiesAmount}
                            isCurrency={true}
                        />
                    </Grid>
                </div>
            </Grid>
        </Grid>
    )
}

type DisastersIndicatorsTitleProps = {
    title: string
}

const DisastersIndicatorsTitle = ({ title }: DisastersIndicatorsTitleProps) => {
    return (
        <Typography variant="body1" className="mb-5 text-muted">
            {title}
        </Typography>
    )
}

type DisastersIndicatorsCardProps = {
    label: string
    value: number
    isCurrency: boolean
}

const DisastersIndicatorsCard = ({ label, value, isCurrency }: DisastersIndicatorsCardProps) => {
    return (
        <>
            <ElevatedCard style={{ height: '100%' }}>
                <div
                    className="p-4 flex-column items-center text-center"
                    style={{
                        height: '100%',
                        justifyContent: 'space-around',
                    }}>
                    <div>
                        <h3 className="text-24">{isCurrency ? <MoneyDisplayAnalyzer value={value} /> : value}</h3>
                    </div>
                    <div>
                        <p className="mb-0 mt-2 text-muted font-normal">{label}</p>
                    </div>
                </div>
            </ElevatedCard>
        </>
    )
}

export default DisastersIndicators
