import { Icon, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import { EnumDocumentParent, EnumUser } from '../../../../../graphql/typings/global_types'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import IconHelper from '../../../../helpers/IconHelper'
import { useAuthUser } from '../../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'
import { useDeletePrevention } from '../../../../hooks/useMutations'
import useSnackBar from '../../../../hooks/useSnackBar'
import { ConfirmDialog } from '../../../Dialog'
import DocumentCreate from '../../../documentsList/DocumentForms/Admin/DocumentCreate/DocumentCreate'
import DocumentsModal from '../../../documentsList/DocumentModal/DocumentsModal'
import PreventionDialog from '../../places/place/preventions/PreventionDialog'
import { PlaceForPrevention, PreventionForPrevention } from './Prevention'

type PreventionActionMenuProps = {
    prevention: PreventionForPrevention
    place: PlaceForPrevention
}

const PreventionActionMenu = ({ prevention, place }: PreventionActionMenuProps) => {
    const auth = useAuthUser()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const isAdmin = auth.user.userType === EnumUser.admin

    const onMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()
        event.preventDefault()

        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    // This is usefull in case the action menu is put on a clickable element
    // To avoid click to be fired on the wrapping element
    // UPDATE => It messes with useManyFilesDialog
    const onRootClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation()
    }

    return (
        <div className="flex items-center" onClick={(event) => onRootClick(event)}>
            <div className="flex-grow"></div>
            <PAM_OpenDocs prevention={prevention} />
            {isAdmin && (
                <>
                    <Tooltip title="Modifier le contrat">
                        <IconButton onClick={onMenuClick}>
                            <Icon>more_vert</Icon>
                        </IconButton>
                    </Tooltip>
                    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                        <PAM_AddDocMenuItem prevention={prevention} handleClose={handleClose} />
                        <PAM_EditPrevMenuItem prevention={prevention} place={place} handleClose={handleClose} />
                        <PAM_DeletePrevMenuItem prevention={prevention} place={place} handleClose={handleClose} />
                    </Menu>
                </>
            )}
        </div>
    )
}

type PAM_OpenDocsProps = {
    prevention: PreventionForPrevention
}

const PAM_OpenDocs = ({ prevention }: PAM_OpenDocsProps) => {
    const [openDocsDialog, setOpenDocsDialog] = useState<boolean>(false)

    const openDocs = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation()
        event.preventDefault()

        setOpenDocsDialog(true)
    }

    return (
        <>
            <Tooltip title="Voir les documents">
                <IconButton onClick={openDocs}>
                    <Icon>{IconHelper.elementIcons.docsIcon}</Icon>
                </IconButton>
            </Tooltip>
            {openDocsDialog && (
                <DocumentsModal
                    open={openDocsDialog}
                    closeDialog={() => setOpenDocsDialog(false)}
                    elementType={'prevention'}
                    elementId={prevention.id}
                />
            )}
        </>
    )
}

type PAM_AddDocMenuItemProps = {
    prevention: PreventionForPrevention
    handleClose: () => void
}

const PAM_AddDocMenuItem = ({ prevention, handleClose }: PAM_AddDocMenuItemProps) => {
    const { openSnack } = useSnackBar()

    const [open, setOpen] = useState<boolean>(false)

    const onClick = () => {
        setOpen(true)
        handleClose()
    }

    const closeDialog = () => {
        setOpen(false)
    }

    const onDone = () => {
        openSnack({
            message: 'Documents ajoutés avec succès !',
            type: 'success',
        })
        closeDialog()
    }

    return (
        <>
            <MenuItem onClick={onClick}>Ajouter des documents</MenuItem>
            <DocumentCreate
                open={open}
                onDone={onDone}
                closeDialog={closeDialog}
                linkTo={{
                    docElementId: prevention.id,
                    docElementType: EnumDocumentParent.Prevention,
                }}
            />
        </>
    )
}

type PAM_EditPrevMenuItemProps = {
    prevention: PreventionForPrevention
    place: PlaceForPrevention
    handleClose: () => void
}

const PAM_EditPrevMenuItem = ({ prevention, place, handleClose }: PAM_EditPrevMenuItemProps) => {
    const { openSnack } = useSnackBar()

    const [open, setOpen] = useState<boolean>(false)

    const onClick = () => {
        handleClose()
        setOpen(true)
    }

    const closeDialog = () => {
        setOpen(false)
    }

    const onDone = () => {
        openSnack({
            message: 'Période modifiée avec succès !',
            type: 'success',
        })
        closeDialog()
    }

    return (
        <>
            <MenuItem onClick={onClick}>Modifier</MenuItem>
            <PreventionDialog
                onDone={onDone}
                open={open}
                closeDialog={closeDialog}
                prevention={prevention}
                place={place}
            />
        </>
    )
}

type PAM_DeletePrevMenuItemProps = {
    prevention: PreventionForPrevention
    place: PlaceForPrevention
    handleClose: () => void
}

const PAM_DeletePrevMenuItem = ({ prevention, place, handleClose }: PAM_DeletePrevMenuItemProps) => {
    const { openSnack } = useSnackBar()
    const { errorAlert } = useErrorService()
    const { customer } = useCustomerLoadedExisting()
    const deletePreventionMutation = useDeletePrevention()

    const [open, setOpen] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const onClick = () => {
        handleClose()
        setOpen(true)
    }

    const closeDialog = () => {
        setOpen(false)
    }

    const onDone = async () => {
        setLoading(true)

        try {
            const response = await deletePreventionMutation.run({
                id: prevention.id,
                placeId: place.id,
                customerId: customer.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression de la prévention'
            } else {
                openSnack({
                    message: 'Période supprimée avec succès !',
                    type: 'success',
                })
                closeDialog()
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <MenuItem onClick={onClick}>Supprimer</MenuItem>
            <ConfirmDialog
                open={open}
                title="Suppression d'une période"
                text="Voulez-vous vraiment supprimer cette période de prévention ?"
                onConfirmed={onDone}
                onCancelled={closeDialog}
                loading={loading}
            />
        </>
    )
}

export default PreventionActionMenu
