import { gql } from '@apollo/client/core'

export default gql`
    mutation DuplicateContract($fromContractId: ID!) {
        duplicateContract(input: { fromContractId: $fromContractId }) {
            errors
            status
            statusCode
            duplicatedContract {
                id
            }
        }
    }
`
