import { gql } from '@apollo/client/core'
import Enterprise_Minimal from '../Enterprise/Enterprise_Minimal'
import Fleet_Minimal from '../Fleet/Fleet_Minimal'
import Good_Minimal from '../Good/Good_Minimal'
import Place_Minimal from '../Place/Place_Minimal'
import Site_Minimal from '../Site/Site_Minimal'
import Vehicle_Minimal from '../Vehicle/Vehicle_Minimal'
import Guarantee_Minimal from './Guarantee_Minimal'

export default gql`
    ${Enterprise_Minimal}
    ${Site_Minimal}
    ${Place_Minimal}
    ${Good_Minimal}
    ${Fleet_Minimal}
    ${Guarantee_Minimal}
    ${Vehicle_Minimal}
    fragment Guarantee_Full on Guarantee {
        ...Guarantee_Minimal
        enterprises {
            ...Enterprise_Minimal
        }
        sites {
            ...Site_Minimal
        }
        places: locals {
            ...Place_Minimal
        }
        goods {
            ...Good_Minimal
        }
        fleets {
            ...Fleet_Minimal
        }
        vehicles {
            ...Vehicle_Minimal
        }
    }
`
