import { CurrencyFormat } from './MoneyInput'

type MoneyDisplayAnalyzerProps = {
    value: string | number
}

const MoneyDisplayAnalyzer = ({ value }: MoneyDisplayAnalyzerProps) => {
    if (typeof value === 'number') {
        return <CurrencyFormat value={value} displayType="text" />
    } else {
        const withoutSpaces = value.trim()
        const regex = /^(?<money>[\s?\d+]+([,.]\d+)?)?\s*€?$/g
        const result = withoutSpaces.match(regex)

        if (result && result.length > 0) {
            // It is a currency
            // We replace the coma by a dot, if necessary
            // We remove blank spaces as well
            const currency = parseFloat(result[0].replace(',', '.').replace(' ', ''))
            return <CurrencyFormat value={currency} displayType="text" />
        } else {
            return <>{value}</>
        }
    }
}

export default MoneyDisplayAnalyzer
