import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import React from 'react'
import MoneyDisplayAnalyzer from '../../../../components/customInputs/MoneyDisplayAnalyzer'
import ElevatedCard from '../../../../components/ElevatedCard'
import { AdminDashboardData } from '../../../../helpers/data/models/AdminDashboardData'
import IconHelper from '../../../../helpers/IconHelper'

type CardDataProps = {
    icon: string
    value: string | number
    label: string
    color: 'primary' | 'secondary'
    type: 'currency' | 'other'
}

type AEADashboardCardsProps = {
    dashboardData: AdminDashboardData
}

const AEADashboardCards = ({ dashboardData }: AEADashboardCardsProps) => {
    const cardData: CardDataProps[] = [
        {
            icon: 'supervisor_account',
            value: `${dashboardData.nbCustomers}`,
            label: 'Client(s)',
            color: 'primary',
            type: 'other',
        },
        {
            icon: 'local_atm',
            value: dashboardData.agreementAeaProfit,
            label: 'Montant mensuel des abonnements',
            color: 'primary',
            type: 'currency',
        },
        {
            icon: 'local_atm',
            value: dashboardData.disasterAeaProfit,
            label: 'Honoraires sur les sinistres',
            color: 'primary',
            type: 'currency',
        },
        {
            icon: IconHelper.elementIcons.disasterIcon,
            value: `${dashboardData.nbDisasters}`,
            label: 'Total des sinistres',
            color: 'primary',
            type: 'other',
        },
        {
            icon: IconHelper.elementIcons.disasterIcon,
            value: `${dashboardData.nbOpenedDisasters}`,
            label: 'Sinistres en cours',
            color: 'secondary',
            type: 'other',
        },
    ]

    return (
        <Grid container spacing={4}>
            {cardData.map((data, index) => {
                return (
                    <Grid item xs={6} sm={6} md={4} lg={4} key={index}>
                        <ElevatedCard style={{ height: '100%' }}>
                            <div
                                className="p-4 flex-column items-center text-center"
                                style={{
                                    height: '100%',
                                    justifyContent: 'space-around',
                                }}>
                                <div className="mb-2">
                                    <Icon color={data.color} fontSize="large">
                                        {data.icon}
                                    </Icon>
                                </div>
                                <div>
                                    <h3 className="text-24">
                                        {data.type === 'currency' ? (
                                            <MoneyDisplayAnalyzer value={data.value} />
                                        ) : (
                                            <span>{data.value}</span>
                                        )}
                                    </h3>
                                </div>
                                <div>
                                    <p className="mb-0 mt-2 text-muted font-normal">{data.label}</p>
                                </div>
                            </div>
                        </ElevatedCard>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default AEADashboardCards
