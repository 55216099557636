import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { useState } from 'react'
import { ContractGeneric_contract } from '../../../../graphql/queries/typings/ContractGeneric'
import { InsuranceGeneric_insurance } from '../../../../graphql/queries/typings/InsuranceGeneric'
import { isStringInputValid } from '../../../../utilsTs'
import ContractHelper from '../../../helpers/ContractHelper'
import DateHelper from '../../../helpers/DateHelper'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDuplicateContract, useUpdateContract } from '../../../hooks/useMutations'
import CustomButton from '../../CustomButton'
import MoneyInput from '../../customInputs/MoneyInput'
import { Dialog } from '../../Dialog'

type DuplicateContractDialogProps = {
    open: boolean
    contract: ContractForDuplicateDialog // in case of editing
    closeDialog: () => void
    onDone: () => void
}

type DuplicateContractData = {
    title: string | null
    bounty: number | null
    startAt: Date
    renewableDate: Date
}

type ContractForDuplicateDialog = Pick<
    ContractGeneric_contract,
    'id' | 'title' | 'startAt' | 'bounty' | 'renewableDate' | 'enterprise' | 'insurance'
> & {
    insurance: Pick<InsuranceGeneric_insurance, 'title'>
}

const DuplicateContractDialog = ({ contract, onDone, open, closeDialog }: DuplicateContractDialogProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { fromDateToServer } = DateHelper()

    const emptyContract: DuplicateContractData = {
        title: null,
        bounty: null,
        startAt: new Date(),
        renewableDate: new Date(),
    }

    const [data, setData] = useState<DuplicateContractData>({
        title: contract.title + ' COPIE',
        bounty: contract.bounty,
        startAt: new Date(contract.startAt),
        renewableDate: new Date(contract.renewableDate),
    })
    const [loading, setLoading] = useState<boolean>(false)

    const duplicateContractMutation = useDuplicateContract()
    const updateContractMutation = useUpdateContract()

    const { errorAlert } = useErrorService()

    const handleClose = () => {
        setData(emptyContract)
        closeDialog()
    }

    const handleDateChange = (date: MaterialUiPickersDate, value: 'startAt' | 'renewableDate') => {
        if (!date) return

        if (value === 'startAt') {
            setData((oldData) => {
                return {
                    ...oldData,
                    startAt: date,
                }
            })
        } else if (value === 'renewableDate') {
            setData((oldData) => {
                return {
                    ...oldData,
                    renewableDate: date,
                }
            })
        }
    }

    const handleSubmit = async () => {
        if (!data.title) return
        if (data.bounty === null) return
        if (!data.renewableDate) return

        setLoading(true)

        try {
            const response = await duplicateContractMutation.run({
                fromContractId: contract.id,
                customerId: customer.id,
                enterpriseId: contract.enterprise.id,
            })

            if (!response.duplicatedContract) {
                throw 'Une erreur est survenue pendant la duplication du contrat'
            }

            const updateResponse = await updateContractMutation.run({
                id: response.duplicatedContract.id,
                title: data.title,
                bounty: data.bounty,
                renewableDate: fromDateToServer(data.renewableDate),
                startAt: fromDateToServer(data.startAt),
                enterpriseId: contract.enterprise.id,
                insuranceId: contract.insurance.id,
            })

            if (updateResponse.contract) {
                onDone()
            } else {
                throw 'Une erreur est survenue pendant la duplication du contrat'
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const handleTitleChange = (value: string) => {
        setData((oldData) => ({
            ...oldData,
            title: value,
        }))
    }

    const handleBountyChange = (value: string) => {
        const valueToSet: number | null = value === '' ? null : parseFloat(value)

        setData((oldData) => ({
            ...oldData,
            bounty: valueToSet,
        }))
    }

    const isFormValid =
        isStringInputValid(data.title) && data.bounty !== null && data.startAt !== null && data.renewableDate !== null

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Duplication du contrat {ContractHelper.getContractLabel(contract)}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    La duplication d'un contrat vous permet de créer une nouvelle version d'un contrat, tout en
                    conservant les éléments couverts et les informations principales.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Nom du contrat *"
                    type="text"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleTitleChange(event.target.value)}
                />
                <MoneyInput
                    margin="dense"
                    id="bounty"
                    label="Prime annuelle *"
                    fullWidth
                    value={data.bounty === null ? '' : data.bounty}
                    onChange={(event) => handleBountyChange(event.target.value)}
                />
                <DatePicker
                    margin="dense"
                    id="startAt"
                    label="Date de prise d'effet *"
                    value={data.startAt}
                    onChange={(date) => handleDateChange(date, 'startAt')}
                    fullWidth
                    format="dd/MM/yyyy"
                    autoOk={true}
                />
                <DatePicker
                    margin="dense"
                    id="renewableDate"
                    label="Date d'échéance *"
                    value={data.renewableDate}
                    onChange={(date) => handleDateChange(date, 'renewableDate')}
                    fullWidth
                    format="dd/MM/yyyy"
                    className="mb-5"
                    autoOk={true}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default DuplicateContractDialog
