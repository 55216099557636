import { gql } from '@apollo/client/core'
import fragments from '../fragments'

export default gql`
    ${fragments.User.User_Minimal}
    query GetCustomers_Minimal {
        customers: projects {
            id
            title
            supervisor {
                ...User_Minimal
            }
        }
    }
`
