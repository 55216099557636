import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'

export type AddIconCustomToolbarProps = {
    addHandler: () => void
    tooltip: string
}

const AddIconCustomToolbar = ({ addHandler, tooltip }: AddIconCustomToolbarProps) => {
    return (
        <React.Fragment>
            <Tooltip title={tooltip}>
                <IconButton onClick={addHandler}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    )
}

export default AddIconCustomToolbar
