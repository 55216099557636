import { useMemo } from 'react'
import { GetEnterpriseContracts_contracts } from '../../../../../../graphql/queries/typings/GetEnterpriseContracts'
import { GetEnterprise_Guarantees_enterprise_guarantees } from '../../../../../../graphql/queries/typings/GetEnterprise_Guarantees'
import { EnumGuaranteeTarget } from '../../../../../../graphql/typings/global_types'
import { useGetEnterpriseGuarantees } from '../../../../../helpers/data/getEnterprise'
import ErrorDisplay from '../../../../../helpers/errors/ErrorDisplay'
import { useEnterpriseContracts } from '../../../../../hooks/useContracts'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useEnterpriseLoadedExisting } from '../../../../../hooks/useEnterprise'
import LoadingDisplay from '../../../../LoadingDisplay'
import ContractsList from '../../../contracts/ContractsList'
import ObjectContracts, { ContractWrapper } from '../../../contracts/ObjectContracts'

const EnterpriseContracts = () => {
    const { customer } = useCustomerLoadedExisting()
    const { enterprise } = useEnterpriseLoadedExisting()
    const contractsProps = useEnterpriseContracts({
        enterpriseId: enterprise.id,
        customerId: customer.id,
    })

    const guaranteesProps = useGetEnterpriseGuarantees(enterprise.id)

    if (contractsProps.contractsLoading || guaranteesProps.enterpriseLoading) return <LoadingDisplay />
    else if (contractsProps.error) {
        return (
            <ErrorDisplay
                message="Erreur lors du chargement des contrats"
                queryError={contractsProps.error}
                debug={contractsProps.error}
            />
        )
    } else if (guaranteesProps.error) {
        return (
            <ErrorDisplay
                message="Erreur lors du chargement des garanties"
                queryError={guaranteesProps.error}
                debug={guaranteesProps.error}
            />
        )
    } else if (!contractsProps.contracts || !guaranteesProps.enterprise)
        return (
            <ErrorDisplay
                message="Erreur lors du chargement des contrats de l'entreprise"
                debug={contractsProps.contracts}
            />
        )
    else
        return (
            <EnterpriseContractsReady
                contracts={contractsProps.contracts}
                guarantees={guaranteesProps.enterprise.guarantees}
            />
        )
}

type EnterpriseContractsReadyProps = {
    contracts: GetEnterpriseContracts_contracts[]
    guarantees: GetEnterprise_Guarantees_enterprise_guarantees[]
}

const EnterpriseContractsReady = ({ contracts, guarantees }: EnterpriseContractsReadyProps) => {
    const { enterprise } = useEnterpriseLoadedExisting()

    return (
        <>
            <ContractsList
                title={"Contrats portés par l'entreprise"}
                contracts={contracts}
                enterpriseId={enterprise.id}
            />
            <div className="mb-5" />
            <EnterpriseGuarantees guarantees={guarantees} />
        </>
    )
}

type EnterpriseGuaranteesProps = {
    guarantees: GetEnterprise_Guarantees_enterprise_guarantees[]
}

const EnterpriseGuarantees = ({ guarantees }: EnterpriseGuaranteesProps) => {
    const { enterprise } = useEnterpriseLoadedExisting()

    const contractsWrappers: ContractWrapper[] = useMemo(() => {
        const contractsWrappers: ContractWrapper[] = []

        guarantees.forEach((guarantee) => {
            const contract = guarantee.contract

            const existingWrapper = contractsWrappers.find((wrapper) => wrapper.contract.id === contract.id)

            if (existingWrapper) {
                existingWrapper.guarantees.push(guarantee)
            } else {
                contractsWrappers.push({
                    contract,
                    guarantees: [guarantee],
                })
            }
        })

        return contractsWrappers
    }, [guarantees])

    return (
        <ObjectContracts
            title="Contrats couvrant cette entreprise"
            onSuccessText="Garanties de l'entreprise modifiées !"
            confirmText="Voulez-vous vraiment retirer cette entreprise de toutes les garanties de ce contrat ?"
            contractsWrappers={contractsWrappers}
            guaranteeTarget={{
                targetId: enterprise.id,
                targetType: EnumGuaranteeTarget.Enterprise,
            }}
        />
    )
}

export default EnterpriseContracts
