import React, { createContext, ReactElement, useContext } from 'react'
import { PreventionCategory_Minimal } from '../../graphql/fragments/Prevention/PreventionCategory/typings/PreventionCategory_Minimal'
import useGetCommonData from '../helpers/data/getCommonData'
import { Broker_Minimal } from '../helpers/data/models/contract/Broker'
import { GuaranteeCategory } from '../helpers/data/models/contract/GuaranteeCategory'
import { Insurance_Minimal } from '../helpers/data/models/contract/Insurance'
import { Insurer_Minimal } from '../helpers/data/models/contract/Insurer'
import { DisasterCategory } from '../helpers/data/models/disaster/DisasterCategory'
import { DisasterEvent_Minimal } from '../helpers/data/models/disaster/DisasterEvent'
import { UserAEA_Minimal } from '../helpers/data/models/User'
import { QueryError } from '../helpers/errors/ErrorHelper'

type CommonDataContext = {
    error?: QueryError
    commonDataLoading: boolean
    brokers: Broker_Minimal[] | null
    insurers: Insurer_Minimal[] | null
    insurances: Insurance_Minimal[] | null
    admins: UserAEA_Minimal[] | null
    disastersEvents: DisasterEvent_Minimal[] | null
    preventionCategories: PreventionCategory_Minimal[] | null
    disasterCategories: DisasterCategory[] | null
    guaranteeCategories: GuaranteeCategory[] | null
}

const CommonDataContext = createContext<CommonDataContext>({
    commonDataLoading: true,
    brokers: null,
    insurers: null,
    insurances: null,
    admins: null,
    disastersEvents: null,
    preventionCategories: null,
    disasterCategories: null,
    guaranteeCategories: null,
})

type ProvideCommonDataProps = {
    children: ReactElement
}

export function ProvideCommonData({ children }: ProvideCommonDataProps) {
    const commonData = useProvideCommonData()

    return <CommonDataContext.Provider value={commonData}>{children}</CommonDataContext.Provider>
}

export const useCommonData = () => {
    return useContext(CommonDataContext)
}

export const useCommonDataLoadedExisting = () => {
    const context = useContext(CommonDataContext)

    if (context.commonDataLoading) {
        console.error('Common data is loading !')
        throw new Error('Common data is loading')
    }
    if (!context.brokers) {
        console.error(`Les courtiers n'ont pas été chargés`)
        throw new Error(`Les courtiers n'ont pas été chargés`)
    }
    if (!context.insurers) {
        console.error(`Les agents généraux n'ont pas été chargés`)
        throw new Error(`Les agents généraux n'ont pas été chargés`)
    }
    if (!context.insurances) {
        console.error(`Les assurances n'ont pas été chargées`)
        throw new Error(`Les assurances n'ont pas été chargées`)
    }
    if (!context.admins) {
        console.error(`Les admins n'ont pas été chargés`)
        throw new Error(`Les admins n'ont pas été chargés`)
    }
    if (!context.disastersEvents) {
        console.error(`Les événements n'ont pas été chargés`)
        throw new Error(`Les événements n'ont pas été chargés`)
    }
    if (!context.preventionCategories) {
        console.error(`Les catégories de prévention n'ont pas été chargées`)
        throw new Error(`Les catégories de prévention n'ont pas été chargées`)
    }
    if (!context.guaranteeCategories) {
        console.error(`Les catégories de garanties n'ont pas été chargées`)
        throw new Error(`Les catégories de garanties n'ont pas été chargées`)
    }
    if (!context.disasterCategories) {
        console.error(`Les catégories de sinistres n'ont pas été chargées`)
        throw new Error(`Les catégories de sinistres n'ont pas été chargées`)
    }

    return {
        commonDataLoading: context.commonDataLoading,
        brokers: context.brokers,
        insurers: context.insurers,
        insurances: context.insurances,
        admins: context.admins,
        disastersEvents: context.disastersEvents,
        preventionCategories: context.preventionCategories,
        disasterCategories: context.disasterCategories,
        guaranteeCategories: context.guaranteeCategories,
    }
}

const useProvideCommonData = (): CommonDataContext => {
    const { commonData, commonDataLoading, error } = useGetCommonData()

    if (!commonData) {
        return {
            commonDataLoading,
            brokers: null,
            insurers: null,
            insurances: null,
            admins: null,
            disastersEvents: null,
            preventionCategories: null,
            disasterCategories: null,
            guaranteeCategories: null,
        }
    }

    return {
        error,
        commonDataLoading,
        ...commonData,
        admins: commonData.admins as UserAEA_Minimal[],
    }
}

export default useCommonData
