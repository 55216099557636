import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment, useMemo, useState } from 'react'
import {
    GetCustomer_Users_project_enterprises,
    GetCustomer_Users_project_users,
} from '../../../../graphql/queries/typings/GetCustomer_Users'
import { EnumUser } from '../../../../graphql/typings/global_types'
import IconHelper from '../../../helpers/IconHelper'
import UserHelper from '../../../helpers/UserHelper'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDeleteUser } from '../../../hooks/useMutations'
import useSnackBar from '../../../hooks/useSnackBar'
import DataTable from '../../DataTable'
import { ConfirmDialog } from '../../Dialog'
import UserDialog from './UserDialog'
import Button from '@material-ui/core/Button/Button'
import UserNotificationsDialog from './UserNotificationsDialog'
import Panel from '../../Panel'
import { CSV_METADATA, exportAsCSV } from '../../../../utilsTs'

type DialogData =
    | {
          open: false
      }
    | {
          open: true
          user: GetCustomer_Users_project_users
      }

type FormattedUser = {
    firstname: string
    lastname: string | null
    phone: string
    email: string
    accessRights: string
    notifications: GetCustomer_Users_project_enterprises[]
    baseUser: GetCustomer_Users_project_users
}

const UsersList = () => {
    const auth = useAuthUser()
    const { customer } = useCustomerLoadedExisting()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogData>({ open: false })
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<DialogData>({ open: false })
    const [openNotificationsDialog, setOpenNotificationDialog] = useState<DialogData>({ open: false })

    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const { openSnack } = useSnackBar()
    const deleteUserMutation = useDeleteUser()

    const isUserAdmin = auth.user.userType === EnumUser.admin
    const users = customer.users.filter((user) => user.userType === EnumUser.user)

    const formattedUsers: FormattedUser[] = useMemo(() => {
        return users.map((user) => {
            const accessRights =
                user.restrictedToEnterprises.length > 0
                    ? UserHelper.getCustomerRestrictedEnterprises(user, customer)
                          .map((enterprise) => enterprise.title)
                          .join(', ')
                    : 'Accès global'

            const notifications = customer.enterprises.filter((enterprise) => {
                return !!enterprise.notificationUsers.find((notifiedUser) => notifiedUser.id === user.id)
            })

            return {
                firstname: user.firstname,
                lastname: user.lastname,
                phone: user.phone || '-',
                email: user.email,
                accessRights,
                notifications,
                baseUser: user,
            }
        })
    }, [users, customer.enterprises])

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `Utilisateur créé avec succès !`,
        })
    }

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: `Utilisateur édité avec succès !`,
        })
    }

    const onEditClick = (user: GetCustomer_Users_project_users) => {
        setOpenEditDialog({ open: true, user })
    }

    const onDeleteClick = (user: GetCustomer_Users_project_users) => {
        setOpenDeleteConfirm({ open: true, user })
    }

    const onDeleteCancelled = () => {
        setOpenDeleteConfirm({ open: false })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteConfirm.open) return

        setDeleteLoading(true)

        await deleteUserMutation.run({
            id: openDeleteConfirm.user.id,
            customerId: customer.id,
        })

        openSnack({
            type: 'success',
            message: `Utilisateur supprimé avec succès !`,
        })
        setOpenDeleteConfirm({ open: false })
        setDeleteLoading(false)
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un utilisateur`,
    }

    const accessRightsColumn: MUIDataTableColumnDef = {
        name: 'accessRights',
        label: "Droits d'accès",
    }

    const openUserNotificationsDialog = (user: FormattedUser) => {
        setOpenNotificationDialog({
            open: true,
            user: user.baseUser,
        })
    }

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'firstname',
                label: 'Prénom',
            },
            {
                name: 'lastname',
                label: 'Nom de famille',
            },
            {
                name: 'email',
                label: 'Adresse mail',
            },
            {
                name: 'phone',
                label: 'Numéro de téléphone',
            },
            {
                name: 'notified_enterprises',
                label: 'Notifications',
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        const user = formattedUsers[dataIndex]

                        return (
                            <Button
                                onClick={() => openUserNotificationsDialog(user)}
                                color="primary"
                                variant="outlined">
                                {user.notifications.length}
                            </Button>
                        )
                    },
                },
            },
            ...(isUserAdmin ? [accessRightsColumn] : []),
        ],
        [formattedUsers]
    )

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isUserAdmin) return undefined

        const user = formattedUsers[dataIndex].baseUser

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier l'utilisateur">
                    <IconButton onClick={() => onEditClick(user)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer l'utilisateur">
                    <IconButton onClick={() => onDeleteClick(user)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const getUsersAsCSV = () => {
        const metadata = CSV_METADATA

        const firstline = "Prénom,Nom de famille,Adresse mail,Numéro de téléphone,Notifications,Droits d'accès\n"

        const addQuotes = (value: string | undefined): string => {
            return value === undefined ? '' : '"' + value + '"'
        }

        const data = formattedUsers
            .map((user) => {
                const notificationsAsString = user.notifications
                    .map((enterprise) => {
                        return enterprise.title
                    })
                    .join(',')

                const row = [
                    addQuotes(user.firstname),
                    addQuotes(user.lastname || '-'),
                    addQuotes(user.email),
                    addQuotes(user.phone),
                    addQuotes(notificationsAsString),
                    addQuotes(user.accessRights),
                ].join(',')
                return row
            })
            .join('\n')

        return metadata + firstline + data
    }

    const downloadUsersAsCSV = () => {
        exportAsCSV(getUsersAsCSV(), 'nutshell_export_users.csv')
    }

    return (
        <Fragment>
            <Panel>
                <DataTable<FormattedUser>
                    title={`Utilisateurs de ${customer.title} `}
                    icon={IconHelper.elementIcons.usersIcon}
                    data={formattedUsers}
                    columns={columns}
                    showSearch={true}
                    addFeature={addFeatureData}
                    actionsColumn={isUserAdmin ? actionsColumn : undefined}
                />
            </Panel>
            {isUserAdmin && (
                <Button variant="outlined" onClick={downloadUsersAsCSV}>
                    Télécharger en CSV
                </Button>
            )}
            <UserDialog onDone={onCreated} open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} />
            {openEditDialog.open && (
                <UserDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    user={openEditDialog.user}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                />
            )}
            {openDeleteConfirm.open && (
                <ConfirmDialog
                    open={openDeleteConfirm.open}
                    title="Suppression d'un utilisateur"
                    text="Voulez-vous vraiment supprimer cet utilisateur ? Il n'aura plus accès à l'application"
                    onConfirmed={onDeleteConfirmed}
                    onCancelled={onDeleteCancelled}
                    loading={deleteLoading}
                />
            )}
            {openNotificationsDialog.open && (
                <UserNotificationsDialog
                    onDone={() => setOpenNotificationDialog({ open: false })}
                    open={openNotificationsDialog.open}
                    user={openNotificationsDialog.user}
                    closeDialog={() => setOpenNotificationDialog({ open: false })}
                />
            )}
        </Fragment>
    )
}

export default UsersList
