import { gql } from "@apollo/client/core";
import Enterprise_Minimal from '../Enterprise/Enterprise_Minimal'

export default gql`
    ${Enterprise_Minimal}
    fragment User_Minimal on User {
        id
        firstname
        lastname
        email
        userType
        phone
        restrictedToEnterprises {
            ...Enterprise_Minimal
        }
        avatar: urlToUserAvatar
    }
`
