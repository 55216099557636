import { gql } from '@apollo/client/core'

export default gql`
    fragment Agreement_Minimal on Agreement {
        id
        title
        startAt
        endAt
        description
        version
        amount
        category
        urlToFile
    }
`
