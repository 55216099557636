import { gql } from '@apollo/client/core'
import GuaranteeCategory from '../fragments/Guarantee/GuaranteeCategory'

export default gql`
    ${GuaranteeCategory}
    query GetGuaranteeCategories {
        guaranteeCategories(limit: 400) {
            ...GuaranteeCategory
        }
    }
`
