import React, { Fragment } from 'react'
import AEADisasterEventsList from './AEADisasterEventsList'

const AEAEvents = () => {
    return (
        <Fragment>
            <div className="m-sm-30">
                <h3 className="m-0 mb-6">Liste des événements de sinistres</h3>
                <AEADisasterEventsList />
            </div>
        </Fragment>
    )
}

export default AEAEvents
