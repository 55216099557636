import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Insurer.Insurer_Minimal}
    mutation CreateInsurer($title: String!, $phone: String, $email: String) {
        createInsurer(input: { title: $title, phone: $phone, email: $email }) {
            errors
            status
            statusCode
            insurer {
                ...Insurer_Minimal
            }
        }
    }
`
