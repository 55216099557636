import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Vehicle.Vehicle_Full}
    query GetVehicle_Full($id: ID!) {
        vehicle(id: $id) {
            ...Vehicle_Full
        }
    }
`