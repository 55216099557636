import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetCustomer_Users_project_preventions } from '../../../../../../graphql/queries/typings/GetCustomer_Users'
import DateHelper from '../../../../../helpers/DateHelper'
import IconHelper from '../../../../../helpers/IconHelper'
import PreventionHelper from '../../../../../helpers/PreventionHelper'
import { Document_Minimal } from '../../../../../helpers/data/models/Document'
import { Prevention_Minimal } from '../../../../../helpers/data/models/prevention/Prevention'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useDeletePrevention } from '../../../../../hooks/useMutations'
import useNavContext from '../../../../../hooks/useNavContext'
import { usePlaceLoadedExisting } from '../../../../../hooks/usePlace'
import useSnackBar from '../../../../../hooks/useSnackBar'
import DataTable from '../../../../DataTable'
import { ConfirmDialog } from '../../../../Dialog'
import PreventionActionMenu from '../../../prevention/prevention/PreventionActionMenu'
import PreventionDialog from './PreventionDialog'

type PreventionForPlacePreventionsList = GetCustomer_Users_project_preventions

type PlacePreventionListListProps = {
    preventions: PreventionForPlacePreventionsList[]
}

type FormattedPrevention = {
    category: string
    status: string
    documents: Document_Minimal[]
    description: string
    prevention: PreventionForPlacePreventionsList
}

type DialogData =
    | {
          open: false
      }
    | { open: true; prevention: Prevention_Minimal }

const PlacePreventionList = ({ preventions: allPreventions }: PlacePreventionListListProps) => {
    const { place } = usePlaceLoadedExisting()
    const { fromServertoString } = DateHelper()
    const { openSnack } = useSnackBar()
    const deletePreventionMutation = useDeletePrevention()
    const { customer } = useCustomerLoadedExisting()
    const { errorAlert } = useErrorService()
    const navigate = useNavigate()
    const { getPreventionRoute } = useNavContext()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogData>({ open: false })
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<DialogData>({ open: false })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const lastPreventions: PreventionForPlacePreventionsList[] = useMemo(
        () => PreventionHelper.getLastPreventions(allPreventions),
        [allPreventions]
    )

    const preventionsData: FormattedPrevention[] = useMemo(() => {
        return lastPreventions
            .sort((prevA, prevB) => {
                return prevA.preventionCategory.title.localeCompare(prevB.preventionCategory.title)
            })
            .map((prevention) => {
                const criticalityText = PreventionHelper.getPreventionCriticalityText(prevention)

                const endDateString = fromServertoString(prevention.endAt)

                return {
                    category: prevention.preventionCategory.title,
                    status: criticalityText,
                    endDate: endDateString,
                    documents: prevention.documents,
                    prevention: prevention,
                    description: prevention.description || '-',
                }
            })
    }, [lastPreventions, fromServertoString])

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'category',
            label: 'Prévention',
        },
        {
            name: 'status',
            label: 'Statut',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const preventionData = preventionsData[dataIndex]
                    const prevention = preventionData.prevention

                    const criticality = PreventionHelper.getPreventionCriticality(prevention)
                    const criticalityColor = PreventionHelper.getPreventionCriticalityColor(criticality)

                    return <div style={{ color: criticalityColor }}>{preventionData.status}</div>
                },
            },
        },
        {
            name: 'description',
            label: 'Commentaire',
        },
    ]

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: 'Prévention créée avec succès !',
        })
    }

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: 'Prévention modifiée avec succès !',
        })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteConfirm.open) return

        setDeleteLoading(true)

        try {
            const response = await deletePreventionMutation.run({
                id: openDeleteConfirm.prevention.id,
                placeId: place.id,
                customerId: customer.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression de la prévention'
            } else {
                openSnack({
                    message: 'Prévention supprimée avec succès',
                    type: 'success',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteConfirm({ open: false })
        }
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'une prévention`,
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        const prevention = preventionsData[dataIndex].prevention

        return <PreventionActionMenu prevention={prevention} place={place} />
    }

    const onPreventionClick = (formattedPrevention: FormattedPrevention) => {
        const prev = formattedPrevention.prevention
        const route = getPreventionRoute(place.id, prev.preventionCategory.id)

        navigate(route)
    }

    return (
        <Fragment>
            <DataTable<FormattedPrevention>
                title={`Plans de prévention du local ${place.title}`}
                icon={IconHelper.elementIcons.preventionIcon}
                data={preventionsData}
                columns={columns}
                showSearch={true}
                actionsColumn={actionsColumn}
                addFeature={addFeatureData}
                rowClick={onPreventionClick}
            />
            <PreventionDialog
                onDone={onCreated}
                open={openAddDialog}
                closeDialog={() => setOpenAddDialog(false)}
                place={place}
            />
            {openEditDialog.open && (
                <PreventionDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    prevention={openEditDialog.prevention}
                    place={place}
                />
            )}
            <ConfirmDialog
                open={openDeleteConfirm.open}
                title="Suppression d'une prévention"
                text="Voulez-vous vraiment supprimer cette prévention ?"
                onConfirmed={onDeleteConfirmed}
                onCancelled={() => setOpenDeleteConfirm({ open: false })}
                loading={deleteLoading}
            />
        </Fragment>
    )
}

export default PlacePreventionList
