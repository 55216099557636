import React from 'react'
import Breadcrumb, { BreadcrumbProps } from '../../../../matx/components/Breadcrumb'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import Panel from '../../Panel'
import AEACustomerRoadmapList from './RoadmapList'

type RoadmapProps = BreadcrumbProps

const Roadmap = (props: RoadmapProps) => {
    const { customer } = useCustomerLoadedExisting()
    const roadmapItems = customer.roadmapItems

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Breadcrumb {...props} />
            </div>
            <Panel>
                <AEACustomerRoadmapList roadmapItems={roadmapItems} />
            </Panel>
        </div>
    )
}

export default Roadmap
