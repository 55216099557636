import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { PreventionCategory_Minimal } from '../../../../../graphql/fragments/Prevention/PreventionCategory/typings/PreventionCategory_Minimal'
import { isStringInputValid } from '../../../../../utilsTs'
import CustomButton from '../../../../components/CustomButton'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import {
    useCreatePreventionCategory,


    useUpdatePreventionCategory
} from '../../../../hooks/useMutations'

type PreventionCategoryDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (preventionCategory: PreventionCategory_Minimal) => void
    preventionCategory?: PreventionCategory_Minimal
}

type Data = {
    title: string | null
    description: string | null
}

const emptyData: Data = {
    title: null,
    description: null,
}

const PreventionCategoryDialog = ({ preventionCategory, onDone, open, closeDialog }: PreventionCategoryDialogProps) => {
    const [data, setData] = useState<Data>(
        preventionCategory
            ? {
                  title: preventionCategory.title,
                  description: preventionCategory.description,
              }
            : emptyData
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createPreventionCategory = useCreatePreventionCategory()
    const updatePreventionCategory = useUpdatePreventionCategory()

    const { errorAlert } = useErrorService()

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title' | 'description') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const handleSubmit = async () => {
        if (!data.title) return

        setLoading(true)

        try {
            if (preventionCategory) {
                //Update
                const response = await updatePreventionCategory.run({
                    id: preventionCategory.id,
                    title: data.title,
                    description: data.description,
                })

                if (response.preventionCategory) {
                    onDone(response.preventionCategory)
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour de la catégorie'
                }
            } else {
                const response = await createPreventionCategory.run({
                    title: data.title,
                    description: data.description,
                })

                if (response.preventionCategory) {
                    onDone(response.preventionCategory)
                } else {
                    throw 'Une erreur est survenue pendant la création de la catégorie'
                }
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid = isStringInputValid(data.title)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'une catégorie</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Nom de la catégorie *"
                    type="text"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    value={data.description || ''}
                    onChange={(event) => handleChange(event.target.value, 'description')}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default PreventionCategoryDialog
