import { gql } from "@apollo/client/core";

export default gql`
    fragment Document_Minimal on Document {
        id
        title
        category
        activeUrl
        status
        statusComment
    }
`

// documentVersions {
//     ...Document_Version
// }
