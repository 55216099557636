import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment } from 'react'
import { EnumDocumentStatus, EnumUser } from '../../../graphql/typings/global_types'
import IconHelper from '../../helpers/IconHelper'
import UserHelper from '../../helpers/UserHelper'
import { useAuthUser } from '../../hooks/useAuth'
import DataTable from '../DataTable'
import IconText from '../IconText'
import Panel from '../Panel'
import { FormattedDoc } from './DocumentsList'
import DocumentListAnswer from './DocumentsListActions/DocumentListAnswer'
import DocumentListDownload from './DocumentsListActions/DocumentListDownload'
import DocumentListReject from './DocumentsListActions/DocumentListReject'
import DocumentListValidate from './DocumentsListActions/DocumentListValidate'

type FormattedValidationDoc = FormattedDoc & {
    validation: string
}

type DocumentsWaitingAdminActionProps = {
    pendingDocsAskedByUser: FormattedDoc[]
    waitingAEAValidationDocs: FormattedDoc[]
}

const DocumentsWaitingAdminAction = ({
    pendingDocsAskedByUser,
    waitingAEAValidationDocs,
}: DocumentsWaitingAdminActionProps) => {
    const formattedWaitingAEAValidationDocs: FormattedValidationDoc[] = waitingAEAValidationDocs.map((doc) => {
        const submitterName = doc.document.askedBy
            ? UserHelper.getFullName(doc.document.askedBy.firstname, doc.document.askedBy.lastname)
            : 'un utilisateur'

        const validationText =
            doc.document.status === EnumDocumentStatus.answered
                ? 'Réponse à une demande admin'
                : `Soumission par ${submitterName}`
        return {
            ...doc,
            validation: validationText,
        }
    })

    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin

    const pendingDocsColumns: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Titre',
        },
        {
            name: 'linkedTo',
            label: 'Associé à',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const document = pendingDocsAskedByUser[dataIndex]

                    return document.linkedToIcon ? (
                        <IconText icon={document.linkedToIcon} text={document.linkedTo} side="left" />
                    ) : (
                        document.linkedTo
                    )
                },
            },
        },
        {
            name: 'statusComment',
            label: 'Intitulé de la demande',
        },
        {
            name: 'askedBy',
            label: 'Initiateur de la demande',
        },
    ]

    const pendingDocsActionsColumn = (dataIndex: number, rowIndex: number): JSX.Element | undefined => {
        const document = pendingDocsAskedByUser[dataIndex].document

        if (isAdmin) {
            return (
                <div className="flex items-center">
                    <div className="flex-grow"></div>
                    <DocumentListAnswer document={document} />
                </div>
            )
        } else {
            // For now, when waiting admin validation, the user can't do anything
            return undefined
            // return (
            //     <div className="flex items-center">
            //         <div className="flex-grow"></div>
            //         <Tooltip title="Annuler la demande">
            //             <IconButton onClick={() => deleteDoc(document)} style={{ color: 'red' }}>
            //                 <Icon>delete</Icon>
            //             </IconButton>
            //         </Tooltip>
            //     </div>
            // )
        }
    }

    const waitingAdminValidationColumns: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Nom',
        },
        {
            name: 'validation',
            label: 'Validation',
        },
        {
            name: 'linkedTo',
            label: 'Associé à',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const document = waitingAEAValidationDocs[dataIndex]

                    return document.linkedToIcon ? (
                        <IconText icon={document.linkedToIcon} text={document.linkedTo} side="left" />
                    ) : (
                        document.linkedTo
                    )
                },
            },
        },
        {
            name: 'category',
            label: 'Type',
        },
        {
            name: 'statusComment',
            label: 'Commentaire du client',
        },
        {
            name: 'askedBy',
            label: 'Initiateur de la demande',
        },
    ]

    const waitingValidationDocsActionColumn = (dataIndex: number, rowIndex: number): JSX.Element | undefined => {
        const document = waitingAEAValidationDocs[dataIndex].document

        if (isAdmin) {
            return (
                <div className="flex items-center">
                    <div className="flex-grow"></div>
                    <DocumentListDownload document={document} />
                    <DocumentListValidate document={document} />
                    <DocumentListReject document={document} />
                </div>
            )
        } else {
            return (
                <div className="flex items-center">
                    <div className="flex-grow"></div>
                    <DocumentListDownload document={document} />
                </div>
            )
        }
    }

    return (
        <Fragment>
            <Panel>
                <DataTable<FormattedDoc>
                    title={`Documents demandés par le client`}
                    icon={IconHelper.elementIcons.pendingDocsIcon}
                    data={pendingDocsAskedByUser}
                    columns={pendingDocsColumns}
                    showSearch={true}
                    actionsColumn={pendingDocsActionsColumn}
                    options={{
                        filter: true,
                        filterType: 'multiselect',
                    }}
                />
            </Panel>
            <Panel>
                <DataTable<FormattedValidationDoc>
                    title={`Documents en attente de validation par AEA`}
                    icon={IconHelper.elementIcons.pendingDocsIcon}
                    data={formattedWaitingAEAValidationDocs}
                    columns={waitingAdminValidationColumns}
                    showSearch={true}
                    actionsColumn={waitingValidationDocsActionColumn}
                    options={{
                        filter: true,
                        filterType: 'multiselect',
                    }}
                />
            </Panel>
        </Fragment>
    )
}

export default DocumentsWaitingAdminAction
