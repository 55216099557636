import { gql } from '@apollo/client/core'
import fragments from '../fragments'

export default gql`
    ${fragments.Vehicle.Vehicle_Minimal}
    query GetVehicles_Minimal($customerId: ID!, $enterpriseId: ID) {
        vehicles(projectId: $customerId, enterpriseId: $enterpriseId, limit: 400) {
            ...Vehicle_Minimal
        }
    }
`
