import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Vehicle.Vehicle_Minimal}
    mutation UpdateVehicle(
        $id: ID!
        $title: String!
        $enterpriseId: ID!
        $fleetId: ID
        $description: String
        $registration: String
    ) {
        updateVehicle(
            input: {
                id: $id
                title: $title
                enterpriseId: $enterpriseId
                fleetId: $fleetId
                description: $description
                matriculation: $registration
            }
        ) {
            errors
            status
            statusCode
            vehicle {
                ...Vehicle_Minimal
            }
        }
    }
`
