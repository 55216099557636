import { gql } from "@apollo/client/core";

export default gql`
    query GetApiInfos {
        getApiInfos {
            coreVersion
            date
            environment
            version
        }
    }
`