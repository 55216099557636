import { gql } from '@apollo/client/core'

export default gql`
    query GetCustomer_EnterprisesForChart($customerID: ID!) {
        project(id: $customerID) {
            id
            title
            enterprises {
                id
                title
                children {
                    id
                    title
                }
                parents {
                    id
                    title
                }
            }
        }
    }
`
