import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Contract.Contract_Full}
    query GetContract_Full($contractId: ID!) {
        contract(id: $contractId) {
            ...Contract_Full
        }
    }
`
