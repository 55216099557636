import { GetContracts_contracts } from '../../../../../../graphql/queries/typings/GetContracts'
import { GetDisasters_Minimal_disasters } from '../../../../../../graphql/queries/typings/GetDisasters_Minimal'
import { GetEnterpriseContracts_contracts } from '../../../../../../graphql/queries/typings/GetEnterpriseContracts'
import ErrorDisplay from '../../../../../helpers/errors/ErrorDisplay'
import { useEnterpriseContracts } from '../../../../../hooks/useContracts'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useEnterpriseDisasters } from '../../../../../hooks/useDisasters'
import { useEnterpriseLoadedExisting } from '../../../../../hooks/useEnterprise'
import DisastersList from '../../../../disaster/DisastersList'
import LoadingDisplay from '../../../../LoadingDisplay'

const EnterpriseDisasters = () => {
    const { customer } = useCustomerLoadedExisting()
    const { enterprise } = useEnterpriseLoadedExisting()

    const { disasters, disastersLoading } = useEnterpriseDisasters({
        customerId: customer.id,
        enterpriseId: enterprise.id,
    })

    const contractsProps = useEnterpriseContracts({
        customerId: customer.id,
        enterpriseId: enterprise.id,
    })

    if (disastersLoading || contractsProps.contractsLoading) return <LoadingDisplay />
    else if (!disasters)
        return <ErrorDisplay message="Erreur lors de la récupération des sinistres" debug={disasters} />
    else if (contractsProps.error)
        return (
            <ErrorDisplay
                message="Erreur lors du chargement des contrats"
                queryError={contractsProps.error}
                debug={contractsProps.error}
            />
        )
    else if (!contractsProps.contracts)
        return <ErrorDisplay message="Erreur lors de la récupération des contrats" debug={disasters} />
    else return <AEAEnterpriseDisastersReady disasters={disasters} contracts={contractsProps.contracts} />
}

type AEAEnterpriseDisastersReadyProps = {
    disasters: GetDisasters_Minimal_disasters[]
    contracts: GetEnterpriseContracts_contracts[]
}

const AEAEnterpriseDisastersReady = ({ disasters, contracts }: AEAEnterpriseDisastersReadyProps) => {
    return <DisastersList disasters={disasters} contracts={contracts} />
}

export default EnterpriseDisasters
