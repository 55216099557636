import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React, { Fragment, useState } from 'react'
import { GetCustomer_Users_project_enterprises } from '../../../../graphql/queries/typings/GetCustomer_Users'
import { EnumUser } from '../../../../graphql/typings/global_types'
import Breadcrumb from '../../../../matx/components/Breadcrumb'
import AgreementHelper from '../../../helpers/AgreementHelper'
import { Agreement_Minimal } from '../../../helpers/data/models/Agreement'
import DateHelper from '../../../helpers/DateHelper'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import IconHelper from '../../../helpers/IconHelper'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDeleteAgreement } from '../../../hooks/useMutations'
import useNavContext from '../../../hooks/useNavContext'
import { useSnackBar } from '../../../hooks/useSnackBar'
import MoneyDisplayAnalyzer from '../../customInputs/MoneyDisplayAnalyzer'
import { ConfirmDialog } from '../../Dialog'
import Panel from '../../Panel'
import TableHeader from '../../TableHeader'
import AgreementDialog from './AgreementDialog'

type EditAgreementData =
    | {
          open: false
      }
    | { open: true; agreement: Agreement_Minimal; enterpriseId: string }

type DeleteAgreementData =
    | {
          open: false
      }
    | { open: true; agreement: Agreement_Minimal }

const AdminContract = () => {
    const { getAdminContractRoute } = useNavContext()
    const { customer } = useCustomerLoadedExisting()

    const { openSnack } = useSnackBar()

    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin

    const { errorAlert } = useErrorService()

    /********************
     * AGREEMENT CREATION
     *******************/
    const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)

    const onCreated = (agreement: Agreement_Minimal) => {
        openSnack({
            type: 'success',
            message: `Contrat créé avec succès !`,
        })
    }

    /********************
     * AGREEMENT EDITION
     *******************/
    const [editDialogOpen, setEditDialogOpen] = useState<EditAgreementData>({ open: false })

    const onEdited = (agreement: Agreement_Minimal) => {
        openSnack({
            type: 'success',
            message: `Contrat modifié avec succès !`,
        })
    }

    /********************
     * AGREEMENT DELETING
     *******************/

    const deleteAgreementMutations = useDeleteAgreement()

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<DeleteAgreementData>({ open: false })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const onDeleteConfirmed = async () => {
        if (!deleteDialogOpen.open) return

        setDeleteLoading(true)

        const response = await deleteAgreementMutations.run({
            customerId: customer.id,
            id: deleteDialogOpen.agreement.id,
        })

        if (response.errors.length > 0) {
            errorAlert('Erreur lors de la suppression du contrat')
        } else {
            openSnack({
                type: 'success',
                message: `Contrat supprimé avec succès !`,
            })
        }

        setDeleteLoading(false)
        setDeleteDialogOpen({ open: false })
    }

    const getEnterpriseAgreementElement = (enterprise: GetCustomer_Users_project_enterprises) => {
        const agreement = AgreementHelper.getLastAgreement(enterprise.agreements)

        const { fromServertoString } = DateHelper()

        if (!agreement) return null

        return (
            <Panel key={enterprise.id}>
                <Fragment>
                    <TableHeader
                        title={`${enterprise.title}`}
                        editClick={() =>
                            setEditDialogOpen({ open: true, agreement: agreement, enterpriseId: enterprise.id })
                        }
                        deleteClick={() => setDeleteDialogOpen({ open: true, agreement: agreement })}
                    />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className="pl-4">Libellé</TableCell>
                                <TableCell>{agreement.title}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Type de contrat</TableCell>
                                <TableCell>{AgreementHelper.getAgreementCategoryLabel(agreement.category)}</TableCell>
                            </TableRow>
                            {agreement.description && (
                                <TableRow>
                                    <TableCell className="pl-4">Note</TableCell>
                                    <TableCell>{agreement.description}</TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell className="pl-4">Enterprise</TableCell>
                                <TableCell>{enterprise.title}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Date de prise d'effet</TableCell>
                                <TableCell>{fromServertoString(agreement.startAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Date de fin du contrat</TableCell>
                                <TableCell>{fromServertoString(agreement.endAt)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Montant de l'abonnement</TableCell>
                                <TableCell>
                                    <MoneyDisplayAnalyzer value={agreement.amount} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Contrat</TableCell>
                                <TableCell>
                                    {agreement.urlToFile ? (
                                        <a href={agreement.urlToFile} target="_blank">
                                            <Button color="primary" size="small" startIcon={<Icon>get_app</Icon>}>
                                                Télécharger
                                            </Button>
                                        </a>
                                    ) : (
                                        <span className="text-muted">Aucun document associé</span>
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <ConfirmDialog
                        open={deleteDialogOpen.open}
                        title="Suppression du contrat"
                        text="Voulez-vous vraiment supprimer ce contrat ?"
                        onConfirmed={onDeleteConfirmed}
                        onCancelled={() => setDeleteDialogOpen({ open: false })}
                        loading={deleteLoading}
                    />
                </Fragment>
            </Panel>
        )
    }

    const hasAgreements = customer.enterprises.reduce((previousValue, enterprise) => {
        if (previousValue) return true
        return AgreementHelper.getLastAgreement(enterprise.agreements) !== null
    }, false)

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Grid container spacing={2} alignItems="center">
                    <Grid item style={{ flexGrow: 1 }}>
                        <Breadcrumb
                            routeSegments={[
                                { name: 'Contrats de prestation AEA', icon: IconHelper.elementIcons.contractIcon },
                            ]}
                            homePath={getAdminContractRoute().pathname}
                        />
                    </Grid>
                    {isAdmin && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Icon>add</Icon>}
                                size="medium"
                                onClick={() => setAddDialogOpen(true)}>
                                Ajouter un contrat
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
            {!hasAgreements && <span>Aucun contrat disponible</span>}
            {customer.enterprises.map(getEnterpriseAgreementElement)}
            <AgreementDialog open={addDialogOpen} closeDialog={() => setAddDialogOpen(false)} onDone={onCreated} />
            {editDialogOpen.open && (
                <AgreementDialog
                    onDone={onEdited}
                    open={editDialogOpen.open}
                    closeDialog={() => setEditDialogOpen({ open: false })}
                    agreement={editDialogOpen.agreement}
                    enterpriseId={editDialogOpen.enterpriseId}
                />
            )}
        </div>
    )
}

export default AdminContract
