import { useMemo } from 'react'
import useGetGoods from '../../../../../../helpers/data/getGoods'
import ErrorDisplay from '../../../../../../helpers/errors/ErrorDisplay'
import { useCustomerLoadedExisting } from '../../../../../../hooks/useCustomer'
import LoadingDisplay from '../../../../../LoadingDisplay'
import DMAButtons, { DMAButtonsProps } from './DMAButtons'
import { DMA_Data, DMA_SetData } from './DocumentManagerAssociation'
import DocumentManagerAssociationElementTextField from './DocumentManagerAssociationElementTextFields'

type DMA_GoodsProps = DMAButtonsProps & {
    data: DMA_Data
    setData: DMA_SetData
}

const DMA_Goods = ({ data, setData, ...buttonsProps }: DMA_GoodsProps) => {
    const { customer } = useCustomerLoadedExisting()
    const goodsProps = useGetGoods({ customerId: customer.id })

    const content = useMemo(() => {
        if (goodsProps.goodsLoading) {
            return <LoadingDisplay size={1} />
        } else if (goodsProps.error) {
            return (
                <ErrorDisplay
                    message="Erreur lors du chargement des biens"
                    queryError={goodsProps.error}
                    debug={goodsProps.error}
                />
            )
        } else if (!goodsProps.goods) {
            return <ErrorDisplay message="Erreur lors du chargement des biens" debug={goodsProps.goods} />
        } else {
            const elements = goodsProps.goods.map((good) => {
                return { label: good.title, id: good.id }
            })

            return (
                <DocumentManagerAssociationElementTextField
                    data={data}
                    setData={setData}
                    elementLabel={'Bien'}
                    elements={elements}
                />
            )
        }
    }, [goodsProps, data, setData])

    return (
        <>
            {content}
            <DMAButtons {...buttonsProps} />
        </>
    )
}

export default DMA_Goods
