import Breadcrumb from '../../../../matx/components/Breadcrumb'
import Contracts from '../../../components/customer/contracts/Contracts'
import IconHelper from '../../../helpers/IconHelper'
import { useNavContext } from '../../../hooks/useNavContext'

const CustomerContracts = () => {
    const { getCustomerRoute, getContractsRoute } = useNavContext()

    const fullSegments = [
        {
            name: 'Contrats et attestations',
            icon: IconHelper.elementIcons.contractIcon,
            path: getContractsRoute().pathname,
        },
    ]

    const customerRoute = getCustomerRoute()

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Breadcrumb homePath={customerRoute.pathname} routeSegments={fullSegments} />
            </div>
            <Contracts />
        </div>
    )
}

export default CustomerContracts
