import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Insurance.Insurance_Minimal}
    mutation UpdateInsurance($id: ID!, $title: String!, $phone: String, $email: String) {
        updateInsurance(input: { id: $id, title: $title, phone: $phone, email: $email }) {
            errors
            status
            statusCode
            insurance {
                ...Insurance_Minimal
            }
        }
    }
`
