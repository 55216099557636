import { ApolloError, QueryResult } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { useAuth } from '../../hooks/useAuth'
import useSnackBar from '../../hooks/useSnackBar'

const isQueryUserTokenError = (queryError: GraphQLError) => {
    if (!queryError.extensions) return false

    return queryError.extensions.code === 'USER_NOT_FOUND'
}

const isQueryUserPermissionError = (queryError: GraphQLError) => {
    if (!queryError.extensions) return false

    return queryError.extensions.code === 'ACCESS_FORBIDDEN'
}

export const useQueryErrorManager = () => {
    const auth = useAuth()
    const { openSnack } = useSnackBar()

    const manageQueryError = (error: any) => {
        if (error.graphQLErrors.length > 0) {
            const graphQLError = error.graphQLErrors[0]

            if (ErrorHelper.isQueryUserTokenError(graphQLError)) {
                openSnack({
                    message: 'Session expirée : redirection vers la page de connexion',
                    type: 'error',
                })
                auth.clearUserData()
            }
        }
    }

    return {
        manageQueryError,
    }
}

export enum QueryErrorType {
    ACCESS_FORBIDDEN = 'ACCESS_FORBIDDEN',
    UNKNOWN = 'UNKNOWN',
}

export type QueryError = {
    message: string
    errorType: QueryErrorType
    error: ApolloError
}

export const getQueryError = (queryResult: QueryResult<any, any>, errorMessage: string): QueryError | undefined => {
    const error = queryResult.error

    if (!error) return undefined

    const graphQLError = error.graphQLErrors.length > 0 ? error.graphQLErrors[0] : undefined

    const errorType: QueryErrorType =
        graphQLError && isQueryUserPermissionError(graphQLError)
            ? QueryErrorType.ACCESS_FORBIDDEN
            : QueryErrorType.UNKNOWN

    return {
        error,
        errorType,
        message: errorMessage,
    }
}

const ErrorHelper = {
    isQueryUserTokenError,
    getQueryError,
}

export default ErrorHelper
