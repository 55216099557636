import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.User.User_Customers}
    mutation UpdateUser($id: ID!, $firstname: String, $lastname: String, $phone: String, $enterpriseIds: [ID!]) {
        updateUser(
            input: { id: $id, firstname: $firstname, lastname: $lastname, phone: $phone, enterpriseIds: $enterpriseIds }
        ) {
            errors
            status
            statusCode
            user {
                ...User_Customers
            }
        }
    }
`
