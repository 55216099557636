import Fade from '@material-ui/core/Fade'
import React, { Fragment } from 'react'
import EnterprisePlacesList from './EnterprisePlacesList'

const EnterprisePlaces = () => {
    return (
        <Fragment>
            <Fade in timeout={300}>
                <div className="mb-6">
                    <EnterprisePlacesList />
                </div>
            </Fade>
        </Fragment>
    )
}

export default EnterprisePlaces
