import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Insurance.Insurance_Minimal}
    mutation CreateInsurance($title: String!, $phone: String, $email: String) {
        createInsurance(input: { title: $title, phone: $phone, email: $email }) {
            errors
            status
            statusCode
            insurance {
                ...Insurance_Minimal
            }
        }
    }
`
