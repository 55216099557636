import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.DisasterCategory}
    mutation UpdateDisasterCategory($id: ID!, $title: String!, $description: String) {
        updateDisasterCategory(input: { id: $id, title: $title, description: $description }) {
            errors
            status
            statusCode
            disasterCategory {
                ...DisasterCategory
            }
        }
    }
`
