import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Fleet.Fleet_Minimal}
    mutation CreateFleet($title: String!, $enterpriseId: ID!, $vehicleIds: [ID!]!) {
        createFleet(input: { title: $title, enterpriseId: $enterpriseId, vehicleIds: $vehicleIds }) {
            errors
            status
            statusCode
            fleet {
                ...Fleet_Minimal
            }
        }
    }
`
