import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Step from '@material-ui/core/Step/Step'
import StepLabel from '@material-ui/core/StepLabel/StepLabel'
import Stepper from '@material-ui/core/Stepper/Stepper'
import { useMemo, useState } from 'react'
import { EnumDocumentCategory, EnumDocumentParent } from '../../../../../../graphql/typings/global_types'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import FileDialogHelper, { FileType } from '../../../../../hooks/useFileDialog'
import Spacer from '../../../../Spacer'
import DocumentManagerAssociation, {
    DMA_Data,
} from '../DocumentFormsComponents/DocumentManagerAssociation/DocumentManagerAssociation'
import DocumentAskToCustomerInformation from './DocumentAskToCustomerInformation'
import DocumentAskToCustomerUploading from './DocumentAskToCustomerUploading'

export type DocumentAskToCustomerProps = {
    open: boolean
    closeDialog: () => void
    onDone: () => void
}

const DocumentAskToCustomer = (props: DocumentAskToCustomerProps) => {
    return (
        <Dialog open={props.open} onClose={props.closeDialog}>
            <DialogTitle>Ajout de documents</DialogTitle>
            <DialogContent>
                <DocumentAskToCustomerReady {...props} />
            </DialogContent>
        </Dialog>
    )
}

type DocumentAskToCustomerReadyProps = DocumentAskToCustomerProps

export type DocumentAskToCustomerData = {
    title: string | null
    category: EnumDocumentCategory | null
    docElementType: EnumDocumentParent | null
    docElementId: string | null
    statusComment: string | null
    askedTo: string[]
}

const emptyData: DocumentAskToCustomerData = {
    title: null,
    category: null,
    docElementType: null,
    docElementId: null,
    statusComment: null,
    askedTo: [],
}

export enum DocumentAskToCustomerSteps {
    INFORMATION,
    ASSOCIATION,
    LOADING,
}

const DocumentAskToCustomerReady = (props: DocumentAskToCustomerReadyProps) => {
    const { errorAlert } = useErrorService()

    const [step, setStep] = useState<DocumentAskToCustomerSteps>(DocumentAskToCustomerSteps.INFORMATION)
    const [data, setData] = useState<DocumentAskToCustomerData>(emptyData)

    const steps: string[] = useMemo(() => {
        return ['Informations', 'Association', 'Envoi']
    }, [])

    const canGoPrevious = useMemo(() => {
        return step === DocumentAskToCustomerSteps.ASSOCIATION
    }, [step])

    const canGoNext = useMemo(() => {
        return step === DocumentAskToCustomerSteps.INFORMATION || step === DocumentAskToCustomerSteps.ASSOCIATION
    }, [step])

    const goPrevious = () => {
        switch (step) {
            case DocumentAskToCustomerSteps.ASSOCIATION:
                setStep(DocumentAskToCustomerSteps.INFORMATION)
                break
            default:
                errorAlert('Oops, une erreur est survenue.', { function: 'goPrevious', step })
        }
    }

    const goNext = () => {
        switch (step) {
            case DocumentAskToCustomerSteps.INFORMATION:
                setStep(DocumentAskToCustomerSteps.ASSOCIATION)
                break
            case DocumentAskToCustomerSteps.ASSOCIATION:
                setStep(DocumentAskToCustomerSteps.LOADING)
                break
            default:
                errorAlert('Oops, une erreur est survenue.', { function: 'goNext', step })
        }
    }

    const setAssociationData = (data: DMA_Data) => {
        setData((oldData) => {
            return {
                ...oldData,
                docElementId: data.docElementId,
                docElementType: data.docElementType,
            }
        })
    }

    return (
        <>
            <Stepper activeStep={step} alternativeLabel style={{ minWidth: 500, padding: 0 }}>
                {steps.map((stepLabel) => (
                    <Step key={stepLabel}>
                        <StepLabel>{stepLabel}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Spacer />
            {step === DocumentAskToCustomerSteps.INFORMATION && (
                <DocumentAskToCustomerInformation
                    data={data}
                    setData={setData}
                    closeDialog={props.closeDialog}
                    goPrevious={goPrevious}
                    canGoPrevious={canGoPrevious}
                    goNext={goNext}
                />
            )}
            {step === DocumentAskToCustomerSteps.ASSOCIATION && (
                <DocumentManagerAssociation
                    data={data}
                    setData={setAssociationData}
                    closeDialog={props.closeDialog}
                    goPrevious={goPrevious}
                    canGoPrevious={canGoPrevious}
                    goNext={goNext}
                    canGoNext={canGoNext}
                />
            )}
            {step === DocumentAskToCustomerSteps.LOADING && (
                <DocumentAskToCustomerUploading
                    emptyData={emptyData}
                    data={data}
                    setData={setData}
                    onDone={props.onDone}
                    closeDialog={props.closeDialog}
                />
            )}
        </>
    )
}

export default DocumentAskToCustomer
