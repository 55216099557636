import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { Fragment, useState } from 'react'
import { EnumUser } from '../../../../../graphql/typings/global_types'
import DataTable from '../../../../components/DataTable'
import { ConfirmDialog } from '../../../../components/Dialog'
import Panel from '../../../../components/Panel'
import { DisasterEvent_Minimal } from '../../../../helpers/data/models/disaster/DisasterEvent'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import { useAuthUser } from '../../../../hooks/useAuth'
import { useCommonDataLoadedExisting } from '../../../../hooks/useCommonData'
import { useDeleteDisasterEvent } from '../../../../hooks/useMutations'
import useSnackBar from '../../../../hooks/useSnackBar'
import DisasterEventDialog from './DisasterEventDialog'

type DialogState =
    | {
          open: true
          disasterEvent: DisasterEvent_Minimal
      }
    | { open: false }

const AEADisasterEventsList = () => {
    const { disastersEvents } = useCommonDataLoadedExisting()
    const { errorAlert } = useErrorService()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const { openSnack } = useSnackBar()
    const deleteDisasterEventMutation = useDeleteDisasterEvent()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Nom',
        },
    ]

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un événement`,
    }

    const onCreated = (disasterEvent: DisasterEvent_Minimal) => {
        openSnack({
            type: 'success',
            message: `Evénement ${disasterEvent.title} créé avec succès !`,
        })
    }

    const onEdited = (disasterEvent: DisasterEvent_Minimal) => {
        openSnack({
            message: 'Evénement modifié avec succès !',
            type: 'success',
        })
    }

    const onEditClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        disasterEvent: DisasterEvent_Minimal
    ) => {
        event.stopPropagation()
        setOpenEditDialog({ open: true, disasterEvent })
    }

    const onDeleteClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        disasterEvent: DisasterEvent_Minimal
    ) => {
        event.stopPropagation()
        setOpenDeleteDialog({ open: true, disasterEvent })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const disasterEvent = openDeleteDialog.disasterEvent

            const response = await deleteDisasterEventMutation.run({
                id: disasterEvent.id,
            })

            if (response.errors.length > 0) {
                throw "Une erreur est survenue pendant la suppression de l'événement."
            } else {
                openSnack({
                    message: 'Evénement supprimé avec succès !',
                    type: 'success',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteDialog({ open: false })
        }
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const disasterEvent = disastersEvents[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier l'événement">
                    <IconButton onClick={(event) => onEditClick(event, disasterEvent)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer l'événement">
                    <IconButton onClick={(event) => onDeleteClick(event, disasterEvent)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <Fragment>
            <Panel>
                <DataTable<DisasterEvent_Minimal>
                    title={'Evènements de sinistres'}
                    data={disastersEvents}
                    columns={columns}
                    showSearch={true}
                    addFeature={addFeatureData}
                    actionsColumn={actionsColumn}
                    options={{
                        sortOrder: {
                            name: 'title',
                            direction: 'asc',
                        },
                    }}
                />
            </Panel>
            <DisasterEventDialog onDone={onCreated} open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} />
            {openEditDialog.open && (
                <DisasterEventDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    disasterEvent={openEditDialog.disasterEvent}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={() => setOpenDeleteDialog({ open: false })}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'un événement"
                    text={`Voulez-vous vraiment supprimer l'événement "${openDeleteDialog.disasterEvent.title}" ? Tous les sinistres associés ne le seront plus.`}
                    loading={deleteLoading}
                />
            )}
        </Fragment>
    )
}

export default AEADisasterEventsList
