import { gql } from "@apollo/client/core";
import Document_User from '../Document/Document_User'

export default gql`
    ${Document_User}
    fragment Place_Documents on Local {
        id
        documents {
            ...Document_User
        }
    }
`
