import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import React, { Fragment, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { EnumUser } from '../../../../graphql/typings/global_types'
import { Fleet_Minimal } from '../../../helpers/data/models/fleet/Fleet'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import IconHelper from '../../../helpers/IconHelper'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDeleteFleet } from '../../../hooks/useMutations'
import { useNavContext } from '../../../hooks/useNavContext'
import useSnackBar from '../../../hooks/useSnackBar'
import DataTable from '../../DataTable'
import { ConfirmDialog } from '../../Dialog'
import Panel from '../../Panel'
import FleetDialog from '../enterprises/enterprise/vehicles/FleetDialog'

type FleetsListProps = {
    fleets: Fleet_Minimal[]
}

type FormattedData = {
    title: string
    enterprise: string
    baseFleet: Fleet_Minimal
}

type DialogState =
    | {
          open: true
          fleet: Fleet_Minimal
      }
    | { open: false }

const FleetsList = ({ fleets }: FleetsListProps) => {
    const navigate = useNavigate()
    // const { getFleetRoute } = useNavContext()
    const { customer } = useCustomerLoadedExisting()
    const enterprises = customer.enterprises
    const { openSnack } = useSnackBar()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const { errorAlert } = useErrorService()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const deleteFleetMutation = useDeleteFleet()

    const columns = [
        {
            name: 'title',
            label: 'Désignation',
        },
        {
            name: 'enterprise',
            label: 'Entreprise propriétaire',
        },
    ]

    const formattedData: FormattedData[] = useMemo(() => {
        return fleets.map((fleet) => {
            const enterprise = enterprises.find((enterprise) => enterprise.id === fleet.enterprise.id)

            return {
                title: fleet.title,
                enterprise: enterprise ? enterprise.title : '-',
                baseFleet: fleet,
            }
        })
    }, [fleets])

    // const onRowClick = (formattedData: FormattedData) => {
    //     navigate(getVehicleRoute(formattedData.baseVehicle.id))
    // }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un véhicule`,
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `Flotte créée avec succès !`,
        })
    }

    const onEdited = () => {
        openSnack({
            message: 'Flotte modifiée avec succès !',
            type: 'success',
        })
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const fleet = fleets[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier la flotte">
                    <IconButton onClick={(event) => onEditClick(event, fleet)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer la flotte">
                    <IconButton onClick={(event) => onDeleteClick(event, fleet)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, fleet: Fleet_Minimal) => {
        event.stopPropagation()
        setOpenEditDialog({ open: true, fleet })
    }

    const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, fleet: Fleet_Minimal) => {
        event.stopPropagation()
        setOpenDeleteDialog({ open: true, fleet })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const fleet = openDeleteDialog.fleet

            const response = await deleteFleetMutation.run({
                enterpriseId: fleet.enterprise.id,
                customerId: customer.id,
                id: fleet.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression du véhicule.'
            } else {
                openSnack({
                    message: 'Véhicule supprimé avec succès !',
                    type: 'success',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteDialog({ open: false })
        }
    }

    return (
        <Fragment>
            <Panel>
                <DataTable<FormattedData>
                    title={'Flottes'}
                    icon={IconHelper.elementIcons.fleetIcon}
                    data={formattedData}
                    columns={columns}
                    showSearch={true}
                    // rowClick={onRowClick}
                    addFeature={addFeatureData}
                    actionsColumn={actionsColumn}
                />
            </Panel>
            <FleetDialog onDone={onCreated} open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} />
            {openEditDialog.open && (
                <FleetDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    fleet={openEditDialog.fleet}
                    enterpriseId={openEditDialog.fleet.enterprise.id}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={() => setOpenDeleteDialog({ open: false })}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'une flotte"
                    text={`Voulez-vous vraiment supprimer cette flotte ?`}
                    loading={deleteLoading}
                />
            )}
        </Fragment>
    )
}

export default FleetsList
