import { Fragment } from 'react'
import ContractElementsList from './ContractElementsList'

const ContractElements = () => {
    return (
        <Fragment>
            <ContractElementsList />
        </Fragment>
    )
}

export default ContractElements
