import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Prevention.PreventionCategory_Minimal}
    query GetPreventionCategories_Minimal {
        preventionCategories {
            ...PreventionCategory_Minimal
        }
    }
`
