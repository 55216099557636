import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import { GetFleet_Documents, GetFleet_DocumentsVariables } from '../../../graphql/queries/typings/GetFleet_Documents'
import { useErrorService } from '../errors/ErrorService'
import { Fleet_Documents } from './models/fleet/Fleet'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'

type FleetData = {
    error?: QueryError
    fleet: Fleet_Documents | null
    fleetLoading: boolean
}

type GetFleetDocumentsProps = {
    id: string
}

const useGetFleetDocuments = ({ id }: GetFleetDocumentsProps): FleetData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetFleet_Documents, GetFleet_DocumentsVariables>(queries.GetFleet_Documents, {
        variables: {
            id,
        },
    })

    const errorMessage = 'Erreur lors de la récupération de la flotte'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }
    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const fleet = responseData && responseData.fleet ? responseData.fleet : null

        return {
            error,
            fleet,
            fleetLoading: false,
        }
    } else {
        return {
            error,
            fleet: null,
            fleetLoading: true,
        }
    }
}

export default useGetFleetDocuments
