import { gql } from '@apollo/client/core'
import Document_User from '../../Document/Document_User'
import Enterprise_Documents from '../../Enterprise/Enterprise_Documents'
import Fleet_Documents from '../../Fleet/Fleet_Documents'
import Place_Documents from '../../Place/Place_Documents'
import Site_Documents from '../../Site/Site_Documents'
import Vehicle_Documents from '../../Vehicle/Vehicle_Documents'

export default gql`
    ${Enterprise_Documents}
    ${Site_Documents}
    ${Place_Documents}
    ${Document_User}
    ${Fleet_Documents}
    ${Vehicle_Documents}
    fragment DisasterDamage_Documents on Damage {
        id
        enterprises {
            ...Enterprise_Documents
        }
        sites {
            ...Site_Documents
        }
        places: locals {
            ...Place_Documents
        }
        goods {
            id
            documents {
                ...Document_User
            }
        }
        fleets {
            ...Fleet_Documents
        }
        vehicles {
            ...Vehicle_Documents
        }
    }
`
