import Preventions from '../../../../components/customer/prevention/Preventions'
import IconHelper from '../../../../helpers/IconHelper'
import RoutesHelper from '../../../../helpers/RoutesHelper'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'

const AEACustomerPreventions = () => {
    const { customer } = useCustomerLoadedExisting()

    return (
        <Preventions
            routeSegments={[{ name: 'Moyens de prévention', icon: IconHelper.elementIcons.preventionIcon }]}
            homePath={RoutesHelper.views.AEA.customer.Home.to({ customerId: customer.id }).pathname}
        />
    )
}

export default AEACustomerPreventions
