import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { Fragment, ReactElement } from 'react'
import Scrollbar from 'react-perfect-scrollbar'
import { Navigation } from '../../../../app/hooks/useNavigation'
import { MatxVerticalNav } from '../../../../matx'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    scrollable: {
        paddingLeft: 20,
        paddingRight: 20,
    },
}))

type SidenavProps = {
    children?: ReactElement
    navigation: Navigation[]
}

const Sidenav = ({ children, navigation }: SidenavProps) => {
    const classes = useStyles()

    return (
        <Fragment>
            <Scrollbar options={{ suppressScrollX: true }} className={clsx('relative px-4', classes.scrollable)}>
                {children}
                <MatxVerticalNav items={navigation} />
                <div className="pb-20"></div>
            </Scrollbar>
        </Fragment>
    )
}

export default Sidenav
