import Enterprise_Documents from './Enterprise_Documents'
import Enterprise_Minimal from './Enterprise_Minimal'
import Enterprise_Users from './Enterprise_Users'

const EnterpriseFragments = {
    Enterprise_Minimal,
    Enterprise_Documents,
    Enterprise_Users,
}

export default EnterpriseFragments
