import Divider from '@material-ui/core/Divider'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router'
import { Enterprise_Minimal } from '../../../../helpers/data/models/Enterprise'
import { Vehicle_Full, Vehicle_Minimal } from '../../../../helpers/data/models/fleet/Vehicle'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'
import useNavContext from '../../../../hooks/useNavContext'
import useSnackBar from '../../../../hooks/useSnackBar'
import IconText from '../../../IconText'
import Panel from '../../../Panel'
import TableHeader from '../../../TableHeader'
import VehicleDialog from '../../enterprises/enterprise/vehicles/VehicleDialog'

type VehicleGeneralProps = {
    vehicle: Vehicle_Full
}

const VehicleGeneral = ({ vehicle }: VehicleGeneralProps) => {
    const { getEnterpriseRoute } = useNavContext()
    const { customer } = useCustomerLoadedExisting()
    const navigate = useNavigate()
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)

    const { openSnack } = useSnackBar()

    const vehicleEnteprise = customer.enterprises.find((enterprise) => enterprise.id === vehicle.enterprise.id)

    const onEnterpriseClick = (vehicleEnteprise: Enterprise_Minimal) => {
        navigate(getEnterpriseRoute(vehicleEnteprise.id))
    }

    const onEdited = (vehicle: Vehicle_Minimal) => {
        openSnack({
            type: 'success',
            message: `Véhicule modifié avec succès !`,
        })
    }

    return (
        <Fragment>
            <Panel>
                <Fragment>
                    <TableHeader
                        title={'Informations générales du véhicule'}
                        editClick={() => setEditDialogOpen(true)}
                    />
                    <Divider />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className="pl-4">Libellé</TableCell>
                                <TableCell>{vehicle.title}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Immatriculation</TableCell>
                                <TableCell>{vehicle.registration || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Entreprise</TableCell>
                                <TableCell>
                                    {vehicleEnteprise ? (
                                        <IconText
                                            text={vehicleEnteprise.title}
                                            icon="logout"
                                            side="right"
                                            iconClick={() => onEnterpriseClick(vehicleEnteprise)}
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Description</TableCell>
                                <TableCell>{vehicle.description || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Flotte</TableCell>
                                <TableCell>{vehicle.fleet?.title || '-'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Fragment>
            </Panel>
            <VehicleDialog
                closeDialog={() => setEditDialogOpen(false)}
                open={editDialogOpen}
                vehicle={vehicle}
                onDone={onEdited}
            />
        </Fragment>
    )
}

export default VehicleGeneral
