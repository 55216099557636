import { useMemo } from 'react'
import useGetVehicles from '../../../../../../helpers/data/getVehicles'
import ErrorDisplay from '../../../../../../helpers/errors/ErrorDisplay'
import { useCustomerLoadedExisting } from '../../../../../../hooks/useCustomer'
import LoadingDisplay from '../../../../../LoadingDisplay'
import DMAButtons, { DMAButtonsProps } from './DMAButtons'
import { DMA_Data, DMA_SetData } from './DocumentManagerAssociation'
import DocumentManagerAssociationElementTextField from './DocumentManagerAssociationElementTextFields'

type DMA_VehiclesProps = DMAButtonsProps & {
    data: DMA_Data
    setData: DMA_SetData
}

const DMA_Vehicles = ({ data, setData, ...buttonsProps }: DMA_VehiclesProps) => {
    const { customer } = useCustomerLoadedExisting()
    const vehiclesProps = useGetVehicles({ customerId: customer.id })

    const content = useMemo(() => {
        if (vehiclesProps.vehiclesLoading) {
            return <LoadingDisplay size={1} />
        } else if (vehiclesProps.error) {
            return (
                <ErrorDisplay
                    message="Erreur lors du chargement des véhicules"
                    queryError={vehiclesProps.error}
                    debug={vehiclesProps.error}
                />
            )
        } else if (!vehiclesProps.vehicles) {
            return <ErrorDisplay message="Erreur lors du chargement des véhicules" debug={vehiclesProps.vehicles} />
        } else {
            const elements = vehiclesProps.vehicles.map((vehicle) => {
                return { label: vehicle.title, id: vehicle.id }
            })

            return (
                <DocumentManagerAssociationElementTextField
                    data={data}
                    setData={setData}
                    elementLabel={'Véhicule'}
                    elements={elements}
                />
            )
        }
    }, [vehiclesProps, data, setData])

    return (
        <>
            {content}
            <DMAButtons {...buttonsProps} />
        </>
    )
}

export default DMA_Vehicles
