import { gql } from '@apollo/client/core'
import User_Minimal from '../User/User_Minimal'
import Enterprise_Minimal from './Enterprise_Minimal'

export default gql`
    ${User_Minimal}
    ${Enterprise_Minimal}
    fragment Enterprise_Users on Enterprise {
        ...Enterprise_Minimal
        notificationUsers(considerAncestors: false) {
            ...User_Minimal
        }
    }
`
