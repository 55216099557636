import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.DisasterDamage_Minimal}
    mutation UpdateDisasterDamage(
        $id: ID!
        $disasterId: ID!
        $guaranteeId: ID!
        $lostAmount: Float!
        $ceiling: String
        $deductible: String
        $indemnity: Float
        $damageTargets: [DamageTargetInput!]!
    ) {
        updateDamage(
            input: {
                id: $id
                disasterId: $disasterId
                guaranteeId: $guaranteeId
                lostAmount: $lostAmount
                ceiling: $ceiling
                deductible: $deductible
                indemnity: $indemnity
                damageTargets: $damageTargets
            }
        ) {
            errors
            status
            statusCode
            disasterDamage: damage {
                ...DisasterDamage_Minimal
            }
        }
    }
`
