import Fade from '@material-ui/core/Fade'
import React, { Fragment } from 'react'
import EnterpriseGeneralRelations from './EnterpriseGeneralRelations'
import EnterpriseGeneralMainData from './EnterpriseGeneralMainData'

const EnterpriseGeneral = () => {
    return (
        <Fragment>
            <>
                <Fade in timeout={300}>
                    <>
                        <div className="mb-6">
                            <EnterpriseGeneralMainData />
                        </div>
                        <EnterpriseGeneralRelations />
                    </>
                </Fade>
            </>
        </Fragment>
    )
}

export default EnterpriseGeneral
