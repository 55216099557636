import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import RoutesHelper from '../../../../app/helpers/RoutesHelper'
import useSettings from '../../../../app/hooks/useSettings'
import LogURL from '../../../../assets/images/aea-custom/AEAE_LOGO_RVB_medium.png'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    brand: {
        padding: '20px 18px 20px 24px',
    },
    hideOnCompact: {
        display: 'none',
    },
}))

type BrandProps = {
    children?: ReactElement
}
const Brand = ({ children }: BrandProps) => {
    const classes = useStyles()
    const { settings } = useSettings()
    const leftSidebar = settings.layout1Settings.leftSidebar
    const { mode } = leftSidebar

    return (
        <Link to={RoutesHelper.views.Root.to()}>
            <div className={clsx('flex items-center justify-between', classes.brand)}>
                <div className="flex items-center">
                    <img src={LogURL} style={{ width: '24px' }} />
                    <span
                        className={clsx({
                            'text-18 ml-2 font-medium sidenavHoverShow': true,
                            [classes.hideOnCompact]: mode === 'compact',
                        })}>
                        Portail AEA
                    </span>
                </div>
                <div
                    className={clsx({
                        sidenavHoverShow: true,
                        [classes.hideOnCompact]: mode === 'compact',
                    })}>
                    {children || null}
                </div>
            </div>
        </Link>
    )
}

export default Brand
