import { gql } from "@apollo/client/core";
import Guarantee_Full from '../Guarantee/Guarantee_Full'

export default gql`
    ${Guarantee_Full}
    fragment Contract_CoveredElements on Contract {
        guarantees {
            ...Guarantee_Full
        }
    }
`
