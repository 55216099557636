import { gql } from "@apollo/client/core";

export default gql`
    mutation UpdateUserPassword(
        $userToken: String!
        $newPassword: String!
        $newPasswordConfirmation: String!
        $firstname: String
        $lastname: String
    ) {
        updateUserPasswordByUserToken(
            input: {
                userToken: $userToken
                newPassword: $newPassword
                newPasswordConfirmation: $newPasswordConfirmation
                firstname: $firstname
                lastname: $lastname
            }
        ) {
            errors
            status
            statusCode
            authToken
        }
    }
`
