import { gql } from "@apollo/client/core";

export default gql`
    mutation DeclareDisaster(
        $customerId: ID!
        $disasterType: String!
        $estimatedAmount: String!
        $placesInvolved: Boolean!
        $placesDescription: String!
        $vehiclesInvolved: Boolean!
        $vehiclesDescription: String!
        $bodyDamageInvolved: Boolean!
        $description: String!
    ) {
        declareDisaster(
            input: {
                projectId: $customerId
                disasterType: $disasterType
                estimatedAmount: $estimatedAmount
                localsInvolved: $placesInvolved
                localsDescription: $placesDescription
                vehiclesInvolved: $vehiclesInvolved
                vehiclesDescription: $vehiclesDescription
                bodyDamageInvolved: $bodyDamageInvolved
                description: $description
            }
        ) {
            errors
            status
            statusCode
        }
    }
`
