import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { isStringInputValid } from '../../../../../utilsTs'
import CustomButton from '../../../../components/CustomButton'
import { DisasterEvent_Minimal } from '../../../../helpers/data/models/disaster/DisasterEvent'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import { useCreateDisasterEvent, useUpdateDisasterEvent } from '../../../../hooks/useMutations'

type DisasterEventDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (DisasterEvent: DisasterEvent_Minimal) => void
    disasterEvent?: DisasterEvent_Minimal
}

type Data = {
    title: string | null
}

const emptyDisasterEvent: Data = {
    title: null,
}

const DisasterEventDialog = ({ disasterEvent, onDone, open, closeDialog }: DisasterEventDialogProps) => {
    const [data, setData] = useState<Data>(
        disasterEvent
            ? {
                  title: disasterEvent.title,
              }
            : emptyDisasterEvent
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createDisasterEventMutation = useCreateDisasterEvent()
    const updateDisasterEventMutation = useUpdateDisasterEvent()

    const { errorAlert } = useErrorService()

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const handleSubmit = async () => {
        if (!data.title) return

        setLoading(true)

        try {
            if (disasterEvent) {
                //Update
                const response = await updateDisasterEventMutation.run({
                    id: disasterEvent.id,
                    title: data.title,
                })

                if (response.disasterEvent) {
                    onDone(response.disasterEvent)
                } else {
                    throw "Une erreur est survenue pendant la mise à jour de l'événement"
                }
            } else {
                const response = await createDisasterEventMutation.run({
                    title: data.title,
                })

                if (response.disasterEvent) {
                    onDone(response.disasterEvent)
                } else {
                    throw "Une erreur est survenue pendant la création de l'événement"
                }
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid = isStringInputValid(data.title)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un événement</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Nom de l'événement *"
                    type="text"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default DisasterEventDialog
