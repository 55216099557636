import { gql } from '@apollo/client/core'

export default gql`
    mutation CreateDisaster(
        $title: String!
        $contractId: ID!
        $eventId: ID
        $disasterCategoryId: ID!
        $status: EnumDisasterStatus!
        $date: String!
        $percentage: Float
        $billingDate: String
        $amount: Float
        $internalReference: String
        $insuranceReference: String
        $comment: String
    ) {
        createDisaster(
            input: {
                title: $title
                contractId: $contractId
                eventId: $eventId
                disasterCategoryId: $disasterCategoryId
                status: $status
                occurredAt: $date
                percentage: $percentage
                billingDate: $billingDate
                amount: $amount
                internalReference: $internalReference
                insuranceReference: $insuranceReference
                comment: $comment
            }
        ) {
            errors
            status
            statusCode
            disaster {
                id
            }
        }
    }
`
