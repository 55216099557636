import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.DisasterPayment_Minimal}
    mutation UpdateDisasterPayment(
        $id: ID!
        $disasterId: ID!
        $amount: Float!
        $scheduledAt: String
        $paidAt: String
        $description: String
        $status: EnumDisasterPaymentStatus!
    ) {
        updateDisasterPayment(
            input: {
                id: $id
                disasterId: $disasterId
                amount: $amount
                scheduledAt: $scheduledAt
                paidAt: $paidAt
                description: $description
                status: $status
            }
        ) {
            errors
            status
            statusCode
            disasterPayment {
                ...DisasterPayment_Minimal
            }
        }
    }
`
