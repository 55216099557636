import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Guarantee.Guarantee_Full}
    mutation CreateContractGuarantee(
        $contractId: ID!
        $guaranteeCategoryId: ID!
        $ceiling: String
        $deductible: String
        $guaranteeTargets: [GuaranteeTargetInput!]
    ) {
        createGuarantee(
            input: {
                contractId: $contractId
                guaranteeCategoryId: $guaranteeCategoryId
                ceiling: $ceiling
                deductible: $deductible
                guaranteeTargets: $guaranteeTargets
            }
        ) {
            errors
            status
            statusCode
            guarantee {
                ...Guarantee_Full
            }
        }
    }
`
