import { gql } from "@apollo/client/core";

export default gql`
    fragment Document_Version on DocumentVersion {
        id
        active
        urlToFile
        value
    }
`
