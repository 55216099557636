import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EnumUser } from '../../../../../../graphql/typings/global_types'
import IconHelper from '../../../../../helpers/IconHelper'
import { Place_Minimal } from '../../../../../helpers/data/models/Place'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useEnterpriseLoadedExisting } from '../../../../../hooks/useEnterprise'
import { useDeletePlace } from '../../../../../hooks/useMutations'
import useNavContext from '../../../../../hooks/useNavContext'
import useSnackBar from '../../../../../hooks/useSnackBar'
import DataTable from '../../../../DataTable'
import { ConfirmDialog } from '../../../../Dialog'
import Panel from '../../../../Panel'
import PlaceDialog from '../../../places/PlaceDialog'

type DialogState =
    | {
          open: true
          place: Place_Minimal
      }
    | { open: false }

const EnterprisePlacesList = () => {
    const { customer } = useCustomerLoadedExisting()
    const { enterprise } = useEnterpriseLoadedExisting()
    const { openSnack } = useSnackBar()
    const { getPlaceRoute } = useNavContext()
    const { errorAlert } = useErrorService()
    const navigate = useNavigate()
    const deletePlaceMutation = useDeletePlace()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const places = enterprise.places

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, place: Place_Minimal) => {
        event.stopPropagation()

        setOpenEditDialog({
            open: true,
            place,
        })
    }

    const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, place: Place_Minimal) => {
        event.stopPropagation()

        setOpenDeleteDialog({
            open: true,
            place,
        })
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `Le local a été créée et ajouté à cette entreprise avec succès !`,
        })
    }

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: `Le local a été édité avec succès !`,
        })
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un local`,
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const response = await deletePlaceMutation.run({
                enterpriseId: enterprise.id,
                id: openDeleteDialog.place.id,
                customerId: customer.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression du local'
            } else {
                openSnack({
                    type: 'success',
                    message: 'Local supprimé avec succès !',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteDialog({
                open: false,
            })
        }
    }

    const onDeleteCancelled = () => {
        setOpenDeleteDialog({
            open: false,
        })
    }

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Libellé',
        },
        {
            name: 'address',
            label: 'Adresse',
        },
        {
            name: 'site',
            label: 'Site',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const site = places[dataIndex].site
                    return <div>{site ? site.title : '-'}</div>
                },
            },
        },
    ]

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const place = places[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier le local">
                    <IconButton onClick={(event) => onEditClick(event, place)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le local">
                    <IconButton onClick={(event) => onDeleteClick(event, place)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const onRowClick = (place: Place_Minimal) => {
        navigate(getPlaceRoute(place.id))
    }

    return (
        <Fragment>
            <Panel>
                <Fragment>
                    <DataTable<Place_Minimal>
                        title={`Locaux de l'entreprise`}
                        icon={IconHelper.elementIcons.placeIcon}
                        data={places}
                        columns={columns}
                        showSearch={true}
                        addFeature={addFeatureData}
                        actionsColumn={actionsColumn}
                        rowClick={onRowClick}
                    />
                    <PlaceDialog
                        onDone={onCreated}
                        open={openAddDialog}
                        closeDialog={() => setOpenAddDialog(false)}
                        placeEnterprise={enterprise}
                    />
                    {openEditDialog.open && (
                        <PlaceDialog
                            onDone={onEdited}
                            open={openEditDialog.open}
                            closeDialog={() => setOpenEditDialog({ open: false })}
                            place={openEditDialog.place}
                            placeEnterprise={enterprise}
                        />
                    )}
                </Fragment>
            </Panel>
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={onDeleteCancelled}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'un local ?"
                    text={`Voulez-vous vraiment supprimer le local "${openDeleteDialog.place.title}" ?`}
                    loading={deleteLoading}
                />
            )}
        </Fragment>
    )
}

export default EnterprisePlacesList
