import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react'
import useSnackBar from '../../hooks/useSnackBar'

export type ErrorBackEnd = {
    statusCode: number
    status: string
    errors: Array<string>
}

const logError = (message: string, debug?: any) => {
    console.error('###############################')
    console.error('Message : ', message)
    if (debug) console.error('Debug : ', debug)
    console.error('###############################')
}

const ErrorService = {
    logError,
}

type ErrorServiceContext = {
    errorAlert: (message: any, debug?: any) => void
}

const ErrorServiceContext = createContext<ErrorServiceContext>({
    errorAlert: (message: string, debug?: any) => {},
})

type ProvideErrorServiceProps = {
    children: ReactNode
}

export function ProvideErrorService({ children }: ProvideErrorServiceProps) {
    const errorService = useProvideErrorService()

    return <ErrorServiceContext.Provider value={errorService}>{children}</ErrorServiceContext.Provider>
}

export const useErrorService = () => {
    return useContext(ErrorServiceContext)
}

export const useProvideErrorService = () => {
    const { openSnack } = useSnackBar()

    const [message, setMessage] = useState<string | null>()

    const errorAlert = (message: string, debug?: any) => {
        logError(message, debug)

        const formattedMessage = typeof message === 'string' ? message : 'Une erreur est survenue.'

        setMessage(formattedMessage)
    }

    useEffect(() => {
        if (!message) return

        openSnack({
            type: 'error',
            message: message,
        })
    }, [message])

    return {
        errorAlert,
    }
}

export default ErrorService
