import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.DisasterEvent_Minimal}
    query GetDisasterEvents_Minimal {
        events {
            ...DisasterEvent_Minimal
        }
    }
`
