import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import {
    GetDisaster_Documents,
    GetDisaster_DocumentsVariables,
    GetDisaster_Documents_disaster,
} from '../../../graphql/queries/typings/GetDisaster_Documents'
import {
    GetDisaster_Full,
    GetDisaster_FullVariables,
    GetDisaster_Full_disaster,
} from '../../../graphql/queries/typings/GetDisaster_Full'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'
import { useErrorService } from '../errors/ErrorService'

type DisasterState = {
    error?: QueryError
    disaster: GetDisaster_Full_disaster | null
    disasterLoading: boolean
}

const useGetDisaster = (disasterId: string): DisasterState => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetDisaster_Full, GetDisaster_FullVariables>(queries.GetDisaster_Full, {
        variables: { disasterId: disasterId },
    })

    const errorMessage = 'Erreur lors de la récupération du sinistre'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const disaster = responseData && responseData.disaster ? responseData.disaster : null

        return {
            error,
            disaster,
            disasterLoading: false,
        }
    } else {
        return {
            error,
            disaster: null,
            disasterLoading: true,
        }
    }
}

type DisasterDocumentsState = {
    error?: QueryError
    disaster: GetDisaster_Documents_disaster | null
    disasterLoading: boolean
}

export const useGetDisasterDocuments = (disasterId: string): DisasterDocumentsState => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetDisaster_Documents, GetDisaster_DocumentsVariables>(queries.GetDisaster_Documents, {
        variables: { disasterId: disasterId },
    })

    const errorMessage = 'Erreur lors de la récupération du sinistre'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const disaster = responseData && responseData.disaster ? responseData.disaster : null

        return {
            error,
            disaster,
            disasterLoading: false,
        }
    } else {
        return {
            error,
            disaster: null,
            disasterLoading: true,
        }
    }
}

export default useGetDisaster
