import React, { Fragment } from 'react'
import Breadcrumb from '../../../../matx/components/Breadcrumb'
import IconHelper from '../../../helpers/IconHelper'
import useNavContext from '../../../hooks/useNavContext'
import PlacesList, { PlaceForList } from './PlacesList'

type PlacesProps = {
    places: PlaceForList[]
}

const Places = ({ places }: PlacesProps) => {
    const { getCustomerRoute, getContractsRoute } = useNavContext()

    const fullSegments = [
        {
            name: 'Locaux',
            icon: IconHelper.elementIcons.placeIcon,
            path: getContractsRoute().pathname,
        },
    ]

    const customerRoute = getCustomerRoute()

    return (
        <Fragment>
            <div className="mb-sm-30">
                <Breadcrumb homePath={customerRoute.pathname} routeSegments={fullSegments} />
            </div>
            <PlacesList places={places} />
        </Fragment>
    )
}

export default Places
