import { gql } from '@apollo/client/core'

export default gql`
    mutation CreateCustomer($title: String!, $supervisorId: ID!) {
        createProject(input: { title: $title, supervisorId: $supervisorId }) {
            errors
            status
            statusCode
            customer: project {
                id
            }
        }
    }
`
