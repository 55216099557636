import {
    List,
    ListItem,
    ListItemText,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { EnterpriseGeneric_enterprise } from '../../../../graphql/queries/typings/EnterpriseGeneric'
import { UserGeneric_user } from '../../../../graphql/queries/typings/UserGeneric'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import CustomButton from '../../CustomButton'
import { Dialog } from '../../Dialog'
import { useMemo } from 'react'
import UserHelper from '../../../helpers/UserHelper'
import { GetCustomer_Users_project_users } from '../../../../graphql/queries/typings/GetCustomer_Users'

type EnterpriseNotificationsDialogProps = {
    enterprise: EnterpriseForDialog
    open: boolean
    closeDialog: () => void
    onDone: () => void
}

type EnterpriseForDialog = Pick<EnterpriseGeneric_enterprise, 'id' | 'notificationUsers'>

const EnterpriseNotificationsDialog = ({
    enterprise,
    onDone,
    open,
    closeDialog,
}: EnterpriseNotificationsDialogProps) => {
    const { customer } = useCustomerLoadedExisting()

    const notificationsUsers: GetCustomer_Users_project_users[] = useMemo(() => {
        return enterprise.notificationUsers
            .map((notif) => {
                return customer.users.find((user) => user.id === notif.id)
            })
            .filter((user): user is GetCustomer_Users_project_users => Boolean(user))
            .sort((userA, userB) => {
                if (!userA || !userB) return 0

                return userA.firstname.localeCompare(userB.firstname)
            })
    }, [enterprise, customer.users])

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Liste des utilisateurs notifiés</DialogTitle>
            <DialogContent>
                {notificationsUsers.length > 0 && (
                    <List disablePadding>
                        {notificationsUsers.map((user) => {
                            const fullName = UserHelper.getFullName(user.firstname, user.lastname)

                            return (
                                <ListItem>
                                    <ListItemText primary={fullName} />
                                </ListItem>
                            )
                        })}
                    </List>
                )}
                {notificationsUsers.length === 0 && (
                    <Typography variant="caption">Aucun utilisateur notifié</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" onClick={onDone} color="primary">
                    OK
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default EnterpriseNotificationsDialog
