import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import {
    GetEnterprise_Documents,
    GetEnterprise_DocumentsVariables,
    GetEnterprise_Documents_enterprise,
} from '../../../graphql/queries/typings/GetEnterprise_Documents'
import {
    GetEnterprise_Elements,
    GetEnterprise_ElementsVariables,
    GetEnterprise_Elements_enterprise,
} from '../../../graphql/queries/typings/GetEnterprise_Elements'
import {
    GetEnterprise_Full,
    GetEnterprise_FullVariables,
    GetEnterprise_Full_enterprise,
} from '../../../graphql/queries/typings/GetEnterprise_Full'
import {
    GetEnterprise_Guarantees,
    GetEnterprise_GuaranteesVariables,
    GetEnterprise_Guarantees_enterprise,
} from '../../../graphql/queries/typings/GetEnterprise_Guarantees'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'
import { useErrorService } from '../errors/ErrorService'

type EnterpriseData = {
    error?: QueryError
    enterprise: GetEnterprise_Full_enterprise | null
    enterpriseLoading: boolean
}

const useGetEnterprise = (enterpriseId: string): EnterpriseData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetEnterprise_Full, GetEnterprise_FullVariables>(queries.GetEnterprise_Full, {
        variables: { enterpriseId },
    })

    const errorMessage = "Erreur lors de la récupération de l'entreprise"
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const enterprise = responseData && responseData.enterprise ? responseData.enterprise : null

        return {
            error,
            enterprise,
            enterpriseLoading: false,
        }
    } else {
        return {
            error,
            enterprise: null,
            enterpriseLoading: true,
        }
    }
}

type EnterpriseDocumentsData = {
    error?: QueryError
    enterprise: GetEnterprise_Documents_enterprise | null
    enterpriseLoading: boolean
}

export const useGetEnterpriseDocuments = (enterpriseId: string): EnterpriseDocumentsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetEnterprise_Documents, GetEnterprise_DocumentsVariables>(
        queries.GetEnterprise_Documents,
        {
            variables: { enterpriseId },
        }
    )

    const errorMessage = "Erreur lors de la récupération de l'entreprise"
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const enterprise = responseData && responseData.enterprise ? responseData.enterprise : null

        return {
            error,
            enterprise,
            enterpriseLoading: false,
        }
    } else {
        return {
            error,
            enterprise: null,
            enterpriseLoading: true,
        }
    }
}

type EnterpriseElementsData = {
    error?: QueryError
    enterprise: GetEnterprise_Elements_enterprise | null
    enterpriseLoading: boolean
}

export const useGetEnterpriseElements = (enterpriseId: string): EnterpriseElementsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetEnterprise_Elements, GetEnterprise_ElementsVariables>(
        queries.GetEnterprise_Elements,
        {
            variables: { enterpriseId },
            fetchPolicy: 'network-only',
        }
    )

    const errorMessage = "Erreur lors de la récupération de l'entreprise"
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const enterprise = responseData && responseData.enterprise ? responseData.enterprise : null

        return {
            error,
            enterprise,
            enterpriseLoading: false,
        }
    } else {
        return {
            error,
            enterprise: null,
            enterpriseLoading: true,
        }
    }
}

type EnterpriseGuaranteesData = {
    error?: QueryError
    enterprise: GetEnterprise_Guarantees_enterprise | null
    enterpriseLoading: boolean
}

export const useGetEnterpriseGuarantees = (enterpriseId: string): EnterpriseGuaranteesData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetEnterprise_Guarantees, GetEnterprise_GuaranteesVariables>(
        queries.GetEnterprise_Guarantees,
        {
            variables: {
                enterpriseId: enterpriseId,
            },
            fetchPolicy: 'cache-and-network',
        }
    )

    const errorMessage = 'Erreur lors de la récupération du local'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const enterprise = responseData && responseData.enterprise ? responseData.enterprise : null

        return {
            error,
            enterprise,
            enterpriseLoading: false,
        }
    } else {
        return {
            error,
            enterprise: null,
            enterpriseLoading: true,
        }
    }
}

export default useGetEnterprise
