import { Icon } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import React from 'react'

export type BreadcrumbProps = {
    routeSegments: BreadcrumbSegment[]
    homePath: string
}

export type BreadcrumbSegment = {
    name: string
    path?: string
    icon?: string
}

const Breadcrumb = ({ routeSegments, homePath }: BreadcrumbProps) => {
    const lastSegment = routeSegments[routeSegments.length - 1]

    return (
        <div>
            <h3>
                <Grid container alignItems="center">
                    <Grid item>{lastSegment.icon && <Icon className="mt-1 mr-3">{lastSegment.icon}</Icon>}</Grid>
                    <Grid item>{lastSegment.name}</Grid>
                </Grid>
            </h3>
        </div>
        // <div className="flex flex-wrap items-center">
        //     {routeSegments ? (
        //         <Hidden xsDown>
        //             <Fragment />
        //             <h3 className="m-0 pb-1px text-16 capitalize align-middle">{lastSegment.name}</h3>
        //             <h4 className="m-0 pb-3px ml-2 text-hint">|</h4>
        //         </Hidden>
        //     ) : null}
        //     <Breadcrumbs
        //         separator={<Icon className="text-hint">navigate_next</Icon>}
        //         className="flex items-center relative">
        //         <NavLink to={homePath}>
        //             <Icon className="align-middle ml-2 mb-1" color="primary">
        //                 home
        //             </Icon>
        //         </NavLink>
        //         {routeSegments
        //             ? routeSegments.map((segment: BreadcrumbSegment, index: number) => {
        //                   return index !== routeSegments.length - 1 ? (
        //                       <NavLink key={index} to={segment.path}>
        //                           <span className="capitalize text-muted">{segment.name}</span>
        //                       </NavLink>
        //                   ) : (
        //                       <span key={index} className="capitalize text-muted">
        //                           <Grid container alignItems="center">
        //                               {/* <Grid item>
        //                                   {segment.icon && <Icon className="text-16 mt-1 mr-2">{segment.icon}</Icon>}
        //                               </Grid> */}
        //                               <Grid item>{segment.name}</Grid>
        //                           </Grid>
        //                       </span>
        //                   )
        //               })
        //             : null}
        //     </Breadcrumbs>
        // </div>
    )
}

export default Breadcrumb
