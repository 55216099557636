import GetAdminDashboardData from './GetAdminDashboardData'
import GetApiInfos from './GetApiInfos'
import GetBrokers_Minimal from './GetBrokers_Minimal'
import GetCommonData from './GetCommonData'
import GetContracts from './GetContracts'
import GetContractsForList from './GetContractsForList'
import GetContracts_Minimal from './GetContracts_Minimal'
import GetContract_CoveredElements from './GetContract_CoveredElements'
import GetContract_Disasters from './GetContract_Disasters'
import GetContract_Documents from './GetContract_Documents'
import GetContract_Full from './GetContract_Full'
import GetCustomerDashboardData from './GetCustomerDashboardData'
import GetCustomers_Minimal from './GetCustomers_Minimal'
import GetCustomer_EnterprisesForChart from './GetCustomer_EnterprisesForChart'
import GetCustomer_Users from './GetCustomer_Users'
import GetDisasterCategories from './GetDisasterCategories'
import GetDisasterEvents_Minimal from './GetDisasterEvents_Minimal'
import GetDisastersIndicators from './GetDisastersIndicators'
import GetDisasters_Minimal from './GetDisasters_Minimal'
import GetDisaster_Documents from './GetDisaster_Documents'
import GetDisaster_Full from './GetDisaster_Full'
import GetDocuments_User from './GetDocuments_User'
import GetEnterpriseContracts from './GetEnterpriseContracts'
import GetEnterprise_Documents from './GetEnterprise_Documents'
import GetEnterprise_Elements from './GetEnterprise_Elements'
import GetEnterprise_Full from './GetEnterprise_Full'
import GetEnterprise_Guarantees from './GetEnterprise_Guarantees'
import GetFleets_Minimal from './GetFleets_Minimal'
import GetFleet_Documents from './GetFleet_Documents'
import GetGoods_Minimal from './GetGoods_Minimal'
import GetGood_Documents from './GetGood_Documents'
import GetGuaranteeCategories from './GetGuaranteeCategories'
import GetInsurances_Minimal from './GetInsurances_Minimal'
import GetInsurers_Minimal from './GetInsurers_Minimal'
import GetPlace from './GetPlace'
import GetPlace_Documents from './GetPlace_Documents'
import GetPreventionCategories_Minimal from './GetPreventionCategories_Minimal'
import GetPreventionsForDMA from './GetPreventionsForDMA'
import GetPreventions_PlaceMinimal from './GetPreventions_PlaceMinimal'
import GetSites_Minimal from './GetSites_Minimal'
import GetUsers_Minimal from './GetUsers_Minimal'
import GetVehicles_Minimal from './GetVehicles_Minimal'
import GetVehicle_Documents from './GetVehicle_Documents'
import GetVehicle_Full from './GetVehicle_Full'
import GetVehicle_Guarantees from './GetVehicle_Guarantees'
import WhoAmI from './WhoAmI'

const queries = {
    GetApiInfos,
    GetBrokers_Minimal,
    GetContracts,
    GetContracts_Minimal,
    GetContract_Full,
    GetEnterprise_Full,
    GetEnterprise_Elements,
    GetInsurances_Minimal,
    GetInsurers_Minimal,
    GetCustomers_Minimal,
    GetCustomer_Users,
    WhoAmI,
    GetPreventions_PlaceMinimal,
    GetPreventionsForDMA,
    GetSites_Minimal,
    GetUsers_Minimal,
    GetDisasters_Minimal,
    GetDisaster_Full,
    GetContract_CoveredElements,
    GetDisasterEvents_Minimal,
    GetContract_Disasters,
    GetAdminDashboardData,
    GetCustomerDashboardData,
    GetDocuments_User,
    GetContract_Documents,
    GetEnterprise_Documents,
    GetEnterprise_Guarantees,
    GetDisaster_Documents,
    GetDisasterCategories,
    GetGuaranteeCategories,
    GetPlace_Documents,
    GetGoods_Minimal,
    GetFleets_Minimal,
    GetVehicles_Minimal,
    GetPreventionCategories_Minimal,
    GetVehicle_Documents,
    GetFleet_Documents,
    GetGood_Documents,
    GetVehicle_Full,
    GetVehicle_Guarantees,
    GetCommonData,
    GetCustomer_EnterprisesForChart,
    GetEnterpriseContracts,
    GetPlace,
    GetDisastersIndicators,
    GetContractsForList,
}

export default queries
