import Icon from '@material-ui/core/Icon/Icon'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { DocumentGeneric_document } from '../../../../graphql/queries/typings/DocumentGeneric'
import DocumentHelper from '../../../helpers/DocumentHelper'
import { useErrorService } from '../../../helpers/errors/ErrorService'

type DocumentForDownload = Pick<DocumentGeneric_document, 'id' | 'title' | 'activeUrl'>

type DocumentListDownloadProps = {
    document: DocumentForDownload
}

const DocumentListDownload = ({ document }: DocumentListDownloadProps) => {
    const { errorAlert } = useErrorService()

    const downloadDoc = () => {
        if (!document.activeUrl) {
            errorAlert('Aucun fichier rattaché.', document)
            return
        }

        DocumentHelper.downloadDocumentFromUrl(document.title, document.activeUrl)
    }

    return (
        <Tooltip title="Télécharger le document">
            <IconButton onClick={downloadDoc} color="primary">
                <Icon>get_app</Icon>
            </IconButton>
        </Tooltip>
    )
}

export default DocumentListDownload
