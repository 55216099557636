import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    ThemeProvider,
} from '@material-ui/core'
import { useState } from 'react'
import {
    EnumDocumentCategory,
    EnumDocumentParent,
    EnumDocumentStatus,
} from '../../../../../graphql/typings/global_types'
import { isStringInputValid } from '../../../../../utilsTs'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import { useAuthUser } from '../../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'
import { useCreateDocument } from '../../../../hooks/useMutations'
import useSettings from '../../../../hooks/useSettings'
import CustomButton from '../../../CustomButton'

type AskingDocumentFormDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: () => void
}

type DocumentFormData = {
    title: string | null
    statusComment: string | null
}

const emptyData: DocumentFormData = {
    title: null,
    statusComment: null,
}

const AskingDocumentFormDialog = ({ open, closeDialog, onDone }: AskingDocumentFormDialogProps) => {
    const [data, setData] = useState<DocumentFormData>(emptyData)

    const [loading, setLoading] = useState<boolean>(false)

    const auth = useAuthUser()
    const { settings } = useSettings()
    const createDocumentMutation = useCreateDocument()
    const { customer } = useCustomerLoadedExisting()
    const { errorAlert } = useErrorService()

    const handleChange = (value: string, prop: 'title' | 'statusComment') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const handleClose = () => {
        setLoading(false)
        setData(emptyData)
        closeDialog()
    }

    const handleSubmit = async () => {
        if (!data.title) return

        setLoading(true)

        try {
            const response = await createDocumentMutation.run({
                customerId: customer.id,
                // By default, we link the doc to the project
                parentType: EnumDocumentParent.Project,
                parentId: customer.id,
                askedById: auth.user.id,
                title: data.title,
                status: EnumDocumentStatus.asked_by_user,
                statusComment: data.statusComment,
                // By default, we set the category to "miscellaneous"
                category: EnumDocumentCategory.miscellaneous,
            })

            if (response.document) {
                onDone()
            } else {
                errorAlert("Une erreur est survenue pendant l'envoi d'un document", response)
            }

            handleClose()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid = isStringInputValid(data.title)

    return (
        <ThemeProvider theme={settings.themes[settings.activeTheme]}>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Demande de document</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Utilisez ce formulaire pour demander un document auprès de votre expert d'assuré.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="title"
                        label="Titre du document *"
                        type="text"
                        fullWidth
                        value={data.title || ''}
                        onChange={(event) => handleChange(event.target.value, 'title')}
                    />
                    <TextField
                        className="mb-5"
                        margin="dense"
                        id="description"
                        label="Indications supplémentaires ?"
                        type="text"
                        fullWidth
                        multiline={true}
                        value={data.statusComment || ''}
                        onChange={(event) => handleChange(event.target.value, 'statusComment')}
                    />
                </DialogContent>
                <DialogActions>
                    <CustomButton color="secondary" onClick={handleClose}>
                        Annuler
                    </CustomButton>
                    <CustomButton
                        loading={loading}
                        variant="outlined"
                        onClick={handleSubmit}
                        color="primary"
                        disabled={!isFormValid}>
                        Valider
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default AskingDocumentFormDialog
