import React from 'react'
import Roadmap from '../../../../components/customer/roadmap/Roadmap'
import IconHelper from '../../../../helpers/IconHelper'
import RoutesHelper from '../../../../helpers/RoutesHelper'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'

const AEACustomerRoadmap = () => {
    const { customer } = useCustomerLoadedExisting()

    return (
        <Roadmap
            routeSegments={[{ name: 'Projets à venir', icon: IconHelper.elementIcons.roadmapIcon }]}
            homePath={RoutesHelper.views.AEA.customer.Home.to({ customerId: customer.id }).pathname}
        />
    )
}

export default AEACustomerRoadmap
