import { BreadcrumbSegment } from '../../../../../matx/components/Breadcrumb'
import IconHelper from '../../../../helpers/IconHelper'
import { useNavContext } from '../../../../hooks/useNavContext'
import { usePlaceLoadedExisting } from '../../../../hooks/usePlace'
import CustomTabs, { CustomTab } from '../../../CustomTabs'
import PlaceContracts from './contracts/PlaceContracts'
import PlaceDisasters from './disasters/PlaceDisasters'
import PlaceDocuments from './documents/PlaceDocuments'
import PlaceGeneral from './general/PlaceGeneral'
import PlacePreventions from './preventions/PlacePreventions'

export type PlaceTabName = 'general' | 'contracts' | 'disasters' | 'documents' | 'prevention'

type PlaceProps = {
    tab?: PlaceTabName
}

const Place = ({ tab }: PlaceProps) => {
    const { place } = usePlaceLoadedExisting()
    const { getPlaceRoute, getPlacesRoute } = useNavContext()

    const tabs: CustomTab<PlaceTabName>[] = [
        {
            id: 'general',
            label: 'Général',
            children: <PlaceGeneral />,
            to: getPlaceRoute(place.id),
        },
        {
            id: 'contracts',
            label: 'Contrats',
            children: <PlaceContracts />,
            to: getPlaceRoute(place.id, 'contracts'),
        },
        {
            id: 'disasters',
            label: 'Sinistres',
            children: <PlaceDisasters />,
            to: getPlaceRoute(place.id, 'disasters'),
        },
        {
            id: 'documents',
            label: 'Documents',
            children: <PlaceDocuments />,
            to: getPlaceRoute(place.id, 'documents'),
        },
        {
            id: 'prevention',
            label: 'Prévention',
            children: <PlacePreventions />,
            to: getPlaceRoute(place.id, 'prevention'),
        },
    ]

    const breadcrumbSegments: BreadcrumbSegment[] = [
        {
            name: 'Locaux',
            path: getPlacesRoute().pathname,
        },
        {
            name: place.title,
            icon: IconHelper.elementIcons.placeIcon,
            path: getPlaceRoute(place.id).pathname,
        },
    ]

    return (
        <CustomTabs
            breadcrumbSegments={breadcrumbSegments}
            activeTab={tab || 'general'}
            tabs={tabs}
        />
    )
}

export default Place
