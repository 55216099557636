import { gql } from '@apollo/client/core'

export default gql`
    mutation UpdateEnterpriseParents($enterpriseId: ID!, $parentIds: [ID!]!) {
        updateEnterpriseParents(input: { enterpriseId: $enterpriseId, parentIds: $parentIds }) {
            errors
            status
            statusCode
            enterprise {
                id
                parents {
                    id
                    title
                }
            }
        }
    }
`
