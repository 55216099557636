import { gql } from '@apollo/client/core'
import Broker_Minimal from '../Broker/Broker_Minimal'
import Guarantee_Minimal from '../Guarantee/Guarantee_Minimal'
import Insurance_Minimal from '../Insurance/Insurance_Minimal'
import Insurer_Minimal from '../Insurer/Insurer_Minimal'
import DisasterCategory from './DisasterCategory'
import DisasterDamage_Minimal from './DisasterDamage/DisasterDamage_Minimal'
import DisasterEvent_Minimal from './DisasterEvent/DisasterEvent_Minimal'
import DisasterPayment_Minimal from './DisasterPayment/DisasterPayment_Minimal'

export default gql`
    ${DisasterCategory}
    ${DisasterEvent_Minimal}
    ${DisasterDamage_Minimal}
    ${DisasterPayment_Minimal}
    ${Insurer_Minimal}
    ${Broker_Minimal}
    ${Insurance_Minimal}
    ${Guarantee_Minimal}
    fragment Disaster_Full on Disaster {
        id
        disasterCategory {
            ...DisasterCategory
        }
        date: occurredAt
        status
        percentage
        amount
        billingDate
        insuranceReference
        internalReference
        comment
        event {
            ...DisasterEvent_Minimal
        }
        title
        disasterDamages: damages {
            ...DisasterDamage_Minimal
        }
        payments: disasterPayments {
            ...DisasterPayment_Minimal
        }
        customer: project {
            id
            title
        }
        contract {
            id
            reference
            title
            bounty
            startAt
            renewableDate
            renewableNotice
            insurer {
                ...Insurer_Minimal
            }
            broker {
                ...Broker_Minimal
            }
            insurance {
                ...Insurance_Minimal
            }
            guarantees {
                ...Guarantee_Minimal
            }
            enterprise {
                id
                address
                title
            }
        }
    }
`
