import { FormControlLabel, Switch, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid/Grid'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { Fragment, useMemo, useState } from 'react'
import { useContractsLoadedExisting } from '../../../hooks/useContracts'
import MoneyDisplayAnalyzer from '../../customInputs/MoneyDisplayAnalyzer'
import { DateRange } from '../../DateCategoryInput'
import ContractsList from './ContractsList'

const Contracts = () => {
    const defaultDate = getYearDateRange(new Date())
    const [dateRange, setDateRange] = useState<DateRange>(defaultDate)
    const [checked, setChecked] = useState<boolean>(true)

    const { contracts } = useContractsLoadedExisting()

    function getYearDateRange(date: Date): DateRange {
        const startDate = new Date(date.getFullYear(), 0, 1)
        const endDate = new Date(date.getFullYear(), 11, 31, 23, 59, 59)

        return {
            startDate,
            endDate,
        }
    }

    const handleDateChange = (date: MaterialUiPickersDate) => {
        if (!date) return
        setDateRange(getYearDateRange(date))
    }

    const filteredContracts = useMemo(() => {
        if (checked) {
            return contracts.filter((contract) => {
                const startTime = new Date(contract.startAt).getTime()
                const endTime = new Date(contract.renewableDate).getTime()
                const now = Date.now()

                return startTime < now && endTime > now
            })
        } else {
            return contracts.filter((contract) => {
                const contractStartTime = new Date(contract.startAt).getTime()
                const startRangeTime = dateRange.startDate?.getTime() || null
                const endRangeTime = dateRange.endDate?.getTime() || null

                if (startRangeTime !== null && endRangeTime !== null) {
                    return contractStartTime >= startRangeTime && contractStartTime <= endRangeTime
                } else if (startRangeTime === null && endRangeTime !== null) {
                    return contractStartTime <= endRangeTime
                } else if (startRangeTime !== null && endRangeTime === null) {
                    return contractStartTime >= startRangeTime
                } else {
                    // start & end null
                    return true
                }
            })
        }
    }, [contracts, dateRange, checked])

    const bountyComponent = useMemo(() => {
        if (!contracts) return

        let bountySum = 0

        filteredContracts.forEach((contract) => {
            bountySum += contract.bounty
        })

        return <MoneyDisplayAnalyzer value={bountySum} />
    }, [filteredContracts])

    const handleSwitchChange = (checked: boolean) => {
        setChecked(checked)
    }

    return (
        <Fragment>
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
                className="mb-5"
                style={{ width: '100%' }}>
                <Grid item>
                    <Grid container direction="row" spacing={1} className="mt-1">
                        <Grid item>
                            <Typography variant="body1">Total des primes : </Typography>
                        </Grid>
                        <Grid item>{bountyComponent}</Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch checked={checked} onChange={(event) => handleSwitchChange(event.target.checked)} />
                        }
                        label="Contrats en cours"
                    />
                </Grid>
                <Grid item>
                    <DatePicker
                        variant="dialog"
                        margin="dense"
                        id="date"
                        label="Année de prise d'effet"
                        views={['year']}
                        value={dateRange.startDate}
                        onChange={(date) => handleDateChange(date)}
                        fullWidth
                        format="yyyy"
                        autoOk={true}
                        inputVariant="outlined"
                        inputProps={{ style: { cursor: 'pointer' } }}
                        style={{ maxWidth: '150px', marginRight: 10, cursor: 'pointer' }}
                        disabled={checked}
                    />
                </Grid>
            </Grid>
            <ContractsList title={'Contrats'} contracts={filteredContracts} />
        </Fragment>
    )
}

export default Contracts
