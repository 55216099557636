import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const localStorageTokenKey = process.env.REACT_APP_LOCAL_STORAGE_AUTH_TOKEN as string

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_SERVER_GRAPHQL_URL,
    // credentials: 'include',
})

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(localStorageTokenKey) || null

    const customHeaders: any = {}
    customHeaders.authToken = token || ''
    customHeaders.FrontApp = 'nutshellWeb'

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            ...customHeaders,
        },
    }
})

const link = authLink.concat(httpLink)

export const client = new ApolloClient<NormalizedCacheObject>({
    cache: new InMemoryCache(),
    link: link as any,
})
