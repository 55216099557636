import { useCallback } from 'react'
import { EnumDocumentParent } from '../../../../../../../graphql/typings/global_types'
import ContractHelper from '../../../../../../helpers/ContractHelper'
import DocumentHelper from '../../../../../../helpers/DocumentHelper'
import { useContractsLoadedExisting } from '../../../../../../hooks/useContracts'
import { useCustomerLoadedExisting } from '../../../../../../hooks/useCustomer'
import { useDisastersLoadedExisting } from '../../../../../../hooks/useDisasters'
import DMAButtons, { DMAButtonsProps } from './DMAButtons'
import { DMA_Data, DMA_SetData } from './DocumentManagerAssociation'
import DocumentManagerAssociationElementTextField, { DMA_Element } from './DocumentManagerAssociationElementTextFields'

type DMA_ElseProps = DMAButtonsProps & {
    data: DMA_Data
    setData: DMA_SetData
}

const DMA_Else = ({ data, setData, ...buttonsProps }: DMA_ElseProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { contracts } = useContractsLoadedExisting()
    const { disasters } = useDisastersLoadedExisting()

    const getDocElementPicker = useCallback(() => {
        if (!data.docElementType) return null
        if (data.docElementType === EnumDocumentParent.Project) return null

        const elementLabel = DocumentHelper.getDocumentElementCategoryLabel(data.docElementType)

        let elementList: DMA_Element[] = []

        switch (data.docElementType) {
            case EnumDocumentParent.Enterprise:
                elementList = customer.enterprises.map((enterprise) => {
                    return { label: enterprise.title, id: enterprise.id }
                })
                break
            case EnumDocumentParent.Contract:
                elementList = contracts.map((contract) => {
                    return { label: ContractHelper.getContractLabel(contract), id: contract.id }
                })
                break
            case EnumDocumentParent.Disaster:
                elementList = disasters.map((disaster) => {
                    return { label: disaster.title, id: disaster.id }
                })
                break
            case EnumDocumentParent.Local:
                elementList = customer.places.map((place) => {
                    return { label: place.title, id: place.id }
                })
                break
            default:
                elementList = []
        }

        return (
            <DocumentManagerAssociationElementTextField
                data={data}
                setData={setData}
                elementLabel={elementLabel}
                elements={elementList}
            />
        )
    }, [data.docElementType, data.docElementId, setData])

    const docElementPicker = getDocElementPicker()

    return (
        <>
            {docElementPicker}
            <DMAButtons {...buttonsProps} />
        </>
    )
}

export default DMA_Else
