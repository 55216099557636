import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import MailIcon from '@material-ui/icons/Mail'
import UserHelper from '../../../helpers/UserHelper'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import ElevatedCard from '../../ElevatedCard'

const CustomerAdmin = () => {
    const auth = useAuthUser()
    const { customer } = useCustomerLoadedExisting()
    const { supervisor } = customer

    const onMailClick = () => {
        const email = supervisor.email
        const subject = `Client ${customer.title} - Utilisateur ${UserHelper.getFullName(
            auth.user.firstname,
            auth.user.lastname
        )}`

        document.location.href = `mailto:${email}?subject=${subject}`
    }

    return (
        <ElevatedCard title="Votre contact AEA">
            <div className="p-6 flex flex-wrap justify-between items-center m--2">
                <div className="flex items-center m-2">
                    <Avatar className="h-72 w-72" src={UserHelper.getAvatar(supervisor)} />
                    <div className="ml-4">
                        <h5 className="m-0">{UserHelper.getFullName(supervisor.firstname, supervisor.lastname)}</h5>
                        <p className="mb-0 mt-2 text-muted font-normal capitalize">{supervisor.email}</p>
                        {supervisor.phone && (
                            <p className="mb-0 mt-2 text-muted font-normal capitalize">{supervisor.phone}</p>
                        )}
                    </div>
                </div>
                <div className="flex m-2">
                    <Button
                        size="medium"
                        className="px-5 mr-1"
                        startIcon={<MailIcon />}
                        onClick={onMailClick}
                        variant="contained"
                        color="primary">
                        Contacter
                    </Button>
                </div>
            </div>
        </ElevatedCard>
    )
}

export default CustomerAdmin
