import { createContext, ReactElement, useContext } from 'react'
import { GetPlace_place } from '../../graphql/queries/typings/GetPlace'
import useGetPlace from '../helpers/data/getPlace'
import { QueryError } from '../helpers/errors/ErrorHelper'

type PlaceContext = {
    error?: QueryError
    place: GetPlace_place | null
    placeLoading: boolean
}

const PlaceContext = createContext<PlaceContext>({
    place: null,
    placeLoading: true,
})

type ProvidePlaceProps = {
    children: ReactElement
    placeId: string
}

export function ProvidePlace({ children, placeId }: ProvidePlaceProps) {
    const placeData = useProvidePlace(placeId)

    return <PlaceContext.Provider value={placeData}>{children}</PlaceContext.Provider>
}

export const usePlace = () => {
    return useContext(PlaceContext)
}

export const usePlaceLoadedExisting = () => {
    const context = useContext(PlaceContext)

    if (context.placeLoading) {
        console.error('Current place is loading !')
        throw new Error('Current place is loading')
    }

    if (!context.place) {
        console.error(`Place is loading !`)
        throw new Error('Place is loading !')
    }

    return {
        place: context.place,
        placeLoading: context.placeLoading,
    }
}

const useProvidePlace = (placeId: string): PlaceContext => {
    const { place, placeLoading, error } = useGetPlace(placeId)

    return {
        place,
        placeLoading,
        error,
    }
}

export default usePlace
