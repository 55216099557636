import DateFnsUtils from '@date-io/date-fns'
import { fr } from 'date-fns/locale'
import { DateRange } from '../components/DateCategoryInput'

const DateHelper = () => {
    const fnUtils = new DateFnsUtils({ locale: fr })

    const fromServertoString = (isoDate: string) => {
        const date = new Date(isoDate).toLocaleDateString()

        return date
    }

    const fromDateToServer = (date: Date) => {
        return date.toISOString()
    }

    const isInDateRange = (serverDate: string, dateRange: DateRange) => {
        const serverDateTimestamp = new Date(serverDate).getTime()

        const startRangeTimestamp = dateRange.startDate ? dateRange.startDate.getTime() : null
        const endRangeTimestamp = dateRange.endDate ? dateRange.endDate.getTime() : null

        const isAfterStart = !startRangeTimestamp || serverDateTimestamp >= startRangeTimestamp
        const isBeforeEnd = !endRangeTimestamp || serverDateTimestamp <= endRangeTimestamp

        return isAfterStart && isBeforeEnd
    }

    return {
        fromServertoString,
        fromDateToServer,
        isInDateRange,
        fnUtils,
        locale: fr,
    }
}

export default DateHelper
