import { Navigate, Outlet } from 'react-router-dom'
import { EnumUser } from '../../../graphql/typings/global_types'
import RoutesHelper from '../../helpers/RoutesHelper'
import { useAuthUser } from '../../hooks/useAuth'
import { ProvideCommonData } from '../../hooks/useCommonData'
import { ProvideCustomers } from '../../hooks/useCustomers'

const AEAZone = () => {
    const auth = useAuthUser()

    if (auth.user.userType === EnumUser.admin) {
        return <AEAZoneReady />
    } else {
        return <Navigate to={RoutesHelper.views.Customer.Home.to()} />
    }
}

const AEAZoneReady = () => {
    return (
        <ProvideCommonData>
            <ProvideCustomers>
                <Outlet />
            </ProvideCustomers>
        </ProvideCommonData>
    )
}

export default AEAZone
