import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { Fragment, useEffect, useState } from 'react'
import { useErrorService } from '../helpers/errors/ErrorService'
import useSnackBar from '../hooks/useSnackBar'

export enum DateCategory {
    all = 'Tout',
    last_30_days = '30 derniers jours',
    last_6_months = '6 derniers mois',
    last_12_months = '12 derniers mois',
    this_year = "Depuis le début de l'année",
}

export type DateRange = {
    startDate: Date | null
    endDate: Date | null
}

type DateCategoryInputProps = {
    onDateRangeChange: (startDate: Date | null, endDate: Date | null) => void
}

const customDateValue = 'custom'

const DateCategoryInput = ({ onDateRangeChange }: DateCategoryInputProps) => {
    const [dateCategory, setDateCategory] = useState<DateCategory>(DateCategory.all)
    const [showPicker, setShowPicker] = useState<boolean>(false)
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: null,
        endDate: null,
    })

    useEffect(() => {
        onDateRangeChange(dateRange.startDate, dateRange.endDate)
    }, [dateRange])

    const { errorAlert } = useErrorService()
    const { openSnack } = useSnackBar()

    const updateDates = (startDate: Date | null, endDate: Date | null) => {
        // Warns the user if the date is invalid

        if (startDate === null || endDate === null) {
            setDateRange({
                startDate,
                endDate,
            })
        } else if (startDate.getTime() > endDate.getTime()) {
            //Error
            openSnack({ message: 'La date de début doit être inférieure à la date de fin', type: 'error' })
        } else {
            setDateRange({
                startDate,
                endDate,
            })
        }
    }

    const handleCategoryChange = (value: string) => {
        const category = value as DateCategory
        setDateCategory(category)

        if (value === customDateValue) {
            setShowPicker(true)
            return
        } else {
            setShowPicker(false)
        }

        var startDate: Date | null = null
        var endDate: Date | null = null

        switch (category) {
            case DateCategory.all:
                startDate = null
                endDate = null
                break
            case DateCategory.last_30_days:
                startDate = new Date()
                startDate.setDate(startDate.getDate() - 30)
                endDate = new Date()
                break
            case DateCategory.last_6_months:
                startDate = new Date()
                startDate.setMonth(startDate.getMonth() - 6)
                endDate = new Date()
                break
            case DateCategory.last_12_months:
                startDate = new Date()
                startDate.setMonth(startDate.getMonth() - 12)
                endDate = new Date()
                break
            case DateCategory.this_year:
                var today = new Date()
                startDate = new Date(today.getFullYear(), 0, 1)
                endDate = new Date()
                break
            default:
                errorAlert('Une erreur est survenue', category)
                startDate = null
                endDate = null
        }

        updateDates(startDate, endDate)
    }

    const handleDateRangeChange = (date: MaterialUiPickersDate, prop: 'start' | 'end') => {
        if (prop === 'start') {
            updateDates(date, dateRange.endDate)
        } else updateDates(dateRange.startDate, date)
    }

    return (
        <Box className="mb-5">
            <TextField
                margin="dense"
                id="dates"
                label="Sélecteur de date"
                type="text"
                variant="outlined"
                value={dateCategory}
                onChange={(event) => handleCategoryChange(event.target.value)}
                select
                style={{ minWidth: 200, marginRight: 10 }}>
                {Object.values(DateCategory).map((dateCategory, index) => {
                    return (
                        <MenuItem value={dateCategory} key={index}>
                            {dateCategory}
                        </MenuItem>
                    )
                })}
                <MenuItem value={customDateValue}>Date personnalisée</MenuItem>
            </TextField>

            {showPicker && (
                <Fragment>
                    <DatePicker
                        variant="dialog"
                        margin="dense"
                        id="start"
                        label="Date de début"
                        value={dateRange.startDate}
                        onChange={(date) => handleDateRangeChange(date, 'start')}
                        fullWidth
                        format="dd/MM/yyyy"
                        autoOk={true}
                        inputVariant="outlined"
                        style={{ maxWidth: '150px', marginRight: 10 }}
                    />
                    <DatePicker
                        variant="dialog"
                        margin="dense"
                        id="end"
                        label="Date de fin"
                        value={dateRange.endDate}
                        onChange={(date) => handleDateRangeChange(date, 'end')}
                        minDate={dateRange.startDate}
                        fullWidth
                        format="dd/MM/yyyy"
                        autoOk={true}
                        inputVariant="outlined"
                        style={{ maxWidth: '150px' }}
                    />
                </Fragment>
            )}
        </Box>
    )
}

export default DateCategoryInput
