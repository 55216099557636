import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Step from '@material-ui/core/Step/Step'
import StepLabel from '@material-ui/core/StepLabel/StepLabel'
import Stepper from '@material-ui/core/Stepper/Stepper'
import { ThemeProvider } from '@material-ui/core/styles'
import { useMemo, useState } from 'react'
import { EnumDocumentCategory, EnumDocumentParent } from '../../../../../../graphql/typings/global_types'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import FileDialogHelper, { FileType } from '../../../../../hooks/useFileDialog'
import useSettings from '../../../../../hooks/useSettings'
import Spacer from '../../../../Spacer'
import DocumentManagerAssociation, {
    DMA_Data,
} from '../DocumentFormsComponents/DocumentManagerAssociation/DocumentManagerAssociation'
import DocumentCreateFiles from './DocumentCreateFiles'
import DocumentCreateInformation from './DocumentCreateInformation'
import DocumentCreateUploading from './DocumentCreateUploading'

export type DocumentCreateProps = {
    open: boolean
    closeDialog: () => void
    onDone: () => void
    linkTo?: {
        docElementType: EnumDocumentParent
        docElementId: string
    }
}

const DocumentCreate = (props: DocumentCreateProps) => {
    const { settings } = useSettings()

    return (
        <ThemeProvider theme={settings.themes[settings.activeTheme]}>
            <Dialog open={props.open} onClose={props.closeDialog}>
                <DialogTitle>Ajout de documents</DialogTitle>
                <DialogContent>
                    <DocumentCreateReady {...props} />
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    )
}

type DocumentCreateReadyProps = DocumentCreateProps

export type DocumentCreateData = {
    category: EnumDocumentCategory | null
    docElementType: EnumDocumentParent | null
    docElementId: string | null
    statusComment: string | null
}

export enum DocumentCreateSteps {
    FILES,
    INFORMATION,
    ASSOCIATION,
    LOADING,
}

export type DocumentManagerNavProps = {
    canGoPrevious: boolean
    canGoNext: boolean
    goPrevious: () => void
    goNext: () => void
}

const DocumentCreateReady = (props: DocumentCreateReadyProps) => {
    const { errorAlert } = useErrorService()
    const manyFileProps = FileDialogHelper.useManyFilesDialog(FileType.ALL)

    const emptyData: DocumentCreateData = useMemo(() => {
        if (!props.linkTo) {
            return {
                category: null,
                docElementType: null,
                docElementId: null,
                statusComment: null,
            }
        } else {
            return {
                category: null,
                docElementType: props.linkTo.docElementType,
                docElementId: props.linkTo.docElementId,
                statusComment: null,
            }
        }
    }, [props.linkTo])

    const [step, setStep] = useState<DocumentCreateSteps>(DocumentCreateSteps.FILES)
    const [data, setData] = useState<DocumentCreateData>(emptyData)

    const steps: string[] = useMemo(() => {
        return ['Téléchargement', 'Informations', 'Association', 'Envoi']
    }, [])

    const canGoPrevious = useMemo(() => {
        return step === DocumentCreateSteps.INFORMATION || step === DocumentCreateSteps.ASSOCIATION
    }, [step])

    const canGoNext = useMemo(() => {
        return (
            step === DocumentCreateSteps.FILES ||
            step === DocumentCreateSteps.INFORMATION ||
            step === DocumentCreateSteps.ASSOCIATION
        )
    }, [step])

    const goPrevious = () => {
        switch (step) {
            case DocumentCreateSteps.INFORMATION:
                setStep(DocumentCreateSteps.FILES)
                break
            case DocumentCreateSteps.ASSOCIATION:
                setStep(DocumentCreateSteps.INFORMATION)
                break
            default:
                errorAlert('Oops, une erreur est survenue.', { function: 'goPrevious', step })
        }
    }

    const goNext = () => {
        switch (step) {
            case DocumentCreateSteps.FILES:
                setStep(DocumentCreateSteps.INFORMATION)
                break
            case DocumentCreateSteps.INFORMATION:
                setStep(DocumentCreateSteps.ASSOCIATION)
                break
            case DocumentCreateSteps.ASSOCIATION:
                setStep(DocumentCreateSteps.LOADING)
                break
            default:
                errorAlert('Oops, une erreur est survenue.', { function: 'goNext', step })
        }
    }

    const navProps: DocumentManagerNavProps = {
        canGoPrevious,
        canGoNext,
        goPrevious,
        goNext,
    }

    const setAssociationData = (data: DMA_Data) => {
        setData((oldData) => {
            return {
                ...oldData,
                docElementId: data.docElementId,
                docElementType: data.docElementType,
            }
        })
    }

    return (
        <>
            <Stepper activeStep={step} alternativeLabel style={{ minWidth: 500, padding: 0 }}>
                {steps.map((stepLabel) => (
                    <Step key={stepLabel}>
                        <StepLabel>{stepLabel}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Spacer />
            {step === DocumentCreateSteps.FILES && (
                <DocumentCreateFiles manyFileProps={manyFileProps} {...navProps} {...props} />
            )}
            {step === DocumentCreateSteps.INFORMATION && (
                <DocumentCreateInformation data={data} setData={setData} {...navProps} {...props} />
            )}
            {step === DocumentCreateSteps.ASSOCIATION && (
                <DocumentManagerAssociation data={data} setData={setAssociationData} {...navProps} {...props} />
            )}
            {step === DocumentCreateSteps.LOADING && (
                <DocumentCreateUploading
                    emptyData={emptyData}
                    data={data}
                    setData={setData}
                    manyFileProps={manyFileProps}
                    onDone={props.onDone}
                    closeDialog={props.closeDialog}
                />
            )}
        </>
    )
}

export default DocumentCreate
