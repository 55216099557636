import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.User.User_Customers}
    query WhoAmI {
        whoami {
            ...User_Customers
        }
    }
`
