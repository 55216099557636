import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.RoadmapItem.RoadmapItem_Minimal}
    mutation CreateRoadmapItem($customerId: ID!, $date: String!, $title: String!, $description: String) {
        createRoadmapItem(input: { projectId: $customerId, date: $date, title: $title, description: $description }) {
            errors
            status
            statusCode
            roadmapItem {
                ...RoadmapItem_Minimal
            }
        }
    }
`
