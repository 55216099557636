import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import NumericFormat, { NumberFormatProps } from 'react-number-format'

type MoneyInputProps = Omit<TextFieldProps, 'type'>

const MoneyInput = (props: MoneyInputProps) => {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: CurrencyFormatInput as any,
            }}
        />
    )
}

interface NumberFormatCustomProps {
    inputRef: (instance: NumericFormat<number> | null) => void
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
}

export const CurrencyFormatInput = (props: NumberFormatCustomProps) => {
    const { inputRef, onChange, ...other } = props

    return (
        <CurrencyFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
        />
    )
}

export const CurrencyFormat = (props: NumberFormatProps) => {
    return (
        <NumericFormat
            {...props}
            thousandSeparator={' '}
            isNumericString
            suffix="€"
            decimalScale={2}
            decimalSeparator={','}
            placeholder={'0,00€'}
            fixedDecimalScale={true}
        />
    )
}

export default MoneyInput
