import { Tooltip } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { Fragment, useState } from 'react'
import { EnumUser } from '../../../../../graphql/typings/global_types'
import DataTable from '../../../../components/DataTable'
import { ConfirmDialog } from '../../../../components/Dialog'
import { Site_Minimal } from '../../../../helpers/data/models/Site'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import IconHelper from '../../../../helpers/IconHelper'
import { useAuthUser } from '../../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'
import { useDeleteSite } from '../../../../hooks/useMutations'
import { useSitesLoadedExisting } from '../../../../hooks/useSites'
import useSnackBar from '../../../../hooks/useSnackBar'
import SiteDialog from './SitesDialog'

type DialogData =
    | {
          open: false
      }
    | {
          open: true
          site: Site_Minimal
      }

type SiteData = {
    title: string
    places: string
    baseSite: Site_Minimal
}

const AEACustomerSitesList = () => {
    const { sites } = useSitesLoadedExisting()
    const { customer } = useCustomerLoadedExisting()
    const auth = useAuthUser()
    const { openSnack } = useSnackBar()
    const deleteSiteMutation = useDeleteSite()
    const { errorAlert } = useErrorService()

    const isAdmin = auth.user.userType === EnumUser.admin

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogData>({ open: false })
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<DialogData>({ open: false })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const formattedSites: SiteData[] = sites.map((site) => {
        const places = customer.places.filter((place) => {
            if (!place.site) return false
            else return place.site.id === site.id
        })

        const placesAsString = places.length === 0 ? 'Aucun' : places.map((place) => place.title).join(',')

        return {
            title: site.title,
            places: placesAsString,
            baseSite: site,
        }
    })

    const onCreated = (site: Site_Minimal) => {
        openSnack({
            type: 'success',
            message: 'Site créé avec succès !',
        })
    }

    const onEditClick = (site: Site_Minimal) => {
        setOpenEditDialog({ open: true, site })
    }

    const onEdited = () => {
        setOpenEditDialog({ open: false })
        openSnack({
            type: 'success',
            message: 'Projet édité avec succès !',
        })
    }

    const onDeleteClick = (site: Site_Minimal) => {
        setOpenDeleteConfirm({ open: true, site })
    }

    const onDeleteCancelled = () => {
        setOpenDeleteConfirm({ open: false })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteConfirm.open) return

        setDeleteLoading(true)

        try {
            const response = await deleteSiteMutation.run({
                id: openDeleteConfirm.site.id,
                customerId: customer.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression du site'
            } else {
                openSnack({
                    type: 'success',
                    message: 'Site supprimé avec succès !',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteConfirm({ open: false })
        }
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un site`,
    }

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Libellé',
        },
        {
            name: 'places',
            label: 'Locaux associés',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const places = formattedSites[dataIndex].places.split(',')

                    return (
                        <div>
                            {places.map((place) => (
                                <div>{place}</div>
                            ))}
                        </div>
                    )
                },
            },
        },
    ]

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return

        const site = sites[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier le site">
                    <IconButton onClick={() => onEditClick(site)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le site">
                    <IconButton onClick={() => onDeleteClick(site)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <Fragment>
            <DataTable
                title={'Sites du projet'}
                icon={IconHelper.elementIcons.siteIcon}
                data={formattedSites}
                columns={columns}
                showSearch={true}
                addFeature={addFeatureData}
                actionsColumn={actionsColumn}
                options={{
                    sortOrder: {
                        name: 'title',
                        direction: 'asc',
                    },
                }}
            />
            <SiteDialog onDone={onCreated} open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} />
            {openEditDialog.open && (
                <SiteDialog
                    site={openEditDialog.site}
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                />
            )}
            {openDeleteConfirm.open && (
                <ConfirmDialog
                    open={openDeleteConfirm.open}
                    title="Suppression d'un site"
                    text="Voulez-vous vraiment supprimer ce site ?"
                    onConfirmed={onDeleteConfirmed}
                    onCancelled={onDeleteCancelled}
                    loading={deleteLoading}
                />
            )}
        </Fragment>
    )
}

export default AEACustomerSitesList
