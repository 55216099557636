import React from 'react'
import { Vehicle_Full } from '../../../../helpers/data/models/fleet/Vehicle'
import DocumentsList from '../../../documentsList/DocumentsList'

type VehicleDocumentsProps = {
    vehicle: Vehicle_Full
}

const VehicleDocuments = ({ vehicle }: VehicleDocumentsProps) => {
    return <DocumentsList mode="confirmed" documents={vehicle.documents} showLink={false} />
}

export default VehicleDocuments
