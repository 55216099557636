import { gql } from "@apollo/client/core";

export default gql`
    query GetCustomerDashboardData($customerId: ID!, $startDate: String!, $endDate: String!) {
        dashboardProject(projectId: $customerId, startDate: $startDate, endDate: $endDate) {
            nbContracts
            nbDisasters
            nbLocalsLinkedToAContract
            nbOpenedDisasters
            pendingIndemnitiesAmount
            contractsBountyAmount
        }
    }
`
