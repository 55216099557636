import { gql } from "@apollo/client/core";

export default gql`
    fragment Insurer_Minimal on Insurer {
        id
        title
        email
        phone
    }
`
