import { createContext, ReactElement, useContext } from 'react'
import { GetEnterprise_Full_enterprise } from '../../graphql/queries/typings/GetEnterprise_Full'
import useGetEnterprise from '../helpers/data/getEnterprise'
import { QueryError } from '../helpers/errors/ErrorHelper'

type EnterpriseContext = {
    error?: QueryError
    enterprise: GetEnterprise_Full_enterprise | null
    enterpriseLoading: boolean
}

const EnterpriseContext = createContext<EnterpriseContext>({
    enterprise: null,
    enterpriseLoading: true,
})

type ProvideEnterpriseProps = {
    enterpriseId: string
    children: ReactElement
}

export function ProvideEnterprise({ children, enterpriseId }: ProvideEnterpriseProps) {
    const enterpriseData = useProvideEnterprise(enterpriseId)

    return <EnterpriseContext.Provider value={enterpriseData}>{children}</EnterpriseContext.Provider>
}

export const useEnterprise = () => {
    return useContext(EnterpriseContext)
}

export const useEnterpriseLoaded = () => {
    const context = useContext(EnterpriseContext)
    if (context.enterpriseLoading) {
        console.error('Current Enterprise is loading !')
        throw new Error('Current Enterprise is loading')
    }

    return context
}

export const useEnterpriseLoadedExisting = () => {
    const context = useContext(EnterpriseContext)
    if (context.enterpriseLoading) {
        console.error('Current Enterprise is loading !')
        throw new Error('Current Enterprise is loading')
    }
    if (!context.enterprise) {
        console.error('Enterprise is null !')
        throw new Error('Enterprise is null')
    }

    return {
        enterprise: context.enterprise,
        enterpriseLoading: context.enterpriseLoading,
    }
}

const useProvideEnterprise = (enterpriseId: string) => {
    const { enterprise, enterpriseLoading, error } = useGetEnterprise(enterpriseId)

    return { enterprise, enterpriseLoading, error }
}

export default useEnterprise
