import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Insurer.Insurer_Minimal}
    query GetInsurers_Minimal {
        insurers {
            ...Insurer_Minimal
        }
    }
`
