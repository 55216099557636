import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { default as React, Fragment, useMemo } from 'react'
import { DisasterDamage_Minimal } from '../../../../../helpers/data/models/disaster/DisasterDamage'
import VehicleHelper from '../../../../../helpers/VehicleHelper'
import ElevatedCard from '../../../../ElevatedCard'

type DisasterIndemnityDamageExpansionProps = {
    disasterDamage: DisasterDamage_Minimal
}

const DisasterIndemnityDamageExpansion = ({ disasterDamage }: DisasterIndemnityDamageExpansionProps) => {
    const places = disasterDamage.places
    const placesComponent = places.map((place) => <Box key={place.id}>{place.title}</Box>)

    const sites = disasterDamage.sites
    const sitesComponent = sites.map((site) => <Box key={site.id}>{site.title}</Box>)

    const enterprises = disasterDamage.enterprises
    const enterprisesComponent = enterprises.map((enterprise) => <Box key={enterprise.id}>{enterprise.title}</Box>)

    const goods = disasterDamage.goods
    const goodsComponent = goods.map((good) => <Box key={good.id}>{good.title}</Box>)

    const vehicles = disasterDamage.vehicles
    const vehiclesComponent = vehicles.map((vehicle) => <Box key={vehicle.id}>{VehicleHelper.getLabel(vehicle)}</Box>)

    const getElementBox = (title: string, listComponent: JSX.Element[]) => {
        return (
            <Grid item lg={3} md={6} xs={12}>
                <ElevatedCard className="p-3">
                    <Fragment>
                        <h6>{title}</h6>
                        <Box>{listComponent}</Box>
                    </Fragment>
                </ElevatedCard>
            </Grid>
        )
    }

    const hasNoLinkedElements = useMemo(
        () =>
            places.length === 0 &&
            sites.length === 0 &&
            enterprises.length === 0 &&
            goods.length === 0 &&
            vehicles.length === 0,
        [places, sites, enterprises, goods, vehicles]
    )

    return (
        <Fragment>
            <Grid container spacing={4} className="text-center">
                {hasNoLinkedElements && (
                    <Grid item>
                        <span className="text-muted">Aucun élément ciblé par le dommage</span>
                    </Grid>
                )}
                {places.length > 0 && getElementBox('Locaux', placesComponent)}
                {sites.length > 0 && getElementBox('Sites', sitesComponent)}
                {enterprises.length > 0 && getElementBox('Entreprises', enterprisesComponent)}
                {goods.length > 0 && getElementBox('Biens', goodsComponent)}
                {vehicles.length > 0 && getElementBox('Véhicules', vehiclesComponent)}
            </Grid>
        </Fragment>
    )
}

export default DisasterIndemnityDamageExpansion
