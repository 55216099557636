import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Contract.Contract_Full}
    mutation UpdateContract(
        $id: ID!
        $title: String!
        $enterpriseId: ID!
        $insuranceId: ID!
        $brokerId: ID
        $insurerId: ID
        $startAt: String!
        $renewableDate: String
        $renewableNotice: Int
        $bounty: Float
        $reference: String
    ) {
        updateContract(
            input: {
                id: $id
                title: $title
                enterpriseId: $enterpriseId
                insuranceId: $insuranceId
                brokerId: $brokerId
                insurerId: $insurerId
                startAt: $startAt
                renewableDate: $renewableDate
                renewableNotice: $renewableNotice
                bounty: $bounty
                reference: $reference
            }
        ) {
            errors
            status
            statusCode
            contract {
                ...Contract_Full
            }
        }
    }
`
