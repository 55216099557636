import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { ChangeEvent, useState } from 'react'
import { ContractGeneric_contract } from '../../../graphql/queries/typings/ContractGeneric'
import { DisasterGeneric_disaster } from '../../../graphql/queries/typings/DisasterGeneric'
import { EnterpriseGeneric, EnterpriseGeneric_enterprise } from '../../../graphql/queries/typings/EnterpriseGeneric'
import { EnumDisasterStatus } from '../../../graphql/typings/global_types'
import { isStringInputValid } from '../../../utilsTs'
import ContractHelper, { ContractForLabel } from '../../helpers/ContractHelper'
import { DisasterEvent_Minimal } from '../../helpers/data/models/disaster/DisasterEvent'
import DateHelper from '../../helpers/DateHelper'
import DisasterHelper from '../../helpers/DisasterHelper'
import { useErrorService } from '../../helpers/errors/ErrorService'
import { useCommonDataLoadedExisting } from '../../hooks/useCommonData'
import { useCreateDisaster, useUpdateDisaster } from '../../hooks/useMutations'
import CustomButton from '../CustomButton'
import MoneyInput from '../customInputs/MoneyInput'
import { Dialog } from '../Dialog'

type DisasterDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: () => void
    disaster?: DisasterForDialog
    disastersEvents: DisasterEvent_Minimal[]
    contractId?: string
    contracts: ContractForDisasterDialog[]
}

type Data = {
    date: Date
    title: string
    eventId: string
    contractId: string | null
    disasterCategoryId: string | null
    percentage: number | null
    billingDate: Date | null
    amount: number | null
    status: EnumDisasterStatus
    insuranceReference: string | null
    internalReference: string | null
    comment: string | null
}

export type ContractForDisasterDialog = ContractForLabel &
    Pick<ContractGeneric_contract, 'id'> & {
        enterprise: Pick<EnterpriseGeneric_enterprise, 'id'>
    }

export type DisasterForDialog = Pick<
    DisasterGeneric_disaster,
    | 'id'
    | 'date'
    | 'title'
    | 'event'
    | 'contract'
    | 'disasterCategory'
    | 'percentage'
    | 'billingDate'
    | 'amount'
    | 'status'
    | 'insuranceReference'
    | 'internalReference'
    | 'comment'
>

const eventFieldDefaultId = '-1'

const DisasterDialog = ({
    onDone,
    open,
    closeDialog,
    disaster,
    disastersEvents,
    contracts,
    contractId,
}: DisasterDialogProps) => {
    const emptyDisaster: Data = {
        date: new Date(),
        title: '',
        eventId: eventFieldDefaultId,
        contractId: contractId || null,
        disasterCategoryId: null,
        percentage: null,
        billingDate: null,
        amount: null,
        status: EnumDisasterStatus.open,
        insuranceReference: null,
        internalReference: null,
        comment: null,
    }

    const baseDisaster: Data = disaster
        ? {
              date: new Date(disaster.date),
              title: disaster.title,
              eventId: disaster.event ? disaster.event.id : eventFieldDefaultId,
              contractId: disaster.contract.id,
              disasterCategoryId: disaster.disasterCategory.id,
              percentage: disaster.percentage,
              billingDate: disaster.billingDate ? new Date(disaster.billingDate) : null,
              amount: disaster.amount,
              status: disaster.status,
              insuranceReference: disaster.insuranceReference,
              internalReference: disaster.internalReference,
              comment: disaster.comment,
          }
        : emptyDisaster

    const [data, setData] = useState<Data>(baseDisaster)
    const [loading, setLoading] = useState<boolean>(false)

    const createDisasterMutation = useCreateDisaster()
    const updateDisasterMutation = useUpdateDisaster()

    const { disasterCategories } = useCommonDataLoadedExisting()

    const { fromDateToServer } = DateHelper()
    const { errorAlert } = useErrorService()

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title' | 'internalReference' | 'insuranceReference' | 'comment') => {
        setData({
            ...data,
            [prop]: value,
        })
    }

    const handleStatusChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const status = event.target.value as EnumDisasterStatus

        setData({
            ...data,
            status,
        })
    }

    const handleDateChange = (date: MaterialUiPickersDate) => {
        if (!date) return

        setData({
            ...data,
            date: date,
        })
    }

    const handleBillingDateChange = (date: MaterialUiPickersDate) => {
        if (!date) return

        setData({
            ...data,
            billingDate: date,
        })
    }

    const handleContractChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const contractId = event.target.value

        setData({
            ...data,
            contractId,
        })
    }

    const handleEventChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const eventId = event.target.value

        setData({
            ...data,
            eventId,
        })
    }

    const handleCategoryChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setData((oldData) => {
            return {
                ...oldData,
                disasterCategoryId: event.target.value,
            }
        })
    }

    const handlePercentageChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setData((oldData) => {
            return {
                ...oldData,
                percentage: parseFloat(event.target.value),
            }
        })
    }

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setData((oldData) => {
            return {
                ...oldData,
                amount: parseFloat(event.target.value),
            }
        })
    }

    const handleDone = async () => {
        if (data.title === '') return
        if (!data.contractId) return
        if (!data.disasterCategoryId) return

        if (!isFormValid) {
            errorAlert('Les données entrées sont invalides.', data)
            console.error('data : ', data)
            return
        }
        const contract = contracts.find((tempContract) => tempContract.id === data.contractId)

        if (!contract) {
            errorAlert('Erreur lors de la saisie du contrat.', 'Contrat absent')
            console.error('data : ', data, contracts)
            return
        }

        setLoading(true)

        const serverDate = fromDateToServer(data.date)
        const serverBillingDate = data.billingDate ? fromDateToServer(data.billingDate) : null
        const eventId = data.eventId === eventFieldDefaultId ? null : data.eventId

        try {
            if (disaster) {
                //Updating
                const response = await updateDisasterMutation.run({
                    id: disaster.id,
                    title: data.title,
                    contractId: data.contractId,
                    eventId,
                    disasterCategoryId: data.disasterCategoryId,
                    status: data.status,
                    date: serverDate,
                    percentage: data.percentage,
                    billingDate: serverBillingDate,
                    amount: data.amount,
                    insuranceReference: data.insuranceReference,
                    internalReference: data.internalReference,
                    comment: data.comment,
                })

                if (response.disaster) {
                    onDone()
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour du sinistre'
                }
            } else {
                //Creating
                const response = await createDisasterMutation.run({
                    title: data.title,
                    contractId: data.contractId,
                    eventId,
                    disasterCategoryId: data.disasterCategoryId,
                    status: data.status,
                    date: serverDate,
                    percentage: data.percentage,
                    billingDate: serverBillingDate,
                    amount: data.amount,
                    enterpriseId: contract.enterprise.id,
                    insuranceReference: data.insuranceReference,
                    internalReference: data.internalReference,
                    comment: data.comment,
                })

                if (response.disaster) {
                    onDone()
                } else {
                    throw 'Une erreur est survenue pendant la création du sinistre'
                }

                setData(emptyDisaster)
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    // Because the array is frozen in strict mode, you'll need to copy the array before sorting it:
    const allDisastersTypes = disasterCategories.slice().sort((typeA, typeB) => {
        return typeA.title.localeCompare(typeB.title)
    })

    const isFormValid = isStringInputValid(data.title) && data.contractId !== null && data.disasterCategoryId !== null

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un sinistre</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Saisie des informations principales du sinistre. Les autres informations pourront être ajoutées et
                    modifiées par la suite
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Intitulé *"
                    type="text"
                    fullWidth
                    value={data.title}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    id="internalReference"
                    label="Référence AEA"
                    type="text"
                    fullWidth
                    value={data.internalReference || ''}
                    onChange={(event) => handleChange(event.target.value, 'internalReference')}
                />
                <TextField
                    margin="dense"
                    id="insuranceReference"
                    label="Référence assureur"
                    type="text"
                    fullWidth
                    value={data.insuranceReference || ''}
                    onChange={(event) => handleChange(event.target.value, 'insuranceReference')}
                />
                <TextField
                    margin="dense"
                    name="category"
                    label="Type de sinistre *"
                    fullWidth
                    select
                    onChange={handleCategoryChange}
                    value={data.disasterCategoryId || ''}>
                    {allDisastersTypes.map((category, index) => {
                        return (
                            <MenuItem value={category.id} key={index}>
                                {category.title}
                            </MenuItem>
                        )
                    })}
                </TextField>
                <TextField
                    margin="dense"
                    name="status"
                    label="Statut du sinistre"
                    fullWidth
                    select
                    onChange={handleStatusChange}
                    value={data.status}>
                    {Object.values(EnumDisasterStatus)
                        .sort((statusA, statusB) => {
                            const labelA = DisasterHelper.getDisasterStatusLabel(statusA)
                            const labelB = DisasterHelper.getDisasterStatusLabel(statusB)

                            return labelA.localeCompare(labelB)
                        })
                        .map((statusValue, index) => {
                            return (
                                <MenuItem value={statusValue} key={index}>
                                    {DisasterHelper.getDisasterStatusLabel(statusValue)}
                                </MenuItem>
                            )
                        })}
                </TextField>
                <DatePicker
                    margin="dense"
                    id="disasterDate"
                    label="Date du sinistre"
                    value={data.date}
                    onChange={handleDateChange}
                    format="dd/MM/yyyy"
                    disableFuture={true}
                    fullWidth
                    autoOk={true}
                />
                <TextField
                    margin="dense"
                    name="contract"
                    label="Contrat *"
                    fullWidth
                    select
                    onChange={handleContractChange}
                    value={data.contractId || ''}>
                    {[...contracts]
                        .sort((contractA, contractB) => {
                            return contractA.title.localeCompare(contractB.title)
                        })
                        .map((contract) => (
                            <MenuItem value={contract.id} key={contract.id}>
                                {ContractHelper.getContractLabel(contract)}
                            </MenuItem>
                        ))}
                </TextField>
                <TextField
                    margin="dense"
                    name="event"
                    label="Evènement"
                    fullWidth
                    select
                    onChange={handleEventChange}
                    value={data.eventId}>
                    <MenuItem value={eventFieldDefaultId}>Aucun</MenuItem>
                    {[...disastersEvents]
                        .sort((eventA, eventB) => {
                            return eventA.title.localeCompare(eventB.title)
                        })
                        .map((disasterEvent) => (
                            <MenuItem value={disasterEvent.id} key={disasterEvent.id}>
                                {disasterEvent.title}
                            </MenuItem>
                        ))}
                </TextField>
                <TextField
                    margin="dense"
                    name="percentage"
                    label="Pourcentage des honoraires AEA (%)"
                    fullWidth
                    type="number"
                    onChange={handlePercentageChange}
                    value={data.percentage}
                />
                <MoneyInput
                    margin="dense"
                    name="percentage"
                    label="Montant des honoraires AEA (€)"
                    fullWidth
                    onChange={handleAmountChange}
                    value={data.amount}
                />
                <DatePicker
                    margin="dense"
                    id="disasterBillingDate"
                    label="Date de facturation des honoraires AEA"
                    value={data.billingDate}
                    onChange={handleBillingDateChange}
                    format="dd/MM/yyyy"
                    fullWidth
                    autoOk={true}
                />
                <TextField
                    margin="dense"
                    id="comment"
                    label="Note"
                    type="text"
                    fullWidth
                    multiline
                    value={data.comment || ''}
                    onChange={(event) => handleChange(event.target.value, 'comment')}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleDone}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default DisasterDialog
