import { useGetEnterpriseDocuments } from '../../../../../helpers/data/getEnterprise'
import { Document_User } from '../../../../../helpers/data/models/Document'
import ErrorDisplay from '../../../../../helpers/errors/ErrorDisplay'
import { useEnterpriseLoadedExisting } from '../../../../../hooks/useEnterprise'
import LoadingDisplay from '../../../../LoadingDisplay'
import DocumentsList from '../../../../documentsList/DocumentsList'

const EnterpriseDocuments = () => {
    const { enterprise } = useEnterpriseLoadedExisting()
    const enterpriseProps = useGetEnterpriseDocuments(enterprise.id)

    if (enterpriseProps.enterpriseLoading) {
        return <LoadingDisplay />
    } else if (enterpriseProps.error) {
        return (
            <ErrorDisplay
                message="Erreur lors du chargement des documents de l'entreprise"
                queryError={enterpriseProps.error}
                debug={enterpriseProps.error}
            />
        )
    } else if (!enterpriseProps.enterprise) {
        return (
            <ErrorDisplay
                message="Erreur lors de la récupération des documents de l'entreprise"
                debug={enterpriseProps}
            />
        )
    } else return <EnterpriseDocumentsReady documents={enterpriseProps.enterprise.documents} />
}

type EnterpriseDocumentsReadyProps = {
    documents: Document_User[]
}

const EnterpriseDocumentsReady = ({ documents }: EnterpriseDocumentsReadyProps) => {
    return <DocumentsList documents={documents} mode="confirmed" showLink={false} />
}

export default EnterpriseDocuments
