import GuaranteeCategory from './GuaranteeCategory'
import Guarantee_Full from './Guarantee_Full'
import Guarantee_Minimal from './Guarantee_Minimal'

const GuaranteeFragments = {
    Guarantee_Minimal,
    Guarantee_Full,
    GuaranteeCategory,
}

export default GuaranteeFragments
