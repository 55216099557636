import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.DisasterEvent_Minimal}
    mutation CreateDisasterEvent($title: String!) {
        createEvent(input: { title: $title }) {
            errors
            status
            statusCode
            disasterEvent: event {
                ...DisasterEvent_Minimal
            }
        }
    }
`
