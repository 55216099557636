import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { isStringInputValid } from '../../../../../utilsTs'
import CustomButton from '../../../../components/CustomButton'
import { Broker_Minimal } from '../../../../helpers/data/models/contract/Broker'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import { useCreateBroker, useUpdateBroker } from '../../../../hooks/useMutations'

type BrokerDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (Broker: Broker_Minimal) => void
    broker?: Broker_Minimal
}

type Data = {
    title: string | null
    phone: string | null
    email: string | null
}

const emptyBroker: Data = {
    title: null,
    phone: null,
    email: null,
}

const BrokerDialog = ({ broker, onDone, open, closeDialog }: BrokerDialogProps) => {
    const [data, setData] = useState<Data>(
        broker
            ? {
                  title: broker.title,
                  phone: broker.phone,
                  email: broker.email,
              }
            : emptyBroker
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createBrokerMutation = useCreateBroker()
    const updateBrokerMutation = useUpdateBroker()

    const { errorAlert } = useErrorService()

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title' | 'phone' | 'email') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const handleSubmit = async () => {
        if (!data.title) return

        setLoading(true)

        try {
            if (broker) {
                //Update
                const response = await updateBrokerMutation.run({
                    id: broker.id,
                    title: data.title,
                    phone: data.phone,
                    email: data.email,
                })

                if (response.broker) {
                    onDone(response.broker)
                } else {
                    throw "Une erreur est survenue pendant la mise à jour du courtier"
                }
            } else {
                const response = await createBrokerMutation.run({
                    title: data.title,
                    phone: data.phone,
                    email: data.email,
                })

                if (response.broker) {
                    onDone(response.broker)
                } else {
                    throw "Une erreur est survenue pendant la création du courtier"
                }
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid = isStringInputValid(data.title)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un courtier</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Nom du courtier *"
                    type="text"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    id="phone"
                    label="Numéro de téléphone"
                    type="text"
                    fullWidth
                    value={data.phone || ''}
                    onChange={(event) => handleChange(event.target.value, 'phone')}
                />

                <TextField
                    margin="dense"
                    id="email"
                    label="Email de contact"
                    type="email"
                    fullWidth
                    value={data.email || ''}
                    onChange={(event) => handleChange(event.target.value, 'email')}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default BrokerDialog
