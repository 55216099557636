import { gql } from "@apollo/client/core";
import Document_Minimal from '../Document/Document_Minimal'
import PreventionCategory_Minimal from './PreventionCategory/PreventionCategory_Minimal'

export default gql`
    ${Document_Minimal}
    ${PreventionCategory_Minimal}
    fragment Prevention_Minimal on Prevention {
        id
        description
        documents {
            ...Document_Minimal
        }
        startAt
        endAt
        preventionCategory {
            ...PreventionCategory_Minimal
        }
    }
`
