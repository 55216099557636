import { createContext, ReactElement, useContext } from 'react'
import { GetContracts_contracts } from '../../graphql/queries/typings/GetContracts'
import { GetEnterpriseContracts_contracts } from '../../graphql/queries/typings/GetEnterpriseContracts'
import useGetContracts, { useGetEnterpriseContracts } from '../helpers/data/getContracts'
import { QueryError } from '../helpers/errors/ErrorHelper'

type ContractsContext = {
    error?: QueryError
    contracts: GetContracts_contracts[] | null
    contractsLoading: boolean
}

const ContractsContext = createContext<ContractsContext>({
    contracts: null,
    contractsLoading: true,
})

type ProvideContractsProps = {
    children: ReactElement
    customerId: string
}

export function ProvideContracts({ children, customerId }: ProvideContractsProps) {
    const sitesData = useProvideContracts(customerId)

    return <ContractsContext.Provider value={sitesData}>{children}</ContractsContext.Provider>
}

export const useContracts = () => {
    return useContext(ContractsContext)
}

export const useContractsLoadedExisting = () => {
    const context = useContext(ContractsContext)
    if (context.contractsLoading) {
        console.error('Erreur : les contrats sont toujours en cours de chargement !')
        throw new Error('Erreur : les contrats sont toujours en cours de chargement !')
    }
    if (!context.contracts) {
        console.error(`Erreur lors de la récupération des contrats !`)
        throw new Error('Erreur lors de la récupération des contrats !')
    }

    return {
        contracts: context.contracts,
        contractsLoading: context.contractsLoading,
    }
}

// If no enterpriseId provided, we get all the customer's contracts
const useProvideContracts = (customerId: string): ContractsContext => {
    const { contracts, contractsLoading, error } = useGetContracts({ customerId })

    return {
        error,
        contracts,
        contractsLoading,
    }
}

type UseEnterpriseContractsProps = {
    customerId: string
    enterpriseId: string
}

type EnterpriseContractsData = {
    error?: QueryError
    contracts: GetEnterpriseContracts_contracts[] | null
    contractsLoading: boolean
}

export const useEnterpriseContracts = ({
    customerId,
    enterpriseId,
}: UseEnterpriseContractsProps): EnterpriseContractsData => {
    const { contracts, contractsLoading, error } = useGetEnterpriseContracts({ customerId, enterpriseId })

    return { contracts, contractsLoading, error }
}

export default useContracts
