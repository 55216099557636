import { gql } from "@apollo/client/core";
import Vehicle_Documents from './Vehicle_Documents'
import Vehicle_Minimal from './Vehicle_Minimal'

export default gql`
    ${Vehicle_Minimal}
    ${Vehicle_Documents}
    fragment Vehicle_Full on Vehicle {
        id
        ...Vehicle_Minimal
        ...Vehicle_Documents
    }
`
