import React, { useRef, ReactNode, useState, useMemo, useCallback, ChangeEvent } from 'react'

export enum FileType {
    ALL = '*',
    IMAGES = 'image/*',
}

function useFileDialog(fileType: FileType) {
    const ref = useRef<HTMLInputElement>(null)
    const [key, setKey] = useState<number>(new Date().valueOf())
    const [file, setFile] = useState<File | null>(null)

    const open = useCallback(() => {
        if (ref && ref.current) ref.current.click()
    }, [ref])

    const deleteFile = () => {
        setFile(null)
    }

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files[0]) setFile(e.target.files[0])
            setKey(new Date().valueOf())
        },
        [setFile, setKey]
    )

    const node = useMemo(() => {
        return (
            <input key={key} ref={ref} type="file" accept={fileType} style={{ display: 'none' }} onChange={onChange} />
        )
    }, [key, ref, onChange, fileType])
    return [node, open, file, deleteFile] as [ReactNode, () => void, File | null, () => void]
}

export type FileHelper = {
    id: string
    file: File
    title: string
    deleteFile: () => void
    editFileTitle: (title: string) => void
}

export type ManyFilesDialog = {
    filesPickerNode: ReactNode
    openFilesPicker: () => void
    filesHelpers: FileHelper[]
}

function useManyFilesDialog(fileType: FileType): ManyFilesDialog {
    const ref = useRef<HTMLInputElement>(null)
    const [key, setKey] = useState<number>(new Date().valueOf())
    const [filesHelpers, setFilesHelpers] = useState<FileHelper[]>([])

    const open = useCallback(() => {
        if (ref && ref.current) ref.current.click()
    }, [ref])

    const deleteFile = (fileHelperID: string) => {
        setFilesHelpers((oldFilesHelper): FileHelper[] => {
            const fileIndex = oldFilesHelper.findIndex((fh) => fh.id === fileHelperID)
            if (fileIndex === -1) return [...oldFilesHelper]
            oldFilesHelper.splice(fileIndex, 1)
            return [...oldFilesHelper]
        })
    }

    const editFileTitle = (fileHelperID: string, title: string) => {
        setFilesHelpers((oldFilesHelper): FileHelper[] => {
            const fileIndex = oldFilesHelper.findIndex((fh) => fh.id === fileHelperID)
            if (fileIndex === -1) return [...oldFilesHelper]

            const newFilesHelper = [...oldFilesHelper]
            if (newFilesHelper[fileIndex]) newFilesHelper[fileIndex].title = title

            return newFilesHelper
        })
    }

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length > 0) {
                const newFiles = e.target.files

                setFilesHelpers((oldFilesHelpers) => {
                    const filesToAdd: FileHelper[] = []

                    for (let i = 0; i < newFiles.length; i++) {
                        const id = `${new Date().valueOf()}${Math.ceil(Math.random() * i * 100000000)}${i}`
                        const file = newFiles[i]

                        const nameWithoutExtention = file.name.substr(0, file.name.lastIndexOf('.')) || file.name

                        filesToAdd.push({
                            id,
                            file: file,
                            deleteFile: () => deleteFile(id),
                            editFileTitle: (title: string) => editFileTitle(id, title),
                            title: nameWithoutExtention,
                        })
                    }

                    return [...oldFilesHelpers, ...filesToAdd]
                })
            }
            setKey(new Date().valueOf())
        },
        [setFilesHelpers, setKey]
    )

    const node = useMemo(() => {
        return (
            <input
                key={key}
                ref={ref}
                type="file"
                accept={fileType}
                style={{ display: 'none' }}
                onChange={onChange}
                multiple
            />
        )
    }, [key, ref, onChange, fileType])
    return { filesPickerNode: node, openFilesPicker: open, filesHelpers }
}

const FileDialogHelper = {
    useFileDialog,
    useManyFilesDialog,
}

export default FileDialogHelper
