import { Typography } from '@material-ui/core'
import Grow from '@material-ui/core/Grow'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MUIDataTable, { MUIDataTableOptions, MUIDataTableProps } from 'mui-datatables'
import React, { Fragment, ReactElement, useMemo } from 'react'
import { EnumUser } from '../../graphql/typings/global_types'
import { useAuthUser } from '../hooks/useAuth'
import AddIconCustomToolbar, { AddIconCustomToolbarProps } from './customer/datatable/AddIconCustomToolbarProps'

type DataTableProps<TData> = Omit<MUIDataTableProps, 'data'> & {
    icon?: string
    data: TData[]
    showSearch?: boolean
    addFeature?: AddIconCustomToolbarProps
    customToolbarElement?: ReactElement
    rowClick?: (object: TData) => void
    cellClick?: (object: TData, colIndex: number) => void
    actionsColumn?: (dataIndex: number, rowIndex: number) => React.ReactNode
}

function DataTable<TData>({
    icon,
    data,
    showSearch,
    addFeature,
    customToolbarElement,
    rowClick,
    cellClick,
    title,
    actionsColumn,
    columns,
    ...tableProps
}: DataTableProps<TData>) {
    const auth = useAuthUser()

    const theme = createTheme({
        overrides: {
            MuiTableRow: {
                root: {
                    cursor: rowClick ? 'pointer' : 'unset',
                },
            },
            MuiTableCell: {
                root: {
                    cursor: cellClick ? 'pointer' : 'unset',
                },
            },
        },
    })

    const rowClickHandler = rowClick
        ? (
              rowData: string[],
              rowMeta: {
                  dataIndex: number
                  rowIndex: number
              }
          ) => {
              rowClick(data[rowMeta.dataIndex])
          }
        : undefined

    const cellClickHandler = cellClick
        ? (
              colData: any,
              cellMeta: {
                  colIndex: number
                  rowIndex: number
                  dataIndex: number
                  event: React.MouseEvent<Element, MouseEvent>
              }
          ) => {
              console.error('Not tested yet - Missing cell hover')
              cellClick(data[cellMeta.dataIndex], cellMeta.colIndex)
          }
        : undefined

    // We add the actions row, if necessary
    const customColumns = useMemo(() => {
        if (actionsColumn) {
            return columns.concat([
                {
                    name: 'action',
                    label: ' ',
                    options: {
                        filter: false,
                        download: false,
                        viewColumns: false,
                        customBodyRenderLite: actionsColumn,
                    },
                },
            ])
        } else return columns
    }, [actionsColumn, columns])

    const baseOptions: MUIDataTableOptions = tableProps.options ? tableProps.options : {}
    const rowsPerPage = baseOptions.rowsPerPage || 30

    const options: MUIDataTableOptions = {
        ...baseOptions,
        responsive: 'simple',
        selectableRows: baseOptions.selectableRows || 'none', // set checkbox for each row
        // search: false, // set search option
        filter: baseOptions.filter || false, // set data filter option
        download: baseOptions.download || false, // set download option
        print: baseOptions.print || false, // set print option
        elevation: 0,
        rowsPerPage: rowsPerPage,
        rowsPerPageOptions: baseOptions.rowsPerPageOptions || [50, 100],
        // pagination: baseOptions.pagination || data.length > rowsPerPage ? true : false,
        pagination: true,
        viewColumns: baseOptions.viewColumns || false,
        onRowClick: rowClickHandler,
        rowHover: rowClick ? true : false,
        onCellClick: cellClickHandler,
        textLabels: {
            body: {
                noMatch: 'Aucun élément à afficher.',
                toolTip: 'Trier',
            },
            pagination: {
                next: 'Page suivante',
                previous: 'Page précédente',
                rowsPerPage: 'Lignes par page :',
                displayRows: 'sur',
                jumpToPage: 'Aller à la page :',
            },
            toolbar: {
                search: 'Recherche',
                downloadCsv: 'Télécharger en CSV',
                print: 'Imprimer',
                viewColumns: 'Voir les colonnes',
                filterTable: 'Filtrer la table',
            },
            filter: {
                all: 'TOUS',
                title: 'FILTRES',
                reset: 'REINITIALISER',
            },
            viewColumns: {
                title: 'Montrer les colonnes',
                titleAria: 'Montrer/Cacher les colonnes',
            },
            selectedRows: {
                text: 'ligne(s) sélectionnée(s)',
                delete: 'Supprimer',
                deleteAria: 'Supprimer les lignes sélectionnées',
            },
        },
    }

    if (showSearch) {
        const customSearchRender = (
            searchText: string,
            handleSearch: (text: string) => void,
            hideSearch: () => void,
            options: any
        ) => {
            return (
                <Grow appear in={true} timeout={300}>
                    <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={({ target: { value } }) => handleSearch(value)}
                        InputProps={{
                            style: {
                                paddingRight: 0,
                            },
                            startAdornment: (
                                <Icon className="mr-2" fontSize="small">
                                    search
                                </Icon>
                            ),
                            endAdornment: (
                                <IconButton onClick={hideSearch}>
                                    <Icon fontSize="small">clear</Icon>
                                </IconButton>
                            ),
                        }}
                    />
                </Grow>
            )
        }
        options.customSearchRender = customSearchRender
    } else {
        options.search = false
    }

    if (addFeature || customToolbarElement) {
        options.customToolbar = () => {
            const addFeatureComponent =
                addFeature && auth.user.userType === EnumUser.admin ? <AddIconCustomToolbar {...addFeature} /> : null

            return (
                <Fragment>
                    {addFeatureComponent}
                    {customToolbarElement}
                </Fragment>
            )
        }
    }

    const formattedTitle = (
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {title}
        </Typography>
    )

    return (
        <MuiThemeProvider theme={theme}>
            <MUIDataTable
                title={formattedTitle}
                columns={data.length > 0 ? customColumns : []}
                data={data as unknown as object[]}
                {...tableProps}
                options={options}
            />
        </MuiThemeProvider>
    )
}

export default DataTable
