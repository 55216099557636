import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import React, { useCallback, useState } from 'react'
import {
    EnterpriseGeneric_enterprise,
    EnterpriseGeneric_enterprise_children,
    EnterpriseGeneric_enterprise_parents
} from '../../../../../../graphql/queries/typings/EnterpriseGeneric'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useUpdateEnterpriseChildren, useUpdateEnterpriseParents } from '../../../../../hooks/useMutations'
import CustomButton from '../../../../CustomButton'
import { Dialog } from '../../../../Dialog'

export type EnterpriseRelationDialogMode = 'daughter' | 'mother'

type EnterpriseRelationDialogProps = {
    enterprise: EnterpriseForRelationDialog
    open: boolean
    closeDialog: () => void
    onDone: () => void
    mode: EnterpriseRelationDialogMode
}

type EnterpriseForRelationDialog = Pick<EnterpriseGeneric_enterprise, 'id'> & {
    children: Pick<EnterpriseGeneric_enterprise_children, 'id'>[]
    parents: Pick<EnterpriseGeneric_enterprise_parents, 'id'>[]
}

const EnterpriseRelationDialog = ({ enterprise, open, closeDialog, onDone, mode }: EnterpriseRelationDialogProps) => {
    const { customer } = useCustomerLoadedExisting()
    const [loading, setLoading] = useState<boolean>(false)
    const { errorAlert } = useErrorService()

    const [linkedEnterprisesIds, setLinkedEnterprisesIds] = useState<string[]>(
        mode === 'daughter'
            ? enterprise.children.map((enterprise) => enterprise.id)
            : enterprise.parents.map((enterprise) => enterprise.id)
    )

    const updateEnterpriseParentsMutation = useUpdateEnterpriseParents()
    const updateEnterpriseChildrenMutation = useUpdateEnterpriseChildren()

    const disabledEnterprisesIds =
        mode === 'daughter'
            ? enterprise.parents.map((parent) => parent.id)
            : enterprise.children.map((child) => child.id)

    const handleClose = () => {
        closeDialog()
    }

    const handleCheckboxChange = (checked: boolean, enterpriseId: string) => {
        if (checked) {
            setLinkedEnterprisesIds((oldLinkedEnterprisesIds) => [...oldLinkedEnterprisesIds, enterpriseId])
        } else {
            setLinkedEnterprisesIds((oldLinkedEnterprisesIds) =>
                oldLinkedEnterprisesIds.filter((id) => id !== enterpriseId)
            )
        }
    }

    const isSelected = useCallback(
        (enterpriseId: string): boolean => {
            return !!linkedEnterprisesIds.find((id) => id === enterpriseId)
        },
        [linkedEnterprisesIds]
    )

    const handleSubmit = async () => {
        setLoading(true)

        try {
            if (mode === 'daughter') {
                const response = await updateEnterpriseChildrenMutation.run({
                    enterpriseId: enterprise.id,
                    childIds: linkedEnterprisesIds,
                })

                if (response.enterprise) {
                    onDone()
                } else {
                    throw "Oops, une erreur est survenue pendant la mise à jour de l'entreprise"
                }
            } else {
                const response = await updateEnterpriseParentsMutation.run({
                    enterpriseId: enterprise.id,
                    parentIds: linkedEnterprisesIds,
                })

                if (response.enterprise) {
                    onDone()
                } else {
                    throw "Oops, une erreur est survenue pendant la mise à jour de l'entreprise"
                }
            }
            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Organigramme entreprise</DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Entreprises {mode === 'daughter' ? 'filles' : 'mères'}</FormLabel>
                    <FormGroup>
                        {customer.enterprises.map((tempEnterprise) => {
                            const isDisabled =
                                tempEnterprise.id === enterprise.id ||
                                disabledEnterprisesIds.includes(tempEnterprise.id)
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={tempEnterprise.id}
                                            onChange={({ target: { checked } }) => {
                                                handleCheckboxChange(checked, tempEnterprise.id)
                                            }}
                                            checked={isSelected(tempEnterprise.id)}
                                            disabled={isDisabled}
                                        />
                                    }
                                    label={tempEnterprise.title}
                                />
                            )
                        })}
                    </FormGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton variant="outlined" onClick={handleSubmit} color="primary" loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default EnterpriseRelationDialog
