import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import CustomButton from '../../CustomButton'

type DocumentUploadProps = {
    localFile: File | null
    deleteFile: () => void
    openFilePicker: () => void
    hasAlreadyFileUploaded: boolean
    loading: boolean
    uploadPercent: number
    disabled?: boolean
} & (
    | {
          editable?: false
      }
    | {
          editable: true
          title: string
          editFileName: (title: string) => void
      }
)

const DocumentUpload = ({
    localFile,
    deleteFile,
    openFilePicker,
    hasAlreadyFileUploaded,
    loading,
    uploadPercent,
    disabled,
    ...props
}: DocumentUploadProps) => {
    if (loading && localFile) {
        return (
            <DocumentUploadProgress
                title={props.editable ? props.title : localFile.name}
                uploadPercent={uploadPercent}
            />
        )
    } else if (localFile) {
        return (
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
                <Grid item>
                    <Icon>description</Icon>
                </Grid>
                <Grid item style={{ flexGrow: 1, overflow: 'hidden' }}>
                    {props.editable && (
                        <TextField
                            margin="dense"
                            id="file_name"
                            type="text"
                            fullWidth
                            value={props.title}
                            onChange={(event) => props.editFileName(event.target.value)}
                        />
                    )}
                    {!props.editable && (
                        <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{localFile.name}</span>
                    )}
                </Grid>
                <Grid item>
                    <IconButton style={{ color: 'red' }} onClick={deleteFile} disabled={loading} size="small">
                        <Icon>delete</Icon>
                    </IconButton>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <CustomButton
                startIcon={<Icon>upload</Icon>}
                color="primary"
                variant="contained"
                onClick={openFilePicker}
                className="mb-2"
                disabled={disabled || loading}>
                {hasAlreadyFileUploaded ? 'Remplacer le document existant' : 'Ajouter des documents'}
            </CustomButton>
        )
    }
}

type DocumentUploadProgressProps = {
    title: string
    uploadPercent: number
}

export const DocumentUploadProgress = ({ title, uploadPercent }: DocumentUploadProgressProps) => {
    return (
        <Grid className="mb-2" container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item style={{ flexGrow: 1, overflow: 'hidden' }}>
                <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{title}</span>
            </Grid>
            <Grid item>
                {uploadPercent < 1 && (
                    <CircularProgress
                        color="primary"
                        value={uploadPercent * 100}
                        variant="determinate"
                        style={{ width: 18, height: 18 }}
                    />
                )}
                {uploadPercent === 1 && <Icon style={{ color: 'green' }}>task_alt</Icon>}
            </Grid>
        </Grid>
    )
}

export default DocumentUpload
