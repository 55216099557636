import { Outlet } from 'react-router-dom'
import { MatxLayout } from '../../../../matx'
import LoadingDisplay from '../../../components/LoadingDisplay'
import ErrorDisplay from '../../../helpers/errors/ErrorDisplay'
import useCommonData from '../../../hooks/useCommonData'
import useCustomers from '../../../hooks/useCustomers'
import { NavType } from '../../../hooks/useNavigation'

const AEARootZone = () => {
    const customersProps = useCustomers()
    const commonProps = useCommonData()

    const children =
        customersProps.customersLoading || commonProps.commonDataLoading ? (
            <LoadingDisplay />
        ) : customersProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement des clients"
                queryError={customersProps.error}
                debug={customersProps.error}
            />
        ) : commonProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement des données"
                queryError={commonProps.error}
                debug={commonProps.error}
            />
        ) : !customersProps.customers ? (
            <ErrorDisplay message="Erreur lors du chargement des clients" />
        ) : (
            <Outlet />
        )

    return (
        <MatxLayout zone={NavType.aea} customers={customersProps.customers || []}>
            {children}
        </MatxLayout>
    )
}

export default AEARootZone
