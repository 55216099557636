import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Document.Document_User}
    mutation CreateDocuments($documentsInput: [DocumentInput!]!) {
        createDocuments(input: { documentsInput: $documentsInput }) {
            errors
            status
            statusCode
            documents {
                ...Document_User
            }
        }
    }
`
