import { gql } from "@apollo/client/core";
import Document_User from '../Document/Document_User'
import DisasterDamage_Documents from './DisasterDamage/DisasterDamage_Documents'

export default gql`
    ${Document_User}
    fragment Disaster_Documents on Disaster {
        id
        documents {
            ...Document_User
        }
    }
`

// disasterDamages: damages {
//     ...DisasterDamage_Documents
// }
