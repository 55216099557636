import { VehicleGeneric_vehicle } from '../../graphql/queries/typings/VehicleGeneric'

export type VehicleForLabel = Pick<VehicleGeneric_vehicle, 'id' | 'title' | 'registration'>

export const getLabel = (vehicle: VehicleForLabel) => {
    let label = vehicle.title
    if (vehicle.registration) label += ' - ' + vehicle.registration

    return label
}

const VehicleHelper = {
    getLabel,
}

export default VehicleHelper
