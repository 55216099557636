import { Fragment } from 'react'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { usePlaceLoadedExisting } from '../../../../../hooks/usePlace'
import Panel from '../../../../Panel'
import PlacePreventionsList from './PlacePreventionsList'

const PlacePreventions = () => {
    const { customer } = useCustomerLoadedExisting()
    const { place } = usePlaceLoadedExisting()

    const preventions = customer.preventions.filter((prevention) => prevention.place.id === place.id)

    return (
        <Fragment>
            <Panel>
                <PlacePreventionsList preventions={preventions} />
            </Panel>
        </Fragment>
    )
}

export default PlacePreventions
