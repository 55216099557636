import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { Fragment, useState } from 'react'
import { DocumentGeneric_document } from '../../../graphql/queries/typings/DocumentGeneric'
import { EnumDocumentStatus } from '../../../graphql/typings/global_types'
import { useErrorService } from '../../helpers/errors/ErrorService'
import { useUpdateDocument } from '../../hooks/useMutations'
import CustomButton from '../CustomButton'
import { Dialog } from '../Dialog'

export type DocumentForReject = Pick<DocumentGeneric_document, 'id' | 'title' | 'status'>

type DialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (document: DocumentForReject) => void
    document: DocumentForReject
}

type Data = {
    statusComment: string | null
}

const emptyData: Data = {
    statusComment: null,
}

const RejectDocumentDialog = ({ onDone, open, closeDialog, document }: DialogProps) => {
    const { errorAlert } = useErrorService()

    const updateDocumentMutation = useUpdateDocument()

    const [data, setData] = useState<Data>(emptyData)
    const [loading, setLoading] = useState<boolean>(false)

    const handleClose = () => {
        setLoading(false)
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title' | 'docElementId' | 'statusComment') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const handleSubmit = async () => {
        if (data.statusComment === null) return

        const rejectStatus =
            document.status === EnumDocumentStatus.submitted_by_user
                ? EnumDocumentStatus.submission_rejected
                : EnumDocumentStatus.rejected

        setLoading(true)

        try {
            const response = await updateDocumentMutation.run({
                id: document.id,
                status: rejectStatus,
                statusComment: data.statusComment,
            })

            if (response.document) {
                onDone(response.document)
            } else {
                errorAlert('Une erreur est survenue pendant le refus du document', response)
            }

            handleClose()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid = data.statusComment !== null

    return (
        <Fragment>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Rejeter le document</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        className="mb-5"
                        margin="dense"
                        id="statusComment"
                        label="Explication du refus"
                        type="text"
                        fullWidth
                        multiline={true}
                        value={data.statusComment || ''}
                        onChange={(event) => handleChange(event.target.value, 'statusComment')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={handleClose}>
                        Annuler
                    </Button>
                    <CustomButton
                        variant="outlined"
                        onClick={handleSubmit}
                        color="primary"
                        disabled={!isFormValid}
                        loading={loading}>
                        Valider
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default RejectDocumentDialog
