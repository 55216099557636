import { Button, CircularProgress } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useNavigate } from 'react-router-dom'
import RoutesHelper from '../helpers/RoutesHelper'
import { useAuth } from '../hooks/useAuth'
import { useForgotPassword } from '../hooks/useMutations'
import ExternalLinkLayout from './external/ExternalLinkLayout'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

type Message = {
    type: 'error' | 'default'
    message: string
}

const ForgotPassword = () => {
    const classes = useStyles()
    const auth = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (auth.loading) return
        if (auth.user) {
            auth.logout()
        }
    }, [auth])

    const [loading, setLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [message, setMessage] = useState<Message | null>()
    const [emailSent, setEmailSent] = useState<boolean>(false)

    const forgotPasswordMutation = useForgotPassword()

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const handleFormSubmit = async () => {
        setLoading(true)
        try {
            await forgotPasswordMutation.run({ email })
            setMessage({
                message: 'Un email a été envoyé à cette adresse pour réinitialiser votre mot de passe.',
                type: 'default',
            })
            setEmailSent(true)
            setLoading(false)
        } catch (e) {
            console.log(e)
            setMessage({
                message:
                    "Cet email n'est pas associé à un compte Austral Expert d'Assuré. Veuillez contacter votre expert d'assuré.",
                type: 'error',
            })
            setLoading(false)
        }
    }

    return (
        <ExternalLinkLayout title="Mot de passe oublié">
            <ValidatorForm onSubmit={handleFormSubmit}>
                <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    size="small"
                    label="Email"
                    onChange={handleEmailChange}
                    type="email"
                    name="email"
                    value={email}
                    validators={['required', 'isEmail']}
                    errorMessages={['Ce champ est requis', "L'email est invalide"]}
                />

                {message && (
                    <p className={message.type === 'error' ? 'text-error' : 'text-success'}>{message.message}</p>
                )}

                {!emailSent && (
                    <div className="flex flex-wrap items-center mb-6">
                        <div className="relative">
                            <Button variant="contained" color="primary" disabled={loading} type="submit">
                                Réinitialiser mon mot de passe
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div>
                )}
                <Button className="text-primary" onClick={() => navigate(RoutesHelper.views.Root.to())}>
                    Retour à la connexion
                </Button>
            </ValidatorForm>
        </ExternalLinkLayout>
    )
}

export default ForgotPassword
