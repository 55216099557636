import { gql } from "@apollo/client/core";
import Vehicle_Minimal from '../Vehicle/Vehicle_Minimal'

export default gql`
    ${Vehicle_Minimal}
    fragment Fleet_Minimal on Fleet {
        id
        title
        enterprise {
            id
        }
        vehicles {
            ...Vehicle_Minimal
        }
    }
`
