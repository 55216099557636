import Breadcrumb from '../../../../../matx/components/Breadcrumb'
import Panel from '../../../../components/Panel'
import IconHelper from '../../../../helpers/IconHelper'
import RoutesHelper from '../../../../helpers/RoutesHelper'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'
import AEACustomerSitesList from './AEACustomerSitesList'

const AEACustomerSites = () => {
    const { customer } = useCustomerLoadedExisting()

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Breadcrumb
                    routeSegments={[{ name: 'Sites', icon: IconHelper.elementIcons.siteIcon }]}
                    homePath={RoutesHelper.views.AEA.customer.Home.to({ customerId: customer.id }).pathname}
                />
            </div>
            <Panel>
                <AEACustomerSitesList />
            </Panel>
        </div>
    )
}

export default AEACustomerSites
