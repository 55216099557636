import { gql } from '@apollo/client/core'
import fragments from '../fragments'

export default gql`
    mutation CreateEnterprise($title: String!, $address: String!, $customerId: ID!, $notificationUserIds: [ID!]) {
        createEnterprise(
            input: {
                title: $title
                address: $address
                projectId: $customerId
                notificationUserIds: $notificationUserIds
            }
        ) {
            errors
            status
            statusCode
            enterprise {
                id
                title
            }
        }
    }
`
