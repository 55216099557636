import axios from 'axios'
import { useMemo, useRef, useState } from 'react'

export type UploadedFileData = {
    id: string
    storage: string
    metadata: {
        size: number
        filename: string
        mime_type: string
    }
}

type UploadingState = {
    uploading: boolean
    uploadPercent: number
}

// Is used to compute upload state
export const useUploadFile = () => {
    const { uploadFile } = UploadManagerToolkit()

    const [uploadingState, setUploadingState] = useState<UploadingState>({
        uploading: false,
        uploadPercent: 0,
    })

    const uploadDocumentFile = (file: File): Promise<UploadedFileData> => {
        setUploadingState({ uploading: true, uploadPercent: 0 })

        const uploadFilePromise = uploadFile(file.name, file.size, file, (progressEvent: ProgressEvent) => {
            // We bind to progress events to update the global & sequence upload percent
            const newPercent = progressEvent.loaded / progressEvent.total

            setUploadingState({ uploading: true, uploadPercent: newPercent })
        })

        uploadFilePromise.then((uploadedFileData) => {
            setUploadingState({ uploading: false, uploadPercent: 1 })
        })

        return uploadFilePromise
    }

    return {
        uploadDocumentFile,
        uploadingState,
    }
}

type FileUploadingStatus = {
    loading: boolean
    uploadPercent: number
}

// Is used to compute upload state
export const useUploadManyFiles = () => {
    const { uploadFile } = UploadManagerToolkit()

    const [uploadingState, setUploadingState] = useState<FileUploadingStatus[]>([])
    const count = useRef<number>(0)

    const uploadDocumentFile = (file: File): Promise<UploadedFileData> => {
        const index = count.current
        count.current++

        //Initialization
        setUploadingState((oldState) => {
            return [
                ...oldState,
                {
                    loading: true,
                    uploadPercent: 0,
                },
            ]
        })

        const uploadFilePromise = uploadFile(file.name, file.size, file, (progressEvent: ProgressEvent) => {
            // We bind to progress events to update upload percent
            const newPercent = progressEvent.loaded / progressEvent.total

            setUploadingState((oldState) => {
                const newState = [...oldState]
                newState[index] = {
                    loading: true,
                    uploadPercent: newPercent,
                }

                return newState
            })
        })

        uploadFilePromise.then((uploadedFileData) => {
            setUploadingState((oldState) => {
                const newState = [...oldState]
                newState[index] = {
                    loading: false,
                    uploadPercent: 1,
                }

                return newState
            })
        })

        return uploadFilePromise
    }

    const isUploading: boolean = useMemo(() => {
        let isUploading = false
        uploadingState.forEach((state) => {
            if (state.loading) isUploading = true
        })
        return isUploading
    }, [uploadingState])

    return {
        uploadDocumentFile,
        uploadingState,
        isUploading,
    }
}

const uploadFile = (
    fileName: string,
    fileSize: number,
    fileContent: Blob,
    progressCallback: any
): Promise<UploadedFileData> => {
    const uploadAccessUrl =
        process.env.REACT_APP_SERVER_S3_URL + `?filename=${fileName}&type=application%2Foctet-stream`

    return new Promise((resolve, reject) => {
        // Asks for upload rights on s3/params
        axios
            .get(uploadAccessUrl)
            .then((response) => {
                // console.log("Got Ocean access URL : ", response.data.url);
                _oceanUpload(response.data.url, response.data.fields)
            })
            .catch(reject)

        // Uploads file on Digital Ocean
        const _oceanUpload = (url: string, fields: any) => {
            const formData = new FormData()

            Object.entries(fields).forEach(([field, value]: [string, any]) => {
                formData.append(field, value)
            })

            formData.append('file', fileContent)

            axios
                .post(url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: function (progressEvent) {
                        if (progressCallback) progressCallback(progressEvent)
                        // console.log('OCEAN Progress Event : ', progressEvent)
                    },
                })
                .then((response) => {
                    _finalizeUpload(fields)
                    // console.log('OCEAN RESPONSE : ', response)
                })
                .catch(reject)
        }

        // Updates the sequence with the new video data
        const _finalizeUpload = (fields: any) => {
            const uploadedFileData = {
                id: fields.key.match(/^cache\/(.+)/)[1], // object key without prefix
                storage: 'cache',
                metadata: {
                    size: fileSize,
                    filename: fileName,
                    mime_type: fields['Content-Type'],
                },
            }

            // We resolve the promise with the formatted data to send to the graphql
            resolve(uploadedFileData)
        }
    })
}

export const getUploadedFileDataAsString = (uploadedFileData: UploadedFileData) => {
    const uploadedFileDataString = JSON.stringify(uploadedFileData)
    return uploadedFileDataString
}

type UploadManagerToolkitProps = {
    uploadFile: (
        fileName: string,
        fileSize: number,
        fileContent: Blob,
        progressCallback: any
    ) => Promise<UploadedFileData>
    getUploadedFileDataAsString: (uploadedFileData: UploadedFileData) => string
}

export function UploadManagerToolkit(): UploadManagerToolkitProps {
    return {
        uploadFile,
        getUploadedFileDataAsString,
    }
}
