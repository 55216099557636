import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { useMemo, useState } from 'react'
import { EnumAgreementCategory } from '../../../../graphql/typings/global_types'
import { isStringInputValid } from '../../../../utilsTs'
import AgreementHelper from '../../../helpers/AgreementHelper'
import { Agreement_Minimal } from '../../../helpers/data/models/Agreement'
import DateHelper from '../../../helpers/DateHelper'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import FileDialogHelper, { FileType } from '../../../hooks/useFileDialog'
import { useCreateAgreement, useUpdateAgreement } from '../../../hooks/useMutations'
import { UploadManagerToolkit, useUploadFile } from '../../../hooks/useUploadManager'
import CustomButton from '../../CustomButton'
import MoneyInput from '../../customInputs/MoneyInput'
import { Dialog } from '../../Dialog'
import DocumentUpload from '../../documentsList/DocumentForms/DocumentUpload'

type AgreementDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (agreement: Agreement_Minimal) => void
    agreement?: Agreement_Minimal
    enterpriseId?: string
}

type AgreementData = {
    title: string | null
    description: string | null
    startAt: Date
    endAt: Date
    amount: number | null
    hasFileUploaded: boolean
    enterpriseId: string | null
    category: EnumAgreementCategory | null
}

const emptyAgreement: AgreementData = {
    title: null,
    description: null,
    startAt: new Date(),
    endAt: new Date(),
    amount: null,
    hasFileUploaded: false,
    enterpriseId: null,
    category: null,
}

const AgreementDialog = ({ onDone, open, closeDialog, agreement, enterpriseId }: AgreementDialogProps) => {
    const [data, setData] = useState<AgreementData>(
        agreement && enterpriseId
            ? {
                  title: agreement.title,
                  description: agreement.description,
                  startAt: new Date(agreement.startAt),
                  endAt: new Date(agreement.endAt),
                  amount: agreement.amount,
                  hasFileUploaded: !!agreement.urlToFile,
                  enterpriseId,
                  category: agreement.category,
              }
            : emptyAgreement
    )

    const [loading, setLoading] = useState<boolean>(false)

    const createAgreementMutation = useCreateAgreement()
    const updateAgreementMutation = useUpdateAgreement()

    const { customer } = useCustomerLoadedExisting()
    const { fromDateToServer } = DateHelper()

    const { errorAlert } = useErrorService()

    /*****************
     * FILE MANAGEMENT
     ****************/

    const { uploadDocumentFile, uploadingState } = useUploadFile()
    const [filePickerNode, openFilePicker, localFile, deleteLocalFile] = FileDialogHelper.useFileDialog(FileType.ALL)
    const { getUploadedFileDataAsString } = UploadManagerToolkit()

    const deleteFile = () => {
        deleteLocalFile()
    }

    const handleClose = () => {
        deleteLocalFile()
        closeDialog()
    }

    const handleStringChange = (value: string, prop: 'title' | 'description' | 'enterpriseId') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }
    const handleNumberChange = (value: string, prop: 'amount') => {
        const numberValue = parseFloat(value)

        setData((oldData) => {
            return {
                ...oldData,
                [prop]: numberValue,
            }
        })
    }

    const handleDateChange = (date: MaterialUiPickersDate, prop: 'startAt' | 'endAt') => {
        if (!date) return

        setData((oldData) => {
            return {
                ...oldData,
                [prop]: date,
            }
        })
    }

    const handleCategoryChange = (value: string) => {
        const category = value as EnumAgreementCategory

        setData((oldData) => {
            return {
                ...oldData,
                category,
            }
        })
    }

    const handleSubmit = async () => {
        if (data.title === null) return
        if (data.startAt === null) return
        if (data.endAt === null) return
        if (data.amount === null) return
        if (data.enterpriseId === null) return

        setLoading(true)

        try {
            let fileDataAsString: string | null = null
            if (localFile) {
                // We upload the doc
                const uploadedFileData = await uploadDocumentFile(localFile)
                fileDataAsString = getUploadedFileDataAsString(uploadedFileData)
            }

            if (agreement) {
                //Update
                const response = await updateAgreementMutation.run({
                    id: agreement.id,
                    title: data.title,
                    category: data.category,
                    description: data.description,
                    amount: data.amount,
                    endAt: fromDateToServer(data.endAt),
                    startAt: fromDateToServer(data.startAt),
                    enterpriseId: data.enterpriseId,
                    file: fileDataAsString,
                })

                if (response.agreement) {
                    onDone(response.agreement)
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour du contrat'
                }
            } else {
                // Creation
                const response = await createAgreementMutation.run({
                    customerId: customer.id,
                    title: data.title,
                    category: data.category,
                    description: data.description,
                    amount: data.amount,
                    endAt: fromDateToServer(data.endAt),
                    startAt: fromDateToServer(data.startAt),
                    enterpriseId: data.enterpriseId,
                    file: fileDataAsString,
                })

                if (response.agreement) {
                    onDone(response.agreement)
                } else {
                    throw 'Une erreur est survenue pendant la création du contrat'
                }

                setData(emptyAgreement)
            }

            deleteLocalFile()
            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid =
        isStringInputValid(data.title) &&
        data.startAt !== null &&
        data.endAt !== null &&
        data.amount !== null &&
        data.enterpriseId !== null

    const enterpriseItems = useMemo(() => {
        return [...customer.enterprises]
            .sort((enterpriseA, enterpriseB) => {
                return enterpriseA.title.localeCompare(enterpriseB.title)
            })
            .map((enterprise) => {
                return (
                    <MenuItem value={enterprise.id} key={enterprise.id}>
                        {enterprise.title}
                    </MenuItem>
                )
            })
    }, [customer.enterprises])

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un contrat</DialogTitle>
            <DialogContent>
                <DocumentUpload
                    loading={loading}
                    uploadPercent={uploadingState.uploadPercent}
                    localFile={localFile}
                    openFilePicker={openFilePicker}
                    hasAlreadyFileUploaded={data.hasFileUploaded}
                    deleteFile={deleteFile}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Nom du contrat *"
                    type="text"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleStringChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    value={data.description || ''}
                    onChange={(event) => handleStringChange(event.target.value, 'description')}
                />
                <MoneyInput
                    margin="dense"
                    id="amount"
                    label="Montant de l'abonnement *"
                    fullWidth
                    value={data.amount === null ? '' : data.amount}
                    onChange={(event) => handleNumberChange(event.target.value, 'amount')}
                />
                <TextField
                    margin="dense"
                    id="enterprise"
                    label="Entreprise *"
                    type="text"
                    fullWidth
                    value={data.enterpriseId || ''}
                    onChange={(event) => handleStringChange(event.target.value, 'enterpriseId')}
                    select>
                    {enterpriseItems}
                </TextField>
                <TextField
                    margin="dense"
                    id="category"
                    label="Couverture du contrat *"
                    type="text"
                    fullWidth
                    value={data.category || ''}
                    onChange={(event) => handleCategoryChange(event.target.value)}
                    select>
                    {Object.values(EnumAgreementCategory)
                        .sort((catA, catB) => {
                            const labelA = AgreementHelper.getAgreementCategoryLabel(catA)
                            const labelB = AgreementHelper.getAgreementCategoryLabel(catB)
                            return labelA.localeCompare(labelB)
                        })
                        .map((category, index) => {
                            return (
                                <MenuItem value={category} key={index}>
                                    {AgreementHelper.getAgreementCategoryLabel(category)}
                                </MenuItem>
                            )
                        })}
                </TextField>
                <DatePicker
                    margin="dense"
                    id="startAt"
                    label="Début du contrat *"
                    value={data.startAt}
                    onChange={(date) => handleDateChange(date, 'startAt')}
                    fullWidth
                    format="dd/MM/yyyy"
                    autoOk={true}
                />
                <DatePicker
                    margin="dense"
                    id="endAt"
                    label="Fin du contrat *"
                    value={data.endAt}
                    onChange={(date) => handleDateChange(date, 'endAt')}
                    fullWidth
                    format="dd/MM/yyyy"
                    className="mb-5"
                    autoOk={true}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
            {filePickerNode}
        </Dialog>
    )
}

export default AgreementDialog
