import React from 'react'
import { EnumUser } from '../../../../graphql/typings/global_types'
import Breadcrumb, { BreadcrumbProps } from '../../../../matx/components/Breadcrumb'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import InfoPanel from '../../InfoPanel'
import Panel from '../../Panel'
import PreventionList from './PreventionList'

type PreventionProps = BreadcrumbProps

const Preventions = (props: PreventionProps) => {
    const { customer } = useCustomerLoadedExisting()
    const auth = useAuthUser()

    const isAdmin = auth.user.userType === EnumUser.admin
    const emptyText = 'Pour ajouter / modifier des préventions, rendez-vous sur la page de chaque local, onglet "Préventions".'

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Breadcrumb {...props} />
            </div>
            {isAdmin && <InfoPanel icon="info" text={emptyText} />}
            <Panel>
                <PreventionList preventions={customer.preventions} />
            </Panel>
        </div>
    )
}

export default Preventions
