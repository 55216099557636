import { createContext, ReactElement, useContext, useState } from 'react'
import SnackBar, { SnackData, SnackType } from '../components/snackbar/SnackBar'

type SnackBarProps = {
    message: string
    type?: SnackType
}

type SnackBarContext = {
    openSnack: (props: SnackBarProps) => void
}

const SnackBarContext = createContext<SnackBarContext>({
    openSnack: (props: SnackBarProps) => {},
})

type ProvideSnackBarProps = {
    children: ReactElement
}

export function ProvideSnackBar({ children }: ProvideSnackBarProps) {
    const snackBarData = useProvideSnackBar()

    return (
        <SnackBarContext.Provider value={snackBarData}>
            {snackBarData.snackBar}
            {children}
        </SnackBarContext.Provider>
    )
}

export const useSnackBar = () => {
    return {
        openSnack: useContext(SnackBarContext).openSnack,
    }
}

const useProvideSnackBar = () => {
    const [snackData, setSnackData] = useState<SnackData>({ open: false })

    const snackBar = (
        <SnackBar
            open={snackData.open}
            close={() => setSnackData({ open: false })}
            type={snackData.open ? snackData.type : 'success'}
            text={snackData.open ? snackData.message : ''}
        />
    )

    const openSnack = ({ message, type }: SnackBarProps) => {
        setSnackData((oldData) => ({
            message,
            type: type || null,
            open: true,
        }))
    }

    return {
        snackBar,
        openSnack,
    }
}

export default useSnackBar
