import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Document.Document_User}
    query GetDocuments_User($customerId: ID!) {
        documents(projectId: $customerId, limit: 200) {
            ...Document_User
        }
    }
`
