import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Vehicle.Vehicle_Documents}
    query GetVehicle_Documents($id: ID!) {
        vehicle(id: $id) {
            ...Vehicle_Documents
        }
    }
`
