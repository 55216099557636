import { gql } from '@apollo/client/core'

export default gql`
    mutation CreateContract(
        $title: String!
        $enterpriseId: ID!
        $insuranceId: ID!
        $brokerId: ID
        $insurerId: ID
        $startAt: String!
        $renewableDate: String
        $renewableNotice: Int
        $bounty: Float
        $reference: String
    ) {
        createContract(
            input: {
                title: $title
                enterpriseId: $enterpriseId
                insuranceId: $insuranceId
                brokerId: $brokerId
                insurerId: $insurerId
                startAt: $startAt
                renewableDate: $renewableDate
                renewableNotice: $renewableNotice
                bounty: $bounty
                reference: $reference
            }
        ) {
            errors
            status
            statusCode
            contract {
                id
            }
        }
    }
`
