import { useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import React, { useEffect, useRef } from 'react'
import useSettings from '../../../app/hooks/useSettings'
import { MatxSuspense } from '../../../matx'
import Layout, { LayoutProps } from './Layout1/Layout'

const MatxLayout = (props: LayoutProps) => {
    const theme = useTheme()
    const isMdScreen = useMediaQuery(theme.breakpoints.down('md'))
    const { settings, updateSettings } = useSettings()
    const ref = useRef({ isMdScreen, settings })

    useEffect(() => {
        let { settings } = ref.current
        let sidebarMode = settings.layout1Settings.leftSidebar.mode
        if (settings.layout1Settings.leftSidebar.show) {
            let mode = isMdScreen ? 'close' : sidebarMode
            updateSettings({ layout1Settings: { leftSidebar: { mode } } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMdScreen])

    return (
        <MatxSuspense>
            <Layout {...props} />
        </MatxSuspense>
    )
}

export default MatxLayout
