import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { Fragment, ReactNode, useState } from 'react'
import { GetEnterprise_Full_enterprise_vehicles } from '../../../../../../graphql/queries/typings/GetEnterprise_Full'
import { EnumUser } from '../../../../../../graphql/typings/global_types'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import IconHelper from '../../../../../helpers/IconHelper'
import VehicleHelper from '../../../../../helpers/VehicleHelper'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useEnterpriseLoadedExisting } from '../../../../../hooks/useEnterprise'
import { useDeleteVehicle } from '../../../../../hooks/useMutations'
import useSnackBar from '../../../../../hooks/useSnackBar'
import DataTable from '../../../../DataTable'
import { ConfirmDialog } from '../../../../Dialog'
import DocumentsModal from '../../../../documentsList/DocumentModal/DocumentsModal'
import Panel from '../../../../Panel'
import VehicleDialog from './VehicleDialog'

type VehicleForList = GetEnterprise_Full_enterprise_vehicles

type VehicleDialogState =
    | {
          open: true
          vehicle: VehicleForList
      }
    | { open: false }

const EnterpriseVehiclesList = () => {
    const { enterprise } = useEnterpriseLoadedExisting()
    const { errorAlert } = useErrorService()
    const { openSnack } = useSnackBar()
    const { customer } = useCustomerLoadedExisting()

    const deleteVehicleMutation = useDeleteVehicle()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<VehicleDialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<VehicleDialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [openDocsDialog, setOpenDocsDialog] = useState<VehicleDialogState>({ open: false })

    const vehicles = enterprise.vehicles

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un véhicule`,
    }

    const onCreated = (vehicle: VehicleForList) => {
        openSnack({
            type: 'success',
            message: `Véhicule créé avec succès !`,
        })
    }

    const onEditClick = (vehicle: VehicleForList) => {
        setOpenEditDialog({
            open: true,
            vehicle,
        })
    }

    const onEdited = (vehicle: VehicleForList) => {
        openSnack({
            type: 'success',
            message: `Véhicule modifié avec succès !`,
        })
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        const vehicle = vehicles[dataIndex]

        const elements: ReactNode[] = [
            <Tooltip title="Voir les documents">
                <IconButton onClick={() => setOpenDocsDialog({ open: true, vehicle })}>
                    <Icon>{IconHelper.elementIcons.docsIcon}</Icon>
                </IconButton>
            </Tooltip>,
        ]

        if (isAdmin) {
            elements.push(
                <Tooltip title="Modifier le véhicule">
                    <IconButton onClick={() => onEditClick(vehicle)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
            )

            elements.push(
                <Tooltip title="Supprimer le véhicule">
                    <IconButton onClick={() => deleteFleet(vehicle)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            )
        }

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                {elements}
            </div>
        )
    }

    const deleteFleet = (vehicle: VehicleForList) => {
        setOpenDeleteDialog({ open: true, vehicle })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const response = await deleteVehicleMutation.run({
                enterpriseId: enterprise.id,
                id: openDeleteDialog.vehicle.id,
                customerId: customer.id,
            })

            if (response.errors.length > 0) {
                throw 'Erreur lors de la suppression du véhicule'
            } else {
                openSnack({
                    type: 'success',
                    message: 'Véhicule supprimé avec succès !',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setOpenDeleteDialog({ open: false })
            setDeleteLoading(false)
        }
    }

    const columns = [
        {
            name: 'title',
            label: 'Désignation',
        },
        {
            name: 'registration',
            label: 'Immatriculation',
        },
        {
            name: 'description',
            label: 'Description',
        },
    ]

    return (
        <Fragment>
            <Panel>
                <DataTable<VehicleForList>
                    title={`Véhicules de l'entreprise`}
                    icon={IconHelper.elementIcons.vehicleIcon}
                    data={vehicles}
                    columns={columns}
                    showSearch={true}
                    addFeature={addFeatureData}
                    actionsColumn={actionsColumn}
                />
            </Panel>
            <VehicleDialog
                onDone={onCreated}
                open={openAddDialog}
                closeDialog={() => setOpenAddDialog(false)}
                vehicleEnterpriseId={enterprise.id}
            />
            {openEditDialog.open && (
                <VehicleDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    vehicle={openEditDialog.vehicle}
                    vehicleEnterpriseId={enterprise.id}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={() => setOpenDeleteDialog({ open: false })}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'un véhicule"
                    text={`Voulez-vous vraiment supprimer le véhicule "${VehicleHelper.getLabel(
                        openDeleteDialog.vehicle
                    )}" ?`}
                    loading={deleteLoading}
                />
            )}
            {openDocsDialog.open && (
                <DocumentsModal
                    open={openDocsDialog.open}
                    closeDialog={() => setOpenDocsDialog({ open: false })}
                    elementType={'vehicle'}
                    elementId={openDocsDialog.vehicle.id}
                />
            )}
        </Fragment>
    )
}

export default EnterpriseVehiclesList
