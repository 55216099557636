import { useMemo } from 'react'
import useGetAdminDashboardData, { GetAdminDashboardDataProps } from '../helpers/data/getAdminDashboardData'
import DateHelper from '../helpers/DateHelper'

type UseAdminDashboardDataProps = Omit<GetAdminDashboardDataProps, 'startDate' | 'endDate'> & {
    startDate: Date | null
    endDate: Date | null
}

const useAdminDashboardData = ({ startDate, endDate, ...props }: UseAdminDashboardDataProps) => {
    const { fromDateToServer } = DateHelper()

    const computedStartDate = useMemo(() => (startDate === null ? new Date(0) : startDate), [startDate])
    const computedEndDate = useMemo(() => (endDate === null ? new Date() : endDate), [endDate])

    const formattedStartDate = fromDateToServer(computedStartDate)
    const formattedEndDate = fromDateToServer(computedEndDate)

    return useGetAdminDashboardData({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        ...props,
    })
}

export default useAdminDashboardData
