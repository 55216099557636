/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum EnumAgreementCategory {
  all_inclusive = "all_inclusive",
  disaster_free = "disaster_free",
}

export enum EnumDamageTarget {
  Enterprise = "Enterprise",
  Fleet = "Fleet",
  Good = "Good",
  Local = "Local",
  Site = "Site",
  Vehicle = "Vehicle",
}

export enum EnumDisasterPaymentStatus {
  checked = "checked",
  waiting_docs = "waiting_docs",
  waiting_payment = "waiting_payment",
}

export enum EnumDisasterStatus {
  closed = "closed",
  open = "open",
  pending_price_calculation = "pending_price_calculation",
  waiting_payments = "waiting_payments",
}

export enum EnumDisasterThrough {
  through_all = "through_all",
  through_contract = "through_contract",
  through_damage = "through_damage",
}

export enum EnumDocumentCategory {
  accounting = "accounting",
  customer_description = "customer_description",
  disaster_administrative_and_others = "disaster_administrative_and_others",
  disaster_appraisals = "disaster_appraisals",
  disaster_declaration_and_report = "disaster_declaration_and_report",
  disaster_deferred_follow_up = "disaster_deferred_follow_up",
  disaster_judicial = "disaster_judicial",
  disaster_loss_and_claim_statement = "disaster_loss_and_claim_statement",
  disaster_original_invoices = "disaster_original_invoices",
  disaster_pictures = "disaster_pictures",
  disaster_pv_and_receipt = "disaster_pv_and_receipt",
  disaster_repaired_invoices = "disaster_repaired_invoices",
  gray_card = "gray_card",
  green_card = "green_card",
  identity_and_license = "identity_and_license",
  insurance_certificate = "insurance_certificate",
  insurance_contract = "insurance_contract",
  kbis = "kbis",
  lease = "lease",
  matrix = "matrix",
  miscellaneous = "miscellaneous",
  organizational_chart = "organizational_chart",
  other_prevention = "other_prevention",
  other_statement = "other_statement",
  prevention = "prevention",
  proof_of_construction = "proof_of_construction",
  reporting = "reporting",
}

export enum EnumDocumentParent {
  Contract = "Contract",
  Disaster = "Disaster",
  Enterprise = "Enterprise",
  Fleet = "Fleet",
  Good = "Good",
  Local = "Local",
  Prevention = "Prevention",
  Project = "Project",
  Site = "Site",
  Vehicle = "Vehicle",
}

export enum EnumDocumentStatus {
  answered = "answered",
  asked_by_admin = "asked_by_admin",
  asked_by_user = "asked_by_user",
  confirmed = "confirmed",
  rejected = "rejected",
  submission_rejected = "submission_rejected",
  submitted_by_user = "submitted_by_user",
}

export enum EnumGuaranteeTarget {
  Enterprise = "Enterprise",
  Fleet = "Fleet",
  Good = "Good",
  Local = "Local",
  Site = "Site",
  Vehicle = "Vehicle",
}

export enum EnumUser {
  admin = "admin",
  user = "user",
}

export interface DamageTargetInput {
  targetType: EnumDamageTarget;
  targetId: string;
}

export interface DocumentInput {
  title?: string | null;
  parentId?: string | null;
  parentType?: EnumDocumentParent | null;
  category?: EnumDocumentCategory | null;
  askedById?: string | null;
  status?: EnumDocumentStatus | null;
  statusComment?: string | null;
  fileDescriptor?: string | null;
}

export interface GuaranteeTargetInput {
  targetType: EnumGuaranteeTarget;
  targetId: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
