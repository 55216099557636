import { EnumUser } from '../../graphql/typings/global_types'
import { ContractTabName } from '../components/customer/contracts/contract/Contract'
import { DisasterTabName } from '../components/customer/disasters/disaster/Disaster'
import { DocumentTabName } from '../components/customer/documents/Documents'
import { EnterpriseTabName } from '../components/customer/enterprises/enterprise/EnterpriseZone'
import { PlaceTabName } from '../components/customer/places/place/Place'
import { VehicleTabName } from '../components/customer/vehicles/vehicle/Vehicle'
import RoutesHelper from '../helpers/RoutesHelper'
import { useAuthUser } from './useAuth'
import { useCustomerLoadedExisting } from './useCustomer'

export const useNavContext = () => {
    const { customer } = useCustomerLoadedExisting()
    const auth = useAuthUser()

    const isUserAEA = auth.user.userType === EnumUser.admin

    const getCustomerRoute = () => {
        return isUserAEA
            ? RoutesHelper.views.AEA.customer.Home.to({
                  customerId: customer.id,
              })
            : RoutesHelper.views.Customer.Home.to()
    }

    const getEnterprisesRoute = () => {
        return isUserAEA
            ? RoutesHelper.views.AEA.customer.enterprises.Home.to({
                  customerId: customer.id,
              })
            : RoutesHelper.views.Customer.enterprises.Home.to()
    }

    const getEnterpriseRoute = (enterpriseId: string, tabName?: EnterpriseTabName) => {
        const aeaCustomerEntepriseRoute = RoutesHelper.views.AEA.customer.enterprises.Enterprise.to({
            customerId: customer.id,
            enterpriseId,
            tab: tabName,
        })

        const customerEnterpriseRoute = RoutesHelper.views.Customer.enterprises.Enterprise.to({
            enterpriseId,
            tab: tabName,
        })

        return isUserAEA ? aeaCustomerEntepriseRoute : customerEnterpriseRoute
    }

    const getOrgChartRoute = () => {
        return isUserAEA
            ? RoutesHelper.views.AEA.customer.orgChart.Home.to({
                  customerId: customer.id,
              })
            : RoutesHelper.views.Customer.orgChart.Home.to()
    }

    const getDisasterRoute = (disasterId: string, tabName?: DisasterTabName) => {
        const aeaCustomerDisasterRoute = RoutesHelper.views.AEA.customer.disasters.Disaster.to({
            customerId: customer.id,
            disasterId,
            tab: tabName,
        })

        const customerDisasterRoute = RoutesHelper.views.Customer.disasters.Disaster.to({
            disasterId,
            tab: tabName,
        })

        return isUserAEA ? aeaCustomerDisasterRoute : customerDisasterRoute
    }

    const getContractRoute = (contractId: string, tabName?: ContractTabName) => {
        const aeaCustomerContractRoute = RoutesHelper.views.AEA.customer.contracts.Contract.to({
            customerId: customer.id,
            contractId,
            tab: tabName,
        })

        const customerContractRoute = RoutesHelper.views.Customer.contracts.Contract.to({
            contractId,
            tab: tabName,
        })

        return isUserAEA ? aeaCustomerContractRoute : customerContractRoute
    }

    const getContractsRoute = () => {
        return isUserAEA
            ? RoutesHelper.views.AEA.customer.contracts.Home.to({
                  customerId: customer.id,
              })
            : RoutesHelper.views.Customer.contracts.Home.to()
    }

    const getVehicleRoute = (vehicleId: string, tabName?: VehicleTabName) => {
        const aeaCustomerVehicleRoute = RoutesHelper.views.AEA.customer.vehicles.Vehicle.to({
            customerId: customer.id,
            vehicleId,
            tab: tabName,
        })

        const customerVehicleRoute = RoutesHelper.views.Customer.vehicles.Vehicle.to({
            vehicleId,
            tab: tabName,
        })

        return isUserAEA ? aeaCustomerVehicleRoute : customerVehicleRoute
    }

    const getVehiclesRoute = () => {
        return isUserAEA
            ? RoutesHelper.views.AEA.customer.vehicles.Home.to({
                  customerId: customer.id,
              })
            : RoutesHelper.views.Customer.vehicles.Home.to()
    }

    const getPlacesRoute = () => {
        return isUserAEA
            ? RoutesHelper.views.AEA.customer.places.Home.to({
                  customerId: customer.id,
              })
            : RoutesHelper.views.Customer.places.Home.to()
    }

    const getPlaceRoute = (placeId: string, tabName?: PlaceTabName) => {
        const aeaCustomerPlaceRoute = RoutesHelper.views.AEA.customer.places.Place.to({
            customerId: customer.id,
            placeId,
            tab: tabName,
        })

        const customerContractRoute = RoutesHelper.views.Customer.places.Place.to({
            placeId,
            tab: tabName,
        })

        return isUserAEA ? aeaCustomerPlaceRoute : customerContractRoute
    }

    const getDisastersRoute = () => {
        return isUserAEA
            ? RoutesHelper.views.AEA.customer.disasters.Home.to({
                  customerId: customer.id,
              })
            : RoutesHelper.views.Customer.disasters.Home.to()
    }

    const getAdminContractRoute = () => {
        return isUserAEA
            ? RoutesHelper.views.AEA.customer.AdminContract.Home.to({
                  customerId: customer.id,
              })
            : RoutesHelper.views.Customer.AdminContract.Home.to()
    }

    const getDocumentsRoute = (tabName?: DocumentTabName) => {
        return isUserAEA
            ? RoutesHelper.views.AEA.customer.Documents.to({
                  customerId: customer.id,
                  tab: tabName,
              })
            : RoutesHelper.views.Customer.Documents.to({ tab: tabName })
    }

    const getPreventionRoute = (placeId: string, categoryId: string) => {
        return isUserAEA
            ? RoutesHelper.views.AEA.customer.preventions.Prevention.to({
                  customerId: customer.id,
                  placeId,
                  categoryId,
              })
            : RoutesHelper.views.Customer.preventions.Prevention.to({
                  placeId,
                  categoryId,
              })
    }

    return {
        getCustomerRoute,
        getEnterprisesRoute,
        getEnterpriseRoute,
        getOrgChartRoute,
        getDisasterRoute,
        getDisastersRoute,
        getVehicleRoute,
        getVehiclesRoute,
        getContractRoute,
        getContractsRoute,
        getPlacesRoute,
        getPlaceRoute,
        getAdminContractRoute,
        getDocumentsRoute,
        getPreventionRoute,
    }
}

export default useNavContext
