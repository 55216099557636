import React from 'react'
import { BreadcrumbSegment } from '../../../../../matx/components/Breadcrumb'
import Disaster from '../../../../components/customer/disasters/disaster/Disaster'
import { Disaster_Full } from '../../../../helpers/data/models/disaster/Disaster'
import RoutesHelper from '../../../../helpers/RoutesHelper'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'

const AEACustomerDisaster = () => {
    const { customer } = useCustomerLoadedExisting()

    const getBreadcrumbSegments = (disaster: Disaster_Full): BreadcrumbSegment[] => {
        return [
            {
                name: 'Sinistres',
                path: RoutesHelper.views.AEA.customer.disasters.Home.to({ customerId: customer.id }).pathname,
            },
            {
                name: disaster.title,
                path: RoutesHelper.views.AEA.customer.disasters.Disaster.to({
                    customerId: customer.id,
                    disasterId: disaster.id,
                }).pathname,
            },
        ]
    }

    return (
        <Disaster
            route={RoutesHelper.views.AEA.customer.disasters.Disaster}
            getBreadcrumbSegments={getBreadcrumbSegments}
        />
    )
}

export default AEACustomerDisaster
