import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { isStringInputValid } from '../../../../../utilsTs'
import CustomButton from '../../../../components/CustomButton'
import { Insurer_Minimal } from '../../../../helpers/data/models/contract/Insurer'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import { useCreateInsurer, useUpdateInsurer } from '../../../../hooks/useMutations'

type InsurerDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (Insurer: Insurer_Minimal) => void
    insurer?: Insurer_Minimal
}

type Data = {
    title: string | null
    phone: string | null
    email: string | null
}

const emptyInsurer: Data = {
    title: null,
    phone: null,
    email: null,
}

const InsurerDialog = ({ insurer, onDone, open, closeDialog }: InsurerDialogProps) => {
    const [data, setData] = useState<Data>(
        insurer
            ? {
                  title: insurer.title,
                  phone: insurer.phone,
                  email: insurer.email,
              }
            : emptyInsurer
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createInsurerMutation = useCreateInsurer()
    const updateInsurerMutation = useUpdateInsurer()

    const { errorAlert } = useErrorService()

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title' | 'phone' | 'email') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const handleSubmit = async () => {
        if (!data.title) return

        setLoading(true)

        try {
            if (insurer) {
                //Update
                const response = await updateInsurerMutation.run({
                    id: insurer.id,
                    title: data.title,
                    phone: data.phone,
                    email: data.email,
                })

                if (response.insurer) {
                    onDone(response.insurer)
                } else {
                    throw "Une erreur est survenue pendant la mise à jour de l'assurance"
                }
            } else {
                const response = await createInsurerMutation.run({
                    title: data.title,
                    phone: data.phone,
                    email: data.email,
                })

                if (response.insurer) {
                    onDone(response.insurer)
                } else {
                    throw "Une erreur est survenue pendant la création de l'assurance"
                }
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid = isStringInputValid(data.title)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un agent général</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Nom de l'agent *"
                    type="text"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    id="phone"
                    label="Numéro de téléphone"
                    type="text"
                    fullWidth
                    value={data.phone || ''}
                    onChange={(event) => handleChange(event.target.value, 'phone')}
                />

                <TextField
                    margin="dense"
                    id="email"
                    label="Email de contact"
                    type="email"
                    fullWidth
                    value={data.email || ''}
                    onChange={(event) => handleChange(event.target.value, 'email')}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default InsurerDialog
