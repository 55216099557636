import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import TextField from '@material-ui/core/TextField'
import { useState } from 'react'
import { FleetGeneric_fleet } from '../../../../../../graphql/queries/typings/FleetGeneric'
import { VehicleGeneric_vehicle } from '../../../../../../graphql/queries/typings/VehicleGeneric'
import { isStringInputValid } from '../../../../../../utilsTs'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import VehicleHelper, { VehicleForLabel } from '../../../../../helpers/VehicleHelper'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useCreateFleet, useUpdateFleet } from '../../../../../hooks/useMutations'
import CustomButton from '../../../../CustomButton'
import MultiSelectCheckbox from '../../../../customInputs/MultiSelectCheckbox'
import { Dialog } from '../../../../Dialog'

type FleetDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (fleet: FleetForDialog) => void
    enterpriseId?: string
    vehicles?: VehicleForLabel[]
    fleet?: FleetForDialog
}

type FleetData = {
    title: string | null
    enterpriseId: string | null
    vehiclesIds: string[]
}

type FleetForDialog = Pick<FleetGeneric_fleet, 'id' | 'title'> & {
    vehicles: Pick<VehicleGeneric_vehicle, 'id'>[]
}

const FleetDialog = ({ onDone, open, closeDialog, fleet, enterpriseId, vehicles }: FleetDialogProps) => {
    const emptyFleet: FleetData = {
        title: null,
        enterpriseId: enterpriseId ? enterpriseId : null,
        vehiclesIds: [],
    }

    const [data, setData] = useState<FleetData>(
        fleet
            ? {
                  title: fleet.title,
                  enterpriseId: enterpriseId ? enterpriseId : null,
                  vehiclesIds: fleet.vehicles.map((vehicle) => vehicle.id),
              }
            : emptyFleet
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createFleetMutation = useCreateFleet()
    const updateFleetMutation = useUpdateFleet()

    const { errorAlert } = useErrorService()
    const { customer } = useCustomerLoadedExisting()

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title' | 'enterpriseId') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const handleVehiclesChange = (selectedValues: string[]) => {
        setData((oldData) => {
            return {
                ...oldData,
                vehiclesIds: selectedValues,
            }
        })
    }

    const handleSubmit = async () => {
        if (!data.title) {
            errorAlert('Vous devez saisir un titre pour la flotte')
            return
        }
        if (!data.enterpriseId) {
            errorAlert('La flotte doit être rattachée à une entreprise')
            return
        }

        setLoading(true)

        try {
            if (fleet) {
                //Update
                const response = await updateFleetMutation.run({
                    id: fleet.id,
                    enterpriseId: data.enterpriseId,
                    title: data.title,
                    vehicleIds: data.vehiclesIds,
                })

                if (response.fleet) {
                    onDone(response.fleet)
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour de la flotte'
                }
            } else {
                const response = await createFleetMutation.run({
                    customerId: customer.id,
                    enterpriseId: data.enterpriseId,
                    title: data.title,
                    vehicleIds: data.vehiclesIds,
                })

                if (response.fleet) {
                    onDone(response.fleet)
                } else {
                    throw 'Une erreur est survenue pendant la création de la flotte'
                }

                setData(emptyFleet)
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const getVehicleLabelFromId = (vehicleId: string) => {
        if (!vehicles) return 'Véhicule inconnu'

        const vehicle = vehicles.find((vehicle) => vehicle.id === vehicleId)

        if (!vehicle) return 'Véhicule inconnu'

        return VehicleHelper.getLabel(vehicle)
    }

    const isFormValid = isStringInputValid(data.title)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'une flotte</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Libellé *"
                    type="text"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    id="type"
                    label={`Entreprise propriétaire`}
                    type="text"
                    fullWidth
                    value={data.enterpriseId || ''}
                    onChange={(event) => handleChange(event.target.value, 'enterpriseId')}
                    disabled={!!enterpriseId}
                    select>
                    {[...customer.enterprises]
                        .sort((enterpriseA, enterpriseB) => {
                            return enterpriseA.title.localeCompare(enterpriseB.title)
                        })
                        .map((enterprise) => {
                            return (
                                <MenuItem value={enterprise.id} key={enterprise.id}>
                                    {enterprise.title}
                                </MenuItem>
                            )
                        })}
                </TextField>
                {vehicles && (
                    <MultiSelectCheckbox
                        selectTitle={vehicles.length === 0 ? 'Aucun véhicule' : 'Véhicules de la flotte'}
                        name="vehicles"
                        value={data.vehiclesIds}
                        onChange={(selectedValues) => handleVehiclesChange(selectedValues)}
                        getLabel={getVehicleLabelFromId}
                        fullWidth
                        disabled={vehicles.length === 0}
                        availableIds={[...vehicles]
                            .sort((vehicleA, vehicleB) => {
                                return vehicleA.title.localeCompare(vehicleB.title)
                            })
                            .map((vehicle) => vehicle.id)}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default FleetDialog
