import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { isStringInputValid } from '../../../../utilsTs'
import { RoadmapItem_Minimal } from '../../../helpers/data/models/RoadmapItem'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useCreateRoadmapItem, useUpdateRoadmapItem } from '../../../hooks/useMutations'
import CustomButton from '../../CustomButton'
import { Dialog } from '../../Dialog'

type DialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (roadmapItem: RoadmapItem_Minimal) => void
    roadmapItem?: RoadmapItem_Minimal
}

const emptyData: Data = {
    title: null,
    year: new Date().getFullYear(),
    description: null,
}

type Data = {
    title: string | null
    year: number
    description: string | null
}

const RoadmapDialog = ({ onDone, open, closeDialog, roadmapItem }: DialogProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { errorAlert } = useErrorService()

    const [data, setData] = useState<Data>(
        roadmapItem
            ? {
                  title: roadmapItem.title,
                  year: new Date(roadmapItem.date).getFullYear(),
                  description: roadmapItem.description,
              }
            : emptyData
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createRoadmapItemMutation = useCreateRoadmapItem()
    const updateRoadmapItemMutation = useUpdateRoadmapItem()

    const handleClose = () => {
        setData(emptyData)
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title' | 'year' | 'description') => {
        setData({
            ...data,
            [prop]: value,
        })
    }

    const handleSubmit = async () => {
        if (!data.title) return

        setLoading(true)

        try {
            if (roadmapItem) {
                //Update
                const response = await updateRoadmapItemMutation.run({
                    id: roadmapItem.id,
                    customerId: customer.id,
                    date: new Date(`01/01/${data.year}`).toISOString(),
                    title: data.title,
                    description: data.description,
                })

                if (response.roadmapItem) {
                    onDone(response.roadmapItem)
                } else {
                    errorAlert('Une erreur est survenue pendant la mise à jour du projet', response)
                }
            } else {
                //Create
                const response = await createRoadmapItemMutation.run({
                    customerId: customer.id,
                    date: new Date(`01/01/${data.year}`).toISOString(),
                    title: data.title,
                    description: data.description,
                })

                if (response.roadmapItem) {
                    onDone(response.roadmapItem)
                } else {
                    errorAlert('Une erreur est survenue pendant la mise à jour du projet', response)
                }
            }

            closeDialog()
            setData(emptyData)
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid = isStringInputValid(data.title)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un projet</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="year"
                    label="Année"
                    type="number"
                    fullWidth
                    value={data.year}
                    onChange={(event) => handleChange(event.target.value, 'year')}
                />
                <TextField
                    margin="dense"
                    id="title"
                    label="Libellé *"
                    type="string"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="string"
                    fullWidth
                    multiline={true}
                    value={data.description || ''}
                    onChange={(event) => handleChange(event.target.value, 'description')}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Créer
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default RoadmapDialog
