import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { client } from '../../graphql/client'
import mutations from '../../graphql/mutations'
import { SignInUser, SignInUserVariables } from '../../graphql/mutations/typings/SignInUser'
import queries from '../../graphql/queries'
import { WhoAmI, WhoAmI_whoami } from '../../graphql/queries/typings/WhoAmI'
import { EnumUser } from '../../graphql/typings/global_types'
import { useQueryErrorManager } from '../helpers/errors/ErrorHelper'
import { useErrorService } from '../helpers/errors/ErrorService'

const localStorageTokenKey = process.env.REACT_APP_LOCAL_STORAGE_AUTH_TOKEN as string

export type Auth = { clearUserData: () => void } & (
    | {
          loading: true
      }
    | {
          loading: false
          user: null
          login: (login: string, password: string) => Promise<WhoAmI_whoami | null>
          signup: (email: string, password: string, firstname: string, lastname: string) => Promise<void>
      }
    | {
          loading: false
          user: WhoAmI_whoami
          authToken: string
          updateUser: (updatedUser: WhoAmI_whoami) => void
          logout: () => Promise<void>
      }
)

type AuthState = {
    loading: boolean
    authToken: string | null
    user: WhoAmI_whoami | null
}

export const useAuthLoaded = () => {
    const context = useContext(AuthContext)
    if (context.loading) {
        console.error('Auth is loading !')
        throw new Error('Auth is loading')
    }

    return context
}

export const useAuthUser = () => {
    const context = useContext(AuthContext)
    if (context.loading) throw new Error('No user available')
    if (context.user === null) throw new Error('No user available')
    return context
}

export const useAuthUserAea = () => {
    const context = useContext(AuthContext)
    if (context.loading) throw new Error('No user available')
    if (context.user === null) throw new Error('No user available')
    if (context.user.userType === EnumUser.user) throw new Error('Customer user found instead of AEA')
    return context
}

export const useAuthUserCustomer = () => {
    const context = useContext(AuthContext)
    if (context.loading) throw new Error('No user available')
    if (context.user === null) throw new Error('No user available')
    if (context.user.userType === EnumUser.admin) throw new Error('AEA user found instead of Customer')
    return context
}

export const useAuthUnlogged = () => {
    const context = useContext(AuthContext)
    if (context.loading) {
        console.error('Auth is loading !')
        throw new Error('Auth is loading')
    } else if (context.user) {
        console.error('Logged User found !')
        throw new Error('Logged User found !')
    }

    return context
}

const AuthContext = createContext<Auth>({ loading: true, clearUserData: () => {} })

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
    const auth = useProvideAuth()

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(AuthContext)
}

// Provider hook that creates auth object, and associates and manage user and kids, with their states
function useProvideAuth(): Auth {
    /*****************
     * USER MANAGEMENT
     ****************/

    const { manageQueryError } = useQueryErrorManager()
    const { errorAlert } = useErrorService()

    // loading is only used for first user fetch. Further fetches (login, logout, signup, etc) does not set loading (but the their own by-caller loading states)
    const [authState, setAuthState] = useState<AuthState>({ loading: true, user: null, authToken: null })

    const [token, setToken] = useState<string | null>(localStorage.getItem(localStorageTokenKey) || null)

    // At the first call of the useProvideAuth context, we refresh the token, if it exists
    useEffect(() => {
        loadUserFromToken()
    }, [])

    // Updates the localstorage everytime the user state is updated
    useEffect(() => {
        localStorage.setItem(localStorageTokenKey, token || '')
    }, [token])

    const setLoading = () => {
        setAuthState((oldState) => {
            return {
                user: oldState.user,
                authToken: null,
                loading: true,
            }
        })
    }

    const setUserNull = () => {
        setAuthState({ loading: false, user: null, authToken: null })
    }

    const loadUserFromToken = async () => {
        if (!token) {
            setAuthState({
                user: null,
                authToken: null,
                loading: false,
            })
        } else {
            setLoading()

            try {
                const whoami = await client.query<WhoAmI>({ query: queries.WhoAmI })

                if (whoami.data.whoami) {
                    const user = {
                        ...whoami.data.whoami,
                        authToken: token,
                    }

                    setAuthState({
                        user,
                        authToken: token,
                        loading: false,
                    })
                } else {
                    clearUserData()
                }
            } catch (error) {
                manageQueryError(error)
                clearUserData()
            }
        }
    }

    const login = async (email: string, password: string): Promise<WhoAmI_whoami | null> => {
        try {
            const signinResponse = await client.mutate<SignInUser, SignInUserVariables>({
                mutation: mutations.SignInUser,
                variables: { email: email, password: password },
            })

            if (
                !signinResponse.data ||
                !signinResponse.data.signinUser ||
                !signinResponse.data.signinUser.authToken ||
                !signinResponse.data.signinUser.user
            ) {
                errorAlert('Mauvaise combinaison email/mot de passe')
                return null
            }

            const user: WhoAmI_whoami = signinResponse.data.signinUser.user
            const authToken = signinResponse.data.signinUser.authToken

            await client.clearStore()

            setToken(authToken)
            setAuthState({ user: user, loading: false, authToken: authToken })
            return user
        } catch (error) {
            errorAlert('Une erreur est survenue')
            return null
        }
    }

    const signup = async (email: string, password: string, firstname: string, lastname: string) => {
        alert('Not implemented yet !')
    }

    async function logout(): Promise<void> {
        clearUserData()
    }

    function updateUser(updatedUser: WhoAmI_whoami) {
        setAuthState((oldState) => {
            return {
                ...oldState,
                user: updatedUser,
            }
        })
    }

    function clearUserData() {
        setToken(null)
        setUserNull()
    }

    const auth: Auth = useMemo(() => {
        if (authState.loading) {
            return { loading: true, clearUserData }
        } else {
            if (authState.user && authState.authToken) {
                return {
                    loading: false,
                    user: authState.user,
                    updateUser,
                    logout,
                    authToken: authState.authToken,
                    clearUserData,
                }
            } else {
                return { loading: false, user: null, login, signup, clearUserData }
            }
        }
    }, [authState, token])

    return auth
}
