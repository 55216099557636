import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.Disaster_Full}
    query GetDisaster_Full($disasterId: ID!) {
        disaster(id: $disasterId) {
            ...Disaster_Full
        }
    }
`
