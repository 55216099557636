import Fade from '@material-ui/core/Fade'
import React, { Fragment } from 'react'
import EnterpriseFleetsList from './EnterpriseFleetsList'
import EnterpriseVehiclesList from './EnterpriseVehiclesList'

const EnterpriseVehicles = () => {
    return (
        <Fade in timeout={300}>
            <Fragment>
                <div className="mb-6">
                    <EnterpriseVehiclesList />
                </div>
                <div className="mb-6">
                    <EnterpriseFleetsList />
                </div>
            </Fragment>
        </Fade>
    )
}

export default EnterpriseVehicles
