import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { useMemo, useState } from 'react'
import {
    ContractGeneric_contract_guarantees,
    ContractGeneric_contract_guarantees_guaranteeCategory,
} from '../../../../../../graphql/queries/typings/ContractGeneric'
import { DisasterGeneric_disaster } from '../../../../../../graphql/queries/typings/DisasterGeneric'
import { DamageTargetInput, EnumDamageTarget } from '../../../../../../graphql/typings/global_types'
import ContractHelper, { ContractForLabel } from '../../../../../helpers/ContractHelper'
import { DisasterDamage_Minimal } from '../../../../../helpers/data/models/disaster/DisasterDamage'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import VehicleHelper from '../../../../../helpers/VehicleHelper'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useDisasterLoadedExisting } from '../../../../../hooks/useDisaster'
import { useCreateDisasterDamage, useUpdateDisasterDamage } from '../../../../../hooks/useMutations'
import CustomButton from '../../../../CustomButton'
import MoneyInput from '../../../../customInputs/MoneyInput'
import MultiSelectCheckbox from '../../../../customInputs/MultiSelectCheckbox'
import { Dialog } from '../../../../Dialog'

type AddElementDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: () => void
    disaster: DisasterForDisasterDamage
    disasterDamage?: DisasterDamage_Minimal
}

type Data = {
    lostAmount: number | null
    guarantee: GuaranteeForDisasterDamage | null
    ceiling: string | null
    deductible: string | null
    indemnity: number | null
    disasterElements: {
        enterprises: string[]
        places: string[]
        sites: string[]
        goods: string[]
        vehicles: string[]
        fleets: string[]
    }
}

const multiSelectStyle = {
    minWidth: 300,
}

const emptyData: Data = {
    lostAmount: 0,
    guarantee: null,
    ceiling: null,
    deductible: null,
    indemnity: null,
    disasterElements: {
        enterprises: [],
        places: [],
        sites: [],
        goods: [],
        vehicles: [],
        fleets: [],
    },
}

type GuaranteeForDisasterDamage = Pick<ContractGeneric_contract_guarantees, 'id' | 'ceiling' | 'deductible'> & {
    guaranteeCategory: Pick<ContractGeneric_contract_guarantees_guaranteeCategory, 'id' | 'title'>
}

type DisasterForDisasterDamage = Pick<DisasterGeneric_disaster, 'id'> & {
    contract: ContractForLabel & {
        guarantees: GuaranteeForDisasterDamage[]
    }
}

const DisasteredDamageDialog = ({ onDone, open, closeDialog, disaster, disasterDamage }: AddElementDialogProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { contractCoveredElements } = useDisasterLoadedExisting()
    const createDisasterDamageMutation = useCreateDisasterDamage()
    const updateDisasterDamageMutation = useUpdateDisasterDamage()
    const { errorAlert } = useErrorService()

    const [data, setData] = useState<Data>(
        disasterDamage
            ? {
                  lostAmount: disasterDamage.lost_amount,
                  guarantee: disasterDamage.guarantee,
                  ceiling: disasterDamage.ceiling,
                  deductible: disasterDamage.deductible,
                  indemnity: disasterDamage.indemnity,
                  disasterElements: {
                      enterprises: disasterDamage.enterprises.map((enterprise) => enterprise.id),
                      places: disasterDamage.places.map((place) => place.id),
                      sites: disasterDamage.sites.map((site) => site.id),
                      goods: disasterDamage.goods.map((good) => good.id),
                      vehicles: disasterDamage.vehicles.map((vehicle) => vehicle.id),
                      fleets: disasterDamage.fleets.map((fleet) => fleet.id),
                  },
              }
            : emptyData
    )
    const [loading, setLoading] = useState<boolean>(false)

    const coveredElements = useMemo(
        () => ContractHelper.getContractCoveredElements(contractCoveredElements),
        [disaster]
    )

    const contractGuarantees = disaster.contract.guarantees

    const handleClose = () => {
        closeDialog()
    }

    const handleStringChange = (value: string, prop: 'deductible' | 'ceiling') => {
        setData({
            ...data,
            [prop]: value,
        })
    }

    const handleIndemnityChange = (value: string) => {
        const indemnity = parseFloat(value)

        setData({
            ...data,
            indemnity: indemnity,
        })
    }

    const handleLostAmountChange = (value: string) => {
        const lostAmount = parseFloat(value)

        setData({
            ...data,
            lostAmount: lostAmount,
        })
    }

    const handleGuaranteeChange = (guaranteeId: string) => {
        const guarantee = contractGuarantees.find((contractGuarantee) => contractGuarantee.id === guaranteeId)

        if (!guarantee) throw new Error(`Garantie avec l'ID ${guarantee} absente des garanties du contrat`)
        else {
            setData((oldData) => {
                const newData: Data = {
                    ...data,
                    guarantee: guarantee,
                }

                // When guarantee changes, if no ceiling/deductible set, we set the guarantee's ones by default
                if (oldData.ceiling === null) {
                    newData.ceiling = guarantee.ceiling
                }
                if (oldData.deductible === null) {
                    newData.deductible = guarantee.deductible
                }

                return newData
            })
        }
    }

    const handleElementChange = (
        selectedValues: string[],
        elementType: 'places' | 'sites' | 'enterprises' | 'goods' | 'vehicles' | 'fleets'
    ) => {
        setData((oldData) => {
            return {
                ...oldData,
                disasterElements: {
                    ...oldData.disasterElements,
                    [elementType]: selectedValues,
                },
            }
        })
    }

    const formatTargets = () => {
        const damageTargets: DamageTargetInput[] = []

        const addTargets = (targets: string[], targetType: EnumDamageTarget): void => {
            targets.forEach((targetId) => damageTargets.push({ targetId, targetType }))
        }

        addTargets(data.disasterElements.enterprises, EnumDamageTarget.Enterprise)
        addTargets(data.disasterElements.places, EnumDamageTarget.Local)
        addTargets(data.disasterElements.sites, EnumDamageTarget.Site)
        addTargets(data.disasterElements.goods, EnumDamageTarget.Good)
        addTargets(data.disasterElements.fleets, EnumDamageTarget.Fleet)
        addTargets(data.disasterElements.vehicles, EnumDamageTarget.Vehicle)

        return damageTargets
    }

    const handleValidate = async () => {
        if (data.guarantee === null) return
        if (data.lostAmount === null) return

        setLoading(true)

        try {
            const targets = formatTargets()

            if (disasterDamage) {
                //Update

                const response = await updateDisasterDamageMutation.run({
                    customerId: customer.id,
                    id: disasterDamage.id,
                    disasterId: disaster.id,
                    guaranteeId: data.guarantee.id,
                    lostAmount: data.lostAmount,
                    damageTargets: targets,
                    ceiling: data.ceiling,
                    deductible: data.deductible,
                    indemnity: data.indemnity,
                })

                if (response.disasterDamage) {
                    onDone()
                } else {
                    throw 'Erreur pendant la mise à jour du dommage.'
                }
            } else {
                //Create
                const response = await createDisasterDamageMutation.run({
                    customerId: customer.id,
                    disasterId: disaster.id,
                    guaranteeId: data.guarantee.id,
                    lostAmount: data.lostAmount,
                    damageTargets: targets,
                    ceiling: data.ceiling,
                    deductible: data.deductible,
                    indemnity: data.indemnity,
                })

                if (response.disasterDamage) {
                    onDone()
                } else {
                    throw 'Erreur pendant la création du dommage.'
                }

                setData(emptyData)
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const getEnterprisesLabel = (id: string) => {
        const enterprise = coveredElements.enterprises.find((enterprise) => enterprise.id === id)

        if (!enterprise) throw new Error(`Entreprise avec id ${id} absente`)

        return enterprise.title
    }

    const getPlacesLabel = (id: string) => {
        const place = coveredElements.places.find((place) => place.id === id)

        if (!place) throw new Error(`Local avec id ${id} absent`)

        return place.title
    }

    const getSitesLabel = (id: string) => {
        const site = coveredElements.sites.find((site) => site.id === id)

        if (!site) throw new Error(`Site avec id ${id} absent`)

        return site.title
    }

    const getGoodsLabel = (id: string) => {
        const good = coveredElements.goods.find((good) => good.id === id)

        if (!good) throw new Error(`Bien avec id ${id} absent`)

        return good.title
    }

    const getVehiclesLabel = (id: string) => {
        const vehicle = coveredElements.vehicles.find((vehicle) => vehicle.id === id)

        if (!vehicle) throw new Error(`Véhicule avec id ${id} absent`)

        return VehicleHelper.getLabel(vehicle)
    }

    const getFleetsLabel = (id: string) => {
        const fleet = coveredElements.fleets.find((fleet) => fleet.id === id)

        if (!fleet) throw new Error(`Flotte avec id ${id} absente`)

        return fleet.title
    }

    const isFormValid = data.guarantee && data.lostAmount

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Dommage garanti</DialogTitle>
            <DialogContent>
                <MoneyInput
                    autoFocus
                    margin="dense"
                    id="lostAmount"
                    label="Montant du préjudice"
                    fullWidth
                    value={data.lostAmount || ''}
                    onChange={(event) => handleLostAmountChange(event.target.value)}
                />
                <TextField
                    className="mt-5"
                    name="event"
                    margin="dense"
                    label={`Garantie du contrat : ${ContractHelper.getContractLabel(disaster.contract)} *`}
                    fullWidth
                    select
                    onChange={(event) => handleGuaranteeChange(event.target.value)}
                    value={data.guarantee ? data.guarantee.id : ''}>
                    {[...contractGuarantees]
                        .sort((guaranteeA, guaranteeB) => {
                            return guaranteeA.guaranteeCategory.title.localeCompare(guaranteeB.guaranteeCategory.title)
                        })
                        .map((guarantee) => (
                            <MenuItem value={guarantee.id} key={guarantee.id}>
                                {guarantee.guaranteeCategory.title}
                            </MenuItem>
                        ))}
                </TextField>
                <TextField
                    className="mt-5 mb-5"
                    name="ceiling"
                    label="Plafond"
                    margin="dense"
                    type="text"
                    fullWidth
                    onChange={(event) => handleStringChange(event.target.value, 'ceiling')}
                    value={data.ceiling || ''}
                />
                <TextField
                    className="mb-5"
                    name="deductible"
                    label="Franchise"
                    type="text"
                    fullWidth
                    onChange={(event) => handleStringChange(event.target.value, 'deductible')}
                    value={data.deductible || ''}
                />
                <MoneyInput
                    className="mb-5"
                    name="indemnity"
                    label="Indemnités dûes"
                    fullWidth
                    onChange={(event) => handleIndemnityChange(event.target.value)}
                    value={data.indemnity || ''}
                />
                <MultiSelectCheckbox
                    selectTitle={
                        coveredElements.enterprises.length === 0
                            ? 'Aucune entreprise dans le contrat'
                            : 'Entreprises sinistrées'
                    }
                    name="enterprises"
                    value={data.disasterElements.enterprises}
                    onChange={(selectedValues) => handleElementChange(selectedValues, 'enterprises')}
                    getLabel={getEnterprisesLabel}
                    style={multiSelectStyle}
                    disabled={coveredElements.enterprises.length === 0}
                    availableIds={[...coveredElements.enterprises]
                        .sort((enterpriseA, enterpriseB) => {
                            return enterpriseA.title.localeCompare(enterpriseB.title)
                        })
                        .map((enterprise) => enterprise.id)}
                />
                {/* <MultiSelect
                    selectTitle={
                        coveredElements.enterprises.length === 0 ? 'Aucun site dans le contrat' : 'Sites sinistrés'
                    }
                    name="sites"
                    value={data.disasterElements.sites}
                    onChange={(event) => handleElementChange(event, 'sites')}
                    getLabel={getSitesLabel}
                    style={multiSelectStyle}
                    disabled={coveredElements.sites.length === 0}>
                    {coveredElements.sites.map((site: Site_Minimal) => (
                        <MenuItem key={site.id} value={site.id}>
                            {site.title}
                        </MenuItem>
                    ))}
                </MultiSelect> */}
                <MultiSelectCheckbox
                    selectTitle={
                        coveredElements.places.length === 0 ? 'Aucun local dans le contrat' : 'Locaux sinistrés'
                    }
                    name="places"
                    value={data.disasterElements.places}
                    onChange={(selectedValues) => handleElementChange(selectedValues, 'places')}
                    getLabel={getPlacesLabel}
                    style={multiSelectStyle}
                    disabled={coveredElements.places.length === 0}
                    availableIds={[...coveredElements.places]
                        .sort((placeA, placeB) => {
                            return placeA.title.localeCompare(placeB.title)
                        })
                        .map((place) => place.id)}
                />
                <MultiSelectCheckbox
                    selectTitle={coveredElements.goods.length === 0 ? 'Aucun bien dans le contrat' : 'Biens sinistrés'}
                    name="goods"
                    value={data.disasterElements.goods}
                    onChange={(selectedValues) => handleElementChange(selectedValues, 'goods')}
                    getLabel={getGoodsLabel}
                    style={multiSelectStyle}
                    disabled={coveredElements.goods.length === 0}
                    availableIds={[...coveredElements.goods]
                        .sort((goodA, goodB) => {
                            return goodA.title.localeCompare(goodB.title)
                        })
                        .map((good) => good.id)}
                />
                <MultiSelectCheckbox
                    selectTitle={
                        coveredElements.fleets.length === 0 ? 'Aucune flotte dans le contrat' : 'Flottes sinistrées'
                    }
                    name="fleets"
                    value={data.disasterElements.fleets}
                    onChange={(selectedValues) => handleElementChange(selectedValues, 'fleets')}
                    getLabel={getFleetsLabel}
                    style={multiSelectStyle}
                    disabled={coveredElements.fleets.length === 0}
                    availableIds={[...coveredElements.fleets]
                        .sort((fleetA, fleetB) => {
                            return fleetA.title.localeCompare(fleetB.title)
                        })
                        .map((fleet) => fleet.id)}
                />
                <MultiSelectCheckbox
                    selectTitle={
                        coveredElements.vehicles.length === 0 ? 'Aucun véhicule dans le contrat' : 'Véhicules sinistrés'
                    }
                    name="vehicles"
                    value={data.disasterElements.vehicles}
                    onChange={(selectedValues) => handleElementChange(selectedValues, 'vehicles')}
                    getLabel={getVehiclesLabel}
                    style={multiSelectStyle}
                    disabled={coveredElements.vehicles.length === 0}
                    availableIds={[...coveredElements.vehicles]
                        .sort((vehicleA, vehicleB) => {
                            return vehicleA.title.localeCompare(vehicleB.title)
                        })
                        .map((vehicle) => vehicle.id)}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleValidate}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default DisasteredDamageDialog
