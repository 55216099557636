import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import { GetFleets_Minimal, GetFleets_MinimalVariables } from '../../../graphql/queries/typings/GetFleets_Minimal'
import { useErrorService } from '../errors/ErrorService'
import { Fleet_Minimal } from './models/fleet/Fleet'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'

type FleetsData = {
    error?: QueryError
    fleets: Fleet_Minimal[] | null
    fleetsLoading: boolean
}

type GetFleetsProps = {
    customerId: string
    enterpriseId?: string
}

const useGetFleets = ({ customerId, enterpriseId }: GetFleetsProps): FleetsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetFleets_Minimal, GetFleets_MinimalVariables>(queries.GetFleets_Minimal, {
        variables: {
            customerId,
            enterpriseId,
        },
    })

    const errorMessage = 'Erreur lors de la récupération des flottes'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const fleets = responseData && responseData.fleets ? responseData.fleets : null

        return {
            error,
            fleets,
            fleetsLoading: false,
        }
    } else {
        return {
            error,
            fleets: null,
            fleetsLoading: true,
        }
    }
}

export default useGetFleets
