import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import { GetVehicles_Minimal, GetVehicles_MinimalVariables } from '../../../graphql/queries/typings/GetVehicles_Minimal'
import { useErrorService } from '../errors/ErrorService'
import { Vehicle_Minimal } from './models/fleet/Vehicle'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'

type VehiclesData = {
    error?: QueryError
    vehicles: Vehicle_Minimal[] | null
    vehiclesLoading: boolean
}

type GetVehiclesProps = {
    customerId: string
    enterpriseId?: string
}

const useGetVehicles = ({ customerId, enterpriseId }: GetVehiclesProps): VehiclesData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetVehicles_Minimal, GetVehicles_MinimalVariables>(queries.GetVehicles_Minimal, {
        variables: {
            customerId,
            enterpriseId,
        },
    })

    const errorMessage = 'Erreur lors de la récupération des véhicules'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const vehicles = responseData && responseData.vehicles ? responseData.vehicles : null

        return {
            error,
            vehicles,
            vehiclesLoading: false,
        }
    } else {
        return {
            error,
            vehicles: null,
            vehiclesLoading: true,
        }
    }
}

export default useGetVehicles
