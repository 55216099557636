import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import {
    GetVehicle_Documents,
    GetVehicle_DocumentsVariables,
} from '../../../graphql/queries/typings/GetVehicle_Documents'
import { useErrorService } from '../errors/ErrorService'
import { Vehicle_Documents } from './models/fleet/Vehicle'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'

type VehicleData = {
    error?: QueryError
    vehicle: Vehicle_Documents | null
    vehicleLoading: boolean
}

type GetVehicleDocumentsProps = {
    id: string
}

const useGetVehicleDocuments = ({ id }: GetVehicleDocumentsProps): VehicleData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetVehicle_Documents, GetVehicle_DocumentsVariables>(queries.GetVehicle_Documents, {
        variables: {
            id,
        },
    })

    const errorMessage = 'Erreur lors de la récupération du véhicule'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const vehicle = responseData && responseData.vehicle ? responseData.vehicle : null

        return {
            error,
            vehicle,
            vehicleLoading: false,
        }
    } else {
        return {
            error,
            vehicle: null,
            vehicleLoading: true,
        }
    }
}

export default useGetVehicleDocuments
