import { gql } from '@apollo/client/core'

export default gql`
    query GetEnterprise_Guarantees($enterpriseId: ID!) {
        enterprise(id: $enterpriseId) {
            id
            guarantees {
                id
                guaranteeCategory {
                    id
                    title
                }
                contract {
                    id
                    title
                    reference
                    insurance {
                        id
                        title
                    }
                    enterprise {
                        id
                        title
                    }
                }
            }
        }
    }
`
