import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import AssignmentIcon from '@material-ui/icons/Assignment'
import EuroSymbolOutlinedIcon from '@material-ui/icons/EuroSymbolOutlined'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetContracts_contracts } from '../../../../../../graphql/queries/typings/GetContracts'
import { EnumDisasterStatus, EnumUser } from '../../../../../../graphql/typings/global_types'
import ContractHelper from '../../../../../helpers/ContractHelper'
import DateHelper from '../../../../../helpers/DateHelper'
import DisasterHelper from '../../../../../helpers/DisasterHelper'
import { DisasterEvent_Minimal } from '../../../../../helpers/data/models/disaster/DisasterEvent'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useDisasterLoadedExisting } from '../../../../../hooks/useDisaster'
import useNavContext from '../../../../../hooks/useNavContext'
import useSnackBar from '../../../../../hooks/useSnackBar'
import ElevatedCard from '../../../../ElevatedCard'
import IconText from '../../../../IconText'
import Panel from '../../../../Panel'
import TableHeader from '../../../../TableHeader'
import MoneyDisplayAnalyzer from '../../../../customInputs/MoneyDisplayAnalyzer'
import DisasterDialog from '../../../../disaster/DisasterDialog'

type DisasterGeneralProps = {
    disastersEvents: DisasterEvent_Minimal[]
    contracts: GetContracts_contracts[]
}

type Stat = {
    icon: string
    amount: string
    title: string
}

const DisasterGeneral = ({ disastersEvents, contracts }: DisasterGeneralProps) => {
    const auth = useAuthUser()
    const { disaster } = useDisasterLoadedExisting()
    const { fromServertoString } = DateHelper()
    const { openSnack } = useSnackBar()
    const navigate = useNavigate()
    const { getContractRoute } = useNavContext()

    const isAdmin = auth.user.userType === EnumUser.admin

    const [editDisasterOpen, setEditDisasterOpen] = useState<boolean>(false)

    const onContractClick = () => {
        navigate(getContractRoute(disaster.contract.id))
    }

    const statusIcon =
        disaster.status === EnumDisasterStatus.open ? (
            <AccessTimeIcon style={{ color: 'grey' }} />
        ) : disaster.status === EnumDisasterStatus.waiting_payments ? (
            <EuroSymbolOutlinedIcon style={{ color: 'orange' }} />
        ) : disaster.status === EnumDisasterStatus.pending_price_calculation ? (
            <AssignmentIcon style={{ color: 'grey' }} />
        ) : (
            <VerifiedUserIcon style={{ color: 'green' }} />
        )

    const openEdit = () => {
        setEditDisasterOpen(true)
    }

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: `Sinistre édité avec succès !`,
        })
    }

    const statList: Stat[] = [
        {
            icon: 'trending_down',
            amount: `${DisasterHelper.getFullLoss(disaster)} €`,
            title: 'Montant du préjudice',
        },
        {
            icon: 'trending_up',
            amount: `${DisasterHelper.getFullPaymentAmount(disaster)} €`,
            title: 'Indemnisation totale',
        },
        {
            icon: 'euro',
            amount: `${DisasterHelper.getPaymentAlreadyPaidAmount(disaster)} €`,
            title: 'Payés',
        },
    ]

    return (
        <Fragment>
            <Grid container spacing={3} className="mb-6">
                {statList.map((item, ind) => (
                    <Grid key={item.title} item md={3} sm={6} xs={12}>
                        <ElevatedCard className="p-5 text-center">
                            <Fragment>
                                <div className="p-2 bg-light-gray">
                                    <Icon color="primary">{item.icon}</Icon>
                                </div>
                                <div>
                                    <h3 className="mt-1 text-29">
                                        <MoneyDisplayAnalyzer value={item.amount} />
                                    </h3>
                                </div>
                                <div>
                                    <p className="m-0 text-muted">{item.title}</p>
                                </div>
                            </Fragment>
                        </ElevatedCard>
                    </Grid>
                ))}
            </Grid>
            <Panel>
                <Fragment>
                    <TableHeader title={'Informations générales du sinistre'} editClick={openEdit} />
                    <Divider />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className="pl-4">Libellé</TableCell>
                                <TableCell>{disaster.title}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Référence AEA</TableCell>
                                <TableCell>{disaster.internalReference || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Référence Assureur</TableCell>
                                <TableCell>{disaster.insuranceReference || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Type de sinistre</TableCell>
                                <TableCell>{disaster.disasterCategory.title}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Date</TableCell>
                                <TableCell>{fromServertoString(disaster.date)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Statut</TableCell>
                                <TableCell>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <Icon className="align-middle mr-2">{statusIcon}</Icon>
                                        </Grid>
                                        <Grid item>{DisasterHelper.getDisasterStatusLabel(disaster.status)}</Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Contrat</TableCell>
                                <TableCell>
                                    <IconText
                                        text={ContractHelper.getContractLabel(disaster.contract)}
                                        icon="logout"
                                        side="right"
                                        iconClick={onContractClick}
                                    />
                                </TableCell>
                            </TableRow>
                            {disaster.event && (
                                <TableRow>
                                    <TableCell className="pl-4">Evénement</TableCell>
                                    <TableCell>{disaster.event.title}</TableCell>
                                </TableRow>
                            )}
                            {isAdmin && (
                                <TableRow>
                                    <TableCell className="pl-4">Pourcentage des honoraires AEA</TableCell>
                                    <TableCell>{disaster.percentage} %</TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell className="pl-4">Montant des honoraires AEA</TableCell>
                                <TableCell>
                                    {disaster.amount === null ? '-' : <MoneyDisplayAnalyzer value={disaster.amount} />}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Date de facturation des honoraires AEA</TableCell>
                                <TableCell>
                                    {disaster.billingDate ? fromServertoString(disaster.billingDate) : '-'}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className="pl-4">Note de l'expert</TableCell>
                                <TableCell>{disaster.comment}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Fragment>
            </Panel>
            <DisasterDialog
                disaster={disaster}
                onDone={onEdited}
                open={editDisasterOpen}
                closeDialog={() => setEditDisasterOpen(false)}
                disastersEvents={disastersEvents}
                contracts={contracts}
            />
        </Fragment>
    )
}

export default DisasterGeneral
