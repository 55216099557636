import { gql } from "@apollo/client/core";

export default gql`
    mutation DeleteDocument($id: ID!) {
        deleteDocument(input: { id: $id }) {
            errors
            status
            statusCode
        }
    }
`
