import { useParams } from 'react-router'
import LoadingDisplay from '../../../../components/LoadingDisplay'
import Place, { PlaceTabName } from '../../../../components/customer/places/place/Place'
import ErrorDisplay from '../../../../helpers/errors/ErrorDisplay'
import usePlace, { ProvidePlace } from '../../../../hooks/usePlace'

const AEACustomerPlace = () => {
    const { placeId, tab } = useParams()

    return placeId !== undefined ? (
        <ProvidePlace placeId={placeId}>
            <AEACustomerPlaceLoading tab={tab ? (tab as PlaceTabName) : undefined} />
        </ProvidePlace>
    ) : (
        <ErrorDisplay message="Adresse incorrecte" />
    )
}

const AEACustomerPlaceLoading = (props: AEACustomerPlaceReadyProps) => {
    const { placeLoading, place, error } = usePlace()

    if (placeLoading) return <LoadingDisplay />
    else if (error) {
        return <ErrorDisplay message="Erreur lors du chargement du local" queryError={error} debug={error} />
    } else if (!place) {
        return <ErrorDisplay message="Erreur lors du chargement du local" />
    } else return <AEACustomerPlaceReady {...props} />
}

type AEACustomerPlaceReadyProps = {
    tab?: PlaceTabName
}

const AEACustomerPlaceReady = (props: AEACustomerPlaceReadyProps) => {
    return <Place {...props} />
}

export default AEACustomerPlace
