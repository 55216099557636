import { gql } from '@apollo/client/core'
import fragments from '../fragments'

export default gql`
    ${fragments.Contract.Contract_Minimal}
    ${fragments.Disaster.Disaster_Minimal}
    query GetContract_Disasters($contractId: ID!) {
        contract(id: $contractId) {
            id
            disasters {
                ...Disaster_Minimal
                contract {
                    ...Contract_Minimal
                }
            }
        }
    }
`
