import { gql } from '@apollo/client/core'

export default gql`
    mutation UnlinkGuaranteeTargetFromGuarantees($guaranteeTarget: GuaranteeTargetInput!, $guaranteeIds: [ID!]!) {
        unlinkGuaranteeTargetFromGuarantees(input: { guaranteeTarget: $guaranteeTarget, guaranteeIds: $guaranteeIds }) {
            errors
            status
            statusCode
        }
    }
`
