import Icon from '@material-ui/core/Icon/Icon'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { useState } from 'react'
import useSnackBar from '../../../hooks/useSnackBar'
import DocumentEdit, { DocumentEditMode, DocumentForEdit } from '../DocumentForms/Admin/DocumentEdit/DocumentEdit'

type DocumentListEditProps = {
    document: DocumentForEdit
}

const DocumentListEdit = ({ document }: DocumentListEditProps) => {
    const { openSnack } = useSnackBar()

    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false)

    const editDoc = () => {
        setOpenEditDialog(true)
    }

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: 'Document modifié avec succès !',
        })
    }

    return (
        <>
            <Tooltip title="Modifier">
                <IconButton onClick={editDoc} color="primary">
                    <Icon>edit</Icon>
                </IconButton>
            </Tooltip>
            <DocumentEdit
                onDone={onEdited}
                document={document}
                open={openEditDialog}
                closeDialog={() => setOpenEditDialog(false)}
                mode={DocumentEditMode.EDITING}
            />
        </>
    )
}

export default DocumentListEdit
