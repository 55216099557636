import React, { Fragment } from 'react'
import AEAGuaranteeCategoriesList from './AEAGuaranteeCategoriesList'

const AEAGuaranteeCategories = () => {
    return (
        <Fragment>
            <div className="m-sm-30">
                <h3 className="m-0 mb-6">Liste des catégories de garanties</h3>
                <AEAGuaranteeCategoriesList />
            </div>
        </Fragment>
    )
}

export default AEAGuaranteeCategories
