import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import { Fragment, useState } from 'react'
import { EnumUser } from '../../../graphql/typings/global_types'
import { Document_User } from '../../helpers/data/models/Document'
import IconHelper from '../../helpers/IconHelper'
import { useAuthUser } from '../../hooks/useAuth'
import useSnackBar from '../../hooks/useSnackBar'
import DocumentAskToCustomer from './DocumentForms/Admin/DocumentAskToCustomer/DocumentAskToCustomer'
import DocumentCreate from './DocumentForms/Admin/DocumentCreate/DocumentCreate'
import AskingDocumentFormDialog from './DocumentForms/User/AskingDocumentsFormDialog'
import SubmitDocumentsFormDialog from './DocumentForms/User/SubmitDocumentsFormDialog'

const DocumentQuickActions = () => {
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin

    const [sendDocDialogOpen, setSendDocDialogOpen] = useState<boolean>(false)
    const [addDocDialogOpen, setAddDocDialogOpen] = useState<boolean>(false)
    const [adminAddDocDialogOpen, setAdminAddDocDialogOpen] = useState<boolean>(false)
    const [adminAskDocDialogOpen, setAdminAskDocDialogOpen] = useState<boolean>(false)

    const { openSnack } = useSnackBar()

    /****************************
     * User asking for a document
     ***************************/

    const onUserAskDocumentClick = () => {
        setSendDocDialogOpen(true)
    }

    const onUserDocumentFormDone = () => {
        openSnack({
            message: 'Document demandé avec succès !',
            type: 'success',
        })
    }
    /****************************
     * User submitting a document
     ***************************/

    const onUserSubmitDocumentClick = () => {
        setAddDocDialogOpen(true)
    }

    const onUserSubmitDocumentDone = (documents: Document_User[]) => {
        openSnack({
            message: 'Document ajouté avec succès ! Une notification a été envoyée aux experts pour validation.',
            type: 'success',
        })
    }

    /****************************
     * Admin submitting a document
     ***************************/

    const onAdminAskClick = () => {
        setAdminAskDocDialogOpen(true)
    }

    const onAdminAskDocDone = () => {
        openSnack({
            message: 'Document demandé au client.',
            type: 'success',
        })
    }

    /****************************
     * Admin submitting a document
     ***************************/

    const onAdminAddClick = () => {
        setAdminAddDocDialogOpen(true)
    }

    const onAdminAddDocDone = () => {
        openSnack({
            message: 'Document(s) créé(s) avec succès',
            type: 'success',
        })
    }

    return (
        <div className="mb-5">
            <Grid container spacing={4} direction="row-reverse">
                {!isAdmin && (
                    <Fragment>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Icon>backup</Icon>}
                                size="small"
                                onClick={onUserSubmitDocumentClick}>
                                Soumettre des documents
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Icon>{IconHelper.elementIcons.docsIcon}</Icon>}
                                size="small"
                                onClick={onUserAskDocumentClick}>
                                Demander un document
                            </Button>
                        </Grid>
                        <AskingDocumentFormDialog
                            open={sendDocDialogOpen}
                            onDone={onUserDocumentFormDone}
                            closeDialog={() => setSendDocDialogOpen(false)}
                        />
                        <SubmitDocumentsFormDialog
                            open={addDocDialogOpen}
                            onDone={onUserSubmitDocumentDone}
                            closeDialog={() => setAddDocDialogOpen(false)}
                        />
                    </Fragment>
                )}
                {isAdmin && (
                    <Fragment>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Icon>{IconHelper.elementIcons.docsIcon}</Icon>}
                                size="small"
                                onClick={onAdminAddClick}>
                                Ajouter des documents
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Icon>{IconHelper.elementIcons.docsIcon}</Icon>}
                                size="small"
                                onClick={onAdminAskClick}>
                                Demander un document au client
                            </Button>
                        </Grid>
                        {adminAddDocDialogOpen && (
                            <DocumentCreate
                                open={adminAddDocDialogOpen}
                                onDone={onAdminAddDocDone}
                                closeDialog={() => setAdminAddDocDialogOpen(false)}
                            />
                        )}
                        {adminAskDocDialogOpen && (
                            <DocumentAskToCustomer
                                open={adminAskDocDialogOpen}
                                onDone={onAdminAskDocDone}
                                closeDialog={() => setAdminAskDocDialogOpen(false)}
                            />
                        )}
                    </Fragment>
                )}
            </Grid>
        </div>
    )
}

export default DocumentQuickActions
