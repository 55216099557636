import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment, useMemo } from 'react'
import { GetDisastersIndicators_disasterIndicators_disasterPaymentsList } from '../../../../graphql/queries/typings/GetDisastersIndicators'
import DateHelper from '../../../helpers/DateHelper'
import IconHelper from '../../../helpers/IconHelper'
import MoneyDisplayAnalyzer from '../../customInputs/MoneyDisplayAnalyzer'
import DataTable from '../../DataTable'
import { DateRange } from '../../DateCategoryInput'

type DisastersPaymentsProps = {
    disasterPayments: GetDisastersIndicators_disasterIndicators_disasterPaymentsList[]
    amountNotPaid: number
    dateRange?: DateRange
}

type FormattedPayment = {
    paid_at: string | null
    title: string
    amountGot: number
    description: string
}

const DisastersPayments = ({ disasterPayments, amountNotPaid, dateRange }: DisastersPaymentsProps) => {
    const { fromServertoString, isInDateRange } = DateHelper()

    const formattedPayments: FormattedPayment[] = useMemo(() => {
        const filteredDisasters = !dateRange
            ? disasterPayments
            : disasterPayments.filter((disasterPayment) =>
                  disasterPayment.paid_at ? isInDateRange(disasterPayment.paid_at, dateRange) : false
              )

        return filteredDisasters.map((payment) => {
            return {
                title: payment.disaster.title,
                paid_at: payment.paid_at,
                amountGot: payment.amount,
                description: payment.description || 'Aucune',
            }
        })
    }, [disasterPayments, dateRange])

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'paid_at',
                label: 'Date de paiement',
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        const payment = formattedPayments[dataIndex]

                        return <div>{payment.paid_at ? fromServertoString(payment.paid_at) : 'N/A'}</div>
                    },
                },
            },
            {
                name: 'title',
                label: 'Sinistre',
            },
            {
                name: 'amountGot',
                label: 'Montant payé',
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        const payment = formattedPayments[dataIndex]

                        return <MoneyDisplayAnalyzer value={payment.amountGot} />
                    },
                },
            },
            {
                name: 'description',
                label: 'Description',
            },
        ],
        [disasterPayments]
    )

    const toolbarElement = (
        <div>
            <b>
                <span>Reste à percevoir : </span>
                <MoneyDisplayAnalyzer value={amountNotPaid} />
            </b>
        </div>
    )

    return (
        <Fragment>
            <DataTable<FormattedPayment>
                title={'Liste des paiements des sinistres'}
                icon={IconHelper.elementIcons.disasterIndemnitiesIcon}
                data={formattedPayments}
                columns={columns}
                showSearch={false}
                customToolbarElement={toolbarElement}
                options={{
                    sortOrder: {
                        name: 'paid_at',
                        direction: 'desc',
                    },
                }}
            />
        </Fragment>
    )
}

export default DisastersPayments
