import { EnumAgreementCategory } from '../../graphql/typings/global_types'
import { Agreement_Minimal } from './data/models/Agreement'

type AgreementForLast = Pick<Agreement_Minimal, 'id' | 'version'>

function getLastAgreement<TAgreement extends AgreementForLast>(agreements: TAgreement[]): TAgreement | null {
    if (agreements.length === 0) return null

    const agreementReducer = (tempLastAgreement: TAgreement, newAgreement: TAgreement) => {
        return newAgreement.version! > tempLastAgreement.version! ? newAgreement : tempLastAgreement
    }

    return agreements.reduce(agreementReducer)
}

const getAgreementCategoryLabel = (category: EnumAgreementCategory): string => {
    switch (category) {
        case EnumAgreementCategory.all_inclusive:
            return 'Tout inclus'
        case EnumAgreementCategory.disaster_free:
            return 'Hors sinistre'
        default:
            return 'Catégorie de contrat inconnue'
    }
}

const AgreementHelper = {
    getLastAgreement,
    getAgreementCategoryLabel,
}

export default AgreementHelper
