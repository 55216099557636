import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EnumUser } from '../../../../graphql/typings/global_types'
import ContractHelper, { ContractForGuaranteeNames } from '../../../helpers/ContractHelper'
import DateHelper from '../../../helpers/DateHelper'
import IconHelper from '../../../helpers/IconHelper'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDeleteContract } from '../../../hooks/useMutations'
import { useNavContext } from '../../../hooks/useNavContext'
import useSnackBar from '../../../hooks/useSnackBar'
import DataTable from '../../DataTable'
import { ConfirmDialog } from '../../Dialog'
import MoneyDisplayAnalyzer from '../../customInputs/MoneyDisplayAnalyzer'
import ContractDialog, { ContractForDialog } from './ContractDialog'
import DuplicateContractDialog from './DuplicateContractDialog'

export type ContractLinkHelper = (contractId: string) => { pathname: string }

type ContractsListProps = {
    title: string
    contracts: ContractForList[]
    enterpriseId?: string
}

type FormattedContract = {
    title: string
    insuranceName: string
    insurerName: string
    brokerName: string
    guarantees: string
    dates: string
    bounty: string
    baseContract: ContractForList
}

type DialogState =
    | {
          open: true
          contract: ContractForList
      }
    | { open: false }

type ContractForList = ContractForDialog & ContractForGuaranteeNames

const ContractsList = ({ title, contracts, enterpriseId }: ContractsListProps) => {
    const navigate = useNavigate()
    const { openSnack } = useSnackBar()
    const { getContractRoute } = useNavContext()
    const { errorAlert } = useErrorService()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const deleteContractMutation = useDeleteContract()
    const { customer } = useCustomerLoadedExisting()
    const { fromServertoString } = DateHelper()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [openDuplicateDialog, setOpenDuplicateDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const formatContracts = (): FormattedContract[] => {
        const formattedContracts: FormattedContract[] = contracts.map((contract) => {
            const guaranteesAsString = ContractHelper.getContractGuaranteesNamesAsList(contract).join(', ')

            return {
                title: contract.title,
                insuranceName: contract.insurance.title,
                insurerName: contract.insurer ? contract.insurer.title : '-',
                brokerName: contract.broker ? contract.broker.title : '-',
                dates: fromServertoString(contract.startAt) + ' - ' + fromServertoString(contract.renewableDate),
                bounty: contract.bounty.toString(),
                guarantees: guaranteesAsString,
                baseContract: contract,
            }
        })

        return formattedContracts
    }

    const formattedContracts = formatContracts()

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'title',
                label: 'Libellé',
            },
            {
                name: 'insuranceName',
                label: 'Assurance',
            },
            {
                name: 'dates',
                label: 'Validité du contrat',
            },
            {
                name: 'bounty',
                label: 'Prime annuelle',
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        const contract = formattedContracts[dataIndex]
                        return <MoneyDisplayAnalyzer value={contract.bounty} />
                    },
                },
            },
            {
                name: 'guarantees',
                label: 'Garanties',
            },
            {
                name: 'insurerName',
                label: 'Agent général',
            },
            {
                name: 'brokerName',
                label: 'Courtier',
            },
        ],
        [contracts]
    )

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un contrat`,
    }

    const onRowClick = (formattedContract: FormattedContract) => {
        navigate(getContractRoute(formattedContract.baseContract.id))
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `Contrat créé avec succès !`,
        })
    }

    const onEdited = () => {
        openSnack({
            message: 'Contrat modifié avec succès !',
            type: 'success',
        })
    }

    const onDuplicated = () => {
        openSnack({
            message: 'Contrat dupliqué avec succès !',
            type: 'success',
        })
    }

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, contract: ContractForList) => {
        event.stopPropagation()
        setOpenEditDialog({ open: true, contract })
    }

    const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, contract: ContractForList) => {
        event.stopPropagation()
        setOpenDeleteDialog({ open: true, contract })
    }

    const onDuplicateClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, contract: ContractForList) => {
        event.stopPropagation()
        setOpenDuplicateDialog({ open: true, contract })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const contract = openDeleteDialog.contract

            const response = await deleteContractMutation.run({
                enterpriseId: contract.enterprise.id,
                customerId: customer.id,
                id: contract.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression du contrat.'
            } else {
                openSnack({
                    message: 'Contrat supprimé avec succès !',
                    type: 'success',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteDialog({ open: false })
        }
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const contract = contracts[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier le contrat">
                    <IconButton onClick={(event) => onEditClick(event, contract)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Dupliquer le contrat">
                    <IconButton onClick={(event) => onDuplicateClick(event, contract)}>
                        <Icon>content_copy</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le contrat">
                    <IconButton onClick={(event) => onDeleteClick(event, contract)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <>
            <DataTable<FormattedContract>
                title={title}
                icon={IconHelper.elementIcons.contractIcon}
                data={formattedContracts}
                columns={columns}
                showSearch={true}
                addFeature={addFeatureData}
                rowClick={onRowClick}
                actionsColumn={actionsColumn}
            />
            <ContractDialog
                onDone={onCreated}
                open={openAddDialog}
                closeDialog={() => setOpenAddDialog(false)}
                enterpriseId={enterpriseId}
            />
            {openEditDialog.open && (
                <ContractDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    contract={openEditDialog.contract}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={() => setOpenDeleteDialog({ open: false })}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'un contrat"
                    text={`Voulez-vous vraiment supprimer le contrat "${ContractHelper.getContractLabel(
                        openDeleteDialog.contract
                    )}" ?`}
                    loading={deleteLoading}
                />
            )}
            {openDuplicateDialog.open && (
                <DuplicateContractDialog
                    onDone={onDuplicated}
                    open={openDuplicateDialog.open}
                    closeDialog={() => setOpenDuplicateDialog({ open: false })}
                    contract={openDuplicateDialog.contract}
                />
            )}
        </>
    )
}

export default ContractsList
