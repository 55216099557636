import { useQuery } from '@apollo/client'
import queries from '../../graphql/queries'
import { GetDocuments_User, GetDocuments_UserVariables } from '../../graphql/queries/typings/GetDocuments_User'
import { Document_User } from '../helpers/data/models/Document'
import { useErrorService } from '../helpers/errors/ErrorService'
import ErrorHelper, { QueryError, QueryErrorType } from '../helpers/errors/ErrorHelper'

type DocumentsData = {
    documents: Document_User[] | null
    documentsLoading: boolean
    error?: QueryError
}

const useDocuments = (customerId: string): DocumentsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetDocuments_User, GetDocuments_UserVariables>(queries.GetDocuments_User, {
        variables: { customerId },
    })

    const errorMessage = 'Erreur lors de la récupération des documents'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const documents = responseData && responseData.documents ? responseData.documents : null

        return {
            error,
            documents,
            documentsLoading: false,
        }
    } else {
        return {
            error,
            documents: null,
            documentsLoading: true,
        }
    }
}

export default useDocuments
