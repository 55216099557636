import { gql } from '@apollo/client/core'
import fragments from '../fragments'

export default gql`
    ${fragments.Broker.Broker_Minimal}
    ${fragments.Insurer.Insurer_Minimal}
    ${fragments.Insurance.Insurance_Minimal}
    ${fragments.User.User_Minimal}
    ${fragments.Disaster.DisasterEvent_Minimal}
    ${fragments.Prevention.PreventionCategory_Minimal}
    ${fragments.Guarantee.GuaranteeCategory}
    ${fragments.Disaster.DisasterCategory}
    query GetCommonData {
        brokers {
            ...Broker_Minimal
        }
        insurers {
            ...Insurer_Minimal
        }
        insurances {
            ...Insurance_Minimal
        }
        admins: users(userTypes: admin) {
            ...User_Minimal
        }
        disastersEvents: events {
            ...DisasterEvent_Minimal
        }
        preventionCategories {
            ...PreventionCategory_Minimal
        }
        guaranteeCategories(limit: 400) {
            ...GuaranteeCategory
        }
        disasterCategories(limit: 200) {
            ...DisasterCategory
        }
    }
`
