import Contract_CoveredElements from './Contract_CoveredElements'
import Contract_Documents from './Contract_Documents'
import Contract_Full from './Contract_Full'
import Contract_Minimal from './Contract_Minimal'

export default {
    Contract_Minimal,
    Contract_CoveredElements,
    Contract_Full,
    Contract_Documents,
}
