import { gql } from '@apollo/client/core'
import Broker_Minimal from '../Broker/Broker_Minimal'
import Insurance_Minimal from '../Insurance/Insurance_Minimal'
import Insurer_Minimal from '../Insurer/Insurer_Minimal'

export default gql`
    ${Broker_Minimal}
    ${Insurance_Minimal}
    ${Insurer_Minimal}
    fragment Contract_Full on Contract {
        id
        reference
        title
        bounty
        startAt
        renewableDate
        renewableNotice
        enterprise {
            id
            title
        }
        insurance {
            ...Insurance_Minimal
        }
        insurer {
            ...Insurer_Minimal
        }
        broker {
            ...Broker_Minimal
        }
        guarantees {
            id
            ceiling
            deductible
            guaranteeCategory {
                id
                title
            }
            enterprises {
                id
                title
            }
            sites {
                id
                title
            }
            places: locals {
                id
                title
            }
            goods {
                id
                title
                enterprise {
                    id
                }
            }
            fleets {
                id
                title
                enterprise {
                    id
                }
            }
            vehicles {
                id
                title
                registration: matriculation
                enterprise {
                    id
                }
            }
        }
    }
`