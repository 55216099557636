import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Site.Site_Minimal}
    mutation CreateSite($title: String!, $customerId: ID!) {
        createSite(input: { title: $title, projectId: $customerId }) {
            errors
            status
            statusCode
            site {
                ...Site_Minimal
            }
        }
    }
`
