import CreateAgreement from './CreateAgreement'
import CreateBroker from './CreateBroker'
import CreateContract from './CreateContract'
import CreateContractGuarantee from './CreateContractGuarantee'
import CreateCustomer from './CreateCustomer'
import CreateDisaster from './CreateDisaster'
import CreateDisasterCategory from './CreateDisasterCategory'
import CreateDisasterDamage from './CreateDisasterDamage'
import CreateDisasterEvent from './CreateDisasterEvent'
import CreateDisasterPayment from './CreateDisasterPayment'
import CreateDocument from './CreateDocument'
import CreateDocuments from './CreateDocuments'
import CreateEnterprise from './CreateEnterprise'
import CreateFleet from './CreateFleet'
import CreateGood from './CreateGood'
import CreateGuaranteeCategory from './CreateGuaranteeCategory'
import CreateInsurance from './CreateInsurance'
import CreateInsurer from './CreateInsurer'
import CreatePlace from './CreatePlace'
import CreatePrevention from './CreatePrevention'
import CreatePreventionCategory from './CreatePreventionCategory'
import CreateRoadmapItem from './CreateRoadmapItem'
import CreateSite from './CreateSite'
import CreateUser from './CreateUser'
import CreateVehicle from './CreateVehicle'
import DeclareDisaster from './DeclareDisaster'
import DeleteAgreement from './DeleteAgreement'
import DeleteBroker from './DeleteBroker'
import DeleteContract from './DeleteContract'
import DeleteContractGuarantee from './DeleteContractGuarantee'
import DeleteCustomer from './DeleteCustomer'
import DeleteDisaster from './DeleteDisaster'
import DeleteDisasterCategory from './DeleteDisasterCategory'
import DeleteDisasterDamage from './DeleteDisasterDamage'
import DeleteDisasterEvent from './DeleteDisasterEvent'
import DeleteDisasterPayment from './DeleteDisasterPayment'
import DeleteDocument from './DeleteDocument'
import DeleteEnterprise from './DeleteEnterprise'
import DeleteFleet from './DeleteFleet'
import DeleteGood from './DeleteGood'
import DeleteGuaranteeCategory from './DeleteGuaranteeCategory'
import DeleteInsurance from './DeleteInsurance'
import DeleteInsurer from './DeleteInsurer'
import DeletePlace from './DeletePlace'
import DeletePrevention from './DeletePrevention'
import DeletePreventionCategory from './DeletePreventionCategory'
import DeleteRoadmapItem from './DeleteRoadmapItem'
import DeleteSite from './DeleteSite'
import DeleteUser from './DeleteUser'
import DeleteVehicle from './DeleteVehicle'
import DuplicateContract from './DuplicateContract'
import ForgotPassword from './ForgotPassword'
import SignInUser from './SigninUser'
import UpdateAgreement from './UpdateAgreement'
import UpdateBroker from './UpdateBroker'
import UpdateContract from './UpdateContract'
import UpdateContractGuarantee from './UpdateContractGuarantee'
import UpdateCustomer from './UpdateCustomer'
import UpdateDisaster from './UpdateDisaster'
import UpdateDisasterCategory from './UpdateDisasterCategory'
import UpdateDisasterDamage from './UpdateDisasterDamage'
import UpdateDisasterEvent from './UpdateDisasterEvent'
import UpdateDisasterPayment from './UpdateDisasterPayment'
import UpdateDocument from './UpdateDocument'
import UpdateEnterprise from './UpdateEnterprise'
import UpdateEnterpriseChildren from './UpdateEnterpriseChildren'
import UpdateEnterpriseParents from './UpdateEnterpriseParents'
import UpdateFleet from './UpdateFleet'
import UpdateGood from './UpdateGood'
import UpdateGuaranteeCategory from './UpdateGuaranteeCategory'
import UpdateInsurance from './UpdateInsurance'
import UpdateInsurer from './UpdateInsurer'
import UpdatePlace from './UpdatePlace'
import UpdatePrevention from './UpdatePrevention'
import UpdatePreventionCategory from './UpdatePreventionCategory'
import UpdateRoadmapItem from './UpdateRoadmapItem'
import UpdateSite from './UpdateSite'
import UpdateUser from './UpdateUser'
import UpdateUserPassword from './UpdateUserPassword'
import UpdateVehicle from './UpdateVehicle'
import LinkGuaranteeTargetToGuarantees from './LinkGuaranteeTargetToGuarantees'
import UnlinkGuaranteeTargetFromGuarantees from './UnlinkGuaranteeTargetFromGuarantees'

const mutations = {
    CreateUser,
    UpdateUser,
    DeleteUser,
    SignInUser,
    UpdateUserPassword,
    ForgotPassword,
    CreateCustomer,
    UpdateCustomer,
    DeleteCustomer,
    CreateEnterprise,
    UpdateEnterprise,
    DeleteEnterprise,
    CreateDisaster,
    UpdateDisaster,
    DeleteDisaster,
    DeclareDisaster,
    CreateDisasterCategory,
    UpdateDisasterCategory,
    DeleteDisasterCategory,
    UpdateEnterpriseChildren,
    UpdateEnterpriseParents,
    CreateContract,
    UpdateContract,
    DeleteContract,
    DuplicateContract,
    CreateGuaranteeCategory,
    UpdateGuaranteeCategory,
    DeleteGuaranteeCategory,
    CreateContractGuarantee,
    UpdateContractGuarantee,
    DeleteContractGuarantee,
    LinkGuaranteeTargetToGuarantees,
    UnlinkGuaranteeTargetFromGuarantees,
    CreateSite,
    UpdateSite,
    DeleteSite,
    CreatePlace,
    UpdatePlace,
    DeletePlace,
    CreateRoadmapItem,
    UpdateRoadmapItem,
    DeleteRoadmapItem,
    CreateDocument,
    CreateDocuments,
    UpdateDocument,
    DeleteDocument,
    CreateAgreement,
    UpdateAgreement,
    DeleteAgreement,
    CreateDisasterDamage,
    UpdateDisasterDamage,
    DeleteDisasterDamage,
    CreateDisasterPayment,
    UpdateDisasterPayment,
    DeleteDisasterPayment,
    CreateFleet,
    UpdateFleet,
    DeleteFleet,
    CreateVehicle,
    UpdateVehicle,
    DeleteVehicle,
    CreateGood,
    UpdateGood,
    DeleteGood,
    CreatePrevention,
    UpdatePrevention,
    DeletePrevention,
    CreatePreventionCategory,
    UpdatePreventionCategory,
    DeletePreventionCategory,
    CreateInsurance,
    UpdateInsurance,
    DeleteInsurance,
    CreateInsurer,
    UpdateInsurer,
    DeleteInsurer,
    CreateBroker,
    UpdateBroker,
    DeleteBroker,
    CreateDisasterEvent,
    UpdateDisasterEvent,
    DeleteDisasterEvent,
}

export default mutations
