import { CardHeader } from '@material-ui/core'
import Card, { CardProps } from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import React, { ReactElement } from 'react'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    clickableCard: {
        cursor: 'pointer',
        '&:hover': {
            // backgroundColor: 'rgba(34, 42, 69, 0.90)',
            // color: 'red',
            boxShadow:
                '0px 5px 5px -3px rgba(34, 42, 69, 62%), 0px 8px 10px 1px rgba(34, 42, 69, 22%), 0px 3px 14px 2px rgba(34, 42, 69, 22%)',
        },
    },
}))

type PanelProps = CardProps & {
    children: ReactElement
}

const ElevatedCard = ({ children, title, onClick, ...cardProps }: PanelProps) => {
    const classes = useStyles()

    const cardClassName = `${cardProps.className ? cardProps.className : ''} ${onClick ? classes.clickableCard : ''}`

    return (
        <Card {...cardProps} elevation={8} className={cardClassName} onClick={onClick}>
            {title && <CardHeader subheader={title} style={{ paddingTop: 10, paddingBottom: 0 }} />}
            {children}
        </Card>
    )
}

export default ElevatedCard
