import Chip from '@material-ui/core/Chip'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Select, { SelectProps } from '@material-ui/core/Select'
import React from 'react'
import { uniqId } from '../../../utilsTs'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

export type MultiSelectProps = Omit<SelectProps, 'multiple' | 'renderValue' | 'MenuProps' | 'input'> & {
    selectTitle: string
    getLabel: (id: string) => string
}

const MultiSelect = ({ value, selectTitle, getLabel, onChange, children, ...props }: MultiSelectProps) => {
    const selectId = uniqId()

    return (
        <FormControl style={{ width: '100%' }} className="mb-5">
            <InputLabel id={selectId}>{selectTitle}</InputLabel>
            <Select
                id={`form-${selectId}`}
                multiple
                labelId={selectId}
                value={value}
                onChange={onChange}
                input={<Input />}
                renderValue={(selected) => {
                    const selectedValues = selected as string[]
                    return (
                        <Grid container wrap="wrap" spacing={1}>
                            {selectedValues.map((value) => (
                                <Grid item>
                                    <Chip
                                        key={value}
                                        label={getLabel(value)}
                                        style={{ backgroundColor: 'lightblue' }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )
                }}
                MenuProps={MenuProps}
                {...props}>
                {children}
            </Select>
        </FormControl>
    )
}

export default MultiSelect
