import { gql } from "@apollo/client/core";
import Enterprise_Minimal from '../../Enterprise/Enterprise_Minimal'
import Fleet_Minimal from '../../Fleet/Fleet_Minimal'
import Good_Minimal from '../../Good/Good_Minimal'
import Guarantee_Minimal from '../../Guarantee/Guarantee_Minimal'
import Place_Minimal from '../../Place/Place_Minimal'
import Site_Minimal from '../../Site/Site_Minimal'

export default gql`
    ${Guarantee_Minimal}
    ${Site_Minimal}
    ${Place_Minimal}
    ${Good_Minimal}
    ${Fleet_Minimal}
    fragment DisasterDamage_Minimal on Damage {
        id
        lost_amount: lostAmount
        ceiling
        deductible
        indemnity
        guarantee {
            ...Guarantee_Minimal
        }
        enterprises {
            id
            title
        }
        sites {
            ...Site_Minimal
        }
        places: locals {
            ...Place_Minimal
        }
        goods {
            ...Good_Minimal
        }
        fleets {
            ...Fleet_Minimal
        }
        vehicles {
            ...Vehicle_Minimal
        }
    }
`
