import { useParams } from 'react-router-dom'
import { EnumUser } from '../../../../../graphql/typings/global_types'
import IconHelper from '../../../../helpers/IconHelper'
import RoutesHelper from '../../../../helpers/RoutesHelper'
import { Enterprise_Full } from '../../../../helpers/data/models/Enterprise'
import ErrorDisplay from '../../../../helpers/errors/ErrorDisplay'
import { useAuthUser } from '../../../../hooks/useAuth'
import useEnterprise, { ProvideEnterprise, useEnterpriseLoadedExisting } from '../../../../hooks/useEnterprise'
import { useNavContext } from '../../../../hooks/useNavContext'
import CustomTabs, { CustomTab } from '../../../CustomTabs'
import LoadingDisplay from '../../../LoadingDisplay'
import EnterpriseContracts from './contracts/EnterpriseContracts'
import EnterpriseDisasters from './disasters/EnterpriseDisasters'
import EnterpriseDocuments from './documents/EnterpriseDocuments'
import EnterpriseGeneral from './general/EnterpriseGeneral'
import EnterpriseGoods from './goods/EnterpriseGoods'
import EnterprisePlaces from './places/EnterprisePlaces'
import EnterpriseVehicles from './vehicles/EnterpriseVehicles'

export type EnterpriseTabName = 'general' | 'sinistres' | 'contrats' | 'locaux' | 'vehicules' | 'biens' | 'documents'

const EnterpriseZone = () => {
    const auth = useAuthUser()

    const enterpriseRoute =
        auth.user.userType === EnumUser.admin
            ? RoutesHelper.views.AEA.customer.enterprises.Enterprise
            : RoutesHelper.views.Customer.enterprises.Enterprise

    // Deserves to update the enterpriseId according to URL params
    const { enterpriseId } = useParams()

    return enterpriseId ? (
        <ProvideEnterprise enterpriseId={enterpriseId}>
            <EnterpriseZoneLoader />
        </ProvideEnterprise>
    ) : (
        <ErrorDisplay message="Adresse incorrecte" />
    )
}

const EnterpriseZoneLoader = () => {
    const { enterprise, enterpriseLoading, error } = useEnterprise()

    return enterpriseLoading ? (
        <LoadingDisplay />
    ) : error ? (
        <ErrorDisplay message="Erreur lors du chargement de l'entreprise" queryError={error} debug={error} />
    ) : !enterprise ? (
        <ErrorDisplay message={'Undefined enterprise after loading'} />
    ) : (
        <EnterpriseZoneReady />
    )
}

const EnterpriseZoneReady = () => {
    const auth = useAuthUser()
    const { enterprise } = useEnterpriseLoadedExisting()
    const enterpriseRoute =
        auth.user.userType === EnumUser.admin
            ? RoutesHelper.views.AEA.customer.enterprises.Enterprise
            : RoutesHelper.views.Customer.enterprises.Enterprise

    const { tab } = useParams()
    const { getEnterpriseRoute, getEnterprisesRoute } = useNavContext()

    const tabs: CustomTab<EnterpriseTabName>[] = [
        {
            id: 'general',
            label: 'Général',
            children: <EnterpriseGeneral />,
            to: getEnterpriseRoute(enterprise.id),
        },
        {
            id: 'sinistres',
            label: 'Sinistres',
            children: <EnterpriseDisasters />,
            to: getEnterpriseRoute(enterprise.id, 'sinistres'),
        },
        {
            id: 'contrats',
            label: 'Contrats',
            children: <EnterpriseContracts />,
            to: getEnterpriseRoute(enterprise.id, 'contrats'),
        },
        {
            id: 'locaux',
            label: 'Locaux',
            children: <EnterprisePlaces />,
            to: getEnterpriseRoute(enterprise.id, 'locaux'),
        },
        {
            id: 'vehicules',
            label: 'Véhicules',
            children: <EnterpriseVehicles />,
            to: getEnterpriseRoute(enterprise.id, 'vehicules'),
        },
        {
            id: 'biens',
            label: 'Biens',
            children: <EnterpriseGoods />,
            to: getEnterpriseRoute(enterprise.id, 'biens'),
        },
        {
            id: 'documents',
            label: 'Documents',
            children: <EnterpriseDocuments />,
            to: getEnterpriseRoute(enterprise.id, 'documents'),
        },
    ]

    const breadcrumbSegments = [
        {
            name: 'Enterprises',
            path: getEnterprisesRoute().pathname,
        },
        {
            name: enterprise.title,
            icon: IconHelper.elementIcons.enterpriseIcon,
            path: getEnterpriseRoute(enterprise.id).pathname,
        },
    ]

    return (
        <CustomTabs<Enterprise_Full, EnterpriseTabName>
            breadcrumbSegments={breadcrumbSegments}
            activeTab={tab ? (tab as EnterpriseTabName) : 'general'}
            tabs={tabs}
        />
    )

    // return <EnterpriseLayout activeTab={tab || 'general'} tabChildren={tabChildren} />
}

export default EnterpriseZone
