import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    ThemeProvider,
} from '@material-ui/core'
import { Fragment, useState } from 'react'
import {
    DocumentInput,
    EnumDocumentCategory,
    EnumDocumentParent,
    EnumDocumentStatus,
} from '../../../../../graphql/typings/global_types'
import { Document_User } from '../../../../helpers/data/models/Document'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import { useAuthUser } from '../../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'
import FileDialogHelper, { FileType } from '../../../../hooks/useFileDialog'
import { useCreateDocuments } from '../../../../hooks/useMutations'
import useSettings from '../../../../hooks/useSettings'
import { UploadManagerToolkit, useUploadManyFiles } from '../../../../hooks/useUploadManager'
import CustomButton from '../../../CustomButton'
import DocumentUpload from '../DocumentUpload'

type SubmitDocumentFormDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (documents: Document_User[]) => void
}

type DocumentFormData = {
    title: string | null
    statusComment: string | null
}

const emptyData: DocumentFormData = {
    title: null,
    statusComment: null,
}

const SubmitDocumentFormDialog = ({ open, closeDialog, onDone }: SubmitDocumentFormDialogProps) => {
    const [data, setData] = useState<DocumentFormData>(emptyData)

    const [loading, setLoading] = useState<boolean>(false)

    const auth = useAuthUser()
    const { settings } = useSettings()
    const { customer } = useCustomerLoadedExisting()
    const createDocumentsMutation = useCreateDocuments()
    const { errorAlert } = useErrorService()

    /*****************
     * FILE MANAGEMENT
     ****************/
    const { uploadDocumentFile, uploadingState } = useUploadManyFiles()
    const { filesPickerNode, openFilesPicker, filesHelpers } = FileDialogHelper.useManyFilesDialog(FileType.ALL)
    const { getUploadedFileDataAsString } = UploadManagerToolkit()

    const handleChange = (value: string, prop: 'title' | 'statusComment') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const handleClose = () => {
        setLoading(false)
        setData(emptyData)
        filesHelpers.forEach((fileHelper) => fileHelper.deleteFile())
        closeDialog()
    }

    const handleSubmit = async () => {
        if (filesHelpers.length === 0) return

        // We check if all files have title
        let allFilesHaveTitle = true
        filesHelpers.forEach((fileHelper) => {
            if (fileHelper.title === '') allFilesHaveTitle = false
        })

        if (!allFilesHaveTitle) {
            errorAlert('Tous les fichiers doivent avoir un titre', filesHelpers)
            return
        }

        setLoading(true)

        try {
            const documentsInput: DocumentInput[] = []

            const promises = filesHelpers.map((fileHelper) =>
                uploadDocumentFile(fileHelper.file).then((uploadedFileData) => {
                    const fileDataAsString = getUploadedFileDataAsString(uploadedFileData)

                    documentsInput.push({
                        title: fileHelper.title,
                        // By default, we link the doc to the project
                        parentType: EnumDocumentParent.Project,
                        parentId: customer.id,
                        askedById: auth.user.id,
                        status: EnumDocumentStatus.submitted_by_user,
                        statusComment: data.statusComment,
                        // By default, we set the category to "miscellaneous"
                        category: EnumDocumentCategory.miscellaneous,
                        fileDescriptor: fileDataAsString,
                    })
                })
            )

            await Promise.all(promises)

            //Creating
            const response = await createDocumentsMutation.run({
                customerId: customer.id,
                documentsInput,
            })

            if (response.documents) {
                onDone(response.documents)
            } else {
                throw "Une erreur est survenue pendant l'envoi d'un document"
            }

            handleClose()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const documentUploadElements = filesHelpers.map((fileHelper, index) => {
        const uploadingStatus = uploadingState[index]

        return (
            <DocumentUpload
                key={fileHelper.id}
                loading={loading}
                uploadPercent={uploadingStatus ? uploadingStatus.uploadPercent : 0}
                localFile={fileHelper.file}
                openFilePicker={openFilesPicker}
                hasAlreadyFileUploaded={false}
                deleteFile={fileHelper.deleteFile}
                editable={true}
                title={fileHelper.title}
                editFileName={fileHelper.editFileTitle}
            />
        )
    })

    const isFormValid = filesHelpers.length > 0

    return (
        <ThemeProvider theme={settings.themes[settings.activeTheme]}>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Dépôt de documents</DialogTitle>
                <DialogContent>
                    <DialogContentText>Déposez des documents qui seront envoyés à l'expert</DialogContentText>
                    <Fragment>
                        <div className="mb-2">{documentUploadElements}</div>
                        <DocumentUpload
                            loading={loading}
                            uploadPercent={0}
                            localFile={null}
                            openFilePicker={openFilesPicker}
                            hasAlreadyFileUploaded={false}
                            deleteFile={() => {}}
                        />
                    </Fragment>
                    <TextField
                        className="mb-5"
                        margin="dense"
                        id="description"
                        label="Indications supplémentaires ?"
                        type="text"
                        fullWidth
                        multiline={true}
                        value={data.statusComment || ''}
                        onChange={(event) => handleChange(event.target.value, 'statusComment')}
                    />
                </DialogContent>
                <DialogActions>
                    <CustomButton color="secondary" onClick={handleClose}>
                        Annuler
                    </CustomButton>
                    <CustomButton
                        loading={loading}
                        variant="outlined"
                        onClick={handleSubmit}
                        color="primary"
                        disabled={!isFormValid}>
                        Valider
                    </CustomButton>
                </DialogActions>
            </Dialog>
            {filesPickerNode}
        </ThemeProvider>
    )
}

export default SubmitDocumentFormDialog
