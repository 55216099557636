import Places from '../../../components/customer/places/Places'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'

const CustomerPlaces = () => {
    const { customer } = useCustomerLoadedExisting()

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Places places={customer.places} />
            </div>
        </div>
    )
}

export default CustomerPlaces
