import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.User.User_Customers}
    mutation SignInUser($email: String!, $password: String!) {
        signinUser(input: { credentials: { email: $email, password: $password } }) {
            authToken
            user {
                ...User_Customers
            }
        }
    }
`
