import { useGetPlaceDocuments } from '../../../../../helpers/data/getPlace'
import { Document_User } from '../../../../../helpers/data/models/Document'
import ErrorDisplay from '../../../../../helpers/errors/ErrorDisplay'
import { usePlaceLoadedExisting } from '../../../../../hooks/usePlace'
import LoadingDisplay from '../../../../LoadingDisplay'
import DocumentsList from '../../../../documentsList/DocumentsList'

const PlaceDocuments = () => {
    const { place } = usePlaceLoadedExisting()

    const placeDocsProps = useGetPlaceDocuments(place.id)

    if (placeDocsProps.placeLoading) return <LoadingDisplay />
    else if (placeDocsProps.error) {
        return (
            <ErrorDisplay
                message="Erreur lors du chargement du local"
                queryError={placeDocsProps.error}
                debug={placeDocsProps.error}
            />
        )
    } else if (!placeDocsProps.place) {
        return <ErrorDisplay message="Erreur lors du chargement du local" />
    } else return <PlaceDocumentsReady documents={placeDocsProps.place.documents} />
}

type PlaceDocumentsReadyProps = {
    documents: Document_User[]
}

const PlaceDocumentsReady = ({ documents }: PlaceDocumentsReadyProps) => {
    return <DocumentsList documents={documents} mode="confirmed" showLink={false} />
}

export default PlaceDocuments
