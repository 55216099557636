import React from 'react'
import Panel from '../../../../components/Panel'
import AEACustomersList from './AEACustomersList'

const AEACustomers = () => {
    return (
        <div className="m-sm-30">
            <h3 className="m-0 mb-6">Accès aux clients</h3>
            <Panel>
                <AEACustomersList />
            </Panel>
        </div>
    )
}

export default AEACustomers
