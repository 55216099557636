import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useNavigate } from 'react-router'
import ErrorView from '../../components/ErrorView'
import RoutesHelper from '../../helpers/RoutesHelper'
import { useAuth } from '../../hooks/useAuth'
import { useUpdateUserPassword } from '../../hooks/useMutations'
import ExternalLinkLayout from './ExternalLinkLayout'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

const AccountConfirm = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const auth = useAuth()

    useEffect(() => {
        if (auth.loading) return
        if (auth.user) {
            auth.logout()
        }
    }, [auth])

    const content = useMemo(() => {
        if (auth.loading) return
        if (auth.user) return

        // Different method is used because urlParams.get parse the result, and so remove special characters such as : +
        const splittedUrl = queryString.split('&')
        if (splittedUrl.length < 3) {
            return (
                <ErrorView
                    message="Erreur - Lien erroné. Veuillez contacter AEA"
                    debug={{
                        queryString,
                        urlParams,
                        splittedUrl,
                    }}
                />
            )
        }

        const userEmail = splittedUrl[1].split('=')[1]
        const userToken = urlParams.get('user_token')
        const firstname = urlParams.get('firstname')
        const lastname = urlParams.get('lastname')

        if (!userToken || !userEmail || !firstname || !lastname) {
            return (
                <ErrorView
                    message="Erreur - Lien erroné. Veuillez contacter AEA"
                    debug={{
                        queryString,
                        urlParams,
                    }}
                />
            )
        }

        return <AccountConfirmReady token={userToken} email={userEmail} firstname={firstname} lastname={lastname} />
    }, [queryString, urlParams, auth])

    return <ExternalLinkLayout title="Confirmez votre compte">{content}</ExternalLinkLayout>
}

type AccountConfirmReadyProps = {
    token: string
    email: string
    firstname: string
    lastname: string
}

const AccountConfirmReady = ({ token, email, firstname, lastname }: AccountConfirmReadyProps) => {
    const navigate = useNavigate()
    const classes = useStyles()

    const [firstName, setFirstName] = useState<string>(firstname)
    const [lastName, setLastName] = useState<string>(lastname)
    const [password, setPassword] = useState<string>('')
    const [passwordConfirm, setPasswordConfirm] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const updateUserMutation = useUpdateUserPassword()

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordConfirmEqual', (value) => {
            if (value !== password) {
                return false
            }
            return true
        })
    }, [password])

    const handleFormSubmit = async () => {
        if (password !== passwordConfirm) {
            setErrorMessage('Les deux mots de passe sont différents')
            return
        }

        setLoading(true)
        try {
            await updateUserMutation.run({
                userToken: token,
                newPassword: password,
                newPasswordConfirmation: passwordConfirm,
                firstname: firstName,
                lastname: lastName,
            })

            navigate(RoutesHelper.views.Customer.Home.to())
        } catch (e) {
            setErrorMessage('Une erreur est survenue. Contactez AEA')
        } finally {
            setLoading(false)
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value

        setErrorMessage(null)

        switch (name) {
            case 'password':
                setPassword(value)
                break
            case 'password_confirm':
                setPasswordConfirm(value)
                break
            case 'firstname':
                setFirstName(value)
                break
            case 'lastname':
                setLastName(value)
                break
        }
    }

    return (
        <Fragment>
            <ValidatorForm onSubmit={handleFormSubmit}>
                <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    size="small"
                    label="Email"
                    onChange={handleInputChange}
                    type="email"
                    name="email"
                    value={email}
                    disabled={true}
                    validators={['required', 'isEmail']}
                    errorMessages={['Ce champ est requis', "L'email est invalide"]}
                />
                <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    size="small"
                    label="Prénom"
                    onChange={handleInputChange}
                    type="text"
                    name="firstname"
                    value={firstName}
                    disabled={true}
                    validators={['required']}
                    errorMessages={['Ce champ est requis']}
                />
                <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    size="small"
                    label="Nom de famille"
                    onChange={handleInputChange}
                    type="text"
                    name="lastname"
                    value={lastName}
                    disabled={true}
                    validators={['required']}
                    errorMessages={['Ce champ est requis']}
                />
                <TextValidator
                    className="mb-6 w-full"
                    label="Password"
                    variant="outlined"
                    size="small"
                    onChange={handleInputChange}
                    name="password"
                    type="password"
                    value={password}
                    validators={['required', 'matchRegexp:.{8}']}
                    errorMessages={['Ce champ est requis', 'Votre mot de passe doit compter au moins 8 caractères']}
                />
                <TextValidator
                    className="mb-6 w-full"
                    label="Password (confirmation)"
                    variant="outlined"
                    size="small"
                    onChange={handleInputChange}
                    name="password_confirm"
                    type="password"
                    value={passwordConfirm}
                    validators={['required', 'matchRegexp:.{8}', 'isPasswordConfirmEqual']}
                    errorMessages={[
                        'Ce champ est requis',
                        'Votre mot de passe doit compter au moins 8 caractères',
                        'Les deux mots de passe doivent être identiques',
                    ]}
                />

                {errorMessage && (
                    <div className="mb-6">
                        <ErrorView message={errorMessage} />
                    </div>
                )}

                <div className="flex flex-wrap items-center mb-4">
                    <div className="relative">
                        <Button variant="contained" color="primary" disabled={loading} type="submit">
                            Confirmer
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </div>
            </ValidatorForm>
        </Fragment>
    )
}

export default AccountConfirm
