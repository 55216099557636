import React from 'react'
import Documents from '../../../../components/customer/documents/Documents'
import IconHelper from '../../../../helpers/IconHelper'
import RoutesHelper from '../../../../helpers/RoutesHelper'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'

const AEACustomerDocument = () => {
    const { customer } = useCustomerLoadedExisting()

    return (
        <Documents
            route={RoutesHelper.views.AEA.customer.Documents}
            routeSegments={[{ name: 'Documents', icon: IconHelper.elementIcons.docsIcon }]}
            homePath={RoutesHelper.views.AEA.customer.Home.to({ customerId: customer.id }).pathname}
        />
    )
}

export default AEACustomerDocument
