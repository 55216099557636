import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Broker.Broker_Minimal}
    mutation CreateBroker($title: String!, $phone: String, $email: String) {
        createBroker(input: { title: $title, phone: $phone, email: $email }) {
            errors
            status
            statusCode
            broker {
                ...Broker_Minimal
            }
        }
    }
`
