import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import React, { useState } from 'react'
import { isStringInputValid } from '../../../../../utilsTs'
import CustomButton from '../../../../components/CustomButton'
import { Dialog } from '../../../../components/Dialog'
import { Site_Minimal } from '../../../../helpers/data/models/Site'
import { useErrorService } from '../../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'
import { useCreateSite, useUpdateSite } from '../../../../hooks/useMutations'

type DialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (site: Site_Minimal) => void
    site?: Site_Minimal
}

const emptyData: Data = {
    title: null,
}

type Data = {
    title: string | null
}

const SiteDialog = ({ onDone, open, closeDialog, site }: DialogProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { errorAlert } = useErrorService()

    const [data, setData] = useState<Data>(site ? { title: site.title } : emptyData)
    const [loading, setLoading] = useState<boolean>(false)

    const createSiteMutation = useCreateSite()
    const updateSiteMutation = useUpdateSite()

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title') => {
        setData({
            ...data,
            [prop]: value,
        })
    }

    const handleSubmit = async () => {
        if (!data.title) return

        setLoading(true)

        if (site) {
            //Update
            const response = await updateSiteMutation.run({
                id: site.id,
                customerId: customer.id,
                title: data.title,
            })

            if (response.site) {
                onDone(response.site)
            } else {
                errorAlert("Une erreur est survenue pendant la mise à jour de l'entreprise", response)
            }
        } else {
            //Create
            const response = await createSiteMutation.run({
                customerId: customer.id,
                title: data.title,
            })

            if (response.site) {
                onDone(response.site)
            } else {
                errorAlert("Une erreur est survenue pendant la mise à jour de l'entreprise", response)
            }
        }

        setLoading(false)
        closeDialog()
    }

    const isFormValid = isStringInputValid(data.title)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un site</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="title"
                    label="Nom du site *"
                    type="string"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default SiteDialog
