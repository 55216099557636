import { gql } from "@apollo/client/core";

export default gql`
    fragment RoadmapItem_Minimal on RoadmapItem {
        id
        title
        date
        description
    }
`
