import { EnumDocumentCategory, EnumDocumentParent, EnumDocumentStatus } from '../../graphql/typings/global_types'
import { DocLinkedElementsIDs, Document_Minimal, Document_User } from './data/models/Document'
import DateHelper from './DateHelper'
import IconHelper from './IconHelper'
import VehicleHelper from './VehicleHelper'

// const getActiveVersion = (document: Document_Minimal): Document_Version | null => {
//     const lastVersion = document.documentVersions.find((version) => version.active)

//     return lastVersion || null
// }

export type DocumentSorted<TDoc> = {
    pendingDocsAskedByAEA: TDoc[]
    pendingDocsAskedByUser: TDoc[]
    waitingAEAValidationDocs: TDoc[]
    confirmedDocs: TDoc[]
    rejectedDocs: TDoc[]
}

const splitDocuments = <TDoc extends Document_Minimal>(documents: TDoc[]): DocumentSorted<TDoc> => {
    const splittedDocuments: DocumentSorted<TDoc> = {
        pendingDocsAskedByAEA: [],
        pendingDocsAskedByUser: [],
        waitingAEAValidationDocs: [],
        confirmedDocs: [],
        rejectedDocs: [],
    }

    documents.forEach((doc) => {
        if (doc.status === EnumDocumentStatus.asked_by_admin) splittedDocuments.pendingDocsAskedByAEA.push(doc)
        else if (doc.status === EnumDocumentStatus.asked_by_user) splittedDocuments.pendingDocsAskedByUser.push(doc)
        else if (doc.status === EnumDocumentStatus.answered || doc.status === EnumDocumentStatus.submitted_by_user)
            splittedDocuments.waitingAEAValidationDocs.push(doc)
        else if (doc.status === EnumDocumentStatus.confirmed) splittedDocuments.confirmedDocs.push(doc)
        else if (doc.status === EnumDocumentStatus.rejected || doc.status === EnumDocumentStatus.submission_rejected)
            splittedDocuments.rejectedDocs.push(doc)
        else {
            console.error('Statut de document non reconnu : ', documents)
        }
    })

    return splittedDocuments
}

const getDocumentCategoryLabel = (category: EnumDocumentCategory): string => {
    switch (category) {
        case EnumDocumentCategory.accounting:
            return 'Comptabilité'
        case EnumDocumentCategory.customer_description:
            return 'Descriptif client'
        case EnumDocumentCategory.gray_card:
            return 'Carte grise'
        case EnumDocumentCategory.identity_and_license:
            return 'Identité et permis'
        case EnumDocumentCategory.kbis:
            return 'Kbis'
        case EnumDocumentCategory.organizational_chart:
            return 'Organigramme'
        case EnumDocumentCategory.prevention:
            return 'Prévention'
        case EnumDocumentCategory.other_prevention:
            return 'Autre prévention'
        case EnumDocumentCategory.other_statement:
            return 'Autre déclaration'
        case EnumDocumentCategory.proof_of_construction:
            return 'Justificatifs construction'
        case EnumDocumentCategory.green_card:
            return 'Carte verte'
        case EnumDocumentCategory.insurance_certificate:
            return "Attestation d'assurance"
        case EnumDocumentCategory.insurance_contract:
            return "Contrat d'assurance"
        case EnumDocumentCategory.lease:
            return 'Bail'
        case EnumDocumentCategory.matrix:
            return 'Matrice'
        case EnumDocumentCategory.miscellaneous:
            return 'Divers'
        case EnumDocumentCategory.reporting:
            return 'Reporting'
        case EnumDocumentCategory.disaster_declaration_and_report:
            return 'Sinistre : Déclaration et constat'
        case EnumDocumentCategory.disaster_pictures:
            return 'Sinistre : Photos'
        case EnumDocumentCategory.disaster_original_invoices:
            return "Sinistre : Factures d'origine"
        case EnumDocumentCategory.disaster_pv_and_receipt:
            return 'Sinistre : PV et quittance'
        case EnumDocumentCategory.disaster_administrative_and_others:
            return 'Sinistre : Administratif et autres'
        case EnumDocumentCategory.disaster_deferred_follow_up:
            return 'Sinistre : Suivi différé'
        case EnumDocumentCategory.disaster_loss_and_claim_statement:
            return 'Sinistre : État pertes et réclamation'
        case EnumDocumentCategory.disaster_repaired_invoices:
            return 'Sinistre : Factures remise en état'
        case EnumDocumentCategory.disaster_judicial:
            return 'Sinistre : Judiciaire'
        case EnumDocumentCategory.disaster_appraisals:
            return 'Sinistre : Expertises'
        default:
            return ''
    }
}

const getDocumentElementCategoryLabel = (docElementCategory: EnumDocumentParent): string => {
    switch (docElementCategory) {
        case EnumDocumentParent.Project:
            return `Client`
        case EnumDocumentParent.Enterprise:
            return `Entreprise`
        case EnumDocumentParent.Contract:
            return `Contrat`
        case EnumDocumentParent.Disaster:
            return `Sinistre`
        case EnumDocumentParent.Site:
            return 'Site'
        case EnumDocumentParent.Local:
            return 'Local'
        case EnumDocumentParent.Fleet:
            return `Flotte`
        case EnumDocumentParent.Vehicle:
            return `Véhicule`
        case EnumDocumentParent.Good:
            return 'Bien'
        case EnumDocumentParent.Prevention:
            return `Prévention`
        default:
            return 'Catégorie inconnue'
    }
}

const getDocumentStatusLabel = (status: EnumDocumentStatus): string => {
    switch (status) {
        case EnumDocumentStatus.answered:
            return `En attente de validation par l'expert`
        case EnumDocumentStatus.asked_by_admin:
            return `Demandé par l'expert`
        case EnumDocumentStatus.asked_by_user:
            return `Demandé par l'assuré`
        case EnumDocumentStatus.confirmed:
            return 'Disponible'
        case EnumDocumentStatus.rejected:
            return `Document rejeté par l'expert`
        case EnumDocumentStatus.submitted_by_user:
            return `Document rejeté par l'expert`
        default:
            return 'Statut inconnu'
    }
}

const downloadDocumentFromUrl = (docTitle: string, docUrl: string) => {
    var link = document.createElement('a')
    link.download = docTitle
    link.href = docUrl
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export type DocumentParentData = {
    parentType: EnumDocumentParent
    parentId: string
}

const getDocumentParentData = (document: DocLinkedElementsIDs): DocumentParentData => {
    if (document.contract) {
        return {
            parentType: EnumDocumentParent.Contract,
            parentId: document.contract.id,
        }
    } else if (document.disaster) {
        return {
            parentType: EnumDocumentParent.Disaster,
            parentId: document.disaster.id,
        }
    } else if (document.enterprise) {
        return {
            parentType: EnumDocumentParent.Enterprise,
            parentId: document.enterprise.id,
        }
    } else if (document.fleet) {
        return {
            parentType: EnumDocumentParent.Fleet,
            parentId: document.fleet.id,
        }
    } else if (document.good) {
        return {
            parentType: EnumDocumentParent.Good,
            parentId: document.good.id,
        }
    } else if (document.place) {
        return {
            parentType: EnumDocumentParent.Local,
            parentId: document.place.id,
        }
    } else if (document.prevention) {
        return {
            parentType: EnumDocumentParent.Prevention,
            parentId: document.prevention.id,
        }
    } else if (document.project) {
        return {
            parentType: EnumDocumentParent.Project,
            parentId: document.project.id,
        }
    } else if (document.site) {
        return {
            parentType: EnumDocumentParent.Site,
            parentId: document.site.id,
        }
    } else if (document.vehicle) {
        return {
            parentType: EnumDocumentParent.Vehicle,
            parentId: document.vehicle.id,
        }
    } else {
        throw "Erreur : le document n'est pas rattaché à un élément connu"
    }
}

export type LinkedTo = {
    label: string
    icon?: string
}

const getSortedDocumentsCategories = () => {
    const categories = Object.values(EnumDocumentCategory)

    return categories.sort((categoryA, categoryB) => {
        const labelA = getDocumentCategoryLabel(categoryA)
        const labelB = getDocumentCategoryLabel(categoryB)

        return labelA.localeCompare(labelB)
    })
}

const DocumentHelper = {
    getDocumentElementCategoryLabel,
    getDocumentCategoryLabel,
    getSortedDocumentsCategories,
    splitDocuments,
    downloadDocumentFromUrl,
    getDocumentStatusLabel,
    getDocumentParentData,
}

export const useDocumentHelper = () => {
    const { fromServertoString } = DateHelper()

    const getDocumentLinkedTo = (document: Document_User): LinkedTo => {
        if (document.contract) {
            return { label: document.contract.title, icon: IconHelper.elementIcons.contractIcon }
        } else if (document.disaster) {
            return { label: document.disaster.title, icon: IconHelper.elementIcons.disasterIcon }
        } else if (document.enterprise) {
            return { label: document.enterprise.title, icon: IconHelper.elementIcons.enterpriseIcon }
        } else if (document.fleet) {
            return { label: document.fleet.title, icon: IconHelper.elementIcons.fleetIcon }
        } else if (document.good) {
            return { label: document.good.title, icon: IconHelper.elementIcons.goodIcon }
        } else if (document.place) {
            return { label: document.place.title, icon: IconHelper.elementIcons.placeIcon }
        } else if (document.prevention) {
            return {
                label: `${document.prevention.preventionCategory.title} - ${
                    document.prevention.place.title
                } (${fromServertoString(document.prevention.startAt)} - ${fromServertoString(
                    document.prevention.endAt
                )})`,
                icon: IconHelper.elementIcons.preventionIcon,
            }
        } else if (document.project) {
            return { label: 'Global' }
        } else if (document.site) {
            return { label: document.site.title, icon: IconHelper.elementIcons.siteIcon }
        } else if (document.vehicle) {
            return { label: VehicleHelper.getLabel(document.vehicle), icon: IconHelper.elementIcons.vehicleIcon }
        } else {
            return { label: 'Inconnu' }
        }
    }

    return {
        ...DocumentHelper,
        getDocumentLinkedTo,
    }
}

export default DocumentHelper
