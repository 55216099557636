import { useMemo } from 'react'
import { EnumGuaranteeTarget } from '../../../../../../graphql/typings/global_types'
import { usePlaceLoadedExisting } from '../../../../../hooks/usePlace'
import ObjectContracts, { ContractWrapper } from '../../../contracts/ObjectContracts'

const PlaceContracts = () => {
    const { place } = usePlaceLoadedExisting()

    const contractsWrappers: ContractWrapper[] = useMemo(() => {
        const contractsWrappers: ContractWrapper[] = []

        place.guarantees.forEach((guarantee) => {
            const contract = guarantee.contract

            const existingWrapper = contractsWrappers.find((wrapper) => wrapper.contract.id === contract.id)

            if (existingWrapper) {
                existingWrapper.guarantees.push(guarantee)
            } else {
                contractsWrappers.push({
                    contract,
                    guarantees: [guarantee],
                })
            }
        })

        return contractsWrappers
    }, [place.guarantees])

    return (
        <ObjectContracts
            title="Contrats sur ce local"
            onSuccessText="Garanties du local modifiées !"
            confirmText="Voulez-vous vraiment retirer ce local de toutes les garanties de ce contrat ?"
            contractsWrappers={contractsWrappers}
            guaranteeTarget={{
                targetId: place.id,
                targetType: EnumGuaranteeTarget.Local,
            }}
        />
    )
}

export default PlaceContracts
