import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment, useMemo, useState } from 'react'
import { EnumUser } from '../../../../graphql/typings/global_types'
import DocumentHelper from '../../../helpers/DocumentHelper'
import IconHelper from '../../../helpers/IconHelper'
import { useAuthUser } from '../../../hooks/useAuth'
import useSnackBar from '../../../hooks/useSnackBar'
import AddIconCustomToolbarProps from '../../customer/datatable/AddIconCustomToolbarProps'
import DataTable from '../../DataTable'
import DocumentCreate from '../DocumentForms/Admin/DocumentCreate/DocumentCreate'
import { DocumentForEdit } from '../DocumentForms/Admin/DocumentEdit/DocumentEdit'
import DocumentListDelete from '../DocumentsListActions/DocumentListDelete'
import DocumentListDownload from '../DocumentsListActions/DocumentListDownload'
import DocumentListEdit from '../DocumentsListActions/DocumentListEdit'

type DocumentsModalListProps = {
    documents: DocumentForEdit[]
}

type FormattedDoc = {
    id: string
    title: string
    category: string
    statusComment: string
    document: DocumentForEdit
}

const DocumentsModalList = ({ documents }: DocumentsModalListProps) => {
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const { openSnack } = useSnackBar()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Nom',
        },
        {
            name: 'category',
            label: 'Type',
        },
        {
            name: 'statusComment',
            label: 'Commentaire',
        },
    ]

    const formattedDocuments: FormattedDoc[] = useMemo(() => {
        return documents.map((doc) => {
            const formattedDoc: FormattedDoc = {
                id: doc.id,
                title: doc.title,
                category: DocumentHelper.getDocumentCategoryLabel(doc.category),
                statusComment: doc.statusComment || '',
                document: doc,
            }

            return formattedDoc
        })
    }, [documents])

    const actions = (dataIndex: number, rowIndex: number) => {
        const document = formattedDocuments[dataIndex].document

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <DocumentListDownload document={document} />
                {isAdmin && (
                    <Fragment>
                        <DocumentListEdit document={document} />
                        <DocumentListDelete document={document} />
                    </Fragment>
                )}
            </div>
        )
    }

    const createDoc = () => {
        setOpenAddDialog(true)
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `Document(s) ajouté(s) avec succès !`,
        })
    }

    const addFeatureData = isAdmin
        ? {
              addHandler: () => {
                  createDoc()
              },
              tooltip: `Ajout d'un document`,
          }
        : undefined

    return (
        <>
            <DataTable<FormattedDoc>
                title={`Documents`}
                icon={IconHelper.elementIcons.docsIcon}
                data={formattedDocuments}
                columns={columns}
                showSearch={true}
                actionsColumn={actions}
                addFeature={addFeatureData}
            />
            <DocumentCreate onDone={onCreated} open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} />
        </>
    )
}

export default DocumentsModalList
