import { Fragment, useState } from 'react'
import { Document_User } from '../../helpers/data/models/Document'
import DocumentHelper, { useDocumentHelper } from '../../helpers/DocumentHelper'
import ErrorDisplay from '../../helpers/errors/ErrorDisplay'
import UserHelper from '../../helpers/UserHelper'
import useSnackBar from '../../hooks/useSnackBar'
import DocumentCreate from './DocumentForms/Admin/DocumentCreate/DocumentCreate'
import DocumentQuickActions from './DocumentQuickActions'
import DocumentsConfirmed from './DocumentsConfirmed'
import DocumentsWaitingAdminAction from './DocumentsWaitingAdminAction'
import DocumentsWaitingUserAction from './DocumentsWaitingUserAction'

export type FormattedDoc = {
    id: string
    title: string
    category: string
    formattedStatus: string
    statusComment: string
    askedBy: string
    document: Document_User
    linkedTo: string
    linkedToIcon?: string
}

type DocumentsListProps = {
    documents: Document_User[]
    showLink: boolean
    mode: 'confirmed' | 'all' | 'waiting_admin' | 'waiting_user'
}

const DocumentsList = ({ documents, mode, showLink }: DocumentsListProps) => {
    const { openSnack } = useSnackBar()
    const { getDocumentLinkedTo } = useDocumentHelper()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)

    /************************
     * ADD MANAGEMENT
     ***********************/

    const addDoc = () => {
        setOpenAddDialog(true)
    }

    /************************
     * DOCUMENTS SORTING
     ***********************/

    const { pendingDocsAskedByAEA, pendingDocsAskedByUser, waitingAEAValidationDocs, confirmedDocs, rejectedDocs } =
        getFormattedDocs()

    function getFormattedDocs() {
        const { pendingDocsAskedByAEA, pendingDocsAskedByUser, waitingAEAValidationDocs, confirmedDocs, rejectedDocs } =
            DocumentHelper.splitDocuments(documents)

        const formatDoc = (document: Document_User): FormattedDoc => {
            const linkedTo = getDocumentLinkedTo(document)

            return {
                id: document.id,
                title: document.title,
                category: DocumentHelper.getDocumentCategoryLabel(document.category),
                document: document,
                formattedStatus: DocumentHelper.getDocumentStatusLabel(document.status),
                statusComment: document.statusComment || '-',
                askedBy: document.askedBy
                    ? UserHelper.getFullName(document.askedBy.firstname, document.askedBy.lastname)
                    : '-',
                linkedTo: linkedTo.label,
                linkedToIcon: linkedTo.icon,
            }
        }

        return {
            pendingDocsAskedByAEA: pendingDocsAskedByAEA.map(formatDoc),
            pendingDocsAskedByUser: pendingDocsAskedByUser.map(formatDoc),
            waitingAEAValidationDocs: waitingAEAValidationDocs.map(formatDoc),
            confirmedDocs: confirmedDocs.map(formatDoc),
            rejectedDocs: rejectedDocs.map(formatDoc),
        }
    }

    const onCreatedMany = () => {
        openSnack({
            type: 'success',
            message: `Document(s) ajouté(s) avec succès !`,
        })
    }

    /************************
     * DOCUMENTS PANELS
     ***********************/

    const documentPanel =
        mode === 'waiting_admin' ? (
            <DocumentsWaitingAdminAction
                pendingDocsAskedByUser={pendingDocsAskedByUser}
                waitingAEAValidationDocs={waitingAEAValidationDocs}
            />
        ) : mode === 'waiting_user' ? (
            <DocumentsWaitingUserAction pendingDocsAskedByAEA={pendingDocsAskedByAEA} rejectedDocs={rejectedDocs} />
        ) : mode === 'confirmed' ? (
            <DocumentsConfirmed addDoc={addDoc} documents={confirmedDocs} showLink={showLink} />
        ) : (
            <ErrorDisplay message={`Mode non implémenté : ${mode}`} />
        )

    return (
        <Fragment>
            <DocumentQuickActions />
            {documentPanel}
            <DocumentCreate onDone={onCreatedMany} open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} />
        </Fragment>
    )
}

export default DocumentsList
