import { Tooltip } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { Fragment, useState } from 'react'
import { EnumUser } from '../../../../graphql/typings/global_types'
import { RoadmapItem_Minimal } from '../../../helpers/data/models/RoadmapItem'
import IconHelper from '../../../helpers/IconHelper'
import { useAuthUser } from '../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDeleteRoadmapItem } from '../../../hooks/useMutations'
import useSnackBar from '../../../hooks/useSnackBar'
import DataTable from '../../DataTable'
import { ConfirmDialog } from '../../Dialog'
import RoadmapDialog from './RoadmapDialog'

type RoadmapListProps = {
    roadmapItems: RoadmapItem_Minimal[]
}

type DialogData =
    | {
          open: false
      }
    | {
          open: true
          roadmapItem: RoadmapItem_Minimal
      }

type RoadmapItemData = {
    year: number
    title: string
    description: string
    baseRoadmapItem: RoadmapItem_Minimal
}

const RoadmapList = ({ roadmapItems }: RoadmapListProps) => {
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const { openSnack } = useSnackBar()
    const deleteRoamapItemMutation = useDeleteRoadmapItem()
    const { customer } = useCustomerLoadedExisting()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogData>({ open: false })
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<DialogData>({ open: false })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const formattedRoadmapItems: RoadmapItemData[] = roadmapItems.map((item) => {
        return {
            year: new Date(item.date).getFullYear(),
            title: item.title,
            description: item.description || '-',
            baseRoadmapItem: item,
        }
    })

    const onCreated = (roadmapItem: RoadmapItem_Minimal) => {
        openSnack({
            type: 'success',
            message: 'Projet créé avec succès !',
        })
    }

    const onEditClick = (roadmapItem: RoadmapItem_Minimal) => {
        setOpenEditDialog({ open: true, roadmapItem })
    }

    const onEdited = () => {
        setOpenEditDialog({ open: false })
        openSnack({
            type: 'success',
            message: 'Projet édité avec succès !',
        })
    }

    const onDeleteClick = (roadmapItem: RoadmapItem_Minimal) => {
        setOpenDeleteConfirm({ open: true, roadmapItem })
    }

    const onDeleteCancelled = () => {
        setOpenDeleteConfirm({ open: false })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteConfirm.open) return

        setDeleteLoading(true)

        await deleteRoamapItemMutation.run({
            id: openDeleteConfirm.roadmapItem.id,
            customerId: customer.id,
        })

        openSnack({
            type: 'success',
            message: 'Projet supprimé avec succès !',
        })

        setDeleteLoading(false)
        setOpenDeleteConfirm({ open: false })
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un projet`,
    }

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'year',
            label: 'Année',
        },
        {
            name: 'title',
            label: 'Projet',
        },
        {
            name: 'description',
            label: 'Description',
        },
    ]

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const roadmapItem = roadmapItems[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier le projet">
                    <IconButton onClick={() => onEditClick(roadmapItem)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le projet">
                    <IconButton onClick={() => onDeleteClick(roadmapItem)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <Fragment>
            <DataTable
                title={'Projets à venir'}
                icon={IconHelper.elementIcons.roadmapIcon}
                data={formattedRoadmapItems}
                columns={columns}
                showSearch={true}
                addFeature={addFeatureData}
                actionsColumn={actionsColumn}
                options={{
                    sortOrder: {
                        name: 'year',
                        direction: 'asc',
                    },
                }}
            />
            <RoadmapDialog onDone={onCreated} open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} />
            {openEditDialog.open && (
                <RoadmapDialog
                    roadmapItem={openEditDialog.roadmapItem}
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                />
            )}
            {openDeleteConfirm.open && (
                <ConfirmDialog
                    open={openDeleteConfirm.open}
                    title="Suppression d'un projet"
                    text="Voulez-vous vraiment supprimer ce projet ?"
                    onConfirmed={onDeleteConfirmed}
                    onCancelled={onDeleteCancelled}
                    loading={deleteLoading}
                />
            )}
        </Fragment>
    )
}

export default RoadmapList
