import { gql } from "@apollo/client/core";
import GuaranteeCategory from './GuaranteeCategory'

export default gql`
    ${GuaranteeCategory}
    fragment Guarantee_Minimal on Guarantee {
        id
        ceiling
        deductible
        guaranteeCategory {
            ...GuaranteeCategory
        }
    }
`
