import DateFnsUtils from '@date-io/date-fns'
// import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider'
import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Fragment } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import LoadingDisplay from './app/components/LoadingDisplay'
import NotFound from './app/components/NotFound'
import { SettingsProvider } from './app/contexts/SettingsContext'
import DateHelper from './app/helpers/DateHelper'
import RoutesHelper from './app/helpers/RoutesHelper'
import customTheme from './app/helpers/customTheme'
import { ProvideErrorService } from './app/helpers/errors/ErrorService'
import { ProvideAuth, useAuth } from './app/hooks/useAuth'
import { ProvideSnackBar } from './app/hooks/useSnackBar'
import AuthenticatedRootSwitch from './app/views/AuthenticatedRootSwitch'
import ForgotPassword from './app/views/ForgotPassword'
import Login from './app/views/Login'
import AEAZone from './app/views/aea/AEAZone'
import AEACustomerHome from './app/views/aea/customer/AEACustomerHome'
import AEACustomerZone from './app/views/aea/customer/AEACustomerZone'
import AEACustomerAdminContract from './app/views/aea/customer/adminContract/AEACustomerAdminContract'
import AEACustomerContract from './app/views/aea/customer/contracts/AEACustomerContract'
import AEACustomerContracts from './app/views/aea/customer/contracts/AEACustomerContracts'
import AEACustomerDisaster from './app/views/aea/customer/disasters/AEACustomerDisaster'
import AEACustomerDisasters from './app/views/aea/customer/disasters/AEACustomerDisasters'
import AEACustomerDocument from './app/views/aea/customer/documents/AEACustomerDocuments'
import AEACustomerEnterprise from './app/views/aea/customer/enterprises/AEACustomerEnterprise'
import AEACustomerEnterprises from './app/views/aea/customer/enterprises/AEACustomerEnterprises'
import AEACustomerOrgChart from './app/views/aea/customer/orgChart/AEACustomerOrgChart'
import AEACustomerPlace from './app/views/aea/customer/places/AEACustomerPlace'
import AEACustomerPlaces from './app/views/aea/customer/places/AEACustomerPlaces'
import AEACustomerPrevention from './app/views/aea/customer/preventions/AEACustomerPrevention'
import AEACustomerPreventions from './app/views/aea/customer/preventions/AEACustomerPreventions'
import AEACustomerRoadmap from './app/views/aea/customer/roadmap/AEACustomerRoadmap'
import AEACustomerSites from './app/views/aea/customer/sites/AEACustomerSites'
import AEACustomerUsers from './app/views/aea/customer/users/AEACustomerUsers'
import AEACustomerVehicle from './app/views/aea/customer/vehicles/AEACustomerVehicle'
import AEACustomerVehicles from './app/views/aea/customer/vehicles/AEACustomerVehicles'
import AEARootZone from './app/views/aea/root/AEARootZone'
import AEAAdmins from './app/views/aea/root/admins/AEAAdmins'
import AEACustomers from './app/views/aea/root/customers/AEACustomers'
import AEADisasterCategories from './app/views/aea/root/disasterCategories/AEADisasterCategories'
import AEAEvents from './app/views/aea/root/events/AEAEvents'
import AEAGuaranteeCategories from './app/views/aea/root/guaranteeCategories/AEAGuaranteeCategories'
import AEAHome from './app/views/aea/root/home/AEAHome'
import AEAInsurances from './app/views/aea/root/insurances/AEAInsurances'
import AEAPreventionCategories from './app/views/aea/root/preventionCategories/AEAPreventionCategories'
import CustomerHome from './app/views/customer/CustomerHome'
import CustomerZone from './app/views/customer/CustomerZone'
import CustomerAdminContract from './app/views/customer/adminContract/CustomerAdminContract'
import CustomerContract from './app/views/customer/contracts/CustomerContract'
import CustomerContracts from './app/views/customer/contracts/CustomerContracts'
import CustomerDisaster from './app/views/customer/disasters/CustomerDisaster'
import CustomerDisasters from './app/views/customer/disasters/CustomerDisasters'
import CustomerDocuments from './app/views/customer/documents/CustomerDocuments'
import CustomerEnterprise from './app/views/customer/enterprises/CustomerEnterprise'
import CustomerEnterprises from './app/views/customer/enterprises/CustomerEnterprises'
import CustomerOrgChart from './app/views/customer/orgChart/CustomerOrgChart'
import CustomerPlace from './app/views/customer/places/CustomerPlace'
import CustomerPlaces from './app/views/customer/places/CustomerPlaces'
import CustomerPrevention from './app/views/customer/preventions/CustomerPrevention'
import CustomerPreventions from './app/views/customer/preventions/CustomerPreventions'
import CustomerRoadmap from './app/views/customer/roadmap/CustomerRoadmap'
import CustomerUsers from './app/views/customer/users/CustomerUsers'
import CustomerVehicle from './app/views/customer/vehicles/CustomerVehicle'
import CustomerVehicles from './app/views/customer/vehicles/CustomerVehicles'
import AccountConfirm from './app/views/external/AccountConfirm'
import PasswordChange from './app/views/external/PasswordChange'
import { client } from './graphql/client'
import { GlobalCss, MatxTheme } from './matx'
import MatxSuspense from './matx/components/MatxSuspense/MatxSuspense'

const GlobalComponentWrapper = () => {
    const { locale } = DateHelper()

    // const AuthenticatedComponent = (
    //     <Routes>
    //         <Route path={RoutesHelper.views.Root.path} element={AuthenticatedRootSwitch} />
    //         <Route path={RoutesHelper.baseZones.aea} element={AEAZone} />
    //         <Route path={RoutesHelper.baseZones.root} element={AEAZone} />
    //         <Route path={RoutesHelper.baseZones.customer} element={CustomerZone} />
    //         <Route element={NotFound} />
    //     </Routes>
    // )

    // const NotAuthenticatedComponent = (
    //     <Routes>
    //         <Route element={Login} />
    //     </Routes>
    // )

    // const AuthGuardComponent = () => (
    //     <AuthGuard authenticated={AuthenticatedComponent} notAuthenticated={NotAuthenticatedComponent} />
    // )

    return (
        <ApolloProvider client={client}>
            <SettingsProvider>
                <MatxTheme>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                        <Fragment>
                            <GlobalCss />
                            <ProvideAuth>
                                <MatxSuspense>
                                    <ProvideSnackBar>
                                        <ProvideErrorService>
                                            <AuthCheck />
                                        </ProvideErrorService>
                                    </ProvideSnackBar>
                                </MatxSuspense>
                            </ProvideAuth>
                        </Fragment>
                    </MuiPickersUtilsProvider>
                </MatxTheme>
            </SettingsProvider>
        </ApolloProvider>
    )
}

const AuthCheck = () => {
    const auth = useAuth()

    const externalRoutes = RoutesHelper.views.external
    const aeaCustomerRoutes = RoutesHelper.views.AEA.customer
    const customerRoutes = RoutesHelper.views.Customer

    return auth.loading ? (
        <LoadingDisplay />
    ) : (
        <BrowserRouter>
            <Routes>
                {auth.user ? (
                    <>
                        <Route path={externalRoutes.AccountConfirm.path} element={<AccountConfirm />} />
                        <Route path={externalRoutes.PasswordChange.path} element={<PasswordChange />} />
                        <Route path={RoutesHelper.views.ForgotPassword.path} element={<ForgotPassword />} />
                        <Route path={RoutesHelper.views.Root.path} element={<AuthenticatedRootSwitch />} />
                        <Route path={RoutesHelper.baseZones.aea} element={<AEAZone />}>
                            <Route path={RoutesHelper.aeaZones.customerZone} element={<AEACustomerZone />}>
                                <Route path={aeaCustomerRoutes.Home.path} element={<AEACustomerHome />} />
                                <Route
                                    path={aeaCustomerRoutes.AdminContract.Home.path}
                                    element={<AEACustomerAdminContract />}
                                />
                                <Route
                                    path={aeaCustomerRoutes.enterprises.Home.path}
                                    element={<AEACustomerEnterprises />}
                                />

                                <Route path={aeaCustomerRoutes.orgChart.Home.path} element={<AEACustomerOrgChart />} />
                                <Route
                                    path={aeaCustomerRoutes.contracts.Home.path}
                                    element={<AEACustomerContracts />}
                                />
                                <Route
                                    path={aeaCustomerRoutes.contracts.Contract.path}
                                    element={<AEACustomerContract />}
                                />
                                <Route path={aeaCustomerRoutes.vehicles.Home.path} element={<AEACustomerVehicles />} />
                                <Route
                                    path={aeaCustomerRoutes.vehicles.Vehicle.path}
                                    element={<AEACustomerVehicle />}
                                />
                                <Route path={aeaCustomerRoutes.places.Home.path} element={<AEACustomerPlaces />} />
                                <Route path={aeaCustomerRoutes.places.Place.path} element={<AEACustomerPlace />} />
                                <Route path={aeaCustomerRoutes.sites.Home.path} element={<AEACustomerSites />} />
                                <Route
                                    path={aeaCustomerRoutes.disasters.Home.path}
                                    element={<AEACustomerDisasters />}
                                />
                                <Route
                                    path={aeaCustomerRoutes.disasters.Disaster.path}
                                    element={<AEACustomerDisaster />}
                                />
                                <Route path={aeaCustomerRoutes.Documents.path} element={<AEACustomerDocument />} />
                                <Route
                                    path={aeaCustomerRoutes.preventions.Home.path}
                                    element={<AEACustomerPreventions />}
                                />
                                <Route
                                    path={aeaCustomerRoutes.preventions.Prevention.path}
                                    element={<AEACustomerPrevention />}
                                />
                                <Route path={aeaCustomerRoutes.Roadmap.path} element={<AEACustomerRoadmap />} />
                                <Route path={aeaCustomerRoutes.Users.path} element={<AEACustomerUsers />} />
                                <Route
                                    path={aeaCustomerRoutes.enterprises.Enterprise.path}
                                    element={<AEACustomerEnterprise />}
                                />
                                <Route element={<NotFound />} />
                            </Route>
                            <Route element={<AEAZoneRedirect />} />
                        </Route>
                        <Route path={RoutesHelper.baseZones.root} element={<AEAZone />}>
                            <Route path={RoutesHelper.aeaZones.rootZone} element={<AEARootZone />}>
                                <Route path={RoutesHelper.views.AEA.Home.path} element={<AEAHome />} />
                                <Route path={RoutesHelper.views.AEA.customers.Home.path} element={<AEACustomers />} />
                                <Route path={RoutesHelper.views.AEA.admins.Home.path} element={<AEAAdmins />} />
                                <Route path={RoutesHelper.views.AEA.insurances.Home.path} element={<AEAInsurances />} />
                                <Route path={RoutesHelper.views.AEA.events.Home.path} element={<AEAEvents />} />
                                <Route
                                    path={RoutesHelper.views.AEA.disasterCategories.Home.path}
                                    element={<AEADisasterCategories />}
                                />
                                <Route
                                    path={RoutesHelper.views.AEA.guaranteeCategories.Home.path}
                                    element={<AEAGuaranteeCategories />}
                                />
                                <Route
                                    path={RoutesHelper.views.AEA.preventionCategories.Home.path}
                                    element={<AEAPreventionCategories />}
                                />
                                <Route element={<NotFound />} />
                            </Route>
                            <Route element={<AEAZoneRedirect />} />
                        </Route>
                        <Route path={RoutesHelper.baseZones.customer} element={<CustomerZone />}>
                            <Route path={customerRoutes.Home.path} element={<CustomerHome />} />
                            <Route path={customerRoutes.AdminContract.Home.path} element={<CustomerAdminContract />} />
                            <Route path={customerRoutes.enterprises.Home.path} element={<CustomerEnterprises />} />
                            <Route path={customerRoutes.enterprises.Enterprise.path} element={<CustomerEnterprise />} />
                            <Route path={customerRoutes.orgChart.Home.path} element={<CustomerOrgChart />} />
                            <Route path={customerRoutes.contracts.Home.path} element={<CustomerContracts />} />
                            <Route path={customerRoutes.contracts.Contract.path} element={<CustomerContract />} />
                            <Route path={customerRoutes.vehicles.Home.path} element={<CustomerVehicles />} />
                            <Route path={customerRoutes.vehicles.Vehicle.path} element={<CustomerVehicle />} />
                            <Route path={customerRoutes.places.Home.path} element={<CustomerPlaces />} />
                            <Route path={customerRoutes.places.Place.path} element={<CustomerPlace />} />
                            <Route path={customerRoutes.disasters.Home.path} element={<CustomerDisasters />} />
                            <Route path={customerRoutes.disasters.Disaster.path} element={<CustomerDisaster />} />
                            <Route path={customerRoutes.Documents.path} element={<CustomerDocuments />} />
                            <Route path={customerRoutes.preventions.Home.path} element={<CustomerPreventions />} />
                            <Route path={customerRoutes.preventions.Prevention.path} element={<CustomerPrevention />} />
                            <Route path={customerRoutes.Roadmap.path} element={<CustomerRoadmap />} />
                            <Route path={customerRoutes.Users.path} element={<CustomerUsers />} />
                            <Route path={'*'} element={<NotFound />} />
                        </Route>
                        <Route path={'*'} element={<NotFound />} />
                    </>
                ) : (
                    <>
                        <Route path={externalRoutes.AccountConfirm.path} element={<AccountConfirm />} />
                        <Route path={externalRoutes.PasswordChange.path} element={<PasswordChange />} />
                        <Route path={RoutesHelper.views.ForgotPassword.path} element={<ForgotPassword />} />
                        <Route path={'*'} element={<Login />} />
                    </>
                )}
            </Routes>
        </BrowserRouter>
    )
}

const AEAZoneRedirect = () => {
    return <Navigate to={RoutesHelper.views.AEA.Home.to()} />
}

export default GlobalComponentWrapper
