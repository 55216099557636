import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React, { Fragment, ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import Breadcrumb, { BreadcrumbSegment } from '../../matx/components/Breadcrumb'
import { HistoryRoute } from '../helpers/RoutesHelper'
import { useNavContext } from '../hooks/useNavContext'

export type BreacrumbGetter<ElementType> = (element: ElementType) => BreadcrumbSegment[]

type CustomTabsProps<ElementType, TabName extends string> = {
    activeTab: TabName
    breadcrumbSegments: BreadcrumbSegment[]
    tabs: CustomTab<TabName>[]
    headerRightElement?: JSX.Element
}

export type CustomTab<TabName extends string> = {
    label: string
    id: TabName
    count?: number
    children: ReactElement
    to: HistoryRoute
}

const CustomTabs = <ElementType, TabName extends string>({
    tabs,
    activeTab,
    breadcrumbSegments,
    headerRightElement,
}: CustomTabsProps<ElementType, TabName>) => {
    const { getCustomerRoute } = useNavContext()
    const customerHomePath = getCustomerRoute().pathname
    const navigate = useNavigate()

    const tabIndex = tabs.findIndex((tab) => tab.id === activeTab)

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const tab = tabs[newValue]
        navigate(tab.to)
    }

    const currentTab = tabs[tabIndex]

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Grid container alignItems="center">
                    <Grid item style={{ flexGrow: 1 }}>
                        <Breadcrumb homePath={customerHomePath} routeSegments={breadcrumbSegments} />
                    </Grid>
                    {headerRightElement && <Grid item>{headerRightElement}</Grid>}
                </Grid>
            </div>
            <Tabs
                className="mt-4"
                value={tabIndex}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto">
                {tabs.map((tab, ind) => (
                    <Tab
                        className="capitalize"
                        value={ind}
                        label={`${tab.label}${tab.count !== undefined ? ` (${tab.count})` : ''}`}
                        key={ind}
                    />
                ))}
            </Tabs>
            <Divider className="mb-6" />
            {tabs.map((tab) => {
                return <Fragment key={tab.id}>{currentTab.id === tab.id ? tab.children : null}</Fragment>
            })}
        </div>
    )
}

export default CustomTabs
