import { gql } from '@apollo/client/core'

export default gql`
    mutation UpdateEnterpriseChildren($enterpriseId: ID!, $childIds: [ID!]!) {
        updateEnterpriseChildren(input: { enterpriseId: $enterpriseId, childIds: $childIds }) {
            errors
            status
            statusCode
            enterprise {
                id
                parents {
                    id
                    title
                }
            }
        }
    }
`
