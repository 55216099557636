import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment } from 'react'
import { EnumUser } from '../../../graphql/typings/global_types'
import IconHelper from '../../helpers/IconHelper'
import { useAuthUser } from '../../hooks/useAuth'
import DataTable from '../DataTable'
import IconText from '../IconText'
import Panel from '../Panel'
import { FormattedDoc } from './DocumentsList'
import DocumentListDelete from './DocumentsListActions/DocumentListDelete'
import DocumentListDownload from './DocumentsListActions/DocumentListDownload'
import DocumentListEdit from './DocumentsListActions/DocumentListEdit'

type DocumentsConfirmedProps = {
    documents: FormattedDoc[]
    addDoc: () => void
    showLink: boolean
}

const DocumentsConfirmed = ({ documents, addDoc, showLink }: DocumentsConfirmedProps) => {
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Nom',
        },
        ...(showLink
            ? [
                  {
                      name: 'linkedTo',
                      label: 'Associé à',
                      options: {
                          customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                              const document = documents[dataIndex]

                              return document.linkedToIcon ? (
                                  <IconText icon={document.linkedToIcon} text={document.linkedTo} side="left" />
                              ) : (
                                  document.linkedTo
                              )
                          },
                      },
                  },
              ]
            : []),
        {
            name: 'category',
            label: 'Type',
        },
        {
            name: 'statusComment',
            label: 'Commentaire',
        },
    ]

    const activeDocsActionsColumn = (dataIndex: number, rowIndex: number) => {
        const document = documents[dataIndex].document

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <DocumentListDownload document={document} />
                {isAdmin && (
                    <Fragment>
                        <DocumentListEdit document={document} />
                        <DocumentListDelete document={document} />
                    </Fragment>
                )}
            </div>
        )
    }

    const addFeatureData = isAdmin
        ? {
              addHandler: () => {
                  addDoc()
              },
              tooltip: `Ajout d'un document`,
          }
        : undefined

    return (
        <Panel>
            <DataTable<FormattedDoc>
                title={`Documents confirmés`}
                icon={IconHelper.elementIcons.docsIcon}
                data={documents}
                columns={columns}
                showSearch={true}
                actionsColumn={activeDocsActionsColumn}
                addFeature={addFeatureData}
                options={{
                    filter: true,
                    filterType: 'multiselect',
                }}
            />
        </Panel>
    )
}

export default DocumentsConfirmed
