import { makeStyles, useTheme } from '@material-ui/core/styles'
import React from 'react'
import { Navigation } from '../../../../app/hooks/useNavigation'
import useSettings from '../../../../app/hooks/useSettings'
import { convertHexToRGB } from '../../../../utils'
import { CustomerBrand } from '../../MatxVerticalNav/MatxVerticalNav'
import Brand from '../SharedCompoents/Brand'
import Sidenav from '../SharedCompoents/Sidenav'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    sidenav: ({ width, primaryRGB, bgImgURL }: { width: number | string; primaryRGB: string; bgImgURL?: string }) => ({
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: width,
        boxShadow: theme.shadows[8],
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        zIndex: 111,
        overflow: 'hidden',
        color: palette.text.primary,
        transition: 'all 250ms ease-in-out',
        backgroundColor: `rgba(${primaryRGB}, 0.96)`,
        // backgroundImage: `linear-gradient(to bottom, rgba(${primaryRGB}, 0.96), rgba(${primaryRGB}, 0.96)), url(${bgImgURL})`,
        // '&:hover': {
        //     width: 'var(--sidenav-width)',
        //     '& .sidenavHoverShow': {
        //         display: 'block',
        //     },
        //     '& .compactNavItem': {
        //         width: '100%',
        //         maxWidth: '100%',
        //         '& .nav-bullet': {
        //             display: 'block',
        //         },
        //         '& .nav-bullet-text': {
        //             display: 'none',
        //         },
        //     },
        // },
    }),
    hideOnCompact: {
        display: 'none',
    },
    userInfo: {},
}))

type Layout1SidenavProps = {
    navigation: Navigation[]
}

const Layout1Sidenav = (props: Layout1SidenavProps) => {
    const theme = useTheme()

    const { settings, updateSettings } = useSettings()

    const leftSidebar = settings.layout1Settings.leftSidebar
    const { mode } = leftSidebar

    const getSidenavWidth = () => {
        switch (mode) {
            case 'compact':
                return 'var(--sidenav-compact-width)'
            default:
                return 'var(--sidenav-width)'
        }
    }

    const primaryRGB = convertHexToRGB(theme.palette.primary.main)
    const classes = useStyles({
        ...leftSidebar,
        width: getSidenavWidth(),
        primaryRGB,
    })

    return (
        <div className={classes.sidenav}>
            <div className="flex-column relative h-full">
                <Brand />
                <Sidenav navigation={props.navigation}>
                    <CustomerBrand />
                </Sidenav>
            </div>
        </div>
    )
}

export default React.memo(Layout1Sidenav)
