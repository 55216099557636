import {
    ContractGeneric_contract,
    ContractGeneric_contract_guarantees,
    ContractGeneric_contract_guarantees_guaranteeCategory
} from '../../graphql/queries/typings/ContractGeneric'
import { InsuranceGeneric_insurance } from '../../graphql/queries/typings/InsuranceGeneric'
import { uniqMergeId } from '../../utilsTs'
import { GuaranteeItems } from './data/models/contract/Guarantee'

export type ContractForLabel = Pick<ContractGeneric_contract, 'title'> & {
    insurance: Pick<InsuranceGeneric_insurance, 'title'>
}

const getContractLabel = (contract: ContractForLabel): string => {
    return `${contract.title} - ${contract.insurance.title}`
}

type ContractForCoveredElements = {
    guarantees: GuaranteeItems[]
}

const getContractCoveredElements = (contract: ContractForCoveredElements): GuaranteeItems => {
    const elements: GuaranteeItems = {
        enterprises: [],
        sites: [],
        places: [],
        goods: [],
        fleets: [],
        vehicles: [],
    }

    contract.guarantees.forEach((guarantee) => {
        elements.enterprises = uniqMergeId(elements.enterprises, guarantee.enterprises)
        elements.sites = uniqMergeId(elements.sites, guarantee.sites)
        elements.places = uniqMergeId(elements.places, guarantee.places)
        elements.goods = uniqMergeId(elements.goods, guarantee.goods)
        elements.fleets = uniqMergeId(elements.fleets, guarantee.fleets)
        elements.vehicles = uniqMergeId(elements.vehicles, guarantee.vehicles)
    })

    return elements
}

export type ContractForGuaranteeNames = Pick<ContractGeneric_contract, 'id'> & {
    guarantees: Array<
        Pick<ContractGeneric_contract_guarantees, 'id'> & {
            guaranteeCategory: Pick<ContractGeneric_contract_guarantees_guaranteeCategory, 'id' | 'title'>
        }
    >
}

const getContractGuaranteesNamesAsList = (contract: ContractForGuaranteeNames) => {
    const guarantees = contract.guarantees.map((guarantee) => guarantee.guaranteeCategory.title)

    return guarantees
}

const ContractHelper = {
    getContractLabel,
    getContractCoveredElements,
    getContractGuaranteesNamesAsList,
}

export default ContractHelper
