import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.Disaster_Full}
    mutation UpdateDisaster(
        $id: ID!
        $title: String!
        $contractId: ID!
        $eventId: ID
        $disasterCategoryId: ID!
        $status: EnumDisasterStatus!
        $date: String!
        $percentage: Float
        $billingDate: String
        $amount: Float
        $internalReference: String
        $insuranceReference: String
        $comment: String
    ) {
        updateDisaster(
            input: {
                id: $id
                title: $title
                contractId: $contractId
                eventId: $eventId
                disasterCategoryId: $disasterCategoryId
                status: $status
                occurredAt: $date
                percentage: $percentage
                billingDate: $billingDate
                amount: $amount
                internalReference: $internalReference
                insuranceReference: $insuranceReference
                comment: $comment
            }
        ) {
            errors
            status
            statusCode
            disaster {
                ...Disaster_Full
            }
        }
    }
`
