import { Fragment, useState } from 'react'
import { GetContracts_contracts } from '../../../../graphql/queries/typings/GetContracts'
import { GetDisastersIndicators_disasterIndicators } from '../../../../graphql/queries/typings/GetDisastersIndicators'
import { EnumDisasterThrough } from '../../../../graphql/typings/global_types'
import DateHelper from '../../../helpers/DateHelper'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDisastersIndicator } from '../../../hooks/useDisasters'
import DateCategoryInput, { DateRange } from '../../DateCategoryInput'
import DisastersList from '../../disaster/DisastersList'
import ErrorView from '../../ErrorView'
import LoadingDisplay from '../../LoadingDisplay'
import Panel from '../../Panel'
import DisastersIndicators from './DisastersIndicators'
import DisastersPayments from './DisastersPayments'

type DisasterProps = {
    contracts: GetContracts_contracts[]
}

const defaultDateRange: DateRange = {
    startDate: null,
    endDate: null,
}

const Disasters = ({ contracts }: DisasterProps) => {
    const { customer } = useCustomerLoadedExisting()
    const [dateRange, setDateRange] = useState<DateRange>(defaultDateRange)
    const { fromDateToServer } = DateHelper()

    const onDateRangeChange = (startDate: Date | null, endDate: Date | null) => {
        setDateRange({
            startDate,
            endDate,
        })
    }

    const queryResult = useDisastersIndicator({
        limit: 1000,
        offset: 0,
        projectID: customer.id,
        startDate: dateRange.startDate ? fromDateToServer(dateRange.startDate) : null,
        endDate: dateRange.endDate ? fromDateToServer(dateRange.endDate) : null,
        through: EnumDisasterThrough.through_all,
    })

    const element = queryResult.disastersIndicatorsLoading ? (
        <LoadingDisplay />
    ) : queryResult.disastersIndicators === null ? (
        <ErrorView message="Erreur lors du chargement des sinistres." debug={queryResult} />
    ) : (
        <DisastersReady
            dateRange={dateRange}
            disastersIndicators={queryResult.disastersIndicators}
            contracts={contracts}
        />
    )

    return (
        <Fragment>
            <DateCategoryInput onDateRangeChange={onDateRangeChange} />
            {element}
        </Fragment>
    )
}

type DisasterReadyProps = {
    dateRange: DateRange
    disastersIndicators: GetDisastersIndicators_disasterIndicators
    contracts: GetContracts_contracts[]
}

const DisastersReady = ({ dateRange, disastersIndicators, contracts }: DisasterReadyProps) => {
    return (
        <Fragment>
            <DisastersIndicators disastersIndicators={disastersIndicators} />
            <div className="mb-8" />
            <DisastersList disasters={disastersIndicators.disastersList} contracts={contracts} dateRange={dateRange} />
            <Panel>
                <DisastersPayments
                    disasterPayments={disastersIndicators.disasterPaymentsList}
                    amountNotPaid={disastersIndicators.pendingIndemnitiesAmount}
                    dateRange={dateRange}
                />
            </Panel>
        </Fragment>
    )
}

export default Disasters
