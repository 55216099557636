import { IconButton, Icon } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { useState } from 'react'
import useSnackBar from '../../../hooks/useSnackBar'
import DocumentEdit, { DocumentEditMode, DocumentForEdit } from '../DocumentForms/Admin/DocumentEdit/DocumentEdit'

type DocumentListValidateProps = {
    document: DocumentForEdit
}

const DocumentListValidate = ({ document }: DocumentListValidateProps) => {
    const { openSnack } = useSnackBar()

    const [openValidateDialog, setOpenValidateDialog] = useState<boolean>(false)

    const validateDoc = () => {
        setOpenValidateDialog(true)
    }

    const onValidated = () => {
        openSnack({
            type: 'success',
            message: 'Document validé avec succès !',
        })
    }

    return (
        <>
            <Tooltip title="Valider le document">
                <IconButton onClick={validateDoc} style={{ color: 'green' }}>
                    <Icon>done</Icon>
                </IconButton>
            </Tooltip>
            <DocumentEdit
                onDone={onValidated}
                document={document}
                open={openValidateDialog}
                closeDialog={() => setOpenValidateDialog(false)}
                mode={DocumentEditMode.VALIDATING}
            />
        </>
    )
}

export default DocumentListValidate
