import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { Fragment, ReactElement } from 'react'
import MUIDialog from '@material-ui/core/Dialog'
import CustomButton from './CustomButton'
import { ThemeProvider } from '@material-ui/core/styles'
import useSettings from '../hooks/useSettings'
import { DialogProps } from '@material-ui/core/Dialog'

type AlertDialogProps = {
    open: boolean
    title: string
    text: string
    onClose: () => void
}

const AlertDialog = (props: AlertDialogProps): ReactElement => {
    return <PreFormattedDialog type="alert" {...props} />
}

type ConfirmDialogProps = {
    open: boolean
    title: string
    text: string
    onConfirmed: () => void
    onCancelled: () => void
    loading?: boolean
}

const ConfirmDialog = (props: ConfirmDialogProps): ReactElement => {
    return <PreFormattedDialog type="confirm" {...props} />
}

type PreFormattedDialogProps = {
    open: boolean
    title: string
    text: string
    loading?: boolean
} & (
    | {
          type: 'confirm'
          onConfirmed: () => void
          onCancelled: () => void
      }
    | {
          type: 'alert'
          onClose: () => void
      }
)

const PreFormattedDialog = ({ open, title, text, ...props }: PreFormattedDialogProps) => {
    return (
        <Dialog
            open={open}
            onClose={props.type === 'confirm' ? props.onCancelled : props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.type === 'confirm' && (
                    <Fragment>
                        <Button onClick={props.onCancelled} color="secondary" autoFocus>
                            Annuler
                        </Button>
                        <CustomButton onClick={props.onConfirmed} color="primary" loading={props.loading}>
                            Confirmer
                        </CustomButton>
                    </Fragment>
                )}
                {props.type === 'alert' && (
                    <Button onClick={props.onClose} color="primary" autoFocus>
                        Compris
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

const Dialog = (props: DialogProps) => {
    const { settings } = useSettings()

    return (
        <ThemeProvider theme={settings.themes[settings.activeTheme]}>
            <MUIDialog {...props} fullWidth />
        </ThemeProvider>
    )
}

export { Dialog, AlertDialog, ConfirmDialog }
