import React, { Fragment } from 'react'
import AEABrokersList from './AEABrokersList'
import AEAInsurancesList from './AEAInsurancesList'
import AEAInsurersList from './AEAInsurersList'

const AEAInsurances = () => {
    return (
        <Fragment>
            <div className="m-sm-30">
                <h3 className="m-0 mb-6">Liste des assurances, agents et courtiers</h3>
                <AEAInsurancesList />
                <AEAInsurersList />
                <AEABrokersList />
            </div>
        </Fragment>
    )
}

export default AEAInsurances
