import { Button, DialogActions } from '@material-ui/core'
import { Dispatch, SetStateAction, useEffect } from 'react'
import FileDialogHelper, { FileType } from '../../../../../hooks/useFileDialog'
import Spacer from '../../../../Spacer'
import DocumentUpload from '../../DocumentUpload'
import { DocumentEditData, DocumentForEdit } from './DocumentEdit'

type DocumentEditFileProps = {
    data: DocumentEditData
    document: DocumentForEdit
    setData: Dispatch<SetStateAction<DocumentEditData>>
    closeDialog: () => void
    goNext: () => void
}

const DocumentEditFile = ({ data, document, setData, closeDialog, goNext }: DocumentEditFileProps) => {
    const [dialogPickerNode, openDialogPicker, localFile, deleteLocalFile] = FileDialogHelper.useFileDialog(
        FileType.ALL
    )

    useEffect(() => {
        if (localFile) {
            setData((oldData) => {
                return { ...oldData, newFile: localFile }
            })
        }
    }, [localFile])

    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <DocumentUpload
                    loading={false}
                    uploadPercent={0}
                    localFile={localFile}
                    openFilePicker={openDialogPicker}
                    hasAlreadyFileUploaded={!!document.activeUrl}
                    deleteFile={deleteLocalFile}
                />
                {dialogPickerNode}
            </div>
            <Spacer />
            <DialogActions>
                <Button color="secondary" onClick={closeDialog}>
                    Annuler
                </Button>
                <Spacer size={1} />
                <Button color="primary" variant="contained" onClick={goNext}>
                    Suivant
                </Button>
            </DialogActions>
        </>
    )
}

export default DocumentEditFile
