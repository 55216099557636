import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { useEffect, useState } from 'react'
import { client } from '../../../../../../graphql/client'
import { UpdateVehicle_updateVehicle_vehicle } from '../../../../../../graphql/mutations/typings/UpdateVehicle'
import queries from '../../../../../../graphql/queries'
import { EnterpriseGeneric_enterprise } from '../../../../../../graphql/queries/typings/EnterpriseGeneric'
import { FleetGeneric_fleet } from '../../../../../../graphql/queries/typings/FleetGeneric'
import {
    GetFleets_Minimal,
    GetFleets_MinimalVariables,
} from '../../../../../../graphql/queries/typings/GetFleets_Minimal'
import { VehicleGeneric_vehicle } from '../../../../../../graphql/queries/typings/VehicleGeneric'
import { isStringInputValid } from '../../../../../../utilsTs'
import { Fleet_Minimal } from '../../../../../helpers/data/models/fleet/Fleet'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useCreateVehicle, useUpdateVehicle } from '../../../../../hooks/useMutations'
import CustomButton from '../../../../CustomButton'
import { Dialog } from '../../../../Dialog'

type VehicleForDialog = Pick<VehicleGeneric_vehicle, 'id' | 'title' | 'description' | 'registration'> & {
    enterprise: Pick<EnterpriseGeneric_enterprise, 'id'>
    fleet: Pick<FleetGeneric_fleet, 'id'> | null
}

type VehicleDialogProps = {
    open: boolean
    closeDialog: () => void
    onDone: (vehicle: UpdateVehicle_updateVehicle_vehicle) => void
    vehicleEnterpriseId?: string
    vehicle?: VehicleForDialog
}

type VehicleData = {
    title: string | null
    fleetId: string
    description: string | null
    registration: string | null
    enterpriseId: string | null
}

const emptyFleetString = 'none'

const emptyVehicle: VehicleData = {
    title: null,
    fleetId: emptyFleetString,
    description: null,
    registration: null,
    enterpriseId: null,
}

const VehicleDialog = ({ onDone, open, closeDialog, vehicle, vehicleEnterpriseId }: VehicleDialogProps) => {
    const [data, setData] = useState<VehicleData>(getFirstData())
    const [loading, setLoading] = useState<boolean>(false)
    const [fleets, setFleets] = useState<Fleet_Minimal[] | null>(null)
    const [fleetsLoading, setFleetsLoading] = useState<boolean>(false)

    const createVehicleMutation = useCreateVehicle()
    const updateVehicleMutation = useUpdateVehicle()

    const { errorAlert } = useErrorService()
    const { customer } = useCustomerLoadedExisting()

    function getFirstData(): VehicleData {
        const firstData = vehicle
            ? {
                  title: vehicle.title,
                  fleetId: vehicle.fleet ? vehicle.fleet.id : emptyFleetString,
                  description: vehicle.description,
                  registration: vehicle.registration,
                  enterpriseId: vehicle.enterprise.id,
              }
            : {
                  ...emptyVehicle,
                  enterpriseId: vehicleEnterpriseId || null,
              }

        return firstData
    }

    useEffect(() => {
        if (data.enterpriseId === null) {
            setFleets(null)
            return
        }

        // We load the fleets every time the data.enterpriseId changes
        loadEnterpriseFleets(data.enterpriseId)
    }, [open, data.enterpriseId])

    const handleClose = () => {
        setData(getFirstData())
        closeDialog()
    }

    const handleChange = (value: string, prop: 'title' | 'registration' | 'description' | 'enterpriseId') => {
        setData((oldData) => {
            return {
                ...oldData,
                [prop]: value,
            }
        })
    }

    const loadEnterpriseFleets = async (enterpriseId: string) => {
        setFleetsLoading(true)
        setFleets(null)

        try {
            const fleetsResponse = await client.query<GetFleets_Minimal, GetFleets_MinimalVariables>({
                query: queries.GetFleets_Minimal,
                variables: { enterpriseId, customerId: customer.id },
            })

            setFleets(fleetsResponse.data.fleets)
            setFleetsLoading(false)
        } catch (error) {
            errorAlert("Une erreur est survenue lors de la récupération des flottes de l'entreprise", error)
        }
    }

    const handleFleetChange = (value: string) => {
        setData((oldData) => {
            return {
                ...oldData,
                fleetId: value,
            }
        })
    }

    const handleSubmit = async () => {
        if (!data.title) return
        if (!data.registration) return
        if (!data.enterpriseId) return

        setLoading(true)

        try {
            if (vehicle) {
                //Update
                const response = await updateVehicleMutation.run({
                    id: vehicle.id,
                    enterpriseId: data.enterpriseId,
                    title: data.title,
                    fleetId: data.fleetId,
                    description: data.description,
                    registration: data.registration,
                })

                if (response.vehicle) {
                    onDone(response.vehicle)
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour du véhicule'
                }
            } else {
                const response = await createVehicleMutation.run({
                    enterpriseId: data.enterpriseId,
                    title: data.title,
                    fleetId: data.fleetId,
                    description: data.description,
                    registration: data.registration,
                    customerId: customer.id,
                })

                if (response.vehicle) {
                    onDone(response.vehicle)
                } else {
                    throw 'Une erreur est survenue pendant la création du véhicule'
                }

                setData(emptyVehicle)
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid =
        isStringInputValid(data.title) && isStringInputValid(data.registration) && isStringInputValid(data.enterpriseId)

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle>Saisie d'un véhicule</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Libellé *"
                    type="text"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    id="type"
                    label={`Entreprise propriétaire`}
                    type="text"
                    fullWidth
                    value={data.enterpriseId || ''}
                    onChange={(event) => handleChange(event.target.value, 'enterpriseId')}
                    select>
                    {[...customer.enterprises]
                        .sort((enterpriseA, enterpriseB) => {
                            return enterpriseA.title.localeCompare(enterpriseB.title)
                        })
                        .map((enterprise) => {
                            return (
                                <MenuItem value={enterprise.id} key={enterprise.id}>
                                    {enterprise.title}
                                </MenuItem>
                            )
                        })}
                </TextField>
                <TextField
                    margin="dense"
                    id="registration"
                    label="Immatriculation *"
                    type="text"
                    fullWidth
                    value={data.registration || ''}
                    onChange={(event) => handleChange(event.target.value, 'registration')}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Commentaire"
                    type="text"
                    fullWidth
                    value={data.description || ''}
                    onChange={(event) => handleChange(event.target.value, 'description')}
                />
                <TextField
                    className="mb-5"
                    margin="dense"
                    id="fleet"
                    label={
                        fleetsLoading
                            ? 'Chargement . . .'
                            : fleets
                            ? 'Associer à une flotte ?'
                            : 'Sélectionnez une entreprise'
                    }
                    type="text"
                    fullWidth
                    value={fleets ? data.fleetId : emptyFleetString}
                    onChange={(event) => handleFleetChange(event.target.value)}
                    select
                    disabled={!fleets || fleetsLoading || fleets.length === 0}>
                    <MenuItem value={emptyFleetString}>Aucune</MenuItem>
                    {fleets &&
                        [...fleets]
                            .sort((fleetA, fleetB) => {
                                return fleetA.title.localeCompare(fleetB.title)
                            })
                            .map((fleet) => {
                                return (
                                    <MenuItem value={fleet.id} key={fleet.id}>
                                        {fleet.title}
                                    </MenuItem>
                                )
                            })}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default VehicleDialog
