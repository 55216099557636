import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Prevention.Prevention_Minimal}
    mutation CreatePrevention(
        $preventionCategoryId: ID!
        $placeId: ID!
        $description: String
        $startAt: String!
        $endAt: String!
        $documentInput: DocumentInput
    ) {
        createPrevention(
            input: {
                preventionCategoryId: $preventionCategoryId
                localId: $placeId
                description: $description
                startAt: $startAt
                endAt: $endAt
                documentInput: $documentInput
            }
        ) {
            errors
            status
            statusCode
            prevention {
                ...Prevention_Minimal
            }
        }
    }
`
