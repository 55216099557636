import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import { CustomerDashboardData } from '../../../helpers/data/models/CustomerDashboardData'
import IconHelper from '../../../helpers/IconHelper'
import { HistoryRoute } from '../../../helpers/RoutesHelper'
import useNavContext from '../../../hooks/useNavContext'
import MoneyDisplayAnalyzer from '../../customInputs/MoneyDisplayAnalyzer'
import ElevatedCard from '../../ElevatedCard'
import { useNavigate } from 'react-router-dom'

type CustomerStatsProps = {
    dashboardData: CustomerDashboardData
}

type CardDataProps = {
    icon: string
    value: string
    label: string
    color: 'primary' | 'secondary'
    clickRoute: HistoryRoute
    isCurrency: boolean
}

const CustomerStats = ({ dashboardData }: CustomerStatsProps) => {
    const { getContractsRoute, getPlacesRoute, getDisastersRoute } = useNavContext()
    const navigate = useNavigate()

    const cardData: CardDataProps[] = [
        {
            value: `${dashboardData.nbContracts}`,
            label: 'Contrats en cours',
            color: 'primary',
            icon: IconHelper.elementIcons.contractIcon,
            clickRoute: getContractsRoute(),
            isCurrency: false,
        },
        {
            value: `${dashboardData.nbLocalsLinkedToAContract}`,
            label: 'Locaux assurés',
            color: 'primary',
            icon: IconHelper.elementIcons.placeIcon,
            clickRoute: getPlacesRoute(),
            isCurrency: false,
        },
        {
            value: `${dashboardData.nbDisasters}`,
            label: 'Total des sinistres',
            color: 'primary',
            icon: IconHelper.elementIcons.disasterIcon,
            clickRoute: getDisastersRoute(),
            isCurrency: false,
        },
        {
            value: `${dashboardData.nbOpenedDisasters}`,
            label: 'Sinistres en cours',
            color: 'secondary',
            icon: IconHelper.elementIcons.disasterIcon,
            clickRoute: getDisastersRoute(),
            isCurrency: false,
        },
        {
            value: `${dashboardData.pendingIndemnitiesAmount}`,
            label: 'Indemnités actuellement en attente',
            color: 'secondary',
            icon: IconHelper.elementIcons.disasterIndemnitiesIcon,
            clickRoute: getDisastersRoute(),
            isCurrency: true,
        },
        {
            value: `${dashboardData.contractsBountyAmount}`,
            label: "Montant des primes d'assurance en cours",
            color: 'secondary',
            icon: IconHelper.elementIcons.contractIcon,
            clickRoute: getContractsRoute(),
            isCurrency: true,
        },
    ]

    return (
        <Grid container spacing={4} justifyContent="center">
            {cardData.map((data, index) => {
                return (
                    <Grid item xs={6} sm={6} md={4} lg={4} key={index}>
                        <ElevatedCard style={{ height: '100%' }} onClick={() => navigate(data.clickRoute)}>
                            <div
                                className="p-4 flex-column items-center text-center"
                                style={{
                                    height: '100%',
                                    justifyContent: 'space-around',
                                }}>
                                <div className="mb-2">
                                    <Icon color={data.color} fontSize="large">
                                        {data.icon}
                                    </Icon>
                                </div>
                                <div>
                                    <h3 className="text-24">
                                        {data.isCurrency ? <MoneyDisplayAnalyzer value={data.value} /> : data.value}
                                    </h3>
                                </div>
                                <div>
                                    <p className="mb-0 mt-2 text-muted font-normal">{data.label}</p>
                                </div>
                            </div>
                        </ElevatedCard>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default CustomerStats
