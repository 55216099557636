import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Broker.Broker_Minimal}
    mutation UpdateBroker($id: ID!, $title: String!, $phone: String, $email: String) {
        updateBroker(input: { id: $id, title: $title, phone: $phone, email: $email }) {
            errors
            status
            statusCode
            broker {
                ...Broker_Minimal
            }
        }
    }
`
