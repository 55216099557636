import { gql } from '@apollo/client/core'

export default gql`
    mutation UpdateCustomer($id: ID!, $title: String!, $supervisorId: ID!) {
        updateProject(input: { id: $id, title: $title, supervisorId: $supervisorId }) {
            errors
            status
            statusCode
            customer: project {
                id
                title
                supervisor {
                    id
                    firstname
                    lastname
                    email
                    userType
                    phone
                    avatar: urlToUserAvatar
                }
            }
        }
    }
`
