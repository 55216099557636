import { gql } from "@apollo/client/core";
import DisasterCategory from '../fragments/Disaster/DisasterCategory'

export default gql`
    ${DisasterCategory}
    query GetDisasterCategories {
        disasterCategories(limit: 200) {
            ...DisasterCategory
        }
    }
`
