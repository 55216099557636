import { EnterpriseGeneric_enterprise } from '../../graphql/queries/typings/EnterpriseGeneric'
import { EnumUser } from '../../graphql/typings/global_types'

const getFullName = (firstname: string, lastname: string | null) => {
    return `${firstname} ${lastname}`
}

type UserForAvatar =
    | {
          userType: EnumUser.admin
          avatar: string | null
      }
    | {
          userType: EnumUser.user
      }

const getAvatar = (user: UserForAvatar) => {
    if (user.userType === EnumUser.admin) return user.avatar || undefined
    else return undefined
}

type CustomerEnterprises = {
    enterprises: Pick<EnterpriseGeneric_enterprise, 'id'>[]
}

type EnterpriseForRestricted = {
    id: string
    title: string
}

type UserForEnterprises = {
    id: string
    restrictedToEnterprises: EnterpriseForRestricted[]
}

// Because the user may be (...) linked to other projects
function getCustomerRestrictedEnterprisesIds(user: UserForEnterprises, customer: CustomerEnterprises): string[] {
    return getCustomerRestrictedEnterprises(user, customer).map((enterprise) => enterprise.id)
}

// Because the user may be (...) linked to other projects
function getCustomerRestrictedEnterprises(
    user: UserForEnterprises,
    customer: CustomerEnterprises
): EnterpriseForRestricted[] {
    const customerEnterprisesIds = customer.enterprises.map((enterprise) => enterprise.id)
    const userEnterprises = user.restrictedToEnterprises

    return userEnterprises.filter((enterprise) => customerEnterprisesIds.includes(enterprise.id))
}

const UserHelper = {
    getFullName,
    getAvatar,
    getCustomerRestrictedEnterprises,
    getCustomerRestrictedEnterprisesIds,
}

export default UserHelper
