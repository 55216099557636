import { gql } from "@apollo/client/core";

export default gql`
    fragment Broker_Minimal on Broker {
        id
        title
        email
        phone
    }
`
