import { gql } from '@apollo/client/core'
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.Disaster_Minimal}
    query GetDisastersIndicators(
        $limit: Int!
        $offset: Int!
        $projectID: ID!
        $enterpriseId: ID
        $categoryIds: [ID!]
        $through: EnumDisasterThrough
        $startDate: String
        $endDate: String
        $statuses: [EnumDisasterStatus!]
    ) {
        disasterIndicators(
            limit: $limit
            offset: $offset
            projectId: $projectID
            enterpriseId: $enterpriseId
            categoryIds: $categoryIds
            through: $through
            startDate: $startDate
            endDate: $endDate
            statuses: $statuses
        ) {
            disasterPaymentsList {
                id
                paid_at: paidAt
                amount
                description
                disaster {
                    id
                    title
                }
            }
            disasterPaymentsPaidAmount
            disastersList {
                ...Disaster_Minimal
            }
            nbDisasters
            pendingIndemnitiesAmount
        }
    }
`
