import { gql } from '@apollo/client/core'

export default gql`
    fragment Good_Minimal on Good {
        id
        title
        enterprise {
            id
            address
            title
        }
        place: local {
            id
        }
    }
`
