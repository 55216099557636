import { useParams } from 'react-router'
import LoadingDisplay from '../../../components/LoadingDisplay'
import Place, { PlaceTabName } from '../../../components/customer/places/place/Place'
import ErrorDisplay from '../../../helpers/errors/ErrorDisplay'
import usePlace, { ProvidePlace } from '../../../hooks/usePlace'

const CustomerPlace = () => {
    const { placeId, tab } = useParams()

    return placeId !== undefined ? (
        <ProvidePlace placeId={placeId}>
            <CustomerPlaceLoading tab={tab ? (tab as PlaceTabName) : undefined} />
        </ProvidePlace>
    ) : (
        <ErrorDisplay message="Adresse incorrecte" />
    )
}

const CustomerPlaceLoading = (props: CustomerPlaceReadyProps) => {
    const { placeLoading, place, error } = usePlace()

    if (placeLoading) return <LoadingDisplay />
    else if (error) {
        return <ErrorDisplay message="Erreur lors du chargement du local" queryError={error} debug={error} />
    } else if (!place) {
        return <ErrorDisplay message="Erreur lors du chargement du local" />
    } else return <CustomerPlaceReady {...props} />
}

type CustomerPlaceReadyProps = {
    tab?: PlaceTabName
}

const CustomerPlaceReady = (props: CustomerPlaceReadyProps) => {
    return <Place {...props} />
}

export default CustomerPlace
