import Agreement from './Agreement'
import Broker from './Broker'
import Contract from './Contract'
import Disaster from './Disaster'
import Document from './Document'
import Enterprise from './Enterprise'
import Fleet from './Fleet'
import Good from './Good'
import Guarantee from './Guarantee'
import Insurance from './Insurance'
import Insurer from './Insurer'
import Place from './Place'
import Prevention from './Prevention'
import RoadmapItem from './RoadmapItem'
import Site from './Site'
import User from './User'
import Vehicle from './Vehicle'

const Fragments = {
    Contract,
    User,
    Guarantee,
    Broker,
    Insurer,
    Insurance,
    Enterprise,
    Document,
    Site,
    Place,
    Good,
    Fleet,
    Vehicle,
    Disaster,
    Prevention,
    RoadmapItem,
    Agreement,
}

export default Fragments
