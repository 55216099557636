import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import { GetPlace, GetPlaceVariables, GetPlace_place } from '../../../graphql/queries/typings/GetPlace'
import { GetPlace_Documents, GetPlace_DocumentsVariables } from '../../../graphql/queries/typings/GetPlace_Documents'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'
import { useErrorService } from '../errors/ErrorService'
import { Place_Documents } from './models/Place'

type PlaceData = {
    error?: QueryError
    place: GetPlace_place | null
    placeLoading: boolean
}

export const useGetPlace = (placeId: string): PlaceData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetPlace, GetPlaceVariables>(queries.GetPlace, {
        variables: { placeID: placeId },
    })

    const errorMessage = 'Erreur lors de la récupération du local'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const place = responseData && responseData.place ? responseData.place : null

        return {
            error,
            place,
            placeLoading: false,
        }
    } else {
        return {
            error,
            place: null,
            placeLoading: true,
        }
    }
}

type PlaceDocumentsData = {
    error?: QueryError
    place: Place_Documents | null
    placeLoading: boolean
}

export const useGetPlaceDocuments = (placeId: string): PlaceDocumentsData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetPlace_Documents, GetPlace_DocumentsVariables>(queries.GetPlace_Documents, {
        variables: { placeId },
    })

    const errorMessage = 'Erreur lors de la récupération du local'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const place = responseData && responseData.local ? responseData.local : null

        return {
            error,
            place,
            placeLoading: false,
        }
    } else {
        return {
            error,
            place: null,
            placeLoading: true,
        }
    }
}

export default useGetPlace
