import { ThemeProvider, useTheme } from '@material-ui/core/styles'
import React, { ReactElement } from 'react'
import { NavType } from '../../../../../app/hooks/useNavigation'
import useSettings from '../../../../../app/hooks/useSettings'

type SidenavThemeProps = {
    children: ReactElement
    zone: NavType
}

const SidenavTheme = ({ children, zone }: SidenavThemeProps) => {
    const theme = useTheme()
    const { settings } = useSettings()
    const sidenavTheme =
        (zone === NavType.aea
            ? settings.themes[settings.layout1Settings.leftSidebar2.theme]
            : settings.themes[settings.layout1Settings.leftSidebar.theme]) || theme

    return <ThemeProvider theme={sidenavTheme}>{children}</ThemeProvider>
}

export default SidenavTheme
