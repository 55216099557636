import { useQuery } from '@apollo/client'
import { createContext, ReactElement, useContext } from 'react'
import queries from '../../graphql/queries'
import { GetDisasters_Minimal_disasters } from '../../graphql/queries/typings/GetDisasters_Minimal'
import {
    GetDisastersIndicators,
    GetDisastersIndicators_disasterIndicators,
    GetDisastersIndicatorsVariables,
} from '../../graphql/queries/typings/GetDisastersIndicators'
import useGetDisasters from '../helpers/data/getDisasters'
import ErrorDisplay from '../helpers/errors/ErrorDisplay'
import { QueryError } from '../helpers/errors/ErrorHelper'

type DisastersContext = {
    error?: QueryError
    disasters: GetDisasters_Minimal_disasters[] | null
    disastersLoading: boolean
}

const DisastersContext = createContext<DisastersContext>({
    disasters: null,
    disastersLoading: true,
})

type ProvideDisastersProps = {
    customerId: string
    children: ReactElement
}

export function ProvideDisasters({ children, customerId }: ProvideDisastersProps) {
    try {
        const disasterData = useProvideDisasters(customerId)

        return <DisastersContext.Provider value={disasterData}>{children}</DisastersContext.Provider>
    } catch (error) {
        return <ErrorDisplay message="Une erreur est survenue pendant la récupération des sinistres" debug={error} />
    }
}

export const useDisasters = () => {
    return useContext(DisastersContext)
}

export const useDisastersLoadedExisting = () => {
    const context = useContext(DisastersContext)
    if (context.disastersLoading) {
        console.error('Erreur : le sinistre est en cours de chargement')
        throw new Error('Erreur : le sinistre est en cours de chargement')
    }
    if (!context.disasters) {
        console.error(`Aucun sinistre trouvé avec cet identifiant !`)
        throw new Error(`Aucun sinistre trouvé avec cet identifiant !`)
    }

    return {
        disasters: context.disasters,
        disastersLoading: context.disastersLoading,
    }
}

// If no enterpriseId provided, we get all the customer's disasters
const useProvideDisasters = (customerId: string) => {
    const { disasters, disastersLoading, error } = useGetDisasters({ customerId })

    return {
        disasters,
        disastersLoading,
        error,
    }
}

type UseEnterpriseDisastersProps = {
    customerId: string
    enterpriseId: string
}

const useEnterpriseDisasters = ({ customerId, enterpriseId }: UseEnterpriseDisastersProps): DisastersContext => {
    const { disasters, disastersLoading } = useGetDisasters({ customerId, enterpriseId })

    return {
        disasters,
        disastersLoading,
    }
}

type DisastersIndicators = {
    disastersIndicators: GetDisastersIndicators_disasterIndicators | null
    disastersIndicatorsLoading: boolean
}

const useDisastersIndicator = (variables: GetDisastersIndicatorsVariables): DisastersIndicators => {
    const queryResult = useQuery<GetDisastersIndicators, GetDisastersIndicatorsVariables>(
        queries.GetDisastersIndicators,
        { variables }
    )

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const disastersIndicators =
            responseData && responseData.disasterIndicators ? responseData.disasterIndicators : null

        return {
            disastersIndicators,
            disastersIndicatorsLoading: false,
        }
    } else {
        return {
            disastersIndicators: null,
            disastersIndicatorsLoading: true,
        }
    }
}

export { useDisastersIndicator, useEnterpriseDisasters }
