import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import { GetCommonData } from '../../../graphql/queries/typings/GetCommonData'
import { useErrorService } from '../errors/ErrorService'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'

type CommonDataState = {
    error?: QueryError
    commonDataLoading: boolean
    commonData: GetCommonData | null
}

const useGetCommonData = (): CommonDataState => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetCommonData>(queries.GetCommonData)

    const errorMessage = 'Erreur lors de la récupération des données'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const commonData = responseData && responseData ? responseData : null

        return {
            error,
            commonData,
            commonDataLoading: false,
        }
    } else {
        return {
            error,
            commonData: null,
            commonDataLoading: true,
        }
    }
}

export default useGetCommonData
