import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import {
    GetGood_Documents,
    GetGood_DocumentsVariables,
    GetGood_Documents_good,
} from '../../../graphql/queries/typings/GetGood_Documents'
import { useErrorService } from '../errors/ErrorService'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'

type GoodData = {
    error?: QueryError
    good: GetGood_Documents_good | null
    goodLoading: boolean
}

type GetGoodDocumentsProps = {
    id: string
}

const useGetGoodDocuments = ({ id }: GetGoodDocumentsProps): GoodData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetGood_Documents, GetGood_DocumentsVariables>(queries.GetGood_Documents, {
        variables: {
            id,
        },
    })

    const errorMessage = 'Erreur lors de la récupération du bien'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const good = responseData && responseData.good ? responseData.good : null

        return {
            error,
            good,
            goodLoading: false,
        }
    } else {
        return {
            error,
            good: null,
            goodLoading: true,
        }
    }
}

export default useGetGoodDocuments
