import { MUIDataTableColumnDef } from 'mui-datatables'
import React from 'react'
import DataTable from '../../../../components/DataTable'
import Panel from '../../../../components/Panel'
import IconHelper from '../../../../helpers/IconHelper'
import { useCommonDataLoadedExisting } from '../../../../hooks/useCommonData'

const AEAAdminsList = () => {
    const { admins } = useCommonDataLoadedExisting()

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'firstname',
            label: 'Prénom',
        },
        {
            name: 'lastname',
            label: 'Nom de famille',
        },
        {
            name: 'email',
            label: 'Adresse mail',
        },
        {
            name: 'phone',
            label: 'Numéro de téléphone',
        },
    ]

    return (
        <Panel>
            <DataTable
                title={'Administrateurs'}
                icon={IconHelper.elementIcons.adminUsersIcon}
                data={admins}
                columns={columns}
                showSearch={true}
                options={{
                    sortOrder: {
                        name: 'lastname',
                        direction: 'asc',
                    },
                }}
            />
        </Panel>
    )
}

export default AEAAdminsList
