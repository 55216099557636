import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Good.Good_Minimal}
    mutation CreateGood($title: String!, $enterpriseId: ID, $placeId: ID) {
        createGood(input: { title: $title, enterpriseId: $enterpriseId, localId: $placeId }) {
            errors
            status
            statusCode
            good {
                ...Good_Minimal
            }
        }
    }
`
