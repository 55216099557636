import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Enterprise.Enterprise_Documents}
    query GetEnterprise_Documents($enterpriseId: ID!) {
        enterprise(id: $enterpriseId) {
            ...Enterprise_Documents
        }
    }
`
