import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { EnumDocumentStatus } from '../../../../../../graphql/typings/global_types'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useUpdateDocument } from '../../../../../hooks/useMutations'
import { UploadManagerToolkit, useUploadFile } from '../../../../../hooks/useUploadManager'
import LoadingDisplay from '../../../../LoadingDisplay'
import Spacer from '../../../../Spacer'
import { DocumentUploadProgress } from '../../DocumentUpload'
import { DocumentEditData, DocumentEditMode, DocumentForEdit } from './DocumentEdit'

type DocumentEditUploadingProps = {
    document: DocumentForEdit
    data: DocumentEditData
    setData: Dispatch<SetStateAction<DocumentEditData>>
    onDone: () => void
    closeDialog: () => void
    mode: DocumentEditMode
}

const DocumentEditUploading = ({ document, data, setData, onDone, closeDialog, mode }: DocumentEditUploadingProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { errorAlert } = useErrorService()

    const { uploadDocumentFile, uploadingState } = useUploadFile()
    const { getUploadedFileDataAsString } = UploadManagerToolkit()

    const updateDocumentMutation = useUpdateDocument()

    const handleSubmit = async () => {
        if (data.title === '') {
            errorAlert('Tous les fichiers doivent avoir un titre', data)
            return
        }
        if (data.category === null) return
        if (data.docElementType === null) return
        if (data.docElementId === null) return

        try {
            let fileDataAsString: string | null = null

            if (data.newFile) {
                await uploadDocumentFile(data.newFile).then((uploadedFileData) => {
                    fileDataAsString = getUploadedFileDataAsString(uploadedFileData)
                })
            }

            const status: EnumDocumentStatus =
                mode === DocumentEditMode.ANSWERING || mode === DocumentEditMode.VALIDATING
                    ? EnumDocumentStatus.confirmed
                    : document.status

            await updateDocumentMutation.run({
                id: document.id,
                title: data.title,
                category: data.category,
                parentType: data.docElementType,
                parentId: data.docElementId || customer.id,
                fileDescriptor: fileDataAsString ? fileDataAsString : null,
                statusComment: data.statusComment,
                status,
            })

            onDone()
            closeDialog()
        } catch (error) {
            errorAlert(error)
        }
    }

    useEffect(() => {
        handleSubmit()
    }, [])

    const loadingTitle = useMemo(() => {
        return data.newFile && uploadingState.uploading ? 'Chargement du fichier...' : 'Mise à jour des informations'
    }, [])

    return (
        <>
            <LoadingDisplay text={loadingTitle} />
            {data.newFile && (
                <>
                    <Spacer />
                    <DocumentUploadProgress title={data.title} uploadPercent={uploadingState.uploadPercent} />
                </>
            )}
        </>
    )
}

export default DocumentEditUploading
