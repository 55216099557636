import { Button, DialogActions, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { EnumDocumentCategory } from '../../../../../../graphql/typings/global_types'
import DocumentHelper from '../../../../../helpers/DocumentHelper'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import useSnackBar from '../../../../../hooks/useSnackBar'
import Spacer from '../../../../Spacer'
import { DocumentEditData } from './DocumentEdit'

type DocumentEditInformationProps = {
    data: DocumentEditData
    setData: Dispatch<SetStateAction<DocumentEditData>>
    closeDialog: () => void
    canGoPrevious: boolean
    goPrevious: () => void
    goNext: () => void
}

const DocumentEditInformation = ({
    data,
    setData,
    closeDialog,
    canGoPrevious,
    goPrevious,
    goNext,
}: DocumentEditInformationProps) => {
    const { openSnack } = useSnackBar()
    const { errorAlert } = useErrorService()

    const handleTitleChange = (title: string) => {
        setData((oldData) => {
            return {
                ...oldData,
                title,
            }
        })
    }

    const handleCommentChange = (statusComment: string) => {
        setData((oldData) => {
            return {
                ...oldData,
                statusComment,
            }
        })
    }

    const handleCategoryChange = (value: string | null) => {
        const valueAsCategory = value ? (value as EnumDocumentCategory) : null

        setData((oldData) => {
            return { ...oldData, category: valueAsCategory }
        })
    }

    const setNext = () => {
        if (data.title === '') {
            errorAlert('Veuillez définir un titre pour le document')
            return
        }
        if (!data.category) {
            errorAlert(`Veuillez définir une catégorie.`)
            return
        }

        goNext()
    }

    // Because the array is frozen in strict mode, you'll need to copy the array before sorting it:
    const categoriesAsList = useMemo(() => DocumentHelper.getSortedDocumentsCategories(), [])

    return (
        <>
            <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Titre"
                type="text"
                fullWidth
                value={data.title || ''}
                onChange={(event) => handleTitleChange(event.target.value)}
            />
            <Autocomplete
                options={categoriesAsList}
                getOptionLabel={(docCategory) => {
                    return DocumentHelper.getDocumentCategoryLabel(docCategory as EnumDocumentCategory)
                }}
                fullWidth
                onChange={(event, newValue) => handleCategoryChange(newValue ? newValue : null)}
                value={data.category || ''}
                renderInput={(params) => (
                    <TextField {...params} label="Type de document(s) *" margin="dense" type="text" />
                )}
            />
            <TextField
                margin="dense"
                id="statusComment"
                label="Commentaire (facultatif)"
                type="text"
                fullWidth
                value={data.statusComment || ''}
                onChange={(event) => handleCommentChange(event.target.value)}
            />
            <Spacer />
            <DialogActions>
                <Button color="secondary" onClick={closeDialog}>
                    Annuler
                </Button>
                <Spacer size={1} />
                {canGoPrevious && (
                    <>
                        <Button color="primary" variant="outlined" onClick={goPrevious}>
                            Précédent
                        </Button>
                        <Spacer size={1} />
                    </>
                )}
                <Button color="primary" variant="contained" onClick={setNext}>
                    Suivant
                </Button>
            </DialogActions>
        </>
    )
}

export default DocumentEditInformation
