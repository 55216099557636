import { gql } from '@apollo/client/core'
import fragments from '../fragments'

export default gql`
    ${fragments.Broker.Broker_Minimal}
    ${fragments.Insurance.Insurance_Minimal}
    ${fragments.Insurer.Insurer_Minimal}
    query GetEnterpriseContracts($customerId: ID!, $enterpriseId: ID!) {
        contracts(projectId: $customerId, enterpriseId: $enterpriseId) {
            id
            reference
            title
            bounty
            startAt
            renewableDate
            renewableNotice
            insurer {
                ...Insurer_Minimal
            }
            broker {
                ...Broker_Minimal
            }
            insurance {
                ...Insurance_Minimal
            }
            enterprise {
                id
                title
            }
            guarantees {
                id
                ceiling
                deductible
                guaranteeCategory {
                    id
                    title
                    description
                }
            }
        }
    }
`
