import React from 'react'
import Roadmap from '../../../components/customer/roadmap/Roadmap'
import IconHelper from '../../../helpers/IconHelper'
import RoutesHelper from '../../../helpers/RoutesHelper'

const CustomerRoadmap = () => {
    return (
        <Roadmap
            routeSegments={[{ name: 'Projets à venir', icon: IconHelper.elementIcons.roadmapIcon }]}
            homePath={RoutesHelper.views.Customer.Home.to().pathname}
        />
    )
}

export default CustomerRoadmap
