import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment, useState } from 'react'
import { EnumUser } from '../../../../../../graphql/typings/global_types'
import { DisasterDamage_Minimal } from '../../../../../helpers/data/models/disaster/DisasterDamage'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useDisasterLoadedExisting } from '../../../../../hooks/useDisaster'
import { useDeleteDisasterDamage } from '../../../../../hooks/useMutations'
import useSnackBar from '../../../../../hooks/useSnackBar'
import MoneyDisplayAnalyzer from '../../../../customInputs/MoneyDisplayAnalyzer'
import DataTable from '../../../../DataTable'
import { ConfirmDialog } from '../../../../Dialog'
import DisasteredDamageDialog from './DisasterDamageDialog'
import DisasterIndemnityDamageExpansion from './DisasterIndemnityDamageExpansion'

type GuaranteedDamage = {
    id: string
    guaranteeType: string
    loss: number
    ceiling: string
    deductible: string
    indemnity: number | '-'
    baseDisasterDamage: DisasterDamage_Minimal
}

type DamageDialogData =
    | {
          open: false
      }
    | { open: true; damage: DisasterDamage_Minimal }

const DisasterIndemnityDamages = () => {
    const { disaster } = useDisasterLoadedExisting()
    const { openSnack } = useSnackBar()
    const deleteDisasterDamageMutation = useDeleteDisasterDamage()
    const { errorAlert } = useErrorService()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DamageDialogData>({ open: false })
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<DamageDialogData>({ open: false })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const onEditClick = (guaranteedDamage: GuaranteedDamage) => {
        setOpenEditDialog({ open: true, damage: guaranteedDamage.baseDisasterDamage })
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: 'Dommage créé avec succès !',
        })
    }

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: 'Dommage modifié avec succès !',
        })
    }

    const getGuaranteedDamages = (): GuaranteedDamage[] => {
        const guaranteedDamages: GuaranteedDamage[] = []

        disaster.disasterDamages.forEach((damage) => {
            guaranteedDamages.push({
                id: damage.id,
                guaranteeType: damage.guarantee.guaranteeCategory.title,
                loss: damage.lost_amount,
                ceiling: damage.ceiling || '-',
                deductible: damage.deductible || '-',
                indemnity: damage.indemnity || '-',
                baseDisasterDamage: damage,
            })
        })

        return guaranteedDamages
    }

    const deleteDamage = (guaranteeDamage: GuaranteedDamage) => {
        setOpenDeleteConfirm({
            open: true,
            damage: guaranteeDamage.baseDisasterDamage,
        })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteConfirm.open) return

        setDeleteLoading(true)

        try {
            const response = await deleteDisasterDamageMutation.run({
                disasterId: disaster.id,
                id: openDeleteConfirm.damage.id,
            })

            if (response.errors.length > 0) {
                throw 'Un problème est survenu pendant la suppression du dommage'
            } else {
                openSnack({
                    type: 'success',
                    message: 'Dommage supprimé avec succès !',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteConfirm({ open: false })
        }
    }

    const disasterDamages: GuaranteedDamage[] = getGuaranteedDamages()

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un client`,
    }

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'guaranteeType',
            label: 'Type de garantie',
        },
        {
            name: 'loss',
            label: 'Montant du préjudice',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const damage = disasterDamages[dataIndex]

                    return <MoneyDisplayAnalyzer value={damage.loss} />
                },
            },
        },
        {
            name: 'ceiling',
            label: 'Plafond',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const damage = disasterDamages[dataIndex]

                    return <MoneyDisplayAnalyzer value={damage.ceiling} />
                },
            },
        },
        {
            name: 'deductible',
            label: `Franchise`,
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const damage = disasterDamages[dataIndex]

                    return <MoneyDisplayAnalyzer value={damage.deductible} />
                },
            },
        },
        {
            name: 'indemnity',
            label: `Indemnités dûes`,
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const damage = disasterDamages[dataIndex]

                    return <MoneyDisplayAnalyzer value={damage.indemnity} />
                },
            },
        },
    ]

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const damage = disasterDamages[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier le dommage">
                    <IconButton onClick={() => onEditClick(damage)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le dommage">
                    <IconButton onClick={() => deleteDamage(damage)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <Fragment>
            <DataTable
                title={'Dommages'}
                data={disasterDamages}
                columns={columns}
                showSearch={true}
                addFeature={addFeatureData}
                actionsColumn={actionsColumn}
                options={{
                    sortOrder: {
                        name: 'guarantee_type',
                        direction: 'asc',
                    },
                    expandableRowsHeader: false,
                    expandableRows: true, // set rows expandable
                    expandableRowsOnClick: true,
                    renderExpandableRow: (rowData, { dataIndex }) => {
                        const colSpan = rowData.length + 1
                        const guaranteedDamage = disasterDamages[dataIndex]
                        const disasterDamage = guaranteedDamage.baseDisasterDamage
                        return (
                            <TableRow>
                                <TableCell colSpan={colSpan} className="p-6">
                                    <DisasterIndemnityDamageExpansion disasterDamage={disasterDamage} />
                                </TableCell>
                            </TableRow>
                        )
                    },
                }}
            />
            <DisasteredDamageDialog
                onDone={onCreated}
                open={openAddDialog}
                closeDialog={() => setOpenAddDialog(false)}
                disaster={disaster}
            />
            {openEditDialog.open && (
                <DisasteredDamageDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    disaster={disaster}
                    disasterDamage={openEditDialog.damage}
                />
            )}
            <ConfirmDialog
                open={openDeleteConfirm.open}
                title="Suppression d'un dommage"
                text="Voulez-vous vraiment supprimer ce dommage ? Les frais seront déduits de tous les calculs liés à ce sinistre."
                onConfirmed={onDeleteConfirmed}
                onCancelled={() => setOpenDeleteConfirm({ open: false })}
                loading={deleteLoading}
            />
        </Fragment>
    )
}

export default DisasterIndemnityDamages
