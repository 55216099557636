import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment, useState } from 'react'
import { GetContract_Full_contract_guarantees } from '../../../../../../graphql/queries/typings/GetContract_Full'
import { EnumUser } from '../../../../../../graphql/typings/global_types'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useContractLoadedExisting } from '../../../../../hooks/useContract'
import { useDeleteContractGuarantee } from '../../../../../hooks/useMutations'
import useSnackBar from '../../../../../hooks/useSnackBar'
import MoneyDisplayAnalyzer from '../../../../customInputs/MoneyDisplayAnalyzer'
import DataTable from '../../../../DataTable'
import { ConfirmDialog } from '../../../../Dialog'
import Panel from '../../../../Panel'
import GuaranteeDialog from './ContractGuaranteeDialog'

type DialogState =
    | {
          open: true
          guaranteeToEdit: GetContract_Full_contract_guarantees
      }
    | { open: false }

type FormattedData = {
    category: string
    ceiling: string
    deductible: string
    baseGuarantee: GetContract_Full_contract_guarantees
}

const ContractGuarantees = () => {
    const { contract } = useContractLoadedExisting()
    const { openSnack } = useSnackBar()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const deleteContractGuaranteeMutation = useDeleteContractGuarantee()
    const { errorAlert } = useErrorService()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const guarantees = contract.guarantees

    const onEditClick = (guarantee: GetContract_Full_contract_guarantees) => {
        setOpenEditDialog({
            open: true,
            guaranteeToEdit: guarantee,
        })
    }

    const onDeleteClick = (guarantee: GetContract_Full_contract_guarantees) => {
        setOpenDeleteDialog({
            open: true,
            guaranteeToEdit: guarantee,
        })
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `La garantie a été créée avec succès !`,
        })
    }

    const onEdited = (guarantee: GetContract_Full_contract_guarantees) => {
        openSnack({
            type: 'success',
            message: `La garantie a été éditée avec succès !`,
        })
    }

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'une garantie`,
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        const guarantee = openDeleteDialog.guaranteeToEdit

        try {
            const response = await deleteContractGuaranteeMutation.run({
                contractId: contract.id,
                id: guarantee.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression de la garantie'
            } else {
                openSnack({
                    type: 'success',
                    message: `La garantie a été supprimée avec succès !`,
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteDialog({
                open: false,
            })
        }
    }

    const onDeleteCancelled = () => {
        setOpenDeleteDialog({
            open: false,
        })
    }

    const columns: MUIDataTableColumnDef[] = [
        {
            name: 'category',
            label: 'Type de garantie',
        },
        {
            name: 'ceiling',
            label: 'Plafond',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const ceiling = formattedGuarantees[dataIndex].ceiling

                    return <MoneyDisplayAnalyzer value={ceiling} />
                },
            },
        },
        {
            name: 'deductible',
            label: 'Franchise',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const deductible = formattedGuarantees[dataIndex].deductible

                    return <MoneyDisplayAnalyzer value={deductible} />
                },
            },
        },
    ]

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const guarantee = guarantees[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier la garantie">
                    <IconButton onClick={() => onEditClick(guarantee)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer la garantie">
                    <IconButton onClick={() => onDeleteClick(guarantee)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const formatGuarantees = (): FormattedData[] => {
        const formattedGuarantees: FormattedData[] = guarantees.map((guarantee) => {
            return {
                ceiling: guarantee.ceiling || '-',
                deductible: guarantee.deductible || '-',
                category: guarantee.guaranteeCategory.title,
                baseGuarantee: guarantee,
            }
        })

        return formattedGuarantees
    }

    const formattedGuarantees = formatGuarantees()

    return (
        <Fragment>
            <Panel>
                <Fragment>
                    <DataTable<FormattedData>
                        title={'Garanties du contrat'}
                        data={formattedGuarantees}
                        columns={columns}
                        showSearch={true}
                        addFeature={addFeatureData}
                        actionsColumn={actionsColumn}
                    />
                </Fragment>
            </Panel>
            <GuaranteeDialog
                onDone={onCreated}
                open={openAddDialog}
                closeDialog={() => setOpenAddDialog(false)}
                contract={contract}
            />
            {openEditDialog.open && (
                <GuaranteeDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    guarantee={openEditDialog.guaranteeToEdit}
                    contract={contract}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={onDeleteCancelled}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'une garantie ?"
                    text={`Voulez-vous vraiment supprimer la garantie "${openDeleteDialog.guaranteeToEdit.guaranteeCategory.title}" ?`}
                    loading={deleteLoading}
                />
            )}
        </Fragment>
    )
}

export default ContractGuarantees
