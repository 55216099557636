import { gql } from "@apollo/client/core";
import Prevention_PlaceMinimal from '../fragments/Prevention/Prevention_PlaceMinimal'

export default gql`
    ${Prevention_PlaceMinimal}
    query GetPreventions_PlaceMinimal($id: ID!) {
        project(id: $id) {
            id
            preventions {
                ...Prevention_PlaceMinimal
            }
        }
    }
`
