import { gql } from '@apollo/client/core'

export default gql`
    query GetPreventionsForDMA($customerID: ID!) {
        customer: project(id: $customerID) {
            id
            preventions {
                id
                startAt
                endAt
                preventionCategory {
                    id
                    title
                }
                place: local {
                    id
                }
            }
        }
    }
`
