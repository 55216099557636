import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Broker.Broker_Minimal}
    query GetBrokers_Minimal {
        brokers {
            ...Broker_Minimal
        }
    }
`
