import React, { Fragment, useState } from 'react'
import { GetDisasters_Minimal_disasters } from '../../../../../graphql/queries/typings/GetDisasters_Minimal'
import { DateRange } from '../../../../components/DateCategoryInput'
import LoadingDisplay from '../../../../components/LoadingDisplay'
import useGetDisasters from '../../../../helpers/data/getDisasters'
import { AdminDashboardData } from '../../../../helpers/data/models/AdminDashboardData'
import ErrorDisplay from '../../../../helpers/errors/ErrorDisplay'
import useAdminDashboardData from '../../../../hooks/useAdminDashboardData'
import AEADashboardCards from './AEADashboardCards'
import AEADashboardDisasters from './AEADashboardDisasters'

const defaultDateRange: DateRange = {
    startDate: null,
    endDate: null,
}

const AEAHome = () => {
    const [dateRange, setDateRange] = useState<DateRange>(defaultDateRange)

    const { dashboardData, dashboardDataLoading } = useAdminDashboardData({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    })
    const { disasters, disastersLoading } = useGetDisasters({})

    const onDateRangeChange = (startDate: Date | null, endDate: Date | null) => {
        setDateRange((oldDateRange) => {
            return {
                ...oldDateRange,
                startDate,
                endDate,
            }
        })
    }

    const content =
        dashboardDataLoading || disastersLoading ? (
            <LoadingDisplay />
        ) : !dashboardData ? (
            <ErrorDisplay message="Erreur lors de la récupération des données du dashboard" debug={dashboardData} />
        ) : !disasters ? (
            <ErrorDisplay message="Erreur lors de la récupération des sinistres" debug={dashboardData} />
        ) : (
            <AEAHomeReady dashboardData={dashboardData} disasters={disasters} setDateRange={setDateRange} />
        )

    return (
        <div className="m-sm-30">
            <h3 className="m-0 mb-6">Synthèse AEA</h3>
            {/* <Grid container style={{ width: '100%' }} justifyContent="flex-end">
                <Grid item>
                    <DateCategoryInput onDateRangeChange={onDateRangeChange} />
                </Grid>
            </Grid> */}
            {content}
        </div>
    )
}

type AEAHomeReadyProps = {
    dashboardData: AdminDashboardData
    disasters: GetDisasters_Minimal_disasters[]
    setDateRange: React.Dispatch<React.SetStateAction<DateRange>>
}

const AEAHomeReady = ({ dashboardData, disasters, setDateRange }: AEAHomeReadyProps) => {
    return (
        <Fragment>
            <div className="mb-6 w-full">
                <AEADashboardCards dashboardData={dashboardData} />
            </div>
            <div className="w-full">
                <AEADashboardDisasters disasters={disasters} />
            </div>
        </Fragment>
    )
}

export default AEAHome
