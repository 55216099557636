import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.DisasterCategory}
    mutation CreateDisasterCategory($title: String!, $description: String) {
        createDisasterCategory(input: { title: $title, description: $description }) {
            errors
            status
            statusCode
            disasterCategory {
                ...DisasterCategory
            }
        }
    }
`
