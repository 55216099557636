import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Good.Good_Minimal}
    query GetGoods_Minimal($customerId: ID!, $enterpriseId: ID) {
        goods(projectId: $customerId, enterpriseId: $enterpriseId) {
            ...Good_Minimal
        }
    }
`
