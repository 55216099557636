import React, { ReactElement, Suspense } from 'react'
import { MatxLoading } from '../../../matx'

type MatxSuspenseProps = {
    children: ReactElement
}

const MatxSuspense = ({ children }: MatxSuspenseProps) => {
    return <Suspense fallback={<MatxLoading />}>{children}</Suspense>
}

export default MatxSuspense
