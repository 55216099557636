import { createContext, useContext } from 'react'
import { GetCustomers_Minimal_customers } from '../../graphql/queries/typings/GetCustomers_Minimal'
import useGetCustomers from '../helpers/data/getCustomers'
import { QueryError } from '../helpers/errors/ErrorHelper'

type CustomersContext = {
    error?: QueryError
    customers: GetCustomers_Minimal_customers[] | null
    customersLoading: boolean
}

const CustomersContext = createContext<CustomersContext>({
    customers: null,
    customersLoading: true,
})

export function ProvideCustomers({ children }: any) {
    const customersData = useProvideCustomers()

    return <CustomersContext.Provider value={customersData}>{children}</CustomersContext.Provider>
}

export const useCustomers = () => {
    return useContext(CustomersContext)
}

export const useCustomersLoadedExisting = () => {
    const context = useContext(CustomersContext)
    if (context.customersLoading === true) {
        console.error('Customers are loading !')
        throw new Error('Customers are loading')
    }
    if (!context.customers) {
        console.error('Customers are null !')
        throw new Error('Customers are null')
    }

    return {
        customers: context.customers,
        customersLoading: context.customersLoading,
    }
}

const useProvideCustomers = () => {
    const { customers, customersLoading, error } = useGetCustomers()

    return { customers, customersLoading, error }
}

export default useCustomers
