import { gql } from '@apollo/client/core'
import Document_User from '../fragments/Document/Document_User'

export default gql`
    ${Document_User}
    query GetGood_Documents($id: ID!) {
        good(id: $id) {
            id
            documents {
                ...Document_User
            }
        }
    }
`
