import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Contract.Contract_CoveredElements}
    query GetContract_CoveredElements($contractId: ID!) {
        contract(id: $contractId) {
            ...Contract_CoveredElements
        }
    }
`
