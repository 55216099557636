import { gql } from '@apollo/client/core'
import fragments from '../fragments'

// ${fragments.Insurer.Insurer_Minimal}
// ${fragments.Broker.Broker_Minimal}
// ${fragments.Insurance.Insurance_Minimal}

export default gql`
    query GetPlace($placeID: ID!) {
        place: local(id: $placeID) {
            id
            title
            address
            wallsOwner
            enterprise {
                id
                address
                title
            }
            site {
                id
                title
            }
            guarantees {
                id
                guaranteeCategory {
                    id
                    title
                }
                contract {
                    id
                    title
                    reference
                    insurance {
                        id
                        title
                    }
                    enterprise {
                        id
                        title
                    }
                }
            }
        }
    }
`

// guarantees {
//     id
//     contract {
//         id
//         title
//         id
//         reference
//         title
//         bounty
//         startAt
//         renewableDate
//         renewableNotice
//         insurer {
//             ...Insurer_Minimal
//         }
//         broker {
//             ...Broker_Minimal
//         }
//         insurance {
//             ...Insurance_Minimal
//         }
//         enterprise {
//             id
//             title
//         }

//     }
// }
