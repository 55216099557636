import { Button, DialogActions } from '@material-ui/core'
import { Fragment, ReactNode, useMemo } from 'react'
import { ManyFilesDialog } from '../../../../../hooks/useFileDialog'
import useSnackBar from '../../../../../hooks/useSnackBar'
import Spacer from '../../../../Spacer'
import DocumentUpload from '../../DocumentUpload'

type DocumentCreateFilesProps = {
    manyFileProps: ManyFilesDialog
    closeDialog: () => void
    goNext: () => void
}

const DocumentCreateFiles = ({ manyFileProps, closeDialog, goNext }: DocumentCreateFilesProps) => {
    const { filesPickerNode, openFilesPicker, filesHelpers } = manyFileProps
    const { openSnack } = useSnackBar()

    const setNext = () => {
        if (filesHelpers.length === 0) {
            openSnack({
                type: 'error',
                message: 'Vous devez choisir au moins un document avant de passer à la suite !',
            })
            return
        }

        goNext()
    }

    const documentUploadElements: ReactNode[] = useMemo(() => {
        const nodes = filesHelpers.map((fileHelper, index) => {
            return (
                <DocumentUpload
                    key={fileHelper.id}
                    loading={false}
                    uploadPercent={0}
                    localFile={fileHelper.file}
                    openFilePicker={openFilesPicker}
                    hasAlreadyFileUploaded={false}
                    deleteFile={fileHelper.deleteFile}
                    editable={true}
                    title={fileHelper.title}
                    editFileName={fileHelper.editFileTitle}
                />
            )
        })

        nodes.push(
            <Fragment key="doc_uploader">
                <Spacer size={2} />
                <div style={{ textAlign: 'center' }}>
                    <DocumentUpload
                        loading={false}
                        uploadPercent={0}
                        localFile={null}
                        openFilePicker={openFilesPicker}
                        hasAlreadyFileUploaded={false}
                        deleteFile={() => {}}
                    />
                </div>
            </Fragment>
        )

        return nodes
    }, [filesHelpers, openFilesPicker])

    return (
        <>
            <div className="mb-2">{documentUploadElements}</div>
            <Spacer />
            {filesPickerNode}
            <DialogActions>
                <Button color="secondary" onClick={closeDialog}>
                    Annuler
                </Button>
                <Spacer size={1} />
                <Button color="primary" variant="contained" onClick={setNext}>
                    Suivant
                </Button>
            </DialogActions>
        </>
    )
}

export default DocumentCreateFiles
