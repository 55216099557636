import { useQuery } from '@apollo/client'
import queries from '../../../graphql/queries'
import {
    GetCustomers_Minimal,
    GetCustomers_Minimal_customers,
} from '../../../graphql/queries/typings/GetCustomers_Minimal'
import { useErrorService } from '../errors/ErrorService'
import ErrorHelper, { QueryError } from '../errors/ErrorHelper'

type CustomersData = {
    error?: QueryError
    customers: GetCustomers_Minimal_customers[] | null
    customersLoading: boolean
}

const useGetCustomers = (): CustomersData => {
    const { errorAlert } = useErrorService()

    const queryResult = useQuery<GetCustomers_Minimal>(queries.GetCustomers_Minimal)

    const errorMessage = 'Erreur lors de la récupération du local'
    const error = ErrorHelper.getQueryError(queryResult, errorMessage)
    if (queryResult.error) {
        errorAlert(errorMessage, queryResult)
    }

    const queryLoading = queryResult.loading
    const responseData = queryResult.data

    if (!queryLoading) {
        const customers = responseData && responseData.customers ? responseData.customers : null

        return {
            error,
            customers,
            customersLoading: false,
        }
    } else {
        return {
            error,
            customers: null,
            customersLoading: true,
        }
    }
}

export default useGetCustomers
