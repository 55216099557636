import { default as React, Fragment } from 'react'
import Panel from '../../../../Panel'
import DisasterIndemnityDamages from './DisasterIndemnityDamages'
import DisasterIndemnityPayments from './DisasterIndemnityPayments'

const DisasterIndemnity = () => {
    return (
        <Fragment>
            <Panel>
                <DisasterIndemnityDamages />
            </Panel>
            <Panel>
                <DisasterIndemnityPayments />
            </Panel>
        </Fragment>
    )
}

export default DisasterIndemnity
