import { CardProps } from '@material-ui/core/Card'
import React, { ReactElement } from 'react'
import ElevatedCard from './ElevatedCard'

type PanelProps = CardProps & {
    children: ReactElement
}

const Panel = ({ children, ...cardProps }: PanelProps) => {
    return (
        <ElevatedCard {...cardProps} className={`${cardProps.className} mb-6`} style={{ ...cardProps.style }}>
            {children}
        </ElevatedCard>
    )
}

export default Panel
