// import SidenavImgUrl from '../../../../assets/images/sidebar/sidebar-bg-dark.jpg'

export type LeftSideBarSettings = {
    show: boolean
    mode: 'full' | 'close' | 'compact' | 'mobile' // full, close, compact, mobile,
    theme: string // View all valid theme colors inside MatxTheme/themeColors.js
    bgImgURL?: string
}

export type LayoutSettings = {
    leftSidebar: LeftSideBarSettings
    leftSidebar2: LeftSideBarSettings
    topbar: {
        show: boolean
        fixed: boolean
        theme: string // View all valid theme colors inside MatxTheme/themeColors.js
    }
}

const Layout1Settings: LayoutSettings = {
    leftSidebar: {
        show: true,
        mode: 'full', // full, close, compact, mobile,
        theme: 'slateDark1', // View all valid theme colors inside MatxTheme/themeColors.js
        // bgImgURL: SidenavImgUrl,
    },
    leftSidebar2: {
        show: true,
        mode: 'full', // full, close, compact, mobile,
        theme: 'whiteBlue', // View all valid theme colors inside MatxTheme/themeColors.js
        // bgImgURL: SidenavImgUrl,
    },
    topbar: {
        show: true,
        fixed: true,
        theme: 'whiteBlue', // View all valid theme colors inside MatxTheme/themeColors.js
    },
}

export default Layout1Settings
