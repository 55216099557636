import { IconButton } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import React from 'react'
import Panel from './Panel'

type InfoPanelProps = {
    icon?: string
    text: string
}

const InfoPanel = ({ icon, text }: InfoPanelProps) => {
    return (
        <Panel className="pl-3 pr-3 text-muted">
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
                {icon && (
                    <Grid item>
                        <Icon>{icon}</Icon>
                    </Grid>
                )}
                <Grid item>
                    <p>{text}</p>
                </Grid>
            </Grid>
        </Panel>
    )
}

export default InfoPanel
