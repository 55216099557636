import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import React, { Fragment, useState } from 'react'
import { GetEnterprise_Full_enterprise_goods } from '../../../../../../graphql/queries/typings/GetEnterprise_Full'
import { EnumUser } from '../../../../../../graphql/typings/global_types'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import IconHelper from '../../../../../helpers/IconHelper'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useEnterpriseLoadedExisting } from '../../../../../hooks/useEnterprise'
import { useDeleteGood } from '../../../../../hooks/useMutations'
import useSnackBar from '../../../../../hooks/useSnackBar'
import DataTable from '../../../../DataTable'
import { ConfirmDialog } from '../../../../Dialog'
import DocumentsModal from '../../../../documentsList/DocumentModal/DocumentsModal'
import Panel from '../../../../Panel'
import GoodDialog from './GoodDialog'

type DialogState =
    | {
          open: true
          good: GetEnterprise_Full_enterprise_goods
      }
    | { open: false }

const EnterpriseGoods = () => {
    const { enterprise } = useEnterpriseLoadedExisting()
    const { errorAlert } = useErrorService()
    const { openSnack } = useSnackBar()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const deleteGoodMutation = useDeleteGood()

    const { customer } = useCustomerLoadedExisting()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [openDocsDialog, setOpenDocsDialog] = useState<DialogState>({ open: false })

    const goods = enterprise.goods

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un bien`,
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `Bien créé avec succès !`,
        })
    }

    const onEditClick = (good: GetEnterprise_Full_enterprise_goods) => {
        setOpenEditDialog({
            open: true,
            good,
        })
    }

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: `Bien modifié avec succès !`,
        })
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const good = goods[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Voir les documents">
                    <IconButton onClick={() => setOpenDocsDialog({ open: true, good })}>
                        <Icon>{IconHelper.elementIcons.docsIcon}</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Modifier le bien">
                    <IconButton onClick={() => onEditClick(good)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le bien">
                    <IconButton onClick={() => deleteGood(good)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const deleteGood = (good: GetEnterprise_Full_enterprise_goods) => {
        setOpenDeleteDialog({ open: true, good })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const response = await deleteGoodMutation.run({
                enterpriseId: enterprise.id,
                id: openDeleteDialog.good.id,
                customerId: customer.id,
            })

            if (response.errors.length > 0) {
                throw 'Erreur lors de la suppression du véhicule'
            } else {
                openSnack({
                    type: 'success',
                    message: 'Véhicule supprimé avec succès !',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setOpenDeleteDialog({ open: false })
            setDeleteLoading(false)
        }
    }

    const columns = [
        {
            name: 'title',
            label: 'Libellé',
        },
        {
            name: 'place',
            label: 'Local',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const place = goods[dataIndex].place
                    return <div>{place ? place.title : '-'}</div>
                },
            },
        },
    ]

    return (
        <Fragment>
            <Panel>
                <DataTable<GetEnterprise_Full_enterprise_goods>
                    title={`Biens de l'entreprise`}
                    icon={IconHelper.elementIcons.goodIcon}
                    data={goods}
                    columns={columns}
                    showSearch={true}
                    addFeature={addFeatureData}
                    actionsColumn={actionsColumn}
                />
            </Panel>
            <GoodDialog
                onDone={onCreated}
                open={openAddDialog}
                closeDialog={() => setOpenAddDialog(false)}
                goodEnterprise={enterprise}
            />
            {openEditDialog.open && (
                <GoodDialog
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                    good={openEditDialog.good}
                    goodEnterprise={enterprise}
                />
            )}
            {openDeleteDialog.open && (
                <ConfirmDialog
                    open={openDeleteDialog.open}
                    onCancelled={() => setOpenDeleteDialog({ open: false })}
                    onConfirmed={onDeleteConfirmed}
                    title="Suppression d'un bien"
                    text={`Voulez-vous vraiment supprimer le bien "${openDeleteDialog.good.title}" ?`}
                    loading={deleteLoading}
                />
            )}
            {openDocsDialog.open && (
                <DocumentsModal
                    open={openDocsDialog.open}
                    closeDialog={() => setOpenDocsDialog({ open: false })}
                    elementType={'good'}
                    elementId={openDocsDialog.good.id}
                />
            )}
        </Fragment>
    )
}

export default EnterpriseGoods
