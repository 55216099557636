import {
    List,
    ListItem,
    ListItemText,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { EnterpriseGeneric_enterprise } from '../../../../graphql/queries/typings/EnterpriseGeneric'
import { UserGeneric_user } from '../../../../graphql/queries/typings/UserGeneric'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import CustomButton from '../../CustomButton'
import { Dialog } from '../../Dialog'
import { useMemo } from 'react'

type UserDialogProps = {
    user: UserForDialog
    open: boolean
    closeDialog: () => void
    onDone: () => void
}

type UserForDialog = Pick<UserGeneric_user, 'id' | 'firstname' | 'lastname' | 'email' | 'phone'> & {
    restrictedToEnterprises: Pick<EnterpriseGeneric_enterprise, 'id' | 'title'>[]
}

const UserDialog = ({ user, onDone, open, closeDialog }: UserDialogProps) => {
    const { customer } = useCustomerLoadedExisting()

    const notifications = useMemo(() => {
        return customer.enterprises.filter((enterprise) => {
            return enterprise.notificationUsers.find((notificationUser) => notificationUser.id === user.id)
        })
    }, [user, customer.enterprises])

    return (
        <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Liste des notifications</DialogTitle>
            <DialogContent>
                {notifications.length > 0 && (
                    <List disablePadding>
                        {notifications.map((enterprise) => {
                            return (
                                <ListItem>
                                    <ListItemText primary={enterprise.title} />
                                </ListItem>
                            )
                        })}
                    </List>
                )}
                {notifications.length === 0 && (
                    <Typography variant="caption">Aucune notification d'entreprise</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <CustomButton variant="outlined" onClick={onDone} color="primary">
                    OK
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default UserDialog
