import React from 'react'
import Breadcrumb from '../../../../matx/components/Breadcrumb'
import IconHelper from '../../../helpers/IconHelper'
import useNavContext from '../../../hooks/useNavContext'
import Panel from '../../Panel'
import EnterprisesList from './EnterprisesList'

const Enterprises = () => {
    const { getEnterprisesRoute } = useNavContext()

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Breadcrumb
                    routeSegments={[{ name: 'Entreprises', icon: IconHelper.elementIcons.enterpriseIcon }]}
                    homePath={getEnterprisesRoute().pathname}
                />
            </div>
            <Panel>
                <EnterprisesList />
            </Panel>
        </div>
    )
}

export default Enterprises
