import { gql } from '@apollo/client/core'

export default gql`
    mutation UpdateEnterprise(
        $id: ID!
        $title: String!
        $address: String!
        $customerId: ID
        $notificationUserIds: [ID!]
    ) {
        updateEnterprise(
            input: {
                id: $id
                title: $title
                address: $address
                projectId: $customerId
                notificationUserIds: $notificationUserIds
            }
        ) {
            errors
            status
            statusCode
            enterprise {
                id
                address
                title
                notificationUsers(considerAncestors: false) {
                    id
                    firstname
                    lastname
                }
            }
        }
    }
`
