import { Button, DialogActions } from '@material-ui/core'
import Spacer from '../../../../../Spacer'

export type DMAButtonsProps = {
    closeDialog: () => void
    goPrevious: () => void
    canGoPrevious: boolean
    goNext: () => void
    canGoNext: boolean
}

const DMAButtons = ({ closeDialog, goPrevious, goNext, canGoNext, canGoPrevious }: DMAButtonsProps) => {
    return (
        <>
            <Spacer />
            <DialogActions>
                <Button color="secondary" onClick={closeDialog}>
                    Annuler
                </Button>
                {canGoPrevious && (
                    <Button color="primary" variant="outlined" onClick={goPrevious}>
                        Précédent
                    </Button>
                )}
                {canGoNext && (
                    <Button color="primary" variant="contained" onClick={goNext}>
                        Confirmer
                    </Button>
                )}
            </DialogActions>
        </>
    )
}

export default DMAButtons
