import { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { DocumentInput, EnumDocumentStatus } from '../../../../../../graphql/typings/global_types'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { ManyFilesDialog } from '../../../../../hooks/useFileDialog'
import { useCreateDocuments } from '../../../../../hooks/useMutations'
import { UploadManagerToolkit, useUploadManyFiles } from '../../../../../hooks/useUploadManager'
import LoadingDisplay from '../../../../LoadingDisplay'
import Spacer from '../../../../Spacer'
import { DocumentUploadProgress } from '../../DocumentUpload'
import { DocumentCreateData } from './DocumentCreate'

type DocumentCreateUploadingProps = {
    emptyData: DocumentCreateData
    data: DocumentCreateData
    setData: Dispatch<SetStateAction<DocumentCreateData>>
    manyFileProps: ManyFilesDialog
    onDone: () => void
    closeDialog: () => void
}

const DocumentCreateUploading = ({
    emptyData,
    data,
    setData,
    manyFileProps,
    onDone,
    closeDialog,
}: DocumentCreateUploadingProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { filesHelpers } = manyFileProps
    const { errorAlert } = useErrorService()
    const { uploadDocumentFile, uploadingState, isUploading } = useUploadManyFiles()
    const { getUploadedFileDataAsString } = UploadManagerToolkit()
    const createDocumentsMutation = useCreateDocuments()

    const handleSubmit = async () => {
        if (data.category === null) return
        if (data.docElementType === null) return
        if (data.docElementId === null) return
        if (filesHelpers.length === 0) return

        // We check if all files have title
        let allFilesHaveTitle = true
        filesHelpers.forEach((fileHelper) => {
            if (fileHelper.title === '') allFilesHaveTitle = false
        })

        if (!allFilesHaveTitle) {
            errorAlert('Tous les fichiers doivent avoir un titre', filesHelpers)
            return
        }

        try {
            const documentsInput: DocumentInput[] = []

            const promises = filesHelpers.map((fileHelper) =>
                uploadDocumentFile(fileHelper.file).then((uploadedFileData) => {
                    const fileDataAsString = getUploadedFileDataAsString(uploadedFileData)

                    const docInput: DocumentInput = {
                        title: fileHelper.title,
                        category: data.category,
                        parentType: data.docElementType,
                        parentId: data.docElementId || customer.id,
                        status: EnumDocumentStatus.confirmed,
                        fileDescriptor: fileDataAsString,
                        statusComment: data.statusComment,
                    }

                    documentsInput.push(docInput)
                })
            )

            const results = await Promise.all(promises)

            //Creating
            const response = await createDocumentsMutation.run({
                customerId: customer.id,
                documentsInput,
            })

            if (response.documents) {
                onDone()
            } else {
                throw "Une erreur est survenue pendant l'envoi d'un document"
            }

            setData(emptyData)
            closeDialog()
        } catch (error) {
            errorAlert(error)
        }
    }

    useEffect(() => {
        handleSubmit()
    }, [])

    const documentUploadElements = filesHelpers.map((fileHelper, index) => {
        const uploadingStatus = uploadingState[index]

        return (
            <DocumentUploadProgress
                title={fileHelper.title}
                uploadPercent={uploadingStatus ? uploadingStatus.uploadPercent : 0}
            />
        )
    })

    const loadingTitle = useMemo(() => {
        return isUploading ? 'Chargement des documents...' : 'Mise à jour des informations'
    }, [])

    return (
        <>
            <LoadingDisplay text={loadingTitle} />
            <Spacer />
            {documentUploadElements}
        </>
    )
}

export default DocumentCreateUploading
