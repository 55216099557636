import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import React, { useState } from 'react'
import { ContractGeneric_contract } from '../../../../graphql/queries/typings/ContractGeneric'
import { EnterpriseGeneric_enterprise } from '../../../../graphql/queries/typings/EnterpriseGeneric'
import { isStringInputValid } from '../../../../utilsTs'
import { Broker_Minimal } from '../../../helpers/data/models/contract/Broker'
import { Insurance_Minimal } from '../../../helpers/data/models/contract/Insurance'
import { Insurer_Minimal } from '../../../helpers/data/models/contract/Insurer'
import { Enterprise_Minimal } from '../../../helpers/data/models/Enterprise'
import DateHelper from '../../../helpers/DateHelper'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import { useCommonDataLoadedExisting } from '../../../hooks/useCommonData'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useCreateContract, useUpdateContract } from '../../../hooks/useMutations'
import CustomButton from '../../CustomButton'
import MoneyInput from '../../customInputs/MoneyInput'
import { Dialog } from '../../Dialog'

type ContractDialogProps = {
    open: boolean
    contract?: ContractForDialog // in case of editing
    closeDialog: () => void
    onDone: () => void
    enterpriseId?: string
}

type ContractData = {
    title: string | null
    reference: string | null
    enterprise: Enterprise_Minimal | null
    insurance: Insurance_Minimal | null
    insurer?: Insurer_Minimal | null
    broker?: Broker_Minimal | null
    bounty: number | null
    renewableNotice: number | null
    startAt: Date
    renewableDate: Date
}

const nullSelectValue = 'null'

export type ContractForDialog = Pick<
    ContractGeneric_contract,
    'id' | 'title' | 'reference' | 'bounty' | 'renewableNotice' | 'startAt' | 'renewableDate'
> & {
    insurance: Insurance_Minimal
    insurer: Insurer_Minimal | null
    broker: Broker_Minimal | null
    enterprise: Pick<EnterpriseGeneric_enterprise, 'id'>
}

const ContractDialog = ({ contract, onDone, open, closeDialog, enterpriseId }: ContractDialogProps) => {
    const { customer } = useCustomerLoadedExisting()
    const { insurances } = useCommonDataLoadedExisting()
    const { fromDateToServer } = DateHelper()
    const { insurers, brokers } = useCommonDataLoadedExisting()

    const emptyContract: ContractData = {
        title: null,
        reference: null,
        enterprise: enterpriseId
            ? customer.enterprises.find((enterprise) => enterprise.id === enterpriseId) || null
            : null,
        insurance: null,
        insurer: null,
        broker: null,
        bounty: null,
        renewableNotice: null,
        startAt: new Date(),
        renewableDate: new Date(),
    }

    const [data, setData] = useState<ContractData>(
        contract
            ? {
                  title: contract.title,
                  reference: contract.reference,
                  enterprise:
                      customer.enterprises.find((enterprise) => enterprise.id === contract.enterprise.id) || null,
                  insurance: contract.insurance,
                  insurer: contract.insurer || null,
                  broker: contract.broker || null,
                  bounty: contract.bounty,
                  renewableNotice: contract.renewableNotice,
                  startAt: new Date(contract.startAt),
                  renewableDate: new Date(contract.renewableDate),
              }
            : emptyContract
    )
    const [loading, setLoading] = useState<boolean>(false)

    const createContractMutation = useCreateContract()
    const updateContractMutation = useUpdateContract()

    const { errorAlert } = useErrorService()

    const handleClose = () => {
        closeDialog()
    }

    const handleChange = (
        value: string,
        prop:
            | 'title'
            | 'reference'
            | 'enterpriseId'
            | 'insuranceId'
            | 'bounty'
            | 'renewableNotice'
            | 'insurerId'
            | 'brokerId'
    ) => {
        if (prop === 'enterpriseId') {
            const enterprise = customer.enterprises.find((enterprise) => enterprise.id === value)

            if (!enterprise) {
                errorAlert(`Erreur lors de la récupération des entreprises`, {
                    enterpriseId: value,
                    enterprises: customer.enterprises,
                })
                return
            }

            setData((oldData) => {
                return {
                    ...oldData,
                    enterprise,
                }
            })
        } else if (prop === 'insuranceId') {
            const insurance = insurances.find((insurance) => insurance.id === value)

            if (!insurance) {
                errorAlert(`Erreur lors de la récupération des assurances`, {
                    insuranceId: value,
                    insurances,
                })
                return
            }

            setData((oldData) => {
                return {
                    ...oldData,
                    insurance,
                }
            })
        } else if (prop === 'insurerId') {
            if (value === nullSelectValue) {
                setData((oldData) => {
                    return {
                        ...oldData,
                        insurer: null,
                    }
                })
                return
            }

            const insurer = insurers.find((insurer) => insurer.id === value)

            if (!insurer) {
                errorAlert(`Erreur lors de la récupération des agents généraux`, {
                    insurerId: value,
                    insurers,
                })
                return
            }

            setData((oldData) => {
                return {
                    ...oldData,
                    insurer,
                }
            })
        } else if (prop === 'brokerId') {
            if (value === nullSelectValue) {
                setData((oldData) => {
                    return {
                        ...oldData,
                        broker: null,
                    }
                })
                return
            }

            const broker = brokers.find((broker) => broker.id === value)

            if (!broker) {
                errorAlert(`Erreur lors de la récupération des courtiers`, {
                    brokerId: value,
                    brokers,
                })
                return
            }

            setData((oldData) => {
                return {
                    ...oldData,
                    broker,
                }
            })
        } else if (prop === 'bounty' || prop === 'renewableNotice') {
            const valueToSet: number | null = value === '' ? null : parseFloat(value)

            setData((oldData) => {
                return {
                    ...oldData,
                    [prop]: valueToSet,
                }
            })
        } else {
            // Other props
            setData((oldData) => {
                return {
                    ...oldData,
                    [prop]: value,
                }
            })
        }
    }

    const handleDateChange = (date: MaterialUiPickersDate, value: 'startAt' | 'renewableDate') => {
        if (!date) return

        if (value === 'startAt') {
            setData((oldData) => {
                return {
                    ...oldData,
                    startAt: date,
                }
            })
        } else if (value === 'renewableDate') {
            setData((oldData) => {
                return {
                    ...oldData,
                    renewableDate: date,
                }
            })
        }
    }

    const handleSubmit = async () => {
        if (!data.reference) return
        if (!data.title) return
        if (!data.enterprise) return
        if (!data.insurance) return
        if (data.bounty === null) return
        if (data.renewableNotice === null) return
        if (!data.renewableDate) return

        setLoading(true)

        try {
            if (contract) {
                //Update
                const response = await updateContractMutation.run({
                    id: contract.id,
                    title: data.title,
                    reference: data.reference,
                    enterpriseId: data.enterprise.id,
                    insuranceId: data.insurance.id,
                    bounty: data.bounty,
                    renewableNotice: data.renewableNotice,
                    startAt: fromDateToServer(data.startAt),
                    renewableDate: fromDateToServer(data.renewableDate),
                    insurerId: data.insurer ? data.insurer.id : null,
                    brokerId: data.broker ? data.broker.id : null,
                })

                if (response.contract) {
                    onDone()
                } else {
                    throw 'Une erreur est survenue pendant la mise à jour du contrat'
                }
            } else {
                const response = await createContractMutation.run({
                    title: data.title,
                    reference: data.reference,
                    enterpriseId: data.enterprise.id,
                    customerId: customer.id,
                    insuranceId: data.insurance.id,
                    bounty: data.bounty,
                    renewableNotice: data.renewableNotice,
                    startAt: fromDateToServer(data.startAt),
                    renewableDate: fromDateToServer(data.renewableDate),
                    insurerId: data.insurer ? data.insurer.id : null,
                    brokerId: data.broker ? data.broker.id : null,
                })

                if (response.contract) {
                    onDone()
                } else {
                    throw 'Une erreur est survenue pendant la création du contrat'
                }

                setData(emptyContract)
            }

            closeDialog()
        } catch (error) {
            errorAlert(error)
        } finally {
            setLoading(false)
        }
    }

    const isFormValid =
        isStringInputValid(data.title) &&
        isStringInputValid(data.reference) &&
        data.enterprise !== null &&
        data.insurance !== null &&
        data.bounty !== null &&
        data.renewableDate !== null &&
        data.renewableNotice !== null

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Saisie d'un contrat</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Saisie des informations principales du contrat. Les autres informations pourront être ajoutées et
                    modifiées par la suite
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Nom du contrat *"
                    type="text"
                    fullWidth
                    value={data.title || ''}
                    onChange={(event) => handleChange(event.target.value, 'title')}
                />
                <TextField
                    margin="dense"
                    id="reference"
                    label="Numéro de contrat *"
                    type="text"
                    fullWidth
                    value={data.reference || ''}
                    onChange={(event) => handleChange(event.target.value, 'reference')}
                />
                <TextField
                    margin="dense"
                    id="enterprise"
                    label="Entreprise *"
                    type="text"
                    fullWidth
                    value={data.enterprise ? data.enterprise.id : ''}
                    onChange={(event) => handleChange(event.target.value, 'enterpriseId')}
                    select>
                    {[...customer.enterprises]
                        .sort((enterpriseA, enterpriseB) => {
                            return enterpriseA.title.localeCompare(enterpriseB.title)
                        })
                        .map((enterprise) => {
                            return (
                                <MenuItem value={enterprise.id} key={enterprise.id}>
                                    {enterprise.title}
                                </MenuItem>
                            )
                        })}
                </TextField>
                <TextField
                    margin="dense"
                    id="insurance"
                    label="Assurance *"
                    type="text"
                    fullWidth
                    value={data.insurance ? data.insurance.id : ''}
                    onChange={(event) => handleChange(event.target.value, 'insuranceId')}
                    select>
                    {[...insurances]
                        .sort((insuranceA, insuranceB) => {
                            return insuranceA.title.localeCompare(insuranceB.title)
                        })
                        .map((insurance) => {
                            return (
                                <MenuItem value={insurance.id} key={insurance.id}>
                                    {insurance.title}
                                </MenuItem>
                            )
                        })}
                </TextField>
                <TextField
                    margin="dense"
                    id="insurer"
                    label="Agent général"
                    type="text"
                    fullWidth
                    value={data.insurer ? data.insurer.id : nullSelectValue}
                    onChange={(event) => handleChange(event.target.value, 'insurerId')}
                    select>
                    <MenuItem value={nullSelectValue}>Aucun</MenuItem>
                    {[...insurers]
                        .sort((insurerA, insurerB) => {
                            return insurerA.title.localeCompare(insurerB.title)
                        })
                        .map((insurer) => {
                            return (
                                <MenuItem value={insurer.id} key={insurer.id}>
                                    {insurer.title}
                                </MenuItem>
                            )
                        })}
                </TextField>
                <TextField
                    margin="dense"
                    id="broker"
                    label="Courtier"
                    type="text"
                    fullWidth
                    value={data.broker ? data.broker.id : nullSelectValue}
                    onChange={(event) => handleChange(event.target.value, 'brokerId')}
                    select>
                    <MenuItem value={nullSelectValue}>Aucun</MenuItem>
                    {[...brokers]
                        .sort((brokerA, brokerB) => {
                            return brokerA.title.localeCompare(brokerB.title)
                        })
                        .map((broker) => {
                            return (
                                <MenuItem value={broker.id} key={broker.id}>
                                    {broker.title}
                                </MenuItem>
                            )
                        })}
                </TextField>
                <MoneyInput
                    margin="dense"
                    id="bounty"
                    label="Prime annuelle *"
                    fullWidth
                    value={data.bounty === null ? '' : data.bounty}
                    onChange={(event) => handleChange(event.target.value, 'bounty')}
                />
                <DatePicker
                    margin="dense"
                    id="startAt"
                    label="Date de prise d'effet *"
                    value={data.startAt}
                    onChange={(date) => handleDateChange(date, 'startAt')}
                    fullWidth
                    format="dd/MM/yyyy"
                    autoOk={true}
                />
                <TextField
                    margin="dense"
                    id="renewableNotice"
                    label="Préavis (mois) *"
                    type="number"
                    fullWidth
                    value={data.renewableNotice === null ? '' : data.renewableNotice}
                    onChange={(event) => handleChange(event.target.value, 'renewableNotice')}
                />
                <DatePicker
                    margin="dense"
                    id="renewableDate"
                    label="Date d'échéance *"
                    value={data.renewableDate}
                    onChange={(date) => handleDateChange(date, 'renewableDate')}
                    fullWidth
                    format="dd/MM/yyyy"
                    className="mb-5"
                    autoOk={true}
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>
                    Annuler
                </Button>
                <CustomButton
                    variant="outlined"
                    onClick={handleSubmit}
                    color="primary"
                    disabled={!isFormValid}
                    loading={loading}>
                    Valider
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default ContractDialog
