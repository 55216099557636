import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.User.User_Customers}
    mutation CreateUser(
        $firstname: String!
        $lastname: String!
        $email: String!
        $phone: String
        $customerIds: [ID!]
        $enterpriseIds: [ID!]
    ) {
        createUser(
            input: {
                firstname: $firstname
                lastname: $lastname
                email: $email
                phone: $phone
                projectIds: $customerIds
                enterpriseIds: $enterpriseIds
            }
        ) {
            errors
            status
            statusCode
            user {
                ...User_Customers
            }
        }
    }
`
