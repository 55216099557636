import { Navigate, Outlet } from 'react-router-dom'
import { EnumUser } from '../../../graphql/typings/global_types'
import { MatxLayout } from '../../../matx'
import LoadingDisplay from '../../components/LoadingDisplay'
import RoutesHelper from '../../helpers/RoutesHelper'
import ErrorDisplay from '../../helpers/errors/ErrorDisplay'
import { useAuthUser } from '../../hooks/useAuth'
import useCommonData, { ProvideCommonData } from '../../hooks/useCommonData'
import useContracts, { ProvideContracts } from '../../hooks/useContracts'
import useCustomer, { ProvideCustomer } from '../../hooks/useCustomer'
import { ProvideDisasters, useDisasters } from '../../hooks/useDisasters'
import { NavType } from '../../hooks/useNavigation'
import { ProvideSites } from '../../hooks/useSites'

const CustomerZone = () => {
    const auth = useAuthUser()

    const user = auth.user

    if (user.userType === EnumUser.user) {
        if (user.customers.length === 0) {
            return <ErrorDisplay message="Aucun client associé" debug={user} />
        }

        const customer = user.customers[0]

        return (
            <ProvideCommonData>
                <ProvideCustomer customerId={customer.id}>
                    <ProvideSites customerId={customer.id}>
                        <ProvideDisasters customerId={customer.id}>
                            <ProvideContracts customerId={customer.id}>
                                <CustomerZoneDataLoader />
                            </ProvideContracts>
                        </ProvideDisasters>
                    </ProvideSites>
                </ProvideCustomer>
            </ProvideCommonData>
        )
    } else if (user.userType === EnumUser.admin) {
        // AEA Redirect
        return <Navigate to={RoutesHelper.views.AEA.Home.to()} />
    } else {
        return <ErrorDisplay message="User type is unknown" debug={user} />
    }
}

const CustomerZoneDataLoader = () => {
    const auth = useAuthUser()
    const customerProps = useCustomer()
    const disastersProps = useDisasters()
    const commonProps = useCommonData()
    const contractsProps = useContracts()

    const children =
        customerProps.customerLoading ||
        disastersProps.disastersLoading ||
        commonProps.commonDataLoading ||
        contractsProps.contractsLoading ? (
            <LoadingDisplay />
        ) : customerProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement du client"
                queryError={customerProps.error}
                debug={customerProps.error}
            />
        ) : disastersProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement des sinistres"
                queryError={disastersProps.error}
                debug={disastersProps.error}
            />
        ) : commonProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement des données"
                queryError={commonProps.error}
                debug={commonProps.error}
            />
        ) : contractsProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement des contrats"
                queryError={contractsProps.error}
                debug={contractsProps.error}
            />
        ) : !customerProps.customer ? (
            <ErrorDisplay message={`Erreur lors du chargement du client`} debug={auth} />
        ) : (
            <Outlet />
        )

    return (
        <MatxLayout zone={NavType.customer} customer={customerProps.customer}>
            {children}
        </MatxLayout>
    )
}

export default CustomerZone
