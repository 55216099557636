import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { Fragment, useEffect, useState } from 'react'
import { EnumDocumentStatus, EnumUser } from '../../../graphql/typings/global_types'
import { Document_User } from '../../helpers/data/models/Document'
import { useErrorService } from '../../helpers/errors/ErrorService'
import IconHelper from '../../helpers/IconHelper'
import { useAuthUser } from '../../hooks/useAuth'
import FileDialogHelper, { FileType } from '../../hooks/useFileDialog'
import { useUpdateDocument } from '../../hooks/useMutations'
import useSnackBar from '../../hooks/useSnackBar'
import { UploadManagerToolkit, useUploadFile } from '../../hooks/useUploadManager'
import DataTable from '../DataTable'
import IconText from '../IconText'
import LoadingDisplay from '../LoadingDisplay'
import Panel from '../Panel'
import { FormattedDoc } from './DocumentsList'
import DocumentListDelete from './DocumentsListActions/DocumentListDelete'
import DocumentListDownload from './DocumentsListActions/DocumentListDownload'

type DocumentsWaitingUserActionProps = {
    pendingDocsAskedByAEA: FormattedDoc[]
    rejectedDocs: FormattedDoc[]
}

type DocumentUploadData = {
    document: Document_User
    futureStatus: EnumDocumentStatus
}

const DocumentsWaitingUserAction = ({ pendingDocsAskedByAEA, rejectedDocs }: DocumentsWaitingUserActionProps) => {
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const { openSnack } = useSnackBar()
    const { uploadDocumentFile } = useUploadFile()
    const { getUploadedFileDataAsString } = UploadManagerToolkit()

    const updateDocumentMutation = useUpdateDocument()

    const [loading, setLoading] = useState<boolean>(false)
    const [documentUploadData, setDocumentUploadData] = useState<DocumentUploadData | null>(null)

    const { errorAlert } = useErrorService()

    /************************
     * UPLOAD MANAGEMENT
     ***********************/

    const [dialogPickerNode, openDialogPicker, localFile] = FileDialogHelper.useFileDialog(FileType.ALL)

    useEffect(() => {
        if (localFile) uploadLocalFile(localFile)
    }, [localFile])

    const uploadDoc = (document: Document_User, futureStatus: EnumDocumentStatus) => {
        setDocumentUploadData({ document, futureStatus })
        openDialogPicker()
    }

    const uploadLocalFile = async (localFile: File) => {
        if (!documentUploadData) {
            console.error('No document set to upload file ...')
            return
        }

        setLoading(true)

        const uploadedFileData = await uploadDocumentFile(localFile)
        const fileDataAsString = getUploadedFileDataAsString(uploadedFileData)

        const response = await updateDocumentMutation.run({
            id: documentUploadData.document.id,
            status: documentUploadData?.futureStatus,
            fileDescriptor: fileDataAsString,
        })

        if (response.document) {
            openSnack({
                type: 'success',
                message: "Document envoyé avec succès ! L'administrateur va être notifié pour vérification.",
            })
        } else {
            errorAlert("Erreur lors de l'envoi du document.", response)
        }

        setLoading(false)
    }

    /************************
     * TABLE MANAGEMENT
     ***********************/

    const pendingDocsColumns: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Nom',
        },
        {
            name: 'linkedTo',
            label: 'Associé à',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const document = pendingDocsAskedByAEA[dataIndex]

                    return document.linkedToIcon ? (
                        <IconText icon={document.linkedToIcon} text={document.linkedTo} side="left" />
                    ) : (
                        document.linkedTo
                    )
                },
            },
        },
        {
            name: 'category',
            label: 'Type',
        },
        {
            name: 'statusComment',
            label: 'Commentaire',
        },
    ]

    const pendingDocsActionsColumn = (dataIndex: number, rowIndex: number): JSX.Element => {
        const document = pendingDocsAskedByAEA[dataIndex].document

        if (loading) {
            return <LoadingDisplay />
        }

        if (isAdmin) {
            return (
                <div className="flex items-center">
                    <div className="flex-grow"></div>
                    <DocumentListDelete document={document} />
                </div>
            )
        } else {
            return (
                <div className="flex items-center">
                    <div className="flex-grow"></div>
                    <Tooltip title="Envoyer le document">
                        <IconButton onClick={() => uploadDoc(document, EnumDocumentStatus.answered)} color="primary">
                            <Icon>backup</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    }

    const uploadRejectedDoc = (document: Document_User) => {
        const newStatus: EnumDocumentStatus =
            document.status === EnumDocumentStatus.submission_rejected
                ? EnumDocumentStatus.submitted_by_user
                : EnumDocumentStatus.answered

        uploadDoc(document, newStatus)
    }

    const rejectedDocsColumn: MUIDataTableColumnDef[] = [
        {
            name: 'title',
            label: 'Nom',
        },
        {
            name: 'linkedTo',
            label: 'Associé à',
            options: {
                customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                    const document = rejectedDocs[dataIndex]

                    return document.linkedToIcon ? (
                        <IconText icon={document.linkedToIcon} text={document.linkedTo} side="left" />
                    ) : (
                        document.linkedTo
                    )
                },
            },
        },
        {
            name: 'category',
            label: 'Type',
        },
        {
            name: 'statusComment',
            label: 'Commentaire',
        },
    ]

    const rejectedDocsActionColumn = (dataIndex: number, rowIndex: number): JSX.Element => {
        const document = rejectedDocs[dataIndex].document

        if (loading) {
            return <LoadingDisplay size={2} />
        }

        if (isAdmin) {
            return (
                <div className="flex items-center">
                    <div className="flex-grow"></div>
                    <DocumentListDownload document={document} />
                    {/* <Tooltip title="Supprimer">
                        <IconButton onClick={() => deleteDoc(document)} style={{ color: 'red' }}>
                            <Icon>delete</Icon>
                        </IconButton>
                    </Tooltip> */}
                </div>
            )
        } else {
            return (
                <div className="flex items-center">
                    <div className="flex-grow"></div>
                    <DocumentListDownload document={document} />
                    <Tooltip title="Envoyer un nouveau document">
                        <IconButton onClick={() => uploadRejectedDoc(document)} color="primary">
                            <Icon>backup</Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
    }

    return (
        <Fragment>
            <Panel>
                <DataTable<FormattedDoc>
                    title={`Documents demandés par AEA`}
                    icon={IconHelper.elementIcons.pendingDocsIcon}
                    data={pendingDocsAskedByAEA}
                    columns={pendingDocsColumns}
                    showSearch={true}
                    actionsColumn={pendingDocsActionsColumn}
                    options={{
                        filter: true,
                        filterType: 'multiselect',
                    }}
                />
            </Panel>
            <Panel>
                <DataTable<FormattedDoc>
                    title={`Documents rejetés par AEA`}
                    icon={IconHelper.elementIcons.pendingDocsIcon}
                    data={rejectedDocs}
                    columns={rejectedDocsColumn}
                    showSearch={true}
                    actionsColumn={rejectedDocsActionColumn}
                    options={{
                        filter: true,
                        filterType: 'multiselect',
                    }}
                />
            </Panel>
            {dialogPickerNode}
        </Fragment>
    )
}

export default DocumentsWaitingUserAction
