import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Insurance.Insurance_Minimal}
    query GetInsurances_Minimal {
        insurances {
            ...Insurance_Minimal
        }
    }
`
