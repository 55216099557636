import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import { default as React, Fragment, useMemo, useState } from 'react'
import { EnumUser } from '../../../../../../graphql/typings/global_types'
import { DisasterPayment_Minimal } from '../../../../../helpers/data/models/disaster/DisasterPayment'
import DateHelper from '../../../../../helpers/DateHelper'
import DisasterHelper from '../../../../../helpers/DisasterHelper'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useDisasterLoadedExisting } from '../../../../../hooks/useDisaster'
import { useDeleteDisasterPayment } from '../../../../../hooks/useMutations'
import useSnackBar from '../../../../../hooks/useSnackBar'
import MoneyDisplayAnalyzer from '../../../../customInputs/MoneyDisplayAnalyzer'
import DataTable from '../../../../DataTable'
import { ConfirmDialog } from '../../../../Dialog'
import PaymentDialog from './PaymentDialog'

type PaymentData = {
    description: string
    amount: number
    dueDate: string
    paymentDate: string
    status: string
    originalPayment: DisasterPayment_Minimal
}

type PaymentDialogData =
    | {
          open: false
      }
    | {
          open: true
          payment: DisasterPayment_Minimal
      }

const DisasterIndemnityPayments = () => {
    const { disaster } = useDisasterLoadedExisting()
    const { fromServertoString } = DateHelper()
    const { openSnack } = useSnackBar()
    const deleteDisasterPaymentMutation = useDeleteDisasterPayment()
    const { errorAlert } = useErrorService()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<PaymentDialogData>({ open: false })
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<PaymentDialogData>({ open: false })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    const getPayments = (): PaymentData[] => {
        const payments: PaymentData[] = []

        disaster.payments.forEach((payment) => {
            payments.push({
                description: payment.description || '[Aucune description]',
                amount: payment.amount,
                dueDate: payment.scheduled_at ? fromServertoString(payment.scheduled_at) : '-',
                paymentDate: payment.paid_at ? fromServertoString(payment.paid_at) : '-',
                status: DisasterHelper.getDisasterPaymentStatusLabel(payment.status),
                originalPayment: payment,
            })
        })

        return payments
    }

    const onEditClick = (paymentData: PaymentData) => {
        setOpenEditDialog({ open: true, payment: paymentData.originalPayment })
    }

    const onEdited = () => {
        setOpenEditDialog({ open: false })
        openSnack({
            type: 'success',
            message: 'Paiement modifié avec succès !',
        })
    }

    const onDeleteClick = (paymentData: PaymentData) => {
        setOpenDeleteConfirm({ open: true, payment: paymentData.originalPayment })
    }

    const onDeleteCancelled = () => {
        setOpenDeleteConfirm({ open: false })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteConfirm.open) return

        setDeleteLoading(true)

        try {
            const response = await deleteDisasterPaymentMutation.run({
                disasterId: disaster.id,
                id: openDeleteConfirm.payment.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression du paiement'
            } else {
                openSnack({
                    type: 'success',
                    message: 'Paiement supprimé avec succès !',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteConfirm({ open: false })
        }
    }

    const payments: PaymentData[] = getPayments()

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'description',
                label: 'Libellé',
            },
            {
                name: 'amount',
                label: 'Montant',
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        const payment = payments[dataIndex]

                        return <MoneyDisplayAnalyzer value={payment.amount} />
                    },
                },
            },
            {
                name: 'dueDate',
                label: "Date d'échéance",
            },
            {
                name: 'paymentDate',
                label: 'Date de paiement',
            },
            {
                name: 'status',
                label: 'Statut',
            },
        ],
        [disaster]
    )

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'une échéance`,
    }

    const onCreated = (payment: DisasterPayment_Minimal) => {
        openSnack({
            type: 'success',
            message: `Une échéance d'une valeur de ${payment.amount} a été créé avec succès !`,
        })
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const payment = payments[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier l'indemnité">
                    <IconButton onClick={() => onEditClick(payment)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer l'indemnité">
                    <IconButton onClick={() => onDeleteClick(payment)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <Fragment>
            <DataTable
                title={`Echéances d'indemnisation`}
                data={payments}
                columns={columns}
                showSearch={true}
                addFeature={addFeatureData}
                actionsColumn={actionsColumn}
                options={{
                    sortOrder: {
                        name: 'dueDate',
                        direction: 'asc',
                    },
                }}
            />
            <PaymentDialog onDone={onCreated} open={openAddDialog} closeDialog={() => setOpenAddDialog(false)} />
            {openEditDialog.open && (
                <PaymentDialog
                    payment={openEditDialog.payment}
                    onDone={onEdited}
                    open={openEditDialog.open}
                    closeDialog={() => setOpenEditDialog({ open: false })}
                />
            )}
            {openDeleteConfirm.open && (
                <ConfirmDialog
                    open={openDeleteConfirm.open}
                    title="Suppression d'un paiement"
                    text="Voulez-vous vraiment supprimer ce paiement ? Tous les calculs de dédommagement associés seront affectés."
                    onConfirmed={onDeleteConfirmed}
                    onCancelled={onDeleteCancelled}
                    loading={deleteLoading}
                />
            )}
        </Fragment>
    )
}

export default DisasterIndemnityPayments
