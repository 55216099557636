import { gql } from '@apollo/client/core'

export default gql`
    mutation CreatePlace($title: String!, $address: String!, $wallsOwner: String!, $enterpriseId: ID!, $siteId: ID) {
        createLocal(
            input: {
                title: $title
                address: $address
                wallsOwner: $wallsOwner
                enterpriseId: $enterpriseId
                siteId: $siteId
            }
        ) {
            errors
            status
            statusCode
            place: local {
                id
                title
                address
                wallsOwner
                enterprise {
                    id
                }
                site {
                    id
                }
            }
        }
    }
`
