import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DateHelper from '../../../../../helpers/DateHelper'
import { useContractLoadedExisting } from '../../../../../hooks/useContract'
import useNavContext from '../../../../../hooks/useNavContext'
import { useSnackBar } from '../../../../../hooks/useSnackBar'
import IconText from '../../../../IconText'
import Panel from '../../../../Panel'
import TableHeader from '../../../../TableHeader'
import MoneyDisplayAnalyzer from '../../../../customInputs/MoneyDisplayAnalyzer'
import ContractDialog from '../../ContractDialog'

const ContractGeneralData = () => {
    const { contract } = useContractLoadedExisting()
    const { openSnack } = useSnackBar()
    const navigate = useNavigate()
    const { getEnterpriseRoute } = useNavContext()
    const { fromServertoString } = DateHelper()

    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: `Contrat ${contract.title} modifié avec succès !`,
        })
    }

    const onEnterpriseClick = () => {
        navigate(getEnterpriseRoute(contract.enterprise.id))
    }

    return (
        <Panel>
            <Fragment>
                <TableHeader title={'Informations générales'} editClick={() => setEditDialogOpen(true)} />
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className="pl-4">Entreprise</TableCell>
                            <TableCell>
                                <IconText
                                    text={contract.enterprise.title}
                                    icon="logout"
                                    side="right"
                                    iconClick={onEnterpriseClick}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="pl-4">Identifiant de contrat</TableCell>
                            <TableCell>{contract.reference}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="pl-4">Assurance</TableCell>
                            <TableCell>{contract.insurance.title}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="pl-4">Prime annuelle</TableCell>
                            <TableCell>
                                <MoneyDisplayAnalyzer value={contract.bounty} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="pl-4">Prise d'effet le</TableCell>
                            <TableCell>{fromServertoString(contract.startAt)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="pl-4">Echéance le</TableCell>
                            <TableCell>{fromServertoString(contract.renewableDate)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="pl-4">Préavis</TableCell>
                            <TableCell>{contract.renewableNotice} mois</TableCell>
                        </TableRow>
                        {contract.insurer && (
                            <TableRow>
                                <TableCell className="pl-4">Agent général</TableCell>
                                <TableCell>{contract.insurer.title}</TableCell>
                            </TableRow>
                        )}
                        {contract.broker && (
                            <TableRow>
                                <TableCell className="pl-4">Courtier</TableCell>
                                <TableCell>{contract.broker.title}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <ContractDialog
                    open={editDialogOpen}
                    closeDialog={() => setEditDialogOpen(false)}
                    contract={contract}
                    onDone={onEdited}
                />
            </Fragment>
        </Panel>
    )
}

export default ContractGeneralData
