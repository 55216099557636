import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import React, { Fragment, useMemo, useState } from 'react'
import { EnumUser } from '../../../../../../graphql/typings/global_types'
import UserHelper from '../../../../../helpers/UserHelper'
import { useAuthUser } from '../../../../../hooks/useAuth'
import { useCustomerLoadedExisting } from '../../../../../hooks/useCustomer'
import { useEnterpriseLoadedExisting } from '../../../../../hooks/useEnterprise'
import useSnackBar from '../../../../../hooks/useSnackBar'
import Panel from '../../../../Panel'
import TableHeader from '../../../../TableHeader'
import EnterpriseDialog from './EnterpriseDialog'

type EnterpriseInfoType = {
    title: string
    value: string | number
}

const EnterpriseGeneralMainData = () => {
    const { user } = useAuthUser()
    const { customer } = useCustomerLoadedExisting()
    const { enterprise } = useEnterpriseLoadedExisting()
    const { openSnack } = useSnackBar()

    const [editEnterpriseOpen, setEditEnterpriseOpen] = useState<boolean>(false)

    const enterpriseInfo: EnterpriseInfoType[] = useMemo(() => {
        const enterpriseInfos = [
            {
                title: 'Raison sociale',
                value: enterprise.title,
            },
            {
                title: 'Adresse du siège social',
                value: enterprise.address || 'Non renseigné',
            },
        ]

        if (user.userType === EnumUser.admin) {
            const users = enterprise.notificationUsers
            enterpriseInfos.push({
                title: 'Utilisateurs notifiés',
                value:
                    users.length > 0
                        ? users.map((user) => UserHelper.getFullName(user.firstname, user.lastname)).join(', ')
                        : 'Aucun',
            })
        }

        return enterpriseInfos
    }, [customer, enterprise])

    const onEdited = () => {
        openSnack({
            type: 'success',
            message: 'Entreprise éditée avec succès !',
        })
    }

    const openEdit = () => {
        setEditEnterpriseOpen(true)
    }

    return (
        <Fragment>
            <Panel>
                <Fragment>
                    <TableHeader title={`Informations générales de l'entreprise`} editClick={openEdit} />
                    <Table>
                        <TableBody>
                            {enterpriseInfo.map((item, ind) => (
                                <TableRow key={ind}>
                                    <TableCell className="pl-4">{item.title}</TableCell>
                                    <TableCell>{item.value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Fragment>
            </Panel>
            <EnterpriseDialog
                enterprise={enterprise}
                onDone={onEdited}
                open={editEnterpriseOpen}
                closeDialog={() => setEditEnterpriseOpen(false)}
            />
        </Fragment>
    )
}

export default EnterpriseGeneralMainData
