import React from 'react'
import Places from '../../../../components/customer/places/Places'
import { useCustomerLoadedExisting } from '../../../../hooks/useCustomer'

const AEACustomerPlaces = () => {
    const { customer } = useCustomerLoadedExisting()

    return (
        <div className="m-sm-30">
            <div className="mb-sm-30">
                <Places places={customer.places} />
            </div>
        </div>
    )
}

export default AEACustomerPlaces
