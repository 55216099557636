import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.User.User_Minimal}
    query GetUsers_Minimal($userTypes: [EnumUser!]) {
        users(userTypes: $userTypes) {
            ...User_Minimal
        }
    }
`
