import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { MUIDataTableColumnDef } from 'mui-datatables'
import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EnumDisasterStatus, EnumUser } from '../../../graphql/typings/global_types'
import DateHelper from '../../helpers/DateHelper'
import DisasterHelper from '../../helpers/DisasterHelper'
import IconHelper from '../../helpers/IconHelper'
import { Disaster_Minimal } from '../../helpers/data/models/disaster/Disaster'
import { useErrorService } from '../../helpers/errors/ErrorService'
import { useAuthUser } from '../../hooks/useAuth'
import { useCommonDataLoadedExisting } from '../../hooks/useCommonData'
import { useDeleteDisaster } from '../../hooks/useMutations'
import { useNavContext } from '../../hooks/useNavContext'
import useSnackBar from '../../hooks/useSnackBar'
import DataTable from '../DataTable'
import { DateRange } from '../DateCategoryInput'
import { ConfirmDialog } from '../Dialog'
import Panel from '../Panel'
import MoneyDisplayAnalyzer from '../customInputs/MoneyDisplayAnalyzer'
import DisasterDialog, { ContractForDisasterDialog } from './DisasterDialog'

type DisastersListProps = {
    disasters: Disaster_Minimal[]
    contracts: ContractForDisastersList[]
    contractId?: string
    dateRange?: DateRange
}

type DisasterData = {
    date: string
    title: string
    status: string
    internalReference: string
    amountNotPaid: number
    baseDisaster: Disaster_Minimal
}

type ContractForDisastersList = ContractForDisasterDialog

type DialogState =
    | {
          open: true
          disaster: Disaster_Minimal
      }
    | { open: false }

const DisastersList = ({ disasters, contracts, contractId, dateRange }: DisastersListProps) => {
    const navigate = useNavigate()
    const { openSnack } = useSnackBar()
    const { getDisasterRoute } = useNavContext()
    const { disastersEvents } = useCommonDataLoadedExisting()
    const { fromServertoString, isInDateRange } = DateHelper()
    const { errorAlert } = useErrorService()
    const auth = useAuthUser()
    const isAdmin = auth.user.userType === EnumUser.admin
    const deleteDisasterMutation = useDeleteDisaster()

    const [openAddDialog, setOpenAddDialog] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<DialogState>({ open: false })
    const [openDeleteDialog, setOpenDeleteDialog] = useState<DialogState>({
        open: false,
    })
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)

    // const defaultStatusesLabel = useMemo(
    //     () =>
    //         [
    //             EnumDisasterStatus.open,
    //             EnumDisasterStatus.pending_price_calculation,
    //             EnumDisasterStatus.waiting_payments,
    //         ].map(DisasterHelper.getDisasterStatusLabel),
    //     []
    // )

    const columns: MUIDataTableColumnDef[] = useMemo(
        () => [
            {
                name: 'date',
                label: 'Date',
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        const disaster = formattedDisasters[dataIndex]
                        const formattedDate = fromServertoString(disaster.date)

                        return <div>{formattedDate}</div>
                    },
                    filter: false,
                },
            },
            {
                name: 'title',
                label: 'Libellé',
                options: {
                    filter: false,
                },
            },
            {
                name: 'internalReference',
                label: 'Référence AEA',
                options: {
                    filter: false,
                },
            },
            {
                name: 'status',
                label: 'Statut',
                options: {
                    filter: true,
                    filterType: 'multiselect',
                    // filterList: defaultStatusesLabel,
                },
            },
            {
                name: 'amountNotPaid',
                label: 'Reste à percevoir',
                options: {
                    customBodyRenderLite: (dataIndex: number, rowIndex: number) => {
                        const disaster = formattedDisasters[dataIndex]

                        return <MoneyDisplayAnalyzer value={disaster.amountNotPaid} />
                    },
                    filter: false,
                },
            },
        ],
        [disasters, disastersEvents]
    )

    const addFeatureData = {
        addHandler: () => {
            setOpenAddDialog(true)
        },
        tooltip: `Ajout d'un sinistre`,
    }

    const onRowClick = (disasterData: DisasterData) => {
        navigate(getDisasterRoute(disasterData.baseDisaster.id))
    }

    const onCreated = () => {
        openSnack({
            type: 'success',
            message: `Sinistre créé avec succès !`,
        })
    }

    const getFormattedDisasters = useCallback((): DisasterData[] => {
        const filteredDisasters = !dateRange
            ? disasters
            : disasters.filter((disaster) => isInDateRange(disaster.date, dateRange))

        const formattedDisasters: DisasterData[] = filteredDisasters.map((disaster) => {
            let amountNotPaid = 0

            if (disaster.status !== EnumDisasterStatus.closed) {
                let amountPaid = 0
                let duedIndemnitiesAmount = 0

                disaster.disasterDamages.forEach((damage) => {
                    duedIndemnitiesAmount += damage.indemnity || 0
                })

                disaster.payments.forEach((payment) => {
                    if (!payment.paid_at) return

                    amountPaid += payment.amount
                })

                amountNotPaid = duedIndemnitiesAmount - amountPaid
            }

            return {
                date: disaster.date,
                title: disaster.title,
                status: DisasterHelper.getDisasterStatusLabel(disaster.status),
                amountNotPaid,
                internalReference: disaster.internalReference || '-',
                baseDisaster: disaster,
            }
        })

        return formattedDisasters
    }, [disasters, dateRange])

    const formattedDisasters = getFormattedDisasters()

    const onEdited = () => {
        openSnack({
            message: 'Sinistre modifié avec succès !',
            type: 'success',
        })
    }

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, disaster: Disaster_Minimal) => {
        event.stopPropagation()
        setOpenEditDialog({ open: true, disaster })
    }

    const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, disaster: Disaster_Minimal) => {
        event.stopPropagation()
        setOpenDeleteDialog({ open: true, disaster })
    }

    const onDeleteConfirmed = async () => {
        if (!openDeleteDialog.open) return

        setDeleteLoading(true)

        try {
            const disaster = openDeleteDialog.disaster

            const response = await deleteDisasterMutation.run({
                id: disaster.id,
                enterpriseId: disaster.contract.enterprise.id,
                contractId: disaster.contract.id,
            })

            if (response.errors.length > 0) {
                throw 'Une erreur est survenue pendant la suppression du sinistre.'
            } else {
                openSnack({
                    message: 'Sinistre supprimé avec succès !',
                    type: 'success',
                })
            }
        } catch (error) {
            errorAlert(error)
        } finally {
            setDeleteLoading(false)
            setOpenDeleteDialog({ open: false })
        }
    }

    const actionsColumn = (dataIndex: number, rowIndex: number) => {
        if (!isAdmin) return null

        const disaster = disasters[dataIndex]

        return (
            <div className="flex items-center">
                <div className="flex-grow"></div>
                <Tooltip title="Modifier le sinistre">
                    <IconButton onClick={(event) => onEditClick(event, disaster)}>
                        <Icon>edit</Icon>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Supprimer le sinistre">
                    <IconButton onClick={(event) => onDeleteClick(event, disaster)}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    return (
        <Panel>
            <Fragment>
                <DataTable<DisasterData>
                    title={'Sinistres'}
                    icon={IconHelper.elementIcons.disasterIcon}
                    data={formattedDisasters}
                    columns={columns}
                    showSearch={true}
                    addFeature={addFeatureData}
                    rowClick={onRowClick}
                    actionsColumn={actionsColumn}
                    options={{
                        filter: true,
                        filterType: 'dropdown',
                        sortOrder: {
                            name: 'date',
                            direction: 'desc',
                        },
                    }}
                />
                <DisasterDialog
                    open={openAddDialog}
                    closeDialog={() => setOpenAddDialog(false)}
                    onDone={onCreated}
                    disastersEvents={disastersEvents}
                    contracts={contracts}
                    contractId={contractId}
                />
                {openEditDialog.open && (
                    <DisasterDialog
                        onDone={onEdited}
                        open={openEditDialog.open}
                        closeDialog={() => setOpenEditDialog({ open: false })}
                        disastersEvents={disastersEvents}
                        contracts={contracts}
                        disaster={openEditDialog.disaster}
                    />
                )}
                {openDeleteDialog.open && (
                    <ConfirmDialog
                        open={openDeleteDialog.open}
                        onCancelled={() => setOpenDeleteDialog({ open: false })}
                        onConfirmed={onDeleteConfirmed}
                        title="Suppression d'un sinistre"
                        text={`Voulez-vous vraiment supprimer le sinistre "${openDeleteDialog.disaster.title}" ? Tous les éléments saisis pour le sinistre seront également supprimés.`}
                        loading={deleteLoading}
                    />
                )}
            </Fragment>
        </Panel>
    )
}

export default DisastersList
