import { useGetContractDocuments } from '../../../../../helpers/data/getContract'
import { Document_User } from '../../../../../helpers/data/models/Document'
import ErrorDisplay from '../../../../../helpers/errors/ErrorDisplay'
import { useContractLoadedExisting } from '../../../../../hooks/useContract'
import DocumentsList from '../../../../documentsList/DocumentsList'
import LoadingDisplay from '../../../../LoadingDisplay'

const ContractDocuments = () => {
    const { contract } = useContractLoadedExisting()
    const contractForDocumentsState = useGetContractDocuments(contract.id)

    if (contractForDocumentsState.contractLoading) {
        return <LoadingDisplay />
    } else if (contractForDocumentsState.contract === null) {
        return <ErrorDisplay message="Erreur lors de la récupération des documents du contrat" />
    } else return <ContractDocumentsReady documents={contractForDocumentsState.contract.documents} />
}

type ContractDocumentsReadyProps = {
    documents: Document_User[]
}

const ContractDocumentsReady = ({ documents }: ContractDocumentsReadyProps) => {
    return <DocumentsList documents={documents} mode="confirmed" showLink={false} />
}

export default ContractDocuments
