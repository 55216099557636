import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.RoadmapItem.RoadmapItem_Minimal}
    mutation UpdateRoadmapItem($id: ID!, $customerId: ID!, $date: String!, $title: String!, $description: String) {
        updateRoadmapItem(
            input: { id: $id, projectId: $customerId, date: $date, title: $title, description: $description }
        ) {
            errors
            status
            statusCode
            roadmapItem {
                ...RoadmapItem_Minimal
            }
        }
    }
`
