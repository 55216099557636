import { useTheme } from '@material-ui/core'

type SpacerProps = {
    size?: number
}

export default function Spacer({ size = 4 }: SpacerProps) {
    const theme = useTheme()
    const spacing = theme.spacing(size)

    return <div style={{ width: spacing, height: spacing, flexShrink: 0 }} />
}
