import Icon from '@material-ui/core/Icon/Icon'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { useState } from 'react'
import { DocumentGeneric_document } from '../../../../graphql/queries/typings/DocumentGeneric'
import { DocLinkedElementsIDs } from '../../../helpers/data/models/Document'
import DocumentHelper from '../../../helpers/DocumentHelper'
import { useErrorService } from '../../../helpers/errors/ErrorService'
import { useCustomerLoadedExisting } from '../../../hooks/useCustomer'
import { useDeleteDocument } from '../../../hooks/useMutations'
import useSnackBar from '../../../hooks/useSnackBar'
import { ConfirmDialog } from '../../Dialog'

type DocumentForDelete = Pick<DocumentGeneric_document, 'id' | 'title'> & DocLinkedElementsIDs

type DocumentListDeleteProps = {
    document: DocumentForDelete
}

const DocumentListDelete = ({ document }: DocumentListDeleteProps) => {
    const { errorAlert } = useErrorService()
    const { customer } = useCustomerLoadedExisting()
    const deleteDocumentMutation = useDeleteDocument()
    const { openSnack } = useSnackBar()

    const [deleting, setDeleting] = useState<boolean>(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)

    const deleteDoc = () => {
        setOpenDeleteDialog(true)
    }

    const onDeleteConfirmed = async () => {
        setDeleting(true)

        const { parentType, parentId } = DocumentHelper.getDocumentParentData(document)

        const response = await deleteDocumentMutation.run({
            id: document.id,
            customerId: customer.id,
            parentType,
            parentId,
        })

        if (response.errors && response.errors.length > 0) {
            errorAlert('Une erreur est survenue pendant la suppression du document.', response)
        } else {
            openSnack({
                type: 'success',
                message: `Document ${document.title} supprimé avec succès !`,
            })
        }

        setDeleting(false)
        setOpenDeleteDialog(false)
    }

    const onDeleteCancelled = () => {
        setOpenDeleteDialog(false)
    }

    return (
        <>
            <Tooltip title="Supprimer">
                <IconButton onClick={deleteDoc} style={{ color: 'red' }}>
                    <Icon>delete</Icon>
                </IconButton>
            </Tooltip>
            <ConfirmDialog
                open={openDeleteDialog}
                title="Suppression d'un document"
                text="Voulez-vous vraiment supprimer ce document ? Cette action est irréversible."
                onConfirmed={onDeleteConfirmed}
                onCancelled={onDeleteCancelled}
                loading={deleting}
            />
        </>
    )
}

export default DocumentListDelete
