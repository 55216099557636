import { gql } from "@apollo/client/core";
import Agreement_Minimal from '../Agreement/Agreement_Minimal'

export default gql`
    ${Agreement_Minimal}
    fragment Enterprise_Minimal on Enterprise {
        id
        address
        title
        agreements {
            ...Agreement_Minimal
        }
    }
`
