import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Disaster.Disaster_Documents}
    query GetDisaster_Documents($disasterId: ID!) {
        disaster(id: $disasterId) {
            ...Disaster_Documents
        }
    }
`
