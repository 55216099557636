import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'

type IconTextProps = {
    icon: string
    iconClick?: () => void
    text: string
    side: 'right' | 'left'
}

const IconText = ({ icon, text, side, iconClick }: IconTextProps) => {
    const iconSimple = <Icon fontSize="small">{icon}</Icon>
    const iconElement = iconClick ? (
        <IconButton size="small" onClick={iconClick}>
            {iconSimple}
        </IconButton>
    ) : (
        iconSimple
    )
    const sideElement = (
        <Grid item style={{ verticalAlign: 'middle' }}>
            {iconElement}
        </Grid>
    )

    return (
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
            {side === 'left' && sideElement}
            <Grid item>
                <span>{text}</span>
            </Grid>
            {side === 'right' && sideElement}
        </Grid>
    )
}

export default IconText
