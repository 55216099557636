import React, { createContext, ReactElement, useState } from 'react'

import { merge } from 'lodash'
import { MatxLayoutSettings, MatxSettings } from '../../matx/components/MatxLayout/settings'

const SettingsContext = createContext({
    settings: MatxLayoutSettings,
    updateSettings: (params?: any) => {},
})

//TODO Improve
type SettingsProviderProps = {
    settings?: MatxSettings
    children: ReactElement
}

export const SettingsProvider = ({ settings, children }: SettingsProviderProps) => {
    const [currentSettings, setCurrentSettings] = useState(settings || MatxLayoutSettings)

    const handleUpdateSettings = (update = {}) => {
        const merged = merge({}, currentSettings, update)
        setCurrentSettings(merged)
    }

    return (
        <SettingsContext.Provider
            value={{
                settings: currentSettings,
                updateSettings: handleUpdateSettings,
            }}>
            {children}
        </SettingsContext.Provider>
    )
}

export default SettingsContext
