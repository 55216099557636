import { Box, Button, Typography } from '@material-ui/core'
import { QueryError, QueryErrorType } from './ErrorHelper'
import ErrorService from './ErrorService'
import Spacer from '../../components/Spacer'
import BlockIcon from '@material-ui/icons/Block'
import MailIcon from '@material-ui/icons/Mail'

type ErrorDisplayProps = {
    message: string
    debug?: any
    queryError?: QueryError
}

const ErrorDisplay = ({ message, debug, queryError }: ErrorDisplayProps) => {
    ErrorService.logError(message, debug)

    const onMailClick = () => {
        const email = 'contact@australexpertdassure.com'
        const subject = `J'ai besoin d'aide`

        document.location.href = `mailto:${email}?subject=${subject}`
    }

    return queryError && queryError.errorType === QueryErrorType.ACCESS_FORBIDDEN ? (
        <Box textAlign="center" maxWidth={600} margin="auto">
            <Spacer size={2} />
            <BlockIcon fontSize="large" style={{ color: 'red' }} />
            <Spacer size={1} />
            <h3 className="m-0 mb-6">Accès restreint</h3>
            <Spacer size={1} />
            <Typography variant="body1">
                Vos droits d'accès ne vous permettent pas d'accéder au contenu de cette page. Si c'est une erreur, merci
                d'en informer votre contact chez AEA ou envoyez un email à contact@australexpertdassure.com.
            </Typography>
            <Spacer size={3} />
            <Button
                size="medium"
                className="px-5 mr-1"
                startIcon={<MailIcon />}
                onClick={onMailClick}
                variant="contained"
                color="primary">
                Contacter
            </Button>
        </Box>
    ) : (
        <div style={{ color: 'red' }} className="p-6">
            {message}
        </div>
    )
}

export default ErrorDisplay
