import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Document.Document_User}
    mutation UpdateDocument(
        $id: ID!
        $title: String
        $parentId: ID
        $parentType: EnumDocumentParent
        $category: EnumDocumentCategory
        $askedById: ID
        $status: EnumDocumentStatus
        $statusComment: String
        $fileDescriptor: String
    ) {
        updateDocument(
            input: {
                id: $id
                documentInput: {
                    title: $title
                    parentId: $parentId
                    parentType: $parentType
                    category: $category
                    askedById: $askedById
                    status: $status
                    statusComment: $statusComment
                    fileDescriptor: $fileDescriptor
                }
            }
        ) {
            errors
            status
            statusCode
            document {
                ...Document_User
            }
        }
    }
`
