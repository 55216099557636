import { gql } from "@apollo/client/core";
import fragments from '../fragments'

export default gql`
    ${fragments.Prevention.Prevention_Minimal}
    mutation UpdatePrevention(
        $id: ID!
        $preventionCategoryId: ID!
        $placeId: ID!
        $description: String
        $startAt: String!
        $endAt: String
    ) {
        updatePrevention(
            input: {
                id: $id
                preventionCategoryId: $preventionCategoryId
                localId: $placeId
                description: $description
                startAt: $startAt
                endAt: $endAt
            }
        ) {
            errors
            status
            statusCode
            prevention {
                ...Prevention_Minimal
            }
        }
    }
`
