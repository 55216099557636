import { gql } from "@apollo/client/core";

export default gql`
    fragment DisasterPayment_Minimal on DisasterPayment {
        id
        amount
        scheduled_at: scheduledAt
        paid_at: paidAt
        description
        status
    }
`
