import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Step from '@material-ui/core/Step/Step'
import StepLabel from '@material-ui/core/StepLabel/StepLabel'
import Stepper from '@material-ui/core/Stepper/Stepper'
import { useMemo, useState } from 'react'
import { DocumentGeneric_document } from '../../../../../../graphql/queries/typings/DocumentGeneric'
import { EnumDocumentCategory, EnumDocumentParent } from '../../../../../../graphql/typings/global_types'
import { DocLinkedElementsIDs } from '../../../../../helpers/data/models/Document'
import DocumentHelper from '../../../../../helpers/DocumentHelper'
import { useErrorService } from '../../../../../helpers/errors/ErrorService'
import Spacer from '../../../../Spacer'
import DocumentManagerAssociation, {
    DMA_Data,
} from '../DocumentFormsComponents/DocumentManagerAssociation/DocumentManagerAssociation'
import DocumentEditFile from './DocumentEditFile'
import DocumentEditInformation from './DocumentEditInformation'
import DocumentEditUploading from './DocumentEditUploading'

export type DocumentForEdit = Pick<
    DocumentGeneric_document,
    'id' | 'title' | 'activeUrl' | 'category' | 'statusComment' | 'status'
> &
    DocLinkedElementsIDs

export enum DocumentEditMode {
    EDITING = 'editing',
    VALIDATING = 'validating',
    ANSWERING = 'answering',
}

export type DocumentEditProps = {
    open: boolean
    closeDialog: () => void
    onDone: () => void
    document: DocumentForEdit
    mode: DocumentEditMode
}

/**
 * THIS DOCUMENT FORM IS USED FOR EDITING & VALIDATING DOCUMENTS
 */

const DocumentEdit = (props: DocumentEditProps) => {
    const title: string = useMemo(() => {
        return getTitle(props.mode)
    }, [props.mode])

    return (
        <Dialog open={props.open} onClose={props.closeDialog}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DocumentEditReady {...props} />
            </DialogContent>
        </Dialog>
    )
}

type DocumentEditReadyProps = DocumentEditProps

export type DocumentEditData = {
    title: string
    newFile: File | null
    category: EnumDocumentCategory | null
    docElementType: EnumDocumentParent | null
    docElementId: string | null
    statusComment: string | null
}

export type DocumentManagerNavProps = {
    canGoPrevious: boolean
    canGoNext: boolean
    goPrevious: () => void
    goNext: () => void
}

const DocumentEditReady = (props: DocumentEditReadyProps) => {
    const { errorAlert } = useErrorService()

    const { document } = props

    const baseDocData = useMemo((): DocumentEditData => {
        const { parentType, parentId } = DocumentHelper.getDocumentParentData(document)

        return {
            title: document.title,
            newFile: null,
            category: document.category,
            docElementType: parentType,
            docElementId: parentId,
            statusComment: document.statusComment,
        }
    }, [document])

    const steps: DocumentEditSteps[] = useMemo(() => {
        return getSteps(props.mode)
    }, [])

    const [step, setStep] = useState<DocumentEditSteps>(steps[0])
    const [data, setData] = useState<DocumentEditData>(baseDocData)

    const canGoPrevious = useMemo(() => {
        return step === DocumentEditSteps.INFORMATION || step === DocumentEditSteps.ASSOCIATION
    }, [step])

    const canGoNext = useMemo(() => {
        return (
            step === DocumentEditSteps.UPLOAD ||
            step === DocumentEditSteps.INFORMATION ||
            step === DocumentEditSteps.ASSOCIATION
        )
    }, [step])

    const goPrevious = () => {
        switch (step) {
            case DocumentEditSteps.INFORMATION:
                setStep(DocumentEditSteps.UPLOAD)
                break
            case DocumentEditSteps.ASSOCIATION:
                setStep(DocumentEditSteps.INFORMATION)
                break
            default:
                errorAlert('Oops, une erreur est survenue.', { function: 'goPrevious', step })
        }
    }

    const goNext = () => {
        switch (step) {
            case DocumentEditSteps.UPLOAD:
                setStep(DocumentEditSteps.INFORMATION)
                break
            case DocumentEditSteps.INFORMATION:
                setStep(DocumentEditSteps.ASSOCIATION)
                break
            case DocumentEditSteps.ASSOCIATION:
                setStep(DocumentEditSteps.LOADING)
                break
            default:
                errorAlert('Oops, une erreur est survenue.', { function: 'goNext', step })
        }
    }

    const setAssociationData = (data: DMA_Data) => {
        setData((oldData) => {
            return {
                ...oldData,
                docElementId: data.docElementId,
                docElementType: data.docElementType,
            }
        })
    }

    const activeStepIndex: number = useMemo(() => {
        let activeStepIndex = 0
        steps.forEach((st, index) => {
            if (st === step) activeStepIndex = index
        })

        return activeStepIndex
    }, [steps, step])

    return (
        <>
            <Stepper activeStep={activeStepIndex} alternativeLabel style={{ minWidth: 500, padding: 0 }}>
                {steps.map((stepLabel) => (
                    <Step key={stepLabel}>
                        <StepLabel>{stepLabel}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Spacer />
            {step === DocumentEditSteps.UPLOAD && (
                <DocumentEditFile
                    data={data}
                    document={document}
                    setData={setData}
                    closeDialog={props.closeDialog}
                    goNext={goNext}
                />
            )}
            {step === DocumentEditSteps.INFORMATION && (
                <DocumentEditInformation
                    data={data}
                    setData={setData}
                    closeDialog={props.closeDialog}
                    goPrevious={goPrevious}
                    canGoPrevious={canGoPrevious}
                    goNext={goNext}
                />
            )}
            {step === DocumentEditSteps.ASSOCIATION && (
                <DocumentManagerAssociation
                    data={data}
                    closeDialog={props.closeDialog}
                    setData={setAssociationData}
                    goPrevious={goPrevious}
                    canGoPrevious={canGoPrevious}
                    goNext={goNext}
                    canGoNext={canGoNext}
                />
            )}
            {step === DocumentEditSteps.LOADING && (
                <DocumentEditUploading
                    data={data}
                    document={document}
                    closeDialog={props.closeDialog}
                    onDone={props.onDone}
                    setData={setData}
                    mode={props.mode}
                />
            )}
        </>
    )
}

/**************
 * UTILS
 *************/

export enum DocumentEditSteps {
    UPLOAD = 'Fichier',
    INFORMATION = 'Informations',
    ASSOCIATION = 'Association',
    LOADING = 'Envoi',
}

const getTitle = (mode: DocumentEditMode): string => {
    switch (mode) {
        case DocumentEditMode.ANSWERING:
            return 'Répondre à une demande'
        case DocumentEditMode.EDITING:
            return 'Modifier un document'
        case DocumentEditMode.VALIDATING:
            return "Validation d'un document"
        default:
            return 'Inconnu'
    }
}

const getSteps = (mode: DocumentEditMode): DocumentEditSteps[] => {
    switch (mode) {
        case DocumentEditMode.ANSWERING:
            return [
                DocumentEditSteps.UPLOAD,
                DocumentEditSteps.INFORMATION,
                DocumentEditSteps.ASSOCIATION,
                DocumentEditSteps.LOADING,
            ]
        case DocumentEditMode.EDITING:
            return [
                DocumentEditSteps.UPLOAD,
                DocumentEditSteps.INFORMATION,
                DocumentEditSteps.ASSOCIATION,
                DocumentEditSteps.LOADING,
            ]
        case DocumentEditMode.VALIDATING:
            return [DocumentEditSteps.INFORMATION, DocumentEditSteps.ASSOCIATION, DocumentEditSteps.LOADING]
        default:
            return []
    }
}

export default DocumentEdit
