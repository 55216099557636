import DisasterCategory from './DisasterCategory'
import DisasterDamage from './DisasterDamage'
import DisasterEvent from './DisasterEvent'
import DisasterPayment from './DisasterPayment'
import Disaster_Documents from './Disaster_Documents'
import Disaster_Full from './Disaster_Full'
import Disaster_Minimal from './Disaster_Minimal'

const DisasterFragments = {
    Disaster_Full,
    Disaster_Minimal,
    Disaster_Documents,
    DisasterCategory,
    ...DisasterPayment,
    ...DisasterDamage,
    ...DisasterEvent,
}

export default DisasterFragments
