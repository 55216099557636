import { gql } from "@apollo/client/core";

export default gql`
    mutation ForgotPassword($email: String!) {
        forgottenPassword(input: { email: $email }) {
            errors
            status
            statusCode
        }
    }
`
