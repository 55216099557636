import { Outlet, useParams } from 'react-router-dom'
import { MatxLayout } from '../../../../matx'
import LoadingDisplay from '../../../components/LoadingDisplay'
import ErrorDisplay from '../../../helpers/errors/ErrorDisplay'
import useCommonData from '../../../hooks/useCommonData'
import useContracts, { ProvideContracts } from '../../../hooks/useContracts'
import useCustomer, { ProvideCustomer } from '../../../hooks/useCustomer'
import useCustomers from '../../../hooks/useCustomers'
import { ProvideDisasters, useDisasters } from '../../../hooks/useDisasters'
import { NavType } from '../../../hooks/useNavigation'
import useSites, { ProvideSites } from '../../../hooks/useSites'

const AEACustomerZone = () => {
    // Deserves to update the customerId according to URL params
    const { customerId } = useParams()

    return customerId !== undefined ? (
        <ProvideCustomer customerId={customerId}>
            <ProvideSites customerId={customerId}>
                <ProvideDisasters customerId={customerId}>
                    <ProvideContracts customerId={customerId}>
                        <AEACustomerZoneCustomerLoader />
                    </ProvideContracts>
                </ProvideDisasters>
            </ProvideSites>
        </ProvideCustomer>
    ) : (
        <ErrorDisplay message="Adresse incorrected" />
    )
}

const AEACustomerZoneCustomerLoader = () => {
    const customersProps = useCustomers()
    const customerProps = useCustomer()
    const disastersProps = useDisasters()
    const commonProps = useCommonData()
    const sitesProps = useSites()
    const contractsProps = useContracts()

    const children =
        customerProps.customerLoading ||
        disastersProps.disastersLoading ||
        customersProps.customersLoading ||
        commonProps.commonDataLoading ||
        sitesProps.sitesLoading ||
        contractsProps.contractsLoading ? (
            <LoadingDisplay />
        ) : customerProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement du client"
                queryError={customerProps.error}
                debug={customerProps.error}
            />
        ) : customersProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement des clients"
                queryError={customersProps.error}
                debug={customersProps.error}
            />
        ) : disastersProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement des sinistres"
                queryError={disastersProps.error}
                debug={disastersProps.error}
            />
        ) : commonProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement des données"
                queryError={commonProps.error}
                debug={commonProps.error}
            />
        ) : sitesProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement des sites"
                queryError={sitesProps.error}
                debug={sitesProps.error}
            />
        ) : contractsProps.error ? (
            <ErrorDisplay
                message="Erreur lors du chargement des contrats"
                queryError={contractsProps.error}
                debug={contractsProps.error}
            />
        ) : !customerProps.customer ? (
            <ErrorDisplay
                message={'Erreur lors du chargement du client'}
                debug={{
                    customerProps,
                    commonProps,
                    customersProps,
                    sitesProps,
                    contractsProps,
                }}
            />
        ) : (
            <Outlet />
        )

    return (
        <MatxLayout
            zone={NavType.aeaCustomer}
            customers={customersProps.customers || []}
            customer={customerProps.customer}>
            {children}
        </MatxLayout>
    )
}

export default AEACustomerZone
