import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'

type CustomButtonProps = ButtonProps & {
    loading?: boolean
}

const CustomButton = ({ loading, disabled, children, ...buttonProps }: CustomButtonProps) => {
    const loadingNode = loading ? <CircularProgress className="ml-2" style={{ width: 18, height: 18 }} /> : null

    return (
        <Button {...buttonProps} disabled={disabled || loading}>
            {children}
            {loadingNode}
        </Button>
    )
}

export default CustomButton
